import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import TaskIcon from "@mui/icons-material/Task";
import {
    Card,
    CardActions,
    CardContent,
    Drawer,
    TextField,
    makeStyles,
} from "@mui/material";
import DonutChart from "react-donut-chart";
import Sidebar from "../Recruitment Sidebar/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import Overview from "../Overview/Overview";
import { useEffect } from "react";
import "./home.css";
import CssBaseline from "@mui/material/CssBaseline";
import RecruitmentRoutes from '../RecruitmentRoutes'

// import RoutesComponent from "../../RoutesComponent/RoutesComponent";

const drawerWidth = 250;

const drawer = <Sidebar />;

var container = undefined;

const data = [
    { id: 0, value: 35, label: "LinkedIn" },
    { id: 1, value: 26, label: "Naukari" },
    { id: 2, value: 28, label: "Glassdoor" },
    { id: 3, value: 11, label: "Others" },
];

const size = {
    width: 370,
    height: 220,
};

export default function RecHome() {
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        navigate("overview");
    }, []);
    return (
        <Box sx={{ display: "flex" }}>
            {/* <CssBaseline /> */}
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >
                <Toolbar
                    sx={{
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{
                            "@media (min-width: 768px)": {
                                display: "none",
                            },
                        }}
                    >
                        <MenuIcon className="menu-icon" />
                    </IconButton>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "@media (max-width: 600px)": {
                                padding: "10px",
                            },

                            "@media (min-width: 961px) and (max-width: 1024px)": {
                                padding: "10px",
                            },
                            "@media (min-width: 1440px)": {
                                padding: "10px",
                            },
                            "@media (min-width: 2560px)": {
                                padding: "20px",
                            },
                        }}
                    >
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                color: "black",
                                fontWeight: "600",
                                fontStyle: "bold",
                                fontFamily: "sans-serif",
                                marginTop: 2,
                                "@media (max-width: 600px)": {
                                    fontSize: "18px",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)": {
                                    fontSize: "20px",
                                },
                                "@media (min-width: 961px) and (max-width: 1024px)": {
                                    fontSize: "24px",
                                },
                                "@media (min-width: 1440px)": {
                                    fontSize: "26px",
                                },
                                "@media (min-width: 2560px)": {
                                    fontSize: "50px",
                                },
                            }}
                        >
                            Recruitment Dashboard
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            border: "2px solid transparent",
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            {/* <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: "2px solid transparent",
          },
        }}
        open
      >
       
      </Drawer> */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 2,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    backgroundColor: "#f0f0f0",
                }}
            >
                <Toolbar />

                {/*----------------ROUTE COMPONENTS--------------- */}
                {/* <RecruitmentRoutes /> */}
                <Outlet />
            </Box>
        </Box>
    );
}