import React, { useState } from "react";
import Slider from "react-slick";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import ViewAllJobs from "./ViewAllJobs/ViewAllJobs";
import JobCard from "./JobCard/JobCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

const formatDateToMMDDYYYY = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`.padStart(2, "0");
  let day = `${d.getDate()}`.padStart(2, "0");
  let year = d.getFullYear();
  return `${month}/${day}/${year}`;
};

const ManageJob = () => {
  const navigate = useNavigate();
  const currentDate = new Date();
  const oneMonthBackDate = new Date(currentDate);
  oneMonthBackDate.setMonth(oneMonthBackDate.getMonth() - 1);

  const formattedCurrentDate = formatDateToMMDDYYYY(currentDate);
  const formattedOneMonthBackDate = formatDateToMMDDYYYY(oneMonthBackDate);

  const jobsStatusCounts = useSelector(
    (state) => state.Jobs.jobsStatusCounts || []
  );

  const [selectedCard, setSelectedCard] = useState("Open");
  const [startDate, setStartDate] = useState(
    new Date(formattedOneMonthBackDate)
  );
  const [endDate, setEndDate] = useState(new Date(formattedCurrentDate));
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const [view, setView] = useState(() => {
    const storedView = localStorage.getItem("view");
    return storedView || "list";
  });

  const handleCardClick = (status) => {
    setSelectedCard(status);
  };

  const handleDateChange = (name, value) => {
    const newDate = new Date(value).toUTCString(); // Convert to UTC string
    const utcNewDate = new Date(newDate); // Convert back to Date object if needed

    if (name === "startDate") {
      if (utcNewDate > endDate) {
        setStartDateError("Start Date should not be greater than End Date.");
      } else {
        setStartDateError("");
        setStartDate(utcNewDate); // Ensure the date is stored in UTC
      }
    } else if (name === "endDate") {
      if (utcNewDate < startDate || utcNewDate <= startDate) {
        setEndDateError("End Date should not be less than Start Date.");
      } else {
        setEndDateError("");
        setEndDate(utcNewDate); // Ensure the date is stored in UTC
      }
    }
  };

  const getColorByStatus = (status) => {
    switch (status) {
      case "Open":
        return "#00C8A8"; //CDFAE4
      case "On-Hold":
        return "#FFD966"; // FDF3D7
      case "Closed":
        return "#F7B7B0"; //F9EAE8
      case "Planned":
        return "#B3D7FF"; //E5F2FF
      default:
        return "#E5E0D6"; //faf7f0
    }
  };

  const getIconBasedOnStatus = (status) => {
    switch (status) {
      case "Open":
        return <WorkOutlineIcon />;
      case "Closed":
        return <DoNotDisturbAltIcon />;
      case "On-Hold":
        return <PendingActionsIcon />;
      case "Planned":
        return <EventAvailableIcon />;
      default:
        return <ScheduleIcon />;
    }
  };

  const getFormattedDate = (date) => {
    return formatDateToMMDDYYYY(date);
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    // slidesToScroll: 1,
    arrows: jobsStatusCounts.length > 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "100%",
        "@media (min-width: 2560px)": {},
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "5px",
          marginBottom: "25px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="date-range-container">
            <DatePicker
              label="Start Date"
              value={startDate}
              maxDate={new Date()}
              onChange={(date) => handleDateChange("startDate", date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "195px", margin: "5px" }}
                  error={!!startDateError}
                />
              )}
            />
            <span className="date-error-helper-text">{startDateError}</span>
          </div>
          <span>-</span>
          <div className="date-range-container">
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => handleDateChange("endDate", date)}
              maxDate={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "195px", margin: "5px" }}
                  error={!!endDateError}
                />
              )}
            />
            <span className="date-error-helper-text">{endDateError}</span>
          </div>
        </LocalizationProvider>
      </Box>

      {jobsStatusCounts.length > 4 ? (
        <div style={{ width: "97%", margin: "0 auto" }}>
          <Slider {...sliderSettings}>
            {jobsStatusCounts.map((card) => (
              <div
                key={card.name}
                className={
                  selectedCard === card.name ? "card cardSelected" : "card"
                }
                onClick={() => handleCardClick(card.name)}
              >
                <div
                  className="job-status-row-1"
                  style={{ backgroundColor: getColorByStatus(card.name) }}
                >
                  {getIconBasedOnStatus(card.name)}
                </div>
                <div
                  className="job-status-row-2"
                  style={{ textAlign: "center" }}
                >
                  <div>{card.name}</div>
                  <div>{card.count}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          {jobsStatusCounts.map((card) => (
            <div
              key={card.name}
              className={
                selectedCard === card.name ? "card cardSelected" : "card"
              }
              onClick={() => handleCardClick(card.name)}
            >
              <div
                className="job-status-row-1"
                style={{ backgroundColor: getColorByStatus(card.name) }}
              >
                {getIconBasedOnStatus(card.name)}
              </div>
              <div className="job-status-row-2" style={{ textAlign: "center" }}>
                <div>{card.name}</div>
                <div>{card.count}</div>
              </div>
            </div>
          ))}
        </Grid>
      )}

      {view === "list" ? (
        <ViewAllJobs
          status={selectedCard}
          startDate={getFormattedDate(startDate)}
          endDate={getFormattedDate(endDate)}
        />
      ) : (
        <JobCard data={[]} filterStart={false} />
      )}
    </Box>
  );
};

export default ManageJob;
