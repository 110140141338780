import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import Divider from "@mui/material/Divider";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Loader from "../../../Loader/Loader";

import "./index.css";

const JobCard = ({ data, filterStart }) => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.Data);
  const loadingData = useSelector((state) => state.Data);
  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("role").split(",");
  useEffect(() => {
    //dispatch(fetchData());
  }, [dispatch]);

  return (
    <Loader
      isLoading={loadingData.loading === true}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div className="job-cards-container">
        {!filterStart &&
          Data.Resources.job.map((each) => {
            return (
              <div className="job-grid-container">
                <div className="job-card">
                  <div className="status-priority-Button-Card">
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        color: "#32CD32",
                        border: "1.5px solid #32CD32",
                        height: "22px",
                        fontWeight: 500,
                        fontSize: "14px",
                        backgroundColor: "#dcfadc",
                        textTransform: "capitalize",
                        "@media (min-width: 2560px)": {
                          height: "60px",
                          width: "120px",
                          fontSize: "25px",
                        },
                      }}
                    >
                      {each.status.value}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        color: "#fff",
                        border: "1.5px solid #FFA500",
                        height: "22px",
                        fontWeight: 500,
                        fontSize: "14px",
                        backgroundColor: "#FFA500",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#FFA500",
                          cursor: "pointer",
                        },
                        "@media (min-width: 2560px)": {
                          height: "60px",
                          width: "120px",
                          fontSize: "25px",
                        },
                      }}
                    >
                      {each.jobPriority.value}
                    </Button>
                  </div>
                  <div className="job-details-container">
                    <div className="job-heading-container">
                      <h1 className="job-heading-text">{each.jobTitle}</h1>
                    </div>
                    <div className="job-skills-desc-container">
                      <ul className="job-require-skills-list">
                        {each.requiredSkills.slice(0, 3).map((e) => (
                          <span className="skills-item">{e.value}</span>
                        ))}
                        {each.requiredSkills.length > 3 && (
                          <span className="skills-item skills-item-count">
                            +{each.requiredSkills.length - 3}
                          </span>
                        )}
                      </ul>
                      <p className="job-desc-text">
                        {each.jobDescription.substr(0, 60)}
                        {each.jobDescription.length > 60 ? "..." : ""}
                      </p>
                    </div>
                    <br />
                    <Divider variant="middle" />
                    {userRole.some((role) =>
                      ["ADMIN", "HR"].includes(role)
                    ) && (
                      <div className="view-job-container">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 1,
                          }}
                        >
                          <Button
                            onClick={() => navigate(`/editJob/${each._id}`)}
                            sx={{
                              fontWeight: "500",
                              fontSize: "17px",
                              "&:hover": {
                                backgroundColor: "#dcdcdc ",
                                cursor: "pointer",
                              },
                              "@media (max-width: 768px)": {
                                width: "150px",
                                fontSize: "15px",
                                fontWeight: 500,
                                marginTop: "-10px",
                                marginBottom: 1,
                              },
                              "@media (min-width: 768px) and (max-width: 1024px)":
                                {
                                  width: "150px",
                                  fontSize: "15px",
                                  fontWeight: 500,
                                  marginBottom: 3,
                                },
                              "@media (min-width: 2560px)": {
                                height: "120px",
                                width: "250px",
                                fontSize: "33px",
                                fontWeight: 500,
                              },
                            }}
                          >
                            VIEW JOB
                          </Button>
                        </Box>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

        {data.length > 0 &&
          data.map((each) => {
            return (
              <div className="card_grid">
                <NavLink to={`/job_details/${each._id}`} className="navLink">
                  <div className="card-client">
                    <div className="ActiveButtonCard">
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          color: "#32CD32",
                          border: "1.5px solid #32CD32",
                          height: "21px",
                          fontWeight: 500,
                          fontSize: "13px",
                          backgroundColor: "#dcfadc",
                          textTransform: "capitalize",
                        }}
                      >
                        Active
                      </Button>
                      <MoreHorizIcon
                        sx={{
                          display: "none",
                          marginLeft: "10px",
                          color: "black",
                        }}
                      />
                    </div>

                    <div className="user-picture">
                      <img
                        src="https://cdn.pixabay.com/photo/2016/08/31/11/54/icon-1633249_960_720.png"
                        alt="userImage"
                      />
                    </div>

                    <p className="name-client">
                      {each.FirstName}
                      <span className="employee-Role">{each.Role.value}</span>
                    </p>

                    <div className="card">
                      <div className="employeeDetails">
                        <div className="employee-department-card">
                          <h3>Department</h3>
                          <p>{each.Department.value}</p>
                        </div>

                        <div className="employee-date-hired-card">
                          <h3>Date Hired</h3>
                          <p>{each.Details.Advance.DOJ}</p>
                        </div>
                      </div>
                      <Divider sx={{ marginTop: "-15px" }} />

                      <div className="employeelinks">
                        <div className="employeelinkcard">
                          <MarkunreadIcon sx={{ color: "black" }} />
                          <h6>{each.Details.Advance.CompanyEmail}</h6>
                        </div>

                        <div className="employeelinkcard">
                          <AddIcCallIcon sx={{ color: "black" }} />
                          <h6>+91 {each.Details.Advance.ContactNumber}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            );
          })}

        {data.length === 0 && filterStart && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
              backgroundColor: "#f0f0f0",
            }}
          >
            <img
              src="https://res.cloudinary.com/dcci6hybb/image/upload/v1677679606/no_data_found_r6ayov.webp"
              alt="no data found"
              className="nodata-img"
            />
          </Box>
        )}
      </div>
    </Loader>
  );
};

export default JobCard;
