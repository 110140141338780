import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import DataGridDemo from './MyReferralTable';
const MyReferral = () => {
    const [referrals, setreferrals] = useState(0)
    return (
        <Box sx={{ paddingTop: '8px' }}>
            <Card sx={{}}>
                <CardContent>
                    <Typography variant='h6'>My Referrals</Typography>
                </CardContent>
                <CardContent>
                    <Typography>Number of referrals:{referrals}</Typography>
                </CardContent>
                <DataGridDemo />
            </Card>
        </Box>
    )
}
export default MyReferral;