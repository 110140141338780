import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./AddApplication/index.css";
import "./jobApplication.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, MenuItem, Select } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Rating } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  getAllApplications,
  addJobApplication,
  getAllEmployeeData,
  getAllEmployeeByRegion,
  tabIndex,
  getActiveJobs,
} from "../../../../redux/dataSlice";
import FormControl from "@mui/material/FormControl";
import { fetchData } from "../../../../redux/dataSlice";

export const ScreeningDetailsForm = ({
  onSubmit,
  initialValues,
  enableScreening,
}) => {
  console.log(
    "status changed in initial screening=========================================================================",
    enableScreening
  );
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState("panel1");
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [formData, setFormData] = useState({
    level1: {
      date: "",
      takenBy: null,
      feedback: "",
      status: "",
      technicalSkillsRating: "",
      softSkillsRating: "",
    },
    level2: {
      date: "",
      takenBy: null,
      feedback: "",
      status: "",
      technicalSkillsRating: "",
      softSkillsRating: "",
    },
    client: {
      date: "",
      takenBy: null,
      feedback: "",
      status: "",
      clientRating: "",
    },
    hr: {
      date: "",
      takenBy: null,
      feedback: "",
      status: "",
      performanceRating: "",
      professionalisimRating: "",
    },
    remark: {
      status: "",
      remarks: "",
      isOnBoarded: false,
      onBoardedDate: null,
      jobOffered: false,
    },
  });
  const [level2FieldsTouched, setLevel2FieldsTouched] = useState(false);
  const [clientScreeningFieldsTouched, setClientScreeningFieldsTouched] =
    useState(false);
  const [hrScreeningFieldsTouched, setHrScreeningFieldsTouched] =
    useState(false);
  const [dataExists, setDataExists] = useState({
    level1: false,
    level2: false,
    client: false,
    hr: false,
  });
  const [errors, setErrors] = useState({
    leve1Errors: {},
    level2Errors: {},
    clientErrors: {},
    hrErrors: {},
    remarksErrors: {},
  });

  const [isLevel2Unlocked, setIsLevel2Unlocked] = useState(false);
  const [isClientScreeningUnlocked, setIsClientScreeningUnlocked] =
    useState(false);
  const [isHrScreeningUnlocked, setIsHrScreeningUnlocked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [dialogId, setDialogId] = useState("leve1");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDialogNo = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      level1: {
        ...prevFormData.level1,
        status: "Selected",
      },
    }));
    setOpen(false);
  };

  const handleDialogYes = () => {
    if (dialogId === "level1") {
      clearFieldsOnL1Change();
      setErrors((prevErrors) => ({
        ...prevErrors,
        level2Errors: {},
      }));
      setIsLevel2Unlocked(false);
    } else if (dialogId === "level2") {
      clearFieldsOnL2Change();
      setErrors((prevErrors) => ({
        ...prevErrors,
        clientErrors: {},
      }));
      setIsClientScreeningUnlocked(false);
      setIsHrScreeningUnlocked(false);
    } else if (dialogId === "client") {
      clearFieldsOnClientChange();
      setErrors((prevErrors) => ({
        ...prevErrors,
        hrErrors: {},
      }));
      setIsHrScreeningUnlocked(false);
    }
    handleClose();
  };

  const clearFieldsOnL1Change = () => {
    setFormData((prevData) => ({
      ...prevData,
      hr: {
        date: "",
        takenBy: null,
        feedback: "",
        status: "",
        performanceRating: "",
        professionalisimRating: "",
      },
      level2: {
        date: "",
        takenBy: null,
        feedback: "",
        status: "",
        technicalSkillsRating: "",
        softSkillsRating: "",
      },
      client: {
        date: "",
        takenBy: null,
        feedback: "",
        status: "",
        clientRating: "",
      },
    }));
  };
  const clearFieldsOnL2Change = () => {
    setFormData((prevData) => ({
      ...prevData,
      client: {
        date: "",
        takenBy: null,
        feedback: "",
        status: "",
        clientRating: "",
      },
      hr: {
        date: "",
        takenBy: null,
        feedback: "",
        status: "",
        performanceRating: "",
        professionalisimRating: "",
      },
    }));
  };
  const clearFieldsOnClientChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      hr: {
        date: "",
        takenBy: null,
        feedback: "",
        status: "",
        performanceRating: "",
        professionalisimRating: "",
      },
    }));
  };

  const checkLevel1Completion = () => {
    const {
      date,
      takenBy,
      feedback,
      status,
      technicalSkillsRating,
      softSkillsRating,
    } = formData.level1;

    return (
      date !== "" &&
      takenBy !== null &&
      feedback !== "" &&
      status === "Selected" &&
      technicalSkillsRating !== 0 &&
      softSkillsRating !== 0
    );
  };
  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  console.log(selectedRegion?._id, "regions2");
  useEffect(() => {
    const isLevel1Complete = checkLevel1Completion();
    setIsLevel2Unlocked(isLevel1Complete);
    setIsClientScreeningUnlocked(isLevel1Complete);
    setIsHrScreeningUnlocked(isLevel1Complete);
  }, [formData.level1]);

  useEffect(() => {
    const { hr } = formData;
    let newRemarksStatus = "";
    if (hr.status === "Rejected" && hrScreeningFieldsTouched) {
      newRemarksStatus = "Rejected";
    } else if (hr.status === "Pending" && hrScreeningFieldsTouched) {
      newRemarksStatus = "Pending";
    } else if (hr.status === "Selected" && hrScreeningFieldsTouched) {
      newRemarksStatus = "Selected";
    } else if (hrScreeningFieldsTouched === false && !formData.hr.status) {
      newRemarksStatus = "";
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      remark: {
        ...prevFormData.remark,
        status: newRemarksStatus,
      },
    }));
  }, [formData.hr.status, hrScreeningFieldsTouched]);

  useEffect(() => {
    if (formData.level1.status !== "Selected") {
      setDialogId("level1");
      setIsLevel2Unlocked(false);
      setIsClientScreeningUnlocked(false);
      setIsHrScreeningUnlocked(false);

      // setLevel2FieldsTouched(false);
      // setClientScreeningFieldsTouched(false);
      // setHrScreeningFieldsTouched(false);
      if (
        level2FieldsTouched ||
        clientScreeningFieldsTouched ||
        hrScreeningFieldsTouched ||
        dataExists.level2
      ) {
        handleClickOpen();
      }
      setLevel2FieldsTouched(false);
      setClientScreeningFieldsTouched(false);
      setClientScreeningFieldsTouched(false);
    } else {
      setIsLevel2Unlocked(true);
      setIsClientScreeningUnlocked(true);
      setIsHrScreeningUnlocked(true);
    }
  }, [formData.level1.status]);
  useEffect(() => {
    if (formData.level2.status !== "Selected") {
      setDialogId("level2");
      setIsClientScreeningUnlocked(false);
      setIsHrScreeningUnlocked(false);
      setClientScreeningFieldsTouched(false);
      setHrScreeningFieldsTouched(false);
      if (
        clientScreeningFieldsTouched ||
        hrScreeningFieldsTouched ||
        dataExists.client
      ) {
        handleClickOpen();
      }
    } else {
      setIsClientScreeningUnlocked(true);
      setIsHrScreeningUnlocked(true);
    }
  }, [formData.level2.status]);
  useEffect(() => {
    if (formData.client.status !== "Selected") {
      setDialogId("client");
      setHrScreeningFieldsTouched(false);
      setIsHrScreeningUnlocked(false);
      if (hrScreeningFieldsTouched || dataExists.hr) {
        handleClickOpen();
      }
    } else {
      setIsHrScreeningUnlocked(true);
    }
  }, [formData.client.status]);

  useEffect(() => {
    if (!initialValues) {
      if (formData.remark.status === "Selected") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          remark: {
            ...prevFormData.remark,
            jobOffered: true,
          },
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          remark: {
            ...prevFormData.remark,
            jobOffered: false,
          },
        }));
      }
    }
  }, [formData.remark.status]);
  useEffect(() => {
    dispatch(fetchData());
    if (selectedRegion && selectedRegion?._id) {
      dispatch(getAllEmployeeByRegion({ id: selectedRegion._id }));
      dispatch(getActiveJobs(selectedRegion._id));
    }
  }, [dispatch, selectedRegion]);

  useEffect(() => {
    if (initialValues) {
      setFormData({
        level1: {
          ...initialValues.level1,
          date: initialValues?.level1?.date
            ? new Date(initialValues.level1.date).toISOString().split("T")[0]
            : "",
          takenBy:
            typeof initialValues?.level1?.takenBy === "object"
              ? initialValues?.level1?.takenBy?._id
              : initialValues?.level1?.takenBy || null,
          feedback: initialValues?.level1?.feedback || "",
          status: initialValues?.level1?.status || "",
          technicalSkillsRating:
            initialValues?.level1?.technicalSkillsRating || 0,
          softSkillsRating: initialValues?.level1?.softSkillsRating || 0,
        },
        level2: {
          ...initialValues.level2,
          date: initialValues?.level2?.date
            ? new Date(initialValues.level2.date).toISOString().split("T")[0]
            : "",
          takenBy:
            typeof initialValues?.level2?.takenBy === "object"
              ? initialValues.level2?.takenBy?._id
              : initialValues?.level2?.takenBy || null,
          feedback: initialValues?.level2?.feedback || "",
          status: initialValues?.level2?.status || "",
          technicalSkillsRating:
            initialValues?.level2?.technicalSkillsRating || 0,
          softSkillsRating: initialValues?.level2?.softSkillsRating || 0,
        },
        client: {
          ...initialValues.client,
          date: initialValues?.client?.date
            ? new Date(initialValues.client.date).toISOString().split("T")[0]
            : "",
          takenBy:
            typeof initialValues?.client?.takenBy === "object"
              ? initialValues?.client?.takenBy?._id
              : initialValues?.client?.takenBy || null,
          feedback: initialValues?.client?.feedback || "",
          status: initialValues?.client?.status || "",
          clientRating: initialValues?.client?.clientRating || 0,
        },
        hr: {
          ...initialValues.hr,
          date: initialValues?.hr?.date
            ? new Date(initialValues.hr.date).toISOString().split("T")[0]
            : "",
          takenBy:
            typeof initialValues?.hr?.takenBy === "object"
              ? initialValues?.hr?.takenBy?._id
              : initialValues?.hr?.takenBy || null,
          feedback: initialValues?.hr?.feedback || "",
          status: initialValues?.hr?.status || "",
          performanceRating: initialValues?.hr?.performanceRating || 0,
          professionalisimRating:
            initialValues?.hr?.professionalisimRating || 0,
        },
        remark: {
          ...initialValues.remark,
          status: initialValues?.remark?.status || "",
          remarks: initialValues?.remark?.remarks || "",
          isOnBoarded: initialValues?.remark?.isOnBoarded || false,
          onBoardedDate: initialValues?.remark?.onBoardedDate || null,
          jobOffered: initialValues?.remark?.jobOffered || false,
        },
      });

      if (
        initialValues?.level1?.date ||
        initialValues?.level1?.feedback ||
        initialValues?.level1?.softSkillsRating ||
        initialValues?.level1?.status ||
        initialValues?.level1?.takenBy ||
        initialValues?.level1?.technicalSkillsRating
      ) {
        setDataExists((prevState) => ({
          ...prevState,
          ["level1"]: true,
        }));
      }
      if (
        initialValues?.level2?.date ||
        initialValues?.level2?.feedback ||
        initialValues?.level2?.softSkillsRating ||
        initialValues?.level2?.status ||
        initialValues?.level2?.takenBy ||
        initialValues?.level2?.technicalSkillsRating
      ) {
        setDataExists((prevState) => ({
          ...prevState,
          ["level2"]: true,
        }));
      }
      if (
        initialValues?.client?.date ||
        initialValues?.client?.feedback ||
        initialValues?.client?.status ||
        initialValues?.client?.takenBy ||
        initialValues?.client?.clientRating
      ) {
        setDataExists((prevState) => ({
          ...prevState,
          ["client"]: true,
        }));
      }
      if (
        initialValues?.hr?.date ||
        initialValues?.hr?.feedback ||
        initialValues?.hr?.status ||
        initialValues?.hr?.takenBy ||
        initialValues?.hr?.performanceRating ||
        initialValues?.hr?.professionalisimRating
      ) {
        setDataExists((prevState) => ({
          ...prevState,
          ["hr"]: true,
        }));
      }
    }
  }, [initialValues]);

  useEffect(() => {
    setIsSubmitEnabled(isFormValid());
  }, [formData]);

  const employees = useSelector(
    (state) => state?.Data?.Resources?.employee?.data
  );

  const validate = async () => {
    const newErrors = {
      leve1Errors: {},
      level2Errors: {},
      clientErrors: {},
      hrErrors: {},
      remarksErrors: {},
    };
    console.log("validate function triggered");
    // const level2HasData = Object.values(formData.level2).some(
    //   (
    //   (value)) =>

    //     value !== "" && value !== undefined && value !== null && value !== 0

    // );
    const level2HasData = Object.values(formData.level2).some(
      (value) =>
        value !== "" && value !== undefined && value !== null && value !== 0
    );
    console.log("level 2 has data", level2HasData);
    setLevel2FieldsTouched(level2HasData);
    const clientHasData = Object.values(formData.client).some(
      (value) =>
        value !== "" && value !== undefined && value !== null && value !== 0
    );
    setClientScreeningFieldsTouched(clientHasData);
    const hrHasData = Object.values(formData.hr).some(
      (value) =>
        value !== "" && value !== undefined && value !== null && value !== 0
    );
    setHrScreeningFieldsTouched(hrHasData);

    // await Promise.all([
    //   setLevel2FieldsTouched(level2HasData),
    //   setClientScreeningFieldsTouched(clientHasData),
    //   setHrScreeningFieldsTouched(hrHasData)
    // ]);

    if (!formData.level1.date.trim())
      newErrors.leve1Errors.screeningDate = "Screening Date is required";
    if (!formData.level1.takenBy)
      newErrors.leve1Errors.screeningBy = "Screening By is required";
    if (!formData.level1.feedback.trim())
      newErrors.leve1Errors.feedback = "Feedback is required";
    if (!formData.level1.status.trim())
      newErrors.leve1Errors.status = "Status is required";
    if (!formData.level1.technicalSkillsRating)
      newErrors.leve1Errors.technicalSkillsRating =
        "technical Skills Rating is required";
    if (!formData.level1.softSkillsRating)
      newErrors.leve1Errors.softSkillsRating = "SoftSkills Rating is required";

    if (level2FieldsTouched && !formData.level2.date.trim())
      newErrors.level2Errors.screeningDate = "Screening Date is required";
    if (level2FieldsTouched && !formData.level2.takenBy)
      newErrors.level2Errors.screeningBy = "Screening By is required";
    if (level2FieldsTouched && !formData.level2.feedback.trim())
      newErrors.level2Errors.feedback = "Feedback is required";
    if (level2FieldsTouched && !formData.level2.status.trim())
      newErrors.level2Errors.status = "Status is required";
    if (level2FieldsTouched && !formData.level2.technicalSkillsRating)
      newErrors.level2Errors.technicalSkillsRating =
        "technical Skills Rating is required";
    if (level2FieldsTouched && !formData.level2.softSkillsRating)
      newErrors.level2Errors.softSkillsRating = "SoftSkills Rating is required";

    if (clientScreeningFieldsTouched && !formData.client.date.trim())
      newErrors.clientErrors.screeningDate = "Screening Date is required";
    if (clientScreeningFieldsTouched && !formData.client.takenBy)
      newErrors.clientErrors.screeningBy = "Screening By is required";
    if (clientScreeningFieldsTouched && !formData.client.feedback.trim())
      newErrors.clientErrors.feedback = "Feedback is required";
    if (clientScreeningFieldsTouched && !formData.client.status.trim())
      newErrors.clientErrors.status = "Status is required";
    if (clientScreeningFieldsTouched && !formData.client.clientRating)
      newErrors.clientErrors.clientRating = "Client Rating is required";

    if (hrScreeningFieldsTouched && !formData.hr.date.trim())
      newErrors.hrErrors.screeningDate = "Screening Date is required";
    if (hrScreeningFieldsTouched && !formData.hr.takenBy)
      newErrors.hrErrors.screeningBy = "Screening By is required";
    if (hrScreeningFieldsTouched && !formData.hr.feedback.trim())
      newErrors.hrErrors.feedback = "Feedback is required";
    if (hrScreeningFieldsTouched && !formData.hr.status.trim())
      newErrors.hrErrors.status = "Status is required";
    if (hrScreeningFieldsTouched && !formData.hr.performanceRating)
      newErrors.hrErrors.performanceRating = "Performance Rating is required";
    if (hrScreeningFieldsTouched && !formData.hr.professionalisimRating)
      newErrors.hrErrors.professionalisimRating =
        "Professionalism Rating is required";

    // if (!formData.remark.status.trim())
    //   newErrors.remarksErrors.status = "Status is required";
    if (!formData.remark.remarks.trim())
      newErrors.remarksErrors.remarks = "Remarks is required";
    if (
      formData.remark.isOnBoarded === null ||
      formData.remark.isOnBoarded === ""
    )
      newErrors.remarksErrors.isOnBoarded = "isOnBoarded is required";
    if (
      formData.remark.jobOffered === null ||
      formData.remark.jobOffered === ""
    )
      newErrors.remarksErrors.jobOffered = "Job Offered is required";
    if (
      formData.remark.jobOffered === true &&
      formData.remark.status !== "Selected"
    ) {
      newErrors.remarksErrors.jobOffered =
        "Seems like the Applicant status is either 'Rejected' or 'Pending' in the previous stages. Please ensure the Applicant is 'Selected' before offering the job.";
    }
    if (
      formData.remark.jobOffered === false &&
      formData.remark.isOnBoarded === true
    ) {
      newErrors.remarksErrors.isOnBoarded =
        "Applicant cannot be Onboarded unless the Job Offered is 'Yes'. Please ensure the Job Offered is 'Yes' before onboarding.";
    }
    if (
      formData.remark.onBoardedDate === null &&
      formData.remark.isOnBoarded === true
    ) {
      newErrors.remarksErrors.onBoardedDate =
        "Please enter the on boarded date";
    }
    if (
      ((formData.level1.status && formData.level1.status !== "Selected") ||
        (formData.level2.status && formData.level2.status !== "Selected") ||
        (formData.client.status && formData.client.status !== "Selected")) &&
      formData.hr.status &&
      formData.hr.status === "Selected"
    ) {
      console.log(
        "l1",
        formData.level1.status,
        "l2",
        formData.level2.status,
        "client",
        formData.client.status,
        "hr",
        formData.hr.status,
        "formData",
        formData,
        "formData.hr.status"
      );

      newErrors.hrErrors.status =
        "Status cannot be selected if any of the above screening levels is rejected/pending";
    }
    setErrors(newErrors);

    const isErrorInLevel1 = Object.keys(newErrors.leve1Errors).length > 0;
    const isErrorInLevel2 = Object.keys(newErrors.level2Errors).length > 0;
    const isErrorInClientScreening =
      Object.keys(newErrors.clientErrors).length > 0;
    const isErrorInHRScreening = Object.keys(newErrors.hrErrors).length > 0;
    const isErrorInRemarks = Object.keys(newErrors.remarksErrors).length > 0;

    if (isErrorInLevel1) setExpanded("panel1");
    if (isErrorInLevel2) setExpanded("panel2");
    if (isErrorInClientScreening) setExpanded("panel3");
    if (isErrorInHRScreening) setExpanded("panel4");
    if (isErrorInRemarks) setExpanded("panel5");
    console.log("errors object", newErrors);
    return Object.values(newErrors).every(
      (errorObj) => Object.keys(errorObj).length === 0
    );
  };

  const handleChange = (section, field) => (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [field]: event.target.value,
      },
    }));
    if (section === "level2") {
      setLevel2FieldsTouched(true);
    } else if (section === "client") {
      setClientScreeningFieldsTouched(true);
    } else if (section === "hr") {
      setHrScreeningFieldsTouched(true);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    const isErrorInLevel1 = Object.keys(errors.leve1Errors).length > 0;
    const isErrorInLevel2 = Object.keys(errors.level2Errors).length > 0;
    const isErrorInClientScreening =
      Object.keys(errors.clientErrors).length > 0;
    const isErrorInHRScreening = Object.keys(errors.hrErrors).length > 0;
    const isErrorInRemarks = Object.keys(errors.remarksErrors).length > 0;

    if (panel === "panel1" && isErrorInLevel1) {
      setExpanded("panel1");
    } else if (panel === "panel2" && isErrorInLevel2) {
      setExpanded("panel2");
    } else if (panel === "panel3" && isErrorInClientScreening) {
      setExpanded("panel3");
    } else if (panel === "panel4" && isErrorInHRScreening) {
      setExpanded("panel4");
    } else if (panel === "panel5" && isErrorInRemarks) {
      setExpanded("panel5");
    } else {
      setExpanded(isExpanded ? panel : false);
    }
  };

  const handleRatingChange = (field, key) => (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        [field]: newValue,
      },
    }));
    if (key === "level2") {
      setLevel2FieldsTouched(true);
    } else if (key === "client") {
      setClientScreeningFieldsTouched(true);
    } else if (key === "hr") {
      setHrScreeningFieldsTouched(true);
    }
  };

  const handleSubmit = () => {
    const payload = { ...formData };
    console.log("validating in the handle submit before onsubmit", validate());
    if (validate()) {
      console.log("validating in the handle submit", validate());
      onSubmit(payload);
    }
  };

  const JobApplicationStatusData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.flatMap(
      (e) => e.JobApplicationStatus
    )
  );

  const isFormValid = () => {
    const { level1, remark } = formData;

    const isLevel1Complete =
      level1.date.trim() !== "" &&
      level1.takenBy !== null &&
      level1.feedback.trim() !== "" &&
      level1.status.trim() !== "" &&
      level1.technicalSkillsRating !== 0 &&
      level1.softSkillsRating !== 0;

    const isRemarksComplete =
      // remark.status.trim() !== "" &&
      remark.remarks.trim() !== "" &&
      remark.isOnBoarded !== null &&
      remark.jobOffered !== null;

    return isLevel1Complete && isRemarksComplete;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to change the status?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All the data related to the next screening levels will be reset.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogNo}>No</Button>
          <Button onClick={handleDialogYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Accordion
        expanded={
          (expanded === "panel1" && enableScreening) ||
          (Object.keys(errors.leve1Errors).length > 0 && enableScreening)
        }
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            enableScreening ? (
              <ExpandMoreIcon />
            ) : (
              <LockIcon sx={{ fontSize: "31px" }} />
            )
          }
        >
          <Typography
            style={{ padding: "16px", fontSize: "18px", fontWeight: "600" }}
          >
            Level1 Screening
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningDate">Screening Date</label>
              <span className="required-star">*</span>
              <TextField
                fullWidth
                name="screeningDate"
                variant="outlined"
                required
                type="date"
                value={formData.level1.date}
                onChange={handleChange("level1", "date")}
                error={!!errors?.leve1Errors?.screeningDate}
                helperText={errors?.leve1Errors?.screeningDate}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningBy">Screening By</label>
              <span className="required-star">*</span>
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.leve1Errors?.screeningBy}
                size="small"
              >
                <Select
                  value={formData.level1.takenBy}
                  onChange={handleChange("level1", "takenBy")}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflow: "auto",
                        marginTop: 8,
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {employees?.map((emp) => (
                    <MenuItem key={emp._id} value={emp._id}>
                      {emp.FirstName}
                      {emp.LastName}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.leve1Errors?.screeningBy && (
                  <Typography color="error">
                    {errors?.leve1Errors?.screeningBy}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="feedback">Feedback</label>
              <span className="required-star">*</span>
              <TextField
                fullWidth
                name="feedback"
                variant="outlined"
                required
                multiline
                rows={4}
                value={formData.level1.feedback}
                onChange={handleChange("level1", "feedback")}
                error={!!errors?.leve1Errors?.feedback}
                helperText={errors?.leve1Errors?.feedback}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="status">Status</label>
              <span className="required-star">*</span>
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.leve1Errors?.status}
                size="small"
              >
                <Select
                  value={formData.level1.status}
                  onChange={handleChange("level1", "status")}
                  displayEmpty
                >
                  {JobApplicationStatusData?.map((status) => (
                    <MenuItem key={status.name} value={status.value}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.leve1Errors?.status && (
                  <Typography color="error">
                    {errors?.leve1Errors?.status}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <label htmlFor="technicalSkillsRating">
                Technical Skills Rating
              </label>
              <span className="required-star">*</span>
              <Rating
                name="technicalSkillsRating"
                value={formData.level1.technicalSkillsRating}
                onChange={handleRatingChange("technicalSkillsRating", "level1")}
                size="small"
              />
              {errors?.leve1Errors?.technicalSkillsRating && (
                <Typography color="error">
                  {errors?.leve1Errors?.technicalSkillsRating}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <label htmlFor="softSkillsRating">Soft Skills Rating</label>
              <span className="required-star">*</span>
              <Rating
                name="softSkillsRating"
                value={formData.level1.softSkillsRating}
                onChange={handleRatingChange("softSkillsRating", "level1")}
                size="small"
              />
              {errors?.leve1Errors?.softSkillsRating && (
                <Typography color="error">
                  {errors?.leve1Errors?.softSkillsRating}
                </Typography>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={
          (expanded === "panel2" &&
            isLevel2Unlocked &&
            formData.level1.status === "Selected") ||
          (Object.keys(errors.level2Errors).length > 0 &&
            isLevel2Unlocked &&
            formData.level1.status === "Selected")
        }
        onChange={handleAccordionChange("panel2")}
      >
        <AccordionSummary
          expandIcon={
            formData.level1.status === "Selected" &&
            enableScreening &&
            isLevel2Unlocked ? (
              <ExpandMoreIcon />
            ) : (
              <LockIcon sx={{ fontSize: "31px" }} />
            )
          }
        >
          <Typography
            style={{ padding: "16px", fontSize: "18px", fontWeight: "600" }}
          >
            Level 2 Screening
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningDate">Screening Date</label>
              {/* {level2FieldsTouched || initialValues?.level2?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}

              <TextField
                fullWidth
                name="screeningDate"
                variant="outlined"
                required
                type="date"
                value={formData.level2.date}
                onChange={handleChange("level2", "date")}
                error={!!errors?.level2Errors?.screeningDate}
                helperText={errors?.level2Errors?.screeningDate}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningBy">Screening By</label>
              {/* {level2FieldsTouched || initialValues?.level2?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.level2Errors?.screeningBy}
                size="small"
              >
                <Select
                  value={formData.level2.takenBy}
                  onChange={handleChange("level2", "takenBy")}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflow: "auto",
                        marginTop: 8,
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {employees?.map((emp) => (
                    <MenuItem key={emp._id} value={emp._id}>
                      {emp.FirstName}
                      {emp.LastName}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.level2Errors?.screeningBy && (
                  <Typography color="error">
                    {errors?.level2Errors?.screeningBy}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="feedback">Feedback</label>
              {/* {level2FieldsTouched || initialValues?.level2?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <TextField
                fullWidth
                name="feedback"
                variant="outlined"
                required
                multiline
                rows={4}
                value={formData.level2.feedback}
                onChange={handleChange("level2", "feedback")}
                error={!!errors?.level2Errors?.feedback}
                helperText={errors?.level2Errors?.feedback}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="status">Status</label>
              {/* {level2FieldsTouched || initialValues?.level2?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.level2Errors?.status}
                size="small"
              >
                <Select
                  value={formData.level2.status}
                  onChange={handleChange("level2", "status")}
                  displayEmpty
                >
                  {JobApplicationStatusData?.map((status) => (
                    <MenuItem key={status.name} value={status.value}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.level2Errors?.status && (
                  <Typography color="error">
                    {errors?.level2Errors?.status}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <label htmlFor="technicalSkillsRating">
                Technical Skills Rating
              </label>
              <Rating
                name="technicalSkillsRating"
                value={formData.level2.technicalSkillsRating}
                onChange={handleRatingChange("technicalSkillsRating", "level2")}
                size="small"
              />
              {errors?.level2Errors?.technicalSkillsRating && (
                <Typography color="error">
                  {errors?.level2Errors?.technicalSkillsRating}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <label htmlFor="softSkillsRating">Soft Skills Rating</label>
              {/* {level2FieldsTouched || initialValues?.level2?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <Rating
                name="softSkillsRating"
                value={formData.level2.softSkillsRating}
                onChange={handleRatingChange("softSkillsRating", "level2")}
                size="small"
              />
              {errors?.level2Errors?.softSkillsRating && (
                <Typography color="error">
                  {errors?.level2Errors?.softSkillsRating}
                </Typography>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={
          (expanded === "panel3" &&
            isClientScreeningUnlocked &&
            formData.level1.status === "Selected") ||
          (Object.keys(errors.clientErrors).length > 0 &&
            isClientScreeningUnlocked &&
            formData.level1.status === "Selected")
        }
        onChange={handleAccordionChange("panel3")}
      >
        <AccordionSummary
          expandIcon={
            formData.level1.status === "Selected" &&
            isClientScreeningUnlocked &&
            enableScreening ? (
              <ExpandMoreIcon />
            ) : (
              <LockIcon sx={{ fontSize: "31px" }} />
            )
          }
        >
          <Typography
            style={{ padding: "16px", fontSize: "18px", fontWeight: "600" }}
          >
            Client Screening
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningDate">Screening Date</label>

              {/* {clientScreeningFieldsTouched ||
              initialValues?.client?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <TextField
                fullWidth
                name="screeningDate"
                variant="outlined"
                required
                type="date"
                value={formData.client.date}
                onChange={handleChange("client", "date")}
                error={!!errors?.clientErrors?.screeningDate}
                helperText={errors?.clientErrors?.screeningDate}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="client-takenBy">Taken By</label>
              {/* {clientScreeningFieldsTouched ||
              initialValues?.client?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <TextField
                fullWidth
                variant="outlined"
                value={formData.client.takenBy}
                onChange={handleChange("client", "takenBy")}
                error={!!errors?.clientErrors?.screeningBy}
                helperText={errors?.clientErrors?.screeningBy}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="feedback">Feedback</label>
              {/* {clientScreeningFieldsTouched ||
              initialValues?.client?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <TextField
                fullWidth
                name="feedback"
                variant="outlined"
                required
                multiline
                rows={4}
                value={formData.client.feedback}
                onChange={handleChange("client", "feedback")}
                error={!!errors?.clientErrors?.feedback}
                helperText={errors?.clientErrors?.feedback}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="status">Status</label>
              {/* {clientScreeningFieldsTouched ||
              initialValues?.client?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.clientErrors?.status}
                size="small"
              >
                <Select
                  value={formData.client.status}
                  onChange={handleChange("client", "status")}
                  displayEmpty
                >
                  {JobApplicationStatusData?.map((status) => (
                    <MenuItem key={status.name} value={status.value}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.clientErrors?.status && (
                  <Typography color="error">
                    {errors?.clientErrors?.status}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <label htmlFor="clientRating">Client Rating</label>
              {/* {clientScreeningFieldsTouched ||
              initialValues?.client?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <Rating
                name="clientRating"
                value={formData.client.clientRating}
                onChange={handleRatingChange("clientRating", "client")}
                size="small"
              />
              {errors?.clientErrors?.clientRating && (
                <Typography color="error">
                  {errors?.clientErrors?.clientRating}
                </Typography>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={
          (expanded === "panel4" &&
            isHrScreeningUnlocked &&
            formData.level1.status === "Selected") ||
          (Object.keys(errors.hrErrors).length > 0 &&
            isHrScreeningUnlocked &&
            formData.level1.status === "Selected")
        }
        onChange={handleAccordionChange("panel4")}
      >
        <AccordionSummary
          expandIcon={
            formData.level1.status === "Selected" &&
            enableScreening &&
            isHrScreeningUnlocked ? (
              <ExpandMoreIcon />
            ) : (
              <LockIcon sx={{ fontSize: "31px" }} />
            )
          }
        >
          <Typography
            style={{ padding: "16px", fontSize: "18px", fontWeight: "600" }}
          >
            HR Screening
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningDate">Screening Date</label>
              {/* {hrScreeningFieldsTouched || initialValues?.hr?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <TextField
                fullWidth
                name="screeningDate"
                variant="outlined"
                required
                type="date"
                value={formData.hr.date}
                onChange={handleChange("hr", "date")}
                error={!!errors?.hrErrors?.screeningDate}
                helperText={errors?.hrErrors?.screeningDate}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningBy">Screening By</label>
              {/* {hrScreeningFieldsTouched || initialValues?.hr?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.hrErrors?.screeningBy}
                size="small"
              >
                <Select
                  value={formData.hr.takenBy}
                  onChange={handleChange("hr", "takenBy")}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflow: "auto",
                        marginTop: 8,
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {employees?.map((emp) => (
                    <MenuItem key={emp._id} value={emp._id}>
                      {emp.FirstName}
                      {emp.LastName}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.hrErrors?.screeningBy && (
                  <Typography color="error">
                    {errors?.hrErrors?.screeningBy}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="feedback">Feedback</label>
              {/* {hrScreeningFieldsTouched || initialValues?.hr?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <TextField
                fullWidth
                name="feedback"
                variant="outlined"
                required
                multiline
                rows={4}
                value={formData.hr.feedback}
                onChange={handleChange("hr", "feedback")}
                error={!!errors?.hrErrors?.feedback}
                helperText={errors?.hrErrors?.feedback}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="status">Status</label>
              {/* {hrScreeningFieldsTouched || initialValues?.hr?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.hrErrors?.status}
                size="small"
              >
                <Select
                  value={formData.hr.status}
                  onChange={handleChange("hr", "status")}
                  displayEmpty
                >
                  {JobApplicationStatusData?.map((status) => (
                    <MenuItem key={status.name} value={status.value}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.hrErrors?.status && (
                  <Typography color="error">
                    {errors?.hrErrors?.status}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <label htmlFor="performanceRating">Performance Rating</label>
              {/* {hrScreeningFieldsTouched || initialValues?.hr?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <Rating
                name="performanceRating"
                value={formData.hr.performanceRating}
                onChange={handleRatingChange("performanceRating", "hr")}
                size="small"
              />
              {errors?.hrErrors?.performanceRating && (
                <Typography color="error">
                  {errors?.hrErrors?.performanceRating}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <label htmlFor="professionalisimRating">
                Professionalism Rating
              </label>
              {/* {hrScreeningFieldsTouched || initialValues?.hr?.date !== null ? (
                <span className="required-star">*</span>
              ) : (
                ""
              )} */}
              <Rating
                name="professionalisimRating"
                value={formData.hr.professionalisimRating}
                onChange={handleRatingChange("professionalisimRating", "hr")}
                size="small"
              />
              {errors?.hrErrors?.professionalisimRating && (
                <Typography color="error">
                  {errors?.hrErrors?.professionalisimRating}
                </Typography>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={
          (expanded === "panel5" && enableScreening) ||
          (Object.keys(errors.remarksErrors).length > 0 && enableScreening)
        }
        onChange={handleAccordionChange("panel5")}
      >
        <AccordionSummary
          expandIcon={
            enableScreening ? (
              <ExpandMoreIcon />
            ) : (
              <LockIcon sx={{ fontSize: "31px" }} />
            )
          }
        >
          <Typography
            style={{ padding: "16px", fontSize: "18px", fontWeight: "600" }}
          >
            Remarks
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label htmlFor="status">Status</label>
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.remarksErrors?.status}
                size="small"
              >
                <Select
                  value={formData.remark.status}
                  onChange={handleChange("remark", "status")}
                  displayEmpty
                  disabled
                >
                  {JobApplicationStatusData?.map((status) => (
                    <MenuItem key={status.name} value={status.value}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.remarksErrors?.status && (
                  <Typography color="error">
                    {errors?.remarksErrors?.status}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="remarks">
                Remarks<span className="required-star">*</span>
              </label>
              <TextField
                fullWidth
                name="remarks"
                variant="outlined"
                required
                multiline
                rows={4}
                value={formData.remark.remarks}
                onChange={handleChange("remark", "remarks")}
                error={!!errors?.remarksErrors?.remarks}
                helperText={errors?.remarksErrors?.remarks}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="jobOffered">Job Offered</label>
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.remarksErrors?.jobOffered}
                size="small"
              >
                <Select
                  value={formData.remark.jobOffered}
                  onChange={handleChange("remark", "jobOffered")}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {errors?.remarksErrors?.jobOffered && (
                  <Typography color="error">
                    {errors?.remarksErrors?.jobOffered}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="isOnBoarded">Is Onboarded</label>
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors?.remarksErrors?.isOnBoarded}
                size="small"
              >
                <Select
                  value={formData.remark.isOnBoarded}
                  onChange={handleChange("remark", "isOnBoarded")}
                  disabled={
                    formData.remark.status !== "Selected" &&
                    !formData.remark.jobOffered
                  }
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {errors?.remarksErrors?.isOnBoarded && (
                  <Typography color="error">
                    {errors?.remarksErrors?.isOnBoarded}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningDate">OnBoarded Date</label>

              <TextField
                fullWidth
                name="onBoardedDate"
                variant="outlined"
                required
                type="date"
                disabled={
                  formData.remark.isOnBoarded !== true &&
                  !formData.remark.jobOffered
                }
                value={formData.remark.onBoardedDate}
                onChange={handleChange("remark", "onBoardedDate")}
                error={!!errors?.remarksErrors?.onBoardedDate}
                helperText={errors?.remarksErrors?.onBoardedDate}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "15px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isSubmitEnabled}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
