import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./dataSlice";
import jobsReducer from "./Jobs/JobsSlice";
import regionSlice from "./regionSlice";

export const store = configureStore({
  reducer: {
    Data: dataReducer,
    Jobs: jobsReducer,
    regions: regionSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializableCheck if needed
    }),
  devTools:
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__(),
});

store.subscribe(() => {
  console.log("New state:", store.getState());
});
