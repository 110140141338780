import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ComboBox() {
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={jobs}
            sx={{ width: 200, }}
            renderInput={(params) => <TextField {...params} label="Choose a Job" />}
        />
    );
}


const jobs = ['Full-Stack Developer', 'Python Developer', 'DevOps Developer', 'FrontEnd Developer', 'BackEnd Developer', 'Rust Developer'];