import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getloginlogs,
  getUsersList,
  updateBlockedStatus,
} from "../../../redux/dataSlice";
import ServerSideDataGrid from "../../utilites/ServerSideDataGrid";
import { toast } from "react-toastify";
import { Box, Switch, Tab, Tabs } from "@mui/material";
import { canAccess } from "../../../utills/PermissionUtils";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const optionsDate = { month: "2-digit", day: "2-digit", year: "numeric" };
  const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };
  const formattedDate = date.toLocaleDateString("en-US", optionsDate);
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);
  return `${formattedDate} ${formattedTime}`;
};

const loginHistoryColumns = [
  {
    headerName: "#",
    width: 80,
    Padding: "10px",
    renderCell: (params) => `${params.api.getRowIndex(params.id) + 1}`,
  },
  { field: "displayName", headerName: "Display Name", width: 160 },
  { field: "email", headerName: "Email", width: 250 },
  { field: "userType", headerName: "User Type", width: 150 },
  { field: "action", headerName: "Activity Type", width: 150 },
  { field: "loginType", headerName: "Login Type", width: 150 },
  {
    field: "createdAt",
    headerName: "Date and Time",
    width: 200,
    valueFormatter: (params) => formatDate(params.value),
  },
];

function AuditPage() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [data, setData] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);
  const [userRoleAccess, setUserRoleAccess] = useState(false);

  const loginLogs = useSelector((state) => state?.Data?.Resources?.LoginLogs);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  const handleToggle = async (id, checked) => {
    dispatch(
      updateBlockedStatus({
        userId: id,
        blockUser: checked,
      })
    )
      .then((response) => {

        if (response?.payload?.success) {
          // Update the specific row's blocked status
          const updatedData = data.map((row) =>
            row.id === id ? { ...row, blocked: checked } : row
          );

          // Show success message
          toast.success(
            response?.payload?.message ?? "Status updated successfully"
          );

          // Update the component state
          setData(updatedData);
          setIsBlocked((prevState) => !prevState); // Toggle the blocked state
        } else {
          // Show error message if update fails
          toast.error(response?.payload?.message ?? "Failed to update status");
        }
      })
      .catch((error) => {
        console.error("Error updating blocked status:", error);
        toast.error("An error occurred while updating the status.");
      });
  };

  const userActivityColumns = [
    // { field: "userId", headerName: "#", width: 80 },
    {
      headerName: "#",
      width: 80,
      Padding: "10px",
      renderCell: (params) => `${params.api.getRowIndex(params.id) + 1}`,
    },
    { field: "displayName", headerName: "Display Name", width: 160 },
    { field: "email", headerName: "Email", width: 250 },

    {
      field: "isUserBlocked",
      headerName: "Blocked",
      renderCell: (params) => (
        <Switch
          checked={params.value || false}
          onChange={(event) => {
            handleToggle(params.row.userId, event.target.checked);
          }}
          color="primary"
        />
      ),
      width: 150,
    },
    { field: "createdAt", headerName: "Date and Time", width: 200 },
  ];

  const mappingComp = {
    0: loginHistoryColumns,
    1: userActivityColumns,
    // 2: userListColumns,
  };

  const fecthMap = {
    0: getloginlogs,
    1: getUsersList,
    // 2:  () => ({ data: [] }),
  };

  const dataselectorkey = {
    0: "LoginLogs",
    1: "BlockuserList",
    // 2:"LoginLogs",
  };
  const dataSelector = dataselectorkey[selectedTab];
  const fetchDataAction = fecthMap[selectedTab];
  const columns = mappingComp[selectedTab];

  useEffect(() => {
    const userRole = canAccess("Application.FullAccessIndia") || canAccess("Application.FullAccessUsa");
    setUserRoleAccess(userRole)
  }, [])

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <Tabs
          sx={{
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 1,
          }}
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="audit tabs"
        >
          <Tab label="Login History" />
          {userRoleAccess && <Tab label="User List" />}
          {/* <Tab label="User Activity"  /> */}
        </Tabs>
      </div>

      <Box>
        <ServerSideDataGrid
          columns={columns}
          getRowId={(row) => row._id}
          fetchDataAction={fetchDataAction}
          dataSelector={dataSelector}
          sliceKey="Data.Resources"
          roles={["ADMIN"]} // Replace with actual role check
          loading={loginLogs.loading}
          showAddButton={false}
        />
      </Box>
    </div>
  );
}

export default AuditPage;
