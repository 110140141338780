import { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { updateConfigData } from "../../../../redux/dataSlice";
import "./index.css";

const AddConfig = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitInactive, setSubmitInactive] = useState(true);
  const configData = useSelector(
    (state) => state.Data.Resources.ConfigData_Details
  );

  const employmentOptions = [].concat(...configData.map((e) => e.Employment));
  const roleOptions = [].concat(...configData.map((e) => e.Role));
  const assetOptions = [].concat(...configData.map((e) => e.Assets));
  const departmentOptions = [].concat(...configData.map((e) => e.Department));
  const designationOptions = [].concat(...configData.map((e) => e.Designation));
  const userTypeOptions = [].concat(...configData.map((e) => e.UserType));
  const skillsOptions = [].concat(...configData.map((e) => e.Skills));
  const experienceOptions = [].concat(...configData.map((e) => e.Experience));

  const assetsStatusOptions = [].concat(
    ...configData.map((e) => e.AssetsStatus)
  );
  const jobApplicationStatusOptions = [].concat(
    ...configData.map((e) => e.JobApplicationStatus)
  );
  const jobStatusOptions = [].concat(...configData.map((e) => e.JobStatus));
  const statusOptions = [].concat(...configData.map((e) => e.Status));

  const locationOptions = [].concat(...configData.map((e) => e.Location));
  const genderOptions = [].concat(...configData.map((e) => e.Gender));
  const priorityOptions = [].concat(...configData.map((e) => e.Priority));
  const isOnboardOptions = [].concat(...configData.map((e) => e.IsOnboard));
  const willingToRelocateOptions = [].concat(
    ...configData.map((e) => e.WillingToRelocate)
  );

  // const configUpdateId = useSelector(
  //   (state) => state.Data.Resources.ConfigData_Details[0]._id
  // );
  // const ID = configUpdateId;

  const configUpdateId = configData.length ? configData[0]._id : "";
  const ID = configUpdateId;

  const [formData, setFormData] = useState({
    _id: ID,
    Employment: employmentOptions,
    Role: roleOptions,
    Assets: assetOptions,
    Department: departmentOptions,
    Designation: designationOptions,
    UserType: userTypeOptions,
    Skills: skillsOptions,
    Experience: experienceOptions,
    AssetsStatus: assetsStatusOptions,
    JobApplicationStatus: jobApplicationStatusOptions,
    JobStatus: jobStatusOptions,
    Status: statusOptions,
    Location: locationOptions,
    Gender: genderOptions,
    Priority: priorityOptions,
    IsOnboard: isOnboardOptions,
    WillingToRelocate: willingToRelocateOptions,
  });

  const handleChange = (event, level) => {
    const { value } = event.target;
    const newOption = {
      name: value,
      value: value,
    };

    const options = {
      Employment: employmentOptions,
      Role: roleOptions,
      Assets: assetOptions,
      Department: departmentOptions,
      Designation: designationOptions,
      UserType: userTypeOptions,
      Skills: skillsOptions,
      Experience: experienceOptions,
      AssetsStatus: assetsStatusOptions,
      JobApplicationStatus: jobApplicationStatusOptions,
      JobStatus: jobStatusOptions,
      Status: statusOptions,
      Location: locationOptions,
      Gender: genderOptions,
      Priority: priorityOptions,
      IsOnboard: isOnboardOptions,
      WillingToRelocate: willingToRelocateOptions,
    };

    setFormData((prevState) => ({
      ...prevState,
      [level]: options[level].some((option) => option.value === value)
        ? options[level]
        : [...options[level], newOption],
    }));

    if (value.length >= 1) {
      setSubmitInactive(false);
    } else {
      setSubmitInactive(true);
    }
  };

  // useEffect(() => {
  //   dispatch(fetchData());
  // }, [dispatch]);

  const notifySuccess = () =>
    toast.success("Config Data Updated Successfully...!");
  const notifyError = (errorMessage) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await dispatch(updateConfigData(formData));
      // .then(response => {
      //   if (response?.data?.statusCode === 401) {
      //     navigate("/session-expired")
      //   }
      // });
      if (updateConfigData.fulfilled.match(response)) {
        notifySuccess();
        setTimeout(() => {
          navigate("/");
        }, 700);
      } else {
        notifyError(response.payload);
        //notifyError();
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  //window.location.reload();
  return (
    <div>
      <Grid container spacing={2} sx={{ margin: "1px" }}>
        <Grid item xs={4}>
          <label htmlFor="Employment">Employment</label> <br />
          <TextField
            id="newEmployment"
            name="Employment"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Employment option  ....Full-Time"
            onChange={(event) => handleChange(event, "Employment")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="Role">Role</label> <br />
          <TextField
            id="newRole"
            name="Role"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add new Role option    software engineer"
            onChange={(event) => handleChange(event, "Role")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="Assets">Asset Type</label> <br />
          <TextField
            id="newAssets"
            name="Assets"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Assets option ....Laptop"
            onChange={(event) => handleChange(event, "Assets")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ margin: "1px" }}>
        <Grid item xs={4}>
          <label htmlFor="Department">Department</label> <br />
          <TextField
            id="newDepartment"
            name="Department"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Department ....option  product development"
            onChange={(event) => handleChange(event, "Department")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="Designation">Designation</label> <br />
          <TextField
            id="newDesignation"
            name="Designation"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Designation option ....junior software engineer"
            onChange={(event) => handleChange(event, "Designation")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="UserType">User Type</label> <br />
          <TextField
            id="newUserType"
            name="UserType"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add UserType option ....ADMIN"
            onChange={(event) => handleChange(event, "UserType")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ margin: "1px" }}>
        <Grid item xs={4}>
          <label htmlFor="Skills">Skills</label> <br />
          <TextField
            id="newSkills"
            name="Skills"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Skills option ....HTML"
            onChange={(event) => handleChange(event, "Skills")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="Experience">Experience</label> <br />
          <TextField
            id="newExperience"
            name="Experience"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Experience option ....<1 Year"
            onChange={(event) => handleChange(event, "Experience")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="AssetsStatus">Asset Status</label> <br />
          <TextField
            id="newAssetsStatus"
            name="AssetsStatus"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add AssetsStatus option ....Assigned"
            onChange={(event) => handleChange(event, "AssetsStatus")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ margin: "1px" }}>
        <Grid item xs={4}>
          <label htmlFor="JobApplicationStatus">
            Employee Screening Status
          </label>{" "}
          <br />
          <TextField
            id="newJobApplicationStatus"
            name="JobApplicationStatus"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add JobApplicationStatus option ....Selected"
            onChange={(event) => handleChange(event, "JobApplicationStatus")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="JobStatus">Job Status</label> <br />
          <TextField
            id="newJobStatus"
            name="JobStatus"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add JobStatus option ....Open"
            onChange={(event) => handleChange(event, "JobStatus")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="Status">Project Status</label> <br />
          <TextField
            id="newStatus"
            name="Status"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Status option ....Completed"
            onChange={(event) => handleChange(event, "Status")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ margin: "1px" }}>
        <Grid item xs={4}>
          <label htmlFor="Location">Location</label> <br />
          <TextField
            id="newLocation"
            name="Location"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Location option ....Onshore"
            onChange={(event) => handleChange(event, "Location")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="Gender">Gender</label> <br />
          <TextField
            id="newGender"
            name="Gender"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Gender option ....Male"
            onChange={(event) => handleChange(event, "Gender")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="Priority">Priority</label> <br />
          <TextField
            id="newPriority"
            name="Priority"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add Priority option ....Low"
            onChange={(event) => handleChange(event, "Priority")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ margin: "1px" }}>
        <Grid item xs={4}>
          <label htmlFor="IsOnboard">IsOnboard</label> <br />
          <TextField
            id="newIsOnboard"
            name="IsOnboard"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add IsOnboard option ....Yes"
            onChange={(event) => handleChange(event, "IsOnboard")}
          />
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="WillingToRelocate">WillingToRelocate</label> <br />
          <TextField
            id="newWillingToRelocate"
            name="WillingToRelocate"
            variant="outlined"
            sx={{ width: "90%", backgroundColor: "white" }}
            placeholder="Add WillingToRelocate option ....Yes"
            onChange={(event) => handleChange(event, "WillingToRelocate")}
          />
        </Grid>
      </Grid>

      {!submitInactive && (
        <div className="submitConfigContainer">
          <Button
            variant="contained"
            color="success"
            type="sumbit"
            sx={{ margin: "5px" }}
            startIcon={<SendIcon />}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default AddConfig;
