import Employeefrom from "../EmployeeForm";
const UpdateEmployee = () => {
  return (
    <>
      <Employeefrom editMode={true} />
    </>
  );
};

export default UpdateEmployee;
