import JobApplicationForm from "../JobApplicationForm";

const AddApplication = () => {
  return (
    <>
      <JobApplicationForm {...{ editMode: false }} />
    </>
  );
};

export default AddApplication;
