// import React, { useState, useEffect } from "react";
// import { AiOutlineClose } from "react-icons/ai";
// import { Link, useParams, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { ToastContainer } from "react-toastify";
// import Select from "react-select";
// import SelectCreateable from "react-select/creatable";
// import makeAnimated from "react-select/animated";
// import {
//   getProjectData,
//   updateProjectCard,
//   deleteProjectCard,
//   addProjectCard,
// } from "../../../../redux/dataSlice";
// import {
//   getAllEmployeeData,
//   getAllEmployeeByRegion,
// } from "../../../../redux/dataSlice";
// import {
//   Grid,
//   Checkbox,
//   Table,
//   TableCell,
//   TableHead,
//   TableBody,
//   Paper,
//   TableRow,
//   TableContainer,
//   TextField,
// } from "@mui/material";
// import CreatableSelect from "react-select/creatable";
// import "./index.css";

// const ProjectForm = ({ editMode = false }) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { selectedRegion } = useSelector((state) => state.regions);
//   const [formData, setFormData] = useState({
//     projectId: "",
//     projectTitle: "",
//     projectDescription: "",
//     vendorName: "",
//     projectStartDate: "",
//     projectEndDate: "",
//     projectStatus: "Yet-to-Start",
//     TechStack: [],
//     region: "",
//     percentageOfCompletion: 0,
//   });
//   const [creating, setCreating] = useState(false);

//   var data;
//   const Data = useSelector((state) => state.Data);

//   if (Data.Resources !== undefined) {
//     data = Data.Resources.Employee_Details;
//   }

//   const StatusData = useSelector((state) =>
//     state.Data.Resources.ConfigData_Details.map((e) => e.Status)
//   );
//   const ConfigData = useSelector(
//     (state) => state.Data.Resources.ConfigData_Details
//   );
//   const employees = useSelector(
//     (state) => state?.Data?.Resources?.employee?.data
//   );
//   const [employeeOptions, setEmployeeOptions] = useState([]);
//   const [tableData, setTableData] = useState([]);

//   const { id } = useParams();
//   const [isFocused, setIsFocused] = useState(false);
//   const [errors, setErrors] = useState({
//     projectStartDate: "",
//     projectEndDate: "",
//   });

//   console.log(selectedRegion?._id, "regions2");

//   const validate = () => {
//     const errors = {};
//     if (!formData.projectId) errors.projectId = "Project ID is required";
//     if (!formData.projectTitle)
//       errors.projectTitle = "Project Title is required";
//     if (!formData.projectDescription)
//       errors.projectDescription = "Project Description is required";
//     if (!formData.vendorName) errors.vendorName = "Client Name is required";
//     if (formData.TechStack.length === 0)
//       errors.TechStack = "Technologies are required";
//     if (!formData.projectStatus)
//       errors.projectStatus = "Project Status is required";
//     if (!formData.projectStartDate && formData.projectEndDate)
//       errors.projectStartDate = "Project cannot have EndDate without StartDate";
//     if (!formData.categoryType)
//       errors.categoryType = "Project Category is required";
//     return errors;
//   };
//   const validateField = (name, value) => {
//     let error = "";
//     switch (name) {
//       case "projectId":
//         if (!value) error = "Project ID is required";
//         break;
//       case "projectTitle":
//         if (!value) error = "Project Title is required";
//         break;
//       case "TechStack":
//         if (!value) error = "Technologies are required";
//         break;
//       case "projectDescription":
//         if (!value) error = "Project Description is required";
//         break;
//       case "vendorName":
//         if (!value) error = "Client Name is required";
//         break;
//       case "categoryType":
//         if (!value) error = "Project Category is required";
//         break;
//       case "projectStatus":
//         if (!value) error = "Project Status is required";
//         break;
//       case "projectStartDate":
//         if (
//           formData.projectEndDate &&
//           new Date(value) > new Date(formData.projectEndDate)
//         ) {
//           error = "Project Start Date cannot be after Project End Date";
//         }
//         break;
//       case "projectEndDate":
//         if (
//           formData.projectStartDate &&
//           new Date(value) < new Date(formData.projectStartDate)
//         ) {
//           error = "Project End Date cannot be before Project Start Date";
//         }
//         break;

//       case "percentageOfCompletion":
//         const percentage = Number(value);
//         if (isNaN(percentage)) {
//           error = "Percentage of Completion must be a number.";
//         } else if (percentage < 0 || percentage > 100) {
//           error = "Percentage of Completion should be between 0 and 100.";
//         }
//         break;

//       default:
//         break;
//     }
//     setErrors((prev) => ({ ...prev, [name]: error }));
//   };
//   const handleBlur = (e) => {
//     const { name, value } = e.target;
//     validateField(name, value);
//   };

//   useEffect(() => {
//     if (editMode) {
//       dispatch(getProjectData(id))
//         .then((response) => {
//           const currentProject = response?.payload || {};
//           const technologies =
//             currentProject.TechStack?.map((tech) => ({
//               label: tech,
//               value: tech,
//             })) || [];

//           // // Map projectTeam and projectTeamOther for Project Team
//           // const newTableData = [];

//           // // projectTeam Mapping
//           // if (currentProject.projectTeam) {
//           //   currentProject.projectTeam.forEach((item) => {
//           //     newTableData.push({
//           //       id: item._id,
//           //       name: `${item?.FirstName || ""} ${item?.LastName || ""}`,
//           //       email: item.email,
//           //       role: item.Project[0].role,
//           //       startDate: item.Project[0].startDate,
//           //       endDate: item.Project[0].endDate,
//           //       percentage: item.Project[0]?.percentage || 100,
//           //       billable:
//           //         item.Project[0]?.billable !== undefined
//           //           ? item.Project[0].billable
//           //           : true,
//           //     });
//           //   });
//           // }

//           // // projectTeamOther Mapping
//           // if (currentProject.projectTeamOther) {
//           //   currentProject.projectTeamOther.forEach((item) => {
//           //     newTableData.push({
//           //       id: "others",
//           //       name: item.name,
//           //       role: item.role || "custom role",
//           //       startDate: item.startDate || "",
//           //       endDate: item.endDate || "",
//           //       percentage: 100,
//           //       billable: true,
//           //     });
//           //   });
//           // }

//           // setTableData(newTableData);
//           const newTableData = currentProject.projectTeam.map((item) => ({
//             id: item._id,
//             name: `${item.FirstName || ""} ${item.LastName || ""}`,
//             email: item.email,
//             role: item.Project[0]?.role || "",
//             startDate: item.Project[0]?.startDate || null,
//             endDate: item.Project[0]?.endDate || null,
//             percentage: item.Project[0]?.percentage || 100,
//             billable:
//               item.Project[0]?.billable !== undefined
//                 ? item.Project[0].billable
//                 : true,
//           }));

//           // Separately mapping projectTeamOther
//           // const newProjectTeamOtherData = currentProject.projectTeamOther.map(
//           //   (item) => ({
//           //     id: "others",
//           //     name: item.name,
//           //     role: item.role || "custom role",
//           //     startDate: item.startDate || null,
//           //     endDate: item.endDate || null,
//           //     percentage: 100,
//           //     billable: true,
//           //   })
//           // );
//           // Assign unique ids for projectTeamOther data
//           const newProjectTeamOtherData = currentProject.projectTeamOther.map(
//             (item, index) => ({
//               id: item._id || "others",
//               // `other-${index}`,
//               name: item.name,
//               role: item.role || "custom role",
//               startDate: item.startDate || null,
//               endDate: item.endDate || null,
//               percentage: 100,
//               billable: true,
//             })
//           );

//           const combinedTeamData = [
//             ...newTableData,
//             ...newProjectTeamOtherData,
//           ];

//           // Set the combined table data for rendering
//           setTableData(combinedTeamData);

//           // Set formData with the existing project data
//           setFormData({
//             ...currentProject,
//             TechStack: technologies, // Populate TechStack for Technologies field
//             projectTeam: combinedTeamData.map((team) => ({
//               value: team.id,
//               label: team.name,
//               role: team.role,
//             })),
//           });
//         })
//         .catch((error) => {
//           console.error("Error fetching project data:", error);
//         });
//     } else {
//       // Set default region value for creating employee
//       if (selectedRegion) {
//         setFormData((prevData) => ({
//           ...prevData,
//           region: selectedRegion?._id,
//         }));
//       }
//     }
//   }, [id, editMode, dispatch, selectedRegion]);

//   // useEffect(() => {
//   //   if (editMode) {
//   //     dispatch(getProjectData(id))
//   //       .then((response) => {
//   //         if (response?.data?.statusCode === 401) {
//   //           navigate("/session-expired");
//   //         }
//   //         const currentProject = response?.payload || {};
//   //         const newTableData = [];

//   //         // Map values from projectTeam
//   //         if (currentProject.projectTeam) {
//   //           currentProject.projectTeam.forEach((item) => {
//   //             newTableData.push({
//   //               id: item._id,
//   //               name: `${item?.FirstName ? item.FirstName : ""} ${
//   //                 item?.LastName ? item.LastName : ""
//   //               }`,
//   //               email: item.email,
//   //               role: item.Project[0].role,
//   //               startDate: item.Project[0].startDate,
//   //               endDate: item.Project[0].endDate,
//   //               percentage: item.Project?.[0]?.percentage || 100,
//   //               billable:
//   //                 item.Project?.[0]?.billable !== undefined
//   //                   ? item.Project[0].billable
//   //                   : true,
//   //             });
//   //           });
//   //         }

//   //         // Handle projectTeamOther population for editMode
//   //         if (editMode && currentProject.projectTeamOther) {
//   //           currentProject.projectTeamOther.forEach((item) => {
//   //             newTableData.push({
//   //               id: "others", // Unique identifier for 'other' entries
//   //               name: item.name,
//   //               role: item.role || "custom role", // Default role for others
//   //               startDate: item.startDate || "", // Optional startDate for others
//   //               endDate: item.endDate || "",
//   //               percentage: 100, // Default percentage for others
//   //               billable: true, // Default billable value for others
//   //             });
//   //           });
//   //         }

//   //         setTableData(newTableData); // Set the final table data
//   //         setFormData({ ...currentProject });
//   //       })
//   //       .catch((error) => {
//   //         console.error("Error fetching project data:", error);
//   //       });
//   //   }
//   // }, [id, editMode, dispatch]);

//   useEffect(() => {
//     console.log("projects form regions", selectedRegion);
//     if (selectedRegion && selectedRegion?._id) {
//       dispatch(getAllEmployeeByRegion({ id: selectedRegion?._id })).then(
//         (response) => {
//           console.log("response from dropdown", response);
//           setEmployeeOptions(
//             response.payload.map((emp) => {
//               return {
//                 label: `${emp?.FirstName ? emp.FirstName : ""} ${
//                   emp?.LastName ? emp.LastName : ""
//                 }`,
//                 value: emp._id,
//               };
//             })
//           );
//           // }
//         }
//       );
//     }
//   }, [selectedRegion]);

//   const handleRoleChange = (selectedOption, index) => {
//     setTableData((prevTableData) => {
//       const updatedTableData = [...prevTableData];
//       if (updatedTableData[index]?.id === "others") {
//         const existingOthersIndex = updatedTableData.findIndex(
//           (item) => item.id === "others"
//         );

//         if (existingOthersIndex !== -1 && existingOthersIndex !== index) {
//           return updatedTableData;
//         }
//       }
//       if (index >= 0 && index < updatedTableData.length) {
//         updatedTableData[index] = {
//           ...updatedTableData[index],
//           role: selectedOption ? selectedOption.value : "custom role",
//         };
//       }
//       return updatedTableData;
//     });
//   };
//   function MultiSelectDropdown2(data, opt, key, index) {
//     const selectedRole = tableData[index]?.role || "";
//     const optionList = [];

//     if (data !== undefined) {
//       opt.forEach((each) => {
//         each.map((e) => {
//           optionList.push({ value: e.name, label: e.name });
//           return null;
//         });
//       });
//     }

//     const uniq_options = uniqByKeepLast(optionList, (o) => o.value);

//     return (
//       <Select
//         components={makeAnimated()}
//         theme={customTheme}
//         options={uniq_options}
//         onChange={(selectedOption) => handleRoleChange(selectedOption, index)}
//         placeholder="Select"
//         isSearchable
//         value={
//           selectedRole ? { value: selectedRole, label: selectedRole } : null
//         }
//         required={true}
//         name={key}
//         styles={{
//           control: (styles) => ({
//             ...styles,
//             width: "160px",
//             backgroundColor: "white",
//             "@media (min-width: 1440px) and (max-width: 2560px)": {
//               padding: 4,
//             },
//             "@media (min-width: 2560px)": {
//               padding: 18,
//               fontSize: 30,
//             },
//           }),
//         }}
//       />
//     );
//   }
//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     const newValue = name === "percentageOfCompletion" ? Number(value) : value;
//     const currentDate = new Date().toISOString().split("T")[0];
//     switch (name) {
//       case "percentageOfCompletion":
//         if (newValue === 100) {
//           setFormData((prevFormData) => ({
//             ...prevFormData,
//             percentageOfCompletion: newValue,
//             projectStatus: "Completed",
//             projectEndDate: prevFormData.projectEndDate || currentDate,
//           }));
//         } else {
//           setFormData((prevFormData) => ({
//             ...prevFormData,
//             percentageOfCompletion: newValue,
//             projectStatus:
//               prevFormData.projectStatus === "Completed"
//                 ? "In-Progress"
//                 : prevFormData.projectStatus,
//           }));
//         }
//         break;

//       case "projectEndDate":
//         setFormData((prevFormData) => ({
//           ...prevFormData,
//           projectEndDate: newValue,
//           projectStatus: "Completed",
//           percentageOfCompletion: 100,
//         }));
//         break;

//       case "projectStatus":
//         if (newValue === "Completed") {
//           setFormData((prevFormData) => ({
//             ...prevFormData,
//             projectStatus: newValue,
//             percentageOfCompletion: 100,
//             projectEndDate: prevFormData.projectEndDate || currentDate,
//           }));
//         } else {
//           setFormData((prevFormData) => ({
//             ...prevFormData,
//             projectStatus: newValue,
//           }));
//         }
//         break;

//       default:
//         setFormData((prevFormData) => ({
//           ...prevFormData,
//           [name]: newValue,
//         }));
//         break;
//     }

//     handleBlur(event);
//   };
//   useEffect(() => {
//     if (formData.projectStatus === "Completed" && !formData.projectEndDate) {
//       const currentDate = new Date().toISOString().split("T")[0];
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         projectEndDate: currentDate,
//       }));
//     }
//   }, [formData.projectStatus]);

//   function handleSelectChange(selectedOption, selectedName) {
//     setFormData((prevFormData) => {
//       return {
//         ...prevFormData,
//         [selectedName]: selectedOption?.value || "",
//       };
//     });
//     validateField(selectedName, selectedOption);
//   }

//   const handleDateChange = (fieldName, dateValue) => {
//     setFormData((prevData) => {
//       const newFormData = { ...prevData, [fieldName]: dateValue };

//       handleBlur({ target: { name: fieldName, value: dateValue } });
//       return newFormData;
//     });
//   };

//   function handleMultiSelectChange(selectedOption, selectedName) {
//     setFormData({
//       ...formData,
//       [selectedName]: selectedOption,
//     });
//   }
//   function uniqByKeepLast(each, key) {
//     return [...new Map(each.map((x) => [key(x), x])).values()];
//   }

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const errors = validate();
//     if (Object.keys(errors).length === 0) {
//       setCreating(true);
//       let finalData = { ...formData };

//       Object.keys(finalData).forEach((key) => {
//         if (
//           [
//             "qaLead",
//             "teamLead",
//             "projectManager",
//             "projectTeam",
//             "TechStack",
//           ].includes(key)
//         ) {
//           if (key === "projectTeam") {
//             finalData[key] = finalData[key].map((item) => {
//               const tableRow = tableData.find((row) => row.id === item?.value);
//               return {
//                 id: item.__isNew__ ? "others" : item.value,
//                 name: item.__isNew__
//                   ? item.label
//                   : tableRow?.name || item.label,
//                 percentage: tableRow ? Number(tableRow.percentage) : 100,
//                 billable: tableRow ? tableRow.billable : true,
//                 startDate: tableRow?.startDate || null,
//                 endDate: tableRow?.endDate || null,
//                 role:
//                   item.role || (tableRow?.role ? tableRow.role : "Team Member"),
//               };
//             });

//           } else if (
//             ["qaLead", "teamLead", "projectManager"].includes(key) &&
//             finalData[key]?.__isNew__
//           ) {
//             finalData[key] = { name: finalData[key]?.value, id: "others" };
//           } else if (key === "TechStack") {
//             finalData[key] = finalData[key]?.map((item) => item?.value);
//           } else {
//             const tableRow = tableData.find(
//               (row) => row.id === finalData[key]?.value
//             );
//             finalData[key] = {
//               id: finalData[key]?.value,
//               role: tableRow ? tableRow.role : "Team Member",
//               startDate: tableRow ? tableRow.startDate : null,
//               endDate: tableRow ? tableRow.endDate : null,
//               percentage: tableRow ? Number(tableRow.percentage) : 100,
//               billable: tableRow ? tableRow.billable : true,
//             };
//           }
//         }
//       });
//       try {
//         let reponse;
//         if (editMode) {
//           reponse = await dispatch(updateProjectCard(finalData));
//         } else {
//           reponse = await dispatch(addProjectCard(finalData));
//         }
//         setCreating(false);
//         if (!reponse?.error) {
//           setTimeout(() => {
//             navigate("/projects", {
//               state: { headerText: "Projects" }, // State must be an object
//             });
//           }, 2000);
//         }
//       } catch (error) {
//         setCreating(false);
//       }
//     } else {
//       setErrors(errors);
//     }
//   };

//   function customTheme(theme) {
//     return {
//       ...theme,
//       colors: {
//         ...theme.colors,
//         primary25: "orange",
//         primary: "green",
//       },
//     };
//   }
//   const getValues = (item) => {
//     if (!item) return item;
//     if (typeof item == "string") return { value: item, label: item };
//     if (Array.isArray(item))
//       return item.map((v) => {
//         return { value: v, label: v };
//       });
//     return item;
//   };
//   function MultiSelectDropdown(data, opt, key) {
//     const optionList = [];

//     if (data !== undefined) {
//       opt.forEach((each) => {
//         each.map((e) => {
//           optionList.push({ value: e.name, label: e.name });
//           return null;
//         });
//       });
//     }

//     const uniq_options = uniqByKeepLast(optionList, (o) => o.value);

//     return key === "Skillset" || key === "technology" || key === "TechStack" ? (
//       <Select
//         components={makeAnimated()}
//         theme={customTheme}
//         options={uniq_options}
//         onChange={(selectedOption) => {
//           handleMultiSelectChange(selectedOption, key);
//           setIsFocused(true);
//         }}
//         className="employee-multi-select"
//         placeholder="Select"
//         isSearchable
//         isMulti
//         value={formData?.TechStack}
//         required={true}
//         name={key}
//         onFocus={() => setIsFocused(true)}
//         onBlur={handleBlur}
//         onMenuClose={() => {
//           if (isFocused) {
//             const value = getValues(formData[key]);
//             const error = validateField(key, value.length);
//             setIsFocused(false);
//           }
//         }}
//         styles={{
//           control: (styles) => ({
//             ...styles,
//             backgroundColor: "white",
//             "@media (min-width: 1440px) and (max-width: 2560px)": {
//               padding: 4,
//             },
//             "@media (min-width: 2560px)": {
//               padding: 19,
//               fontSize: 30,
//             },
//           }),
//         }}
//       />
//     ) : (
//       <Select
//         components={makeAnimated()}
//         theme={customTheme}
//         options={uniq_options}
//         onChange={(selectedOption) => handleSelectChange(selectedOption, key)}
//         className="employee-multi-select"
//         placeholder="Select"
//         value={key === "categoryType" && getValues(formData?.categoryType)}
//         isSearchable
//         required={true}
//         styles={{
//           control: (styles) => ({
//             ...styles,
//             backgroundColor: "white",
//             "@media (min-width: 1440px) and (max-width: 2560px)": {
//               padding: 4,
//             },
//             "@media (min-width: 2560px)": {
//               padding: 18,
//               fontSize: 30,
//             },
//           }),
//         }}
//       />
//     );
//   }

//   const handleTableChange = (index, field, value) => {
//     setTableData((prevTableData) => {
//       const updatedTableData = [...prevTableData];
//       if (field === "percentage") {
//         updatedTableData[index][field] = Number(value);
//       } else if (field === "role") {
//         updatedTableData[index][field] = value;
//       } else if (field === "startDate") {
//         updatedTableData[index][field] = value;
//       } else if (field === "endDate") {
//         updatedTableData[index][field] = value;
//       } else if (field === "billable") {
//         updatedTableData[index][field] = !updatedTableData[index][field];
//       } else {
//         updatedTableData[index][field] = value;
//       }
//       return updatedTableData;
//     });
//   };

//   function handleCustomSelectChange(selectedOption, selectedName) {
//     const selectedArray = Array.isArray(selectedOption)
//       ? selectedOption
//       : [selectedOption];

//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [selectedName]: selectedOption,
//     }));

//     setTableData((prevTableData) => {
//       if (selectedName === "projectTeam") {
//         const currentTeamIds = (formData?.projectTeam || []).map(
//           (item) => item.value
//         );
//         const selectedIds = selectedArray.map((item) => item.value);
//         const removedIds = currentTeamIds.filter(
//           (id) => !selectedIds.includes(id)
//         );
//         let updatedTableData = prevTableData.filter(
//           (row) => !removedIds.includes(row.id)
//         );
//         const newEntries = selectedArray
//           .filter(
//             (item) => !updatedTableData.some((row) => row.id === item.value)
//           )
//           .map((item) => ({
//             id: item.value,
//             name: item.label,
//             percentage: 100,
//             billable: true,
//           }));

//         return [...updatedTableData, ...newEntries];
//       } else {
//         const newTableData = prevTableData.filter(
//           (row) => row.id !== formData[selectedName]?.value
//         );

//         selectedArray.forEach((item) => {
//           newTableData.push({
//             id: item.value,
//             name: item.label,
//             percentage: 100,
//             billable: true,
//           });
//         });

//         return newTableData;
//       }
//     });
//   }

//   return (
//     <div className="project-form-container">
//       <div className="project-details">
//         <h3>Project Details</h3>
//         <div className="close-container">
//           <Link to="/projects" state={{ headerText: "Projects" }}>
//             <AiOutlineClose className="back-option" />
//           </Link>
//         </div>
//       </div>

//       <form className="project-form">
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6} md={6} lg={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="projectId">
//                 Project Id <span className="required-star">*</span>
//               </label>
//               <input
//                 type="text"
//                 name="projectId"
//                 className="project_input-feald"
//                 placeholder="Enter Project Id"
//                 value={formData.projectId}
//                 onChange={handleChange}
//                 required
//               />
//               {errors.projectId && (
//                 <span className="error-text">{errors.projectId}</span>
//               )}
//             </div>
//           </Grid>

//           <Grid item xs={12} sm={6} md={6} lg={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="projectTitle">
//                 Project Title <span className="required-star">*</span>
//               </label>
//               <input
//                 type="text"
//                 name="projectTitle"
//                 className="project_input-feald"
//                 placeholder="Enter project Title"
//                 value={formData.projectTitle}
//                 onBlur={handleBlur}
//                 onChange={handleChange}
//                 required
//               />
//               {errors.projectTitle && (
//                 <span className="error-text">{errors.projectTitle}</span>
//               )}
//             </div>
//           </Grid>

//           <Grid item xs={12} sm={6} md={6} lg={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="projectDescription">
//                 Project Description <span className="required-star">*</span>
//               </label>
//               <input
//                 type="text"
//                 name="projectDescription"
//                 className="project_input-feald"
//                 placeholder="Enter  Project Desc"
//                 value={formData.projectDescription}
//                 onBlur={handleBlur}
//                 onChange={handleChange}
//                 required
//               />
//               {errors.projectDescription && (
//                 <span className="error-text">{errors.projectDescription}</span>
//               )}
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6}>
//             <div className="employee-label-card">
//               <label htmlFor="CoreSkills">
//                 Technologies <span className="required-star">*</span>
//               </label>
//               {MultiSelectDropdown(
//                 employees,
//                 ConfigData.map((each) => each.Skills),
//                 "TechStack"
//               )}
//               {errors.TechStack && (
//                 <span className="error-text">{errors.TechStack}</span>
//               )}
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6}>
//             <div className="employee-label-card">
//               <label>Project Manager</label>
//               <SelectCreateable
//                 components={makeAnimated()}
//                 theme={customTheme}
//                 options={employeeOptions}
//                 value={formData?.projectManager}
//                 onChange={(selectedOption) =>
//                   handleCustomSelectChange(selectedOption, "projectManager")
//                 }
//                 className="employee-multi-select"
//                 placeholder="Select"
//                 isSearchable
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6}>
//             <div className="employee-label-card">
//               <label>QA Lead</label>
//               <SelectCreateable
//                 components={makeAnimated()}
//                 theme={customTheme}
//                 options={employeeOptions}
//                 value={formData?.qaLead}
//                 onChange={(selectedOption) =>
//                   handleCustomSelectChange(selectedOption, "qaLead")
//                 }
//                 className="employee-multi-select"
//                 placeholder="Select"
//                 isSearchable
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6}>
//             <div className="employee-label-card">
//               <label>Team Lead</label>
//               <SelectCreateable
//                 components={makeAnimated()}
//                 theme={customTheme}
//                 options={employeeOptions}
//                 value={formData?.teamLead}
//                 onChange={(selectedOption) =>
//                   handleCustomSelectChange(selectedOption, "teamLead")
//                 }
//                 className="employee-multi-select"
//                 placeholder="Select"
//                 isSearchable
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6}>
//             <div className="employee-label-card">
//               <label>Project Team</label>

//               <CreatableSelect
//                 isMulti
//                 components={makeAnimated()}
//                 theme={customTheme}
//                 options={employeeOptions}
//                 value={formData?.projectTeam}
//                 onChange={(selectedOption) =>
//                   handleCustomSelectChange(selectedOption, "projectTeam")
//                 }
//                 className="employee-multi-select"
//                 placeholder="Select"
//                 isSearchable
//               />
//             </div>
//           </Grid>

//           <Grid item xs={12} sm={6} md={6} lg={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="vendorName">
//                 Client Name <span className="required-star">*</span>
//               </label>
//               <input
//                 type="text"
//                 name="vendorName"
//                 className="project_input-feald"
//                 placeholder="Enter Vendor Name"
//                 value={formData.vendorName}
//                 onBlur={handleBlur}
//                 onChange={handleChange}
//                 required
//               />
//               {errors.vendorName && (
//                 <span className="error-text">{errors.vendorName}</span>
//               )}
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6}>
//             <div className="employee-label-card">
//               <label htmlFor="categoryType">
//                 Project Category <span className="required-star">*</span>
//               </label>
//               {MultiSelectDropdown(
//                 employees,
//                 ConfigData.map((each) => each.projectCategory),
//                 "categoryType"
//               )}
//               {errors.categoryType && (
//                 <span className="error-text">{errors.categoryType}</span>
//               )}
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6} lg={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="projectEndDate" className="Project_status">
//                 Project Status <span className="required-star">*</span>
//               </label>
//               <div className="select-container">
//                 {StatusData.length > 0 &&
//                   StatusData.map((option) =>
//                     option.map((each) => (
//                       <div key={each.value}>
//                         <input
//                           type="radio"
//                           id={each.value}
//                           name="projectStatus"
//                           value={each.value}
//                           checked={formData.projectStatus === each.value}
//                           onChange={handleChange}
//                           className="radio-input"
//                         />
//                         <label htmlFor={each.value}>{each.name}</label>
//                       </div>
//                     ))
//                   )}
//               </div>
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6} lg={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="percentageOfCompletion">
//                 Percentage of Completion{" "}
//               </label>
//               <input
//                 type="number"
//                 name="percentageOfCompletion"
//                 className="project_input-feald"
//                 placeholder="Enter Percentage of Completion"
//                 value={formData.percentageOfCompletion}
//                 onBlur={handleBlur}
//                 onChange={handleChange}
//                 min={0}
//                 max={100}
//                 required
//               />
//               {errors.percentageOfCompletion && (
//                 <span className="error-text">
//                   {errors.percentageOfCompletion}
//                 </span>
//               )}
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="projectStartDate">Project Start Date</label>
//               <TextField
//                 className="project_input-feald"
//                 id="projectStartDate"
//                 type="date"
//                 required
//                 sx={{
//                   width: "100%",
//                   maxWidth: 505,
//                   backgroundColor: "white",
//                   "@media (max-width: 600px)": {
//                     maxWidth: 400,
//                   },
//                   "@media (min-width: 601px) and (max-width: 960px)": {
//                     maxWidth: 250,
//                   },
//                   "@media (min-width: 961px) and (max-width: 2560px)": {
//                     maxWidth: "100vw",
//                   },
//                 }}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 onBlur={handleBlur}
//                 value={formData?.projectStartDate?.slice(0, 10)}
//                 onChange={(e) =>
//                   handleDateChange("projectStartDate", e.target.value)
//                 }
//                 inputProps={{
//                   max:
//                     formData.projectStatus === "Completed" ||
//                     formData.projectStatus === "In-Progress"
//                       ? new Date().toISOString().split("T")[0]
//                       : undefined,
//                 }}
//                 error={!!errors.projectStartDate}
//               />
//               {errors.projectStartDate && (
//                 <span className="error-text">{errors.projectStartDate}</span>
//               )}
//             </div>
//           </Grid>

//           <Grid item xs={12} sm={6} md={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="projectEndDate">Project End Date</label>
//               <TextField
//                 className="project_input-feald"
//                 id="projectEndDate"
//                 type="date"
//                 required
//                 sx={{ width: "100%", maxWidth: 505, backgroundColor: "white" }}
//                 InputLabelProps={{ shrink: true }}
//                 value={formData?.projectEndDate?.slice(0, 10)}
//                 onChange={(e) =>
//                   handleChange({
//                     target: { name: "projectEndDate", value: e.target.value },
//                   })
//                 }
//                 inputProps={{
//                   max: new Date().toISOString().split("T")[0],
//                 }}
//                 error={!!errors.projectEndDate}
//               />

//               {errors.projectEndDate && (
//                 <span className="error-text">{errors.projectEndDate}</span>
//               )}
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6} lg={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="JIRA_URL">Jira URL</label>
//               <input
//                 type="text"
//                 name="JIRA_URL"
//                 className="project_input-feald"
//                 placeholder="Enter Jira Url"
//                 value={formData.JIRA_URL}
//                 onChange={handleChange}
//               />
//             </div>
//           </Grid>

//           <Grid item xs={12} sm={6} md={6} lg={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="app_url">App URL</label>
//               <input
//                 type="text"
//                 name="APP_URL"
//                 className="project_input-feald"
//                 placeholder="Enter App Url"
//                 value={formData.APP_URL}
//                 onChange={handleChange}
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={6} lg={6}>
//             <div className="project-name-label-card">
//               <label htmlFor="AdditionalInfo">Additional Information</label>
//               <input
//                 type="text"
//                 name="AdditionalInfo"
//                 className="project_input-feald"
//                 placeholder="Additional Information..."
//                 value={formData?.AdditionalInfo}
//                 onChange={handleChange}
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={3} md={3} lg={3}>
//             <label htmlFor="region">Region</label>
//             <div>
//               {editMode ? (
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="region"
//                   value={formData.region?.RegionName || ""}
//                   style={{
//                     height: 40,
//                     width: 220,
//                     borderRadius: 3,
//                     border: "solid gray",
//                   }}
//                   readOnly
//                 />
//               ) : (
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="region"
//                   value={selectedRegion?.name || ""}
//                   readOnly
//                   style={{
//                     height: 40,
//                     width: 220,
//                     borderRadius: 3,
//                     border: "solid gray",
//                   }}
//                 />
//               )}
//             </div>
//           </Grid>

//           <Grid container spacing={2}>
//             <Grid item xs={10} sx={{ margin: 2, marginLeft: 10 }}>
//               {tableData.length > 0 && (
//                 <TableContainer
//                   component={Paper}
//                   sx={{
//                     marginTop: 2,
//                     maxHeight: tableData.length > 3 ? 270 : "auto",
//                     overflow: "auto",
//                   }}
//                 >
//                   <Table stickyHeader>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell sx={{ padding: 1 }}>Name</TableCell>
//                         <TableCell sx={{ padding: 1 }}>Role</TableCell>
//                         <TableCell sx={{ padding: 1 }}>StartDate</TableCell>
//                         <TableCell sx={{ padding: 1 }}>EndDate</TableCell>
//                         <TableCell sx={{ padding: 1 }}>Percentage</TableCell>
//                         <TableCell sx={{ padding: 1 }}>Billable</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {tableData.map((row, index) => (
//                         <TableRow key={row.id} sx={{ height: 20 }}>
//                           <TableCell sx={{ padding: 1 }}>{row.name}</TableCell>
//                           <TableCell sx={{ width: "30%" }}>
//                             {MultiSelectDropdown2(
//                               data,
//                               ConfigData.map((each) => each.Role),
//                               "role",
//                               index
//                             )}
//                           </TableCell>
//                           <TableCell sx={{ width: "30%" }}>
//                             <TextField
//                               name="startDate"
//                               variant="outlined"
//                               required
//                               type="date"
//                               value={
//                                 row.startDate
//                                   ? new Date(row.startDate)
//                                       .toISOString()
//                                       .split("T")[0]
//                                   : ""
//                               }
//                               onChange={(e) =>
//                                 handleTableChange(
//                                   index,
//                                   "startDate",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </TableCell>
//                           <TableCell>
//                             <TextField
//                               name="endDate"
//                               variant="outlined"
//                               type="date"
//                               value={
//                                 row.endDate
//                                   ? new Date(row.endDate)
//                                       .toISOString()
//                                       .split("T")[0]
//                                   : ""
//                               }
//                               onChange={(e) =>
//                                 handleTableChange(
//                                   index,
//                                   "endDate",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </TableCell>
//                           <TableCell sx={{ padding: 1 }}>
//                             <TextField
//                               type="number"
//                               value={row.percentage}
//                               onChange={(e) =>
//                                 handleTableChange(
//                                   index,
//                                   "percentage",
//                                   e.target.value
//                                 )
//                               }
//                               inputProps={{ min: "0", max: "100" }}
//                             />
//                           </TableCell>
//                           <TableCell sx={{ padding: 1 }}>
//                             <Checkbox
//                               checked={row.billable}
//                               onChange={() =>
//                                 handleTableChange(index, "billable")
//                               }
//                               sx={{ padding: 0 }}
//                             />
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               )}
//             </Grid>
//           </Grid>
//         </Grid>

//         <Grid item xs={12} sm={12} md={12}>
//           <div className="employee-Actions-container">
//             <button
//               onClick={handleSubmit}
//               className="employee-Action-btn"
//               type="button"
//             >
//               {editMode
//                 ? creating
//                   ? "Updating..."
//                   : "Update"
//                 : creating
//                 ? "Creating..."
//                 : "Create"}
//             </button>
//           </div>
//         </Grid>
//       </form>
//       <ToastContainer />
//     </div>
//   );
// };

// export default ProjectForm;

import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import SelectCreateable from "react-select/creatable";
import makeAnimated from "react-select/animated";
import {
  getProjectData,
  updateProjectCard,
  deleteProjectCard,
  addProjectCard,
} from "../../../../redux/dataSlice";
import {
  getAllEmployeeData,
  getAllEmployeeByRegion,
} from "../../../../redux/dataSlice";
import {
  Grid,
  Checkbox,
  Table,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableContainer,
  TextField,
} from "@mui/material";
import CreatableSelect from "react-select/creatable";
import "./index.css";

const ProjectForm = ({ editMode = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedRegion } = useSelector((state) => state.regions);
  const [formData, setFormData] = useState({
    projectId: "",
    projectTitle: "",
    projectDescription: "",
    vendorName: "",
    projectStartDate: "",
    projectEndDate: "",
    projectStatus: "Yet-to-Start",
    TechStack: [],
    region: "",
    percentageOfCompletion: 0,
  });
  const [creating, setCreating] = useState(false);

  var data;
  const Data = useSelector((state) => state.Data);

  if (Data.Resources !== undefined) {
    data = Data.Resources.Employee_Details;
  }

  const StatusData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.map((e) => e.Status)
  );
  const ConfigData = useSelector(
    (state) => state.Data.Resources.ConfigData_Details
  );
  const employees = useSelector(
    (state) => state?.Data?.Resources?.employee?.data
  );
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [tableData, setTableData] = useState([]);

  const { id } = useParams();
  const [isFocused, setIsFocused] = useState(false);
  const [errors, setErrors] = useState({
    projectStartDate: "",
    projectEndDate: "",
  });

  console.log(selectedRegion?._id, "regions2");

  const validate = () => {
    const errors = {};
    if (!formData.projectId) errors.projectId = "Project ID is required";
    if (!formData.projectTitle)
      errors.projectTitle = "Project Title is required";
    if (!formData.projectDescription)
      errors.projectDescription = "Project Description is required";
    if (!formData.vendorName) errors.vendorName = "Client Name is required";
    if (formData.TechStack.length === 0)
      errors.TechStack = "Technologies are required";
    if (!formData.projectStatus)
      errors.projectStatus = "Project Status is required";
    if (!formData.projectStartDate && formData.projectEndDate)
      errors.projectStartDate = "Project cannot have EndDate without StartDate";
    if (!formData.categoryType)
      errors.categoryType = "Project Category is required";
    return errors;
  };
  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "projectId":
        if (!value) error = "Project ID is required";
        break;
      case "projectTitle":
        if (!value) error = "Project Title is required";
        break;
      case "TechStack":
        if (!value) error = "Technologies are required";
        break;
      case "projectDescription":
        if (!value) error = "Project Description is required";
        break;
      case "vendorName":
        if (!value) error = "Client Name is required";
        break;
      case "categoryType":
        if (!value) error = "Project Category is required";
        break;
      case "projectStatus":
        if (!value) error = "Project Status is required";
        break;
      case "projectStartDate":
        if (
          formData.projectEndDate &&
          new Date(value) > new Date(formData.projectEndDate)
        ) {
          error = "Project Start Date cannot be after Project End Date";
        }
        break;
      case "projectEndDate":
        if (
          formData.projectStartDate &&
          new Date(value) < new Date(formData.projectStartDate)
        ) {
          error = "Project End Date cannot be before Project Start Date";
        }
        break;

      case "percentageOfCompletion":
        const percentage = Number(value);
        if (isNaN(percentage)) {
          error = "Percentage of Completion must be a number.";
        } else if (percentage < 0 || percentage > 100) {
          error = "Percentage of Completion should be between 0 and 100.";
        }
        break;

      default:
        break;
    }
    setErrors((prev) => ({ ...prev, [name]: error }));
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  useEffect(() => {
    if (editMode) {
      dispatch(getProjectData(id))
        .then((response) => {
          const currentProject = response?.payload || {};
          console.log("response in formData in ProjectData", response?.payload);
          // Map values for Technologies (TechStack)
          const technologies =
            currentProject.TechStack?.map((tech) => ({
              label: tech,
              value: tech,
            })) || [];

          const newTableData = currentProject.projectTeam.map((item) => ({
            id: item._id,
            name: `${item.FirstName || ""} ${item.LastName || ""}`,
            email: item.email,
            role: item.Project[0]?.role || "",
            startDate: item.Project[0]?.startDate || null,
            endDate: item.Project[0]?.endDate || null,
            percentage: item.Project[0]?.percentage || 100,
            billable:
              item.Project[0]?.billable !== undefined
                ? item.Project[0].billable
                : true,
          }));

          // Separately mapping projectTeamOther
          const newProjectTeamOtherData = currentProject.projectTeamOther.map(
            (item) => ({
              id: item.id,
              name: item.name,
              role: item.role || "custom role",
              startDate: item.startDate || null,
              endDate: item.endDate || null,
              percentage: item.percentage || 100,
              billable: item.billable || true,
            })
          );

          const combinedTeamData = [
            ...newTableData,
            ...newProjectTeamOtherData,
          ];

          // Set the combined table data for rendering
          setTableData(combinedTeamData);

          // Set formData with the existing project data
          setFormData({
            ...currentProject,
            TechStack: technologies, // Populate TechStack for Technologies field
            projectTeam: combinedTeamData.map((team) => ({
              value: team.id,
              label: team.name,
              role: team.role,
            })),
          });
        })
        .catch((error) => {
          console.error("Error fetching project data:", error);
        });
    } else {
      // Set default region value for creating employee
      if (selectedRegion) {
        setFormData((prevData) => ({
          ...prevData,
          region: selectedRegion?._id,
        }));
      }
    }
  }, [id, editMode, dispatch, selectedRegion]);

  useEffect(() => {
    console.log("projects form regions", selectedRegion);
    if (selectedRegion && selectedRegion?._id) {
      dispatch(getAllEmployeeByRegion({ id: selectedRegion?._id })).then(
        (response) => {
          console.log("response from dropdown", response);
          // if (response?.data?.statusCode === 401) {
          //   navigate("/session-expired");
          // } else {
          setEmployeeOptions(
            response?.payload?.map((emp) => {
              return {
                label: `${emp?.FirstName ? emp.FirstName : ""} ${
                  emp?.LastName ? emp.LastName : ""
                }`,
                value: emp._id,
              };
            })
          );
          // }
        }
      );
    }
  }, [selectedRegion]);

  // const handleRoleChange = (selectedOption, index) => {
  //   setTableData((prevTableData) => {
  //     const updatedTableData = [...prevTableData];
  //     if (updatedTableData[index]?.id === "others") {
  //       // Check if another entry with "others" is already in the table
  //       const existingOthersIndex = updatedTableData.findIndex(
  //         (item) => item.id === "others"
  //       );

  //       if (existingOthersIndex !== -1 && existingOthersIndex !== index) {
  //         // If the entry is already in the table and it's not the one we're editing, skip adding a new one
  //         return updatedTableData;
  //       }
  //     }
  //     if (index >= 0 && index < updatedTableData.length) {
  //       updatedTableData[index] = {
  //         ...updatedTableData[index],
  //         role: selectedOption ? selectedOption.value : "custom role",
  //       };
  //     }
  //     return updatedTableData;
  //   });
  // };
  const handleRoleChange = (selectedOption, index) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      if (index >= 0 && index < updatedTableData.length) {
        updatedTableData[index] = {
          ...updatedTableData[index],
          role: selectedOption ? selectedOption.value : "", // Ensure value is handled properly
        };
      }
      return updatedTableData;
    });
  };
  function MultiSelectDropdown2(data, opt, key, index) {
    const selectedRole = tableData[index]?.role || "";
    const optionList = [];

    if (data !== undefined) {
      opt.forEach((each) => {
        each.map((e) => {
          optionList.push({ value: e.name, label: e.name });
          return null;
        });
      });
    }

    const uniq_options = uniqByKeepLast(optionList, (o) => o.value);

    return (
      <Select
        components={makeAnimated()}
        theme={customTheme}
        options={uniq_options}
        onChange={(selectedOption) => handleRoleChange(selectedOption, index)}
        placeholder="Select"
        isSearchable
        value={
          selectedRole ? { value: selectedRole, label: selectedRole } : null
        }
        required={true}
        name={key}
        styles={{
          control: (styles) => ({
            ...styles,
            width: "160px",
            backgroundColor: "white",
            "@media (min-width: 1440px) and (max-width: 2560px)": {
              padding: 4,
            },
            "@media (min-width: 2560px)": {
              padding: 18,
              fontSize: 30,
            },
          }),
        }}
      />
    );
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === "percentageOfCompletion" ? Number(value) : value;
    const currentDate = new Date().toISOString().split("T")[0];
    switch (name) {
      case "percentageOfCompletion":
        if (newValue === 100) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            percentageOfCompletion: newValue,
            projectStatus: "Completed",
            projectEndDate: prevFormData.projectEndDate || currentDate,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            percentageOfCompletion: newValue,
            projectStatus:
              prevFormData.projectStatus === "Completed"
                ? "In-Progress"
                : prevFormData.projectStatus,
          }));
        }
        break;

      case "projectEndDate":
        setFormData((prevFormData) => ({
          ...prevFormData,
          projectEndDate: newValue,
          projectStatus: "Completed",
          percentageOfCompletion: 100,
        }));
        break;

      case "projectStatus":
        if (newValue === "Completed") {
          setFormData((prevFormData) => ({
            ...prevFormData,
            projectStatus: newValue,
            percentageOfCompletion: 100,
            projectEndDate: prevFormData.projectEndDate || currentDate,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            projectStatus: newValue,
          }));
        }
        break;

      default:
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: newValue,
        }));
        break;
    }

    handleBlur(event);
  };
  useEffect(() => {
    if (formData.projectStatus === "Completed" && !formData.projectEndDate) {
      const currentDate = new Date().toISOString().split("T")[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        projectEndDate: currentDate,
      }));
    }
  }, [formData.projectStatus]);

  function handleSelectChange(selectedOption, selectedName) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [selectedName]: selectedOption?.value || "",
      };
    });
    validateField(selectedName, selectedOption);
  }

  const handleDateChange = (fieldName, dateValue) => {
    setFormData((prevData) => {
      const newFormData = { ...prevData, [fieldName]: dateValue };

      handleBlur({ target: { name: fieldName, value: dateValue } });
      return newFormData;
    });
  };

  function handleMultiSelectChange(selectedOption, selectedName) {
    setFormData({
      ...formData,
      [selectedName]: selectedOption,
    });
    validateField(selectedName, selectedOption);
  }
  function uniqByKeepLast(each, key) {
    return [...new Map(each.map((x) => [key(x), x])).values()];
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setCreating(true);
      let finalData = { ...formData };

      Object.keys(finalData).forEach((key) => {
        if (
          [
            "qaLead",
            "teamLead",
            "projectManager",
            "projectTeam",
            "TechStack",
          ].includes(key)
        ) {
          if (key === "projectTeam") {
            finalData[key] = tableData
            // finalData[key] = finalData[key].map((item) => {
            //   const tableRow = tableData.find((row) => row.id === item?.value);
            //   return {
            //     id: item.__isNew__ ? "others" : item.value,
            //     name: item.__isNew__
            //       ? item.label
            //       : tableRow?.name || item.label,
            //     percentage: tableRow ? Number(tableRow.percentage) : 100,
            //     billable: tableRow ? tableRow.billable : true,
            //     startDate: tableRow?.startDate || null,
            //     endDate: tableRow?.endDate || null,
            //     role:
            //       item.role || (tableRow?.role ? tableRow.role : "Team Member"),
            //   };
            // });

            // Handle other fields like qaLead, teamLead, projectManager
          } else if (
            ["qaLead", "teamLead", "projectManager"].includes(key) &&
            finalData[key]?.__isNew__
          ) {
            finalData[key] = { name: finalData[key]?.value, id: "others" };
          } else if (key === "TechStack") {
            finalData[key] = finalData[key]?.map((item) => item?.value);
          } else {
            const tableRow = tableData.find(
              (row) => row.id === finalData[key]?.value
            );
            finalData[key] = {
              id: finalData[key]?.value,
              role: tableRow ? tableRow.role : "Team Member",
              startDate: tableRow ? tableRow.startDate : null,
              endDate: tableRow ? tableRow.endDate : null,
              percentage: tableRow ? Number(tableRow.percentage) : 100,
              billable: tableRow ? tableRow.billable : true,
            };
          }
        }
      });
      try {
        let reponse;
        if (editMode) {
          reponse = await dispatch(updateProjectCard(finalData));
        } else {
          reponse = await dispatch(addProjectCard(finalData));
        }
        setCreating(false);
        if (!reponse?.error) {
          setTimeout(() => {
            navigate("/projects", {
              state: { headerText: "Projects" }, // State must be an object
            });
          }, 2000);
        }
      } catch (error) {
        setCreating(false);
      }
    } else {
      setErrors(errors);
    }
  };

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "orange",
        primary: "green",
      },
    };
  }
  const getValues = (item) => {
    if (!item) return item;
    if (typeof item == "string") return { value: item, label: item };
    if (Array.isArray(item))
      return item.map((v) => {
        return { value: v, label: v };
      });
    return item;
  };
  function MultiSelectDropdown(data, opt, key) {
    const optionList = [];

    if (data !== undefined) {
      opt.forEach((each) => {
        each.map((e) => {
          optionList.push({ value: e.name, label: e.name });
          return null;
        });
      });
    }

    const uniq_options = uniqByKeepLast(optionList, (o) => o.value);

    return key === "Skillset" || key === "technology" || key === "TechStack" ? (
      <Select
        components={makeAnimated()}
        theme={customTheme}
        options={uniq_options}
        onChange={(selectedOption) => {
          handleMultiSelectChange(selectedOption, key);
          setIsFocused(true);
        }}
        className="employee-multi-select"
        placeholder="Select"
        isSearchable
        isMulti
        value={formData?.TechStack}
        required={true}
        name={key}
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        onMenuClose={() => {
          if (isFocused) {
            const value = getValues(formData[key]);
            const error = validateField(key, value.length);
            setIsFocused(false);
          }
        }}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            "@media (min-width: 1440px) and (max-width: 2560px)": {
              padding: 4,
            },
            "@media (min-width: 2560px)": {
              padding: 19,
              fontSize: 30,
            },
          }),
        }}
      />
    ) : (
      <Select
        components={makeAnimated()}
        theme={customTheme}
        options={uniq_options}
        onChange={(selectedOption) => handleSelectChange(selectedOption, key)}
        className="employee-multi-select"
        placeholder="Select"
        value={key === "categoryType" && getValues(formData?.categoryType)}
        isSearchable
        required={true}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            "@media (min-width: 1440px) and (max-width: 2560px)": {
              padding: 4,
            },
            "@media (min-width: 2560px)": {
              padding: 18,
              fontSize: 30,
            },
          }),
        }}
      />
    );
  }

  const handleTableChange = (index, field, value) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      if (field === "percentage") {
        updatedTableData[index][field] = Number(value);
      } else if (field === "role") {
        updatedTableData[index][field] = value;
      } else if (field === "startDate") {
        updatedTableData[index][field] = value;
      } else if (field === "endDate") {
        updatedTableData[index][field] = value;
      } else if (field === "billable") {
        updatedTableData[index][field] = !updatedTableData[index][field];
      } else {
        updatedTableData[index][field] = value;
      }
      return updatedTableData;
    });
  };

  // const generateUniqueId = () => {
  //   return 'id-' + Date.now() + '-' + Math.random().toString(36).substr(2, 9);
  // };
  const generateUniqueId = () => {
    // Generate a timestamp
    const timestamp = Date.now().toString(36); // Convert to base-36
    // Generate a random string
    const randomPart = Math.random().toString(36).substr(2, 9); // 9 characters
    // Concatenate parts with a prefix
    return `uid-${timestamp}-${randomPart}`;
};


  function handleCustomSelectChange(selectedOption, selectedName) {
    const selectedArray = Array.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];

    setFormData((prevFormData) => ({
      ...prevFormData,
      [selectedName]: selectedOption,
    }));

    // setTableData((prevTableData) => {
    //   if (selectedName === "projectTeam") {
    //     const currentTeamIds = (formData?.projectTeam || []).map(
    //       (item) => item.value
    //     );
    //     const selectedIds = selectedArray.map((item) => item.value);
    //     const removedIds = currentTeamIds.filter(
    //       (id) => !selectedIds.includes(id)
    //     );
    //     let updatedTableData = prevTableData.filter(
    //       (row) => !removedIds.includes(row.id)
    //     );
    //     const newEntries = selectedArray
    //       .filter(
    //         (item) => !updatedTableData.some((row) => row.id === item.value)
    //       )
    //       .map((item) => ({
    //         id: item.value,
    //         name: item.label,
    //         percentage: 100,
    //         billable: true,
    //       }));

    //     return [...updatedTableData, ...newEntries];
    //   } else {
    //     const newTableData = prevTableData.filter(
    //       (row) => row.id !== formData[selectedName]?.value
    //     );

    //     selectedArray.forEach((item) => {
    //       newTableData.push({
    //         id: item.value,
    //         name: item.label,
    //         percentage: 100,
    //         billable: true,
    //       });
    //     });

    //     return newTableData;
    //   }
    // });
    setTableData((prevTableData) => {
      if (selectedName === "projectTeam") {
        const selectedIds = selectedArray.map((item) => {
          // If the item is a free-text option, generate a new unique ID
          return item.__isNew__ ? generateUniqueId() : item.value;
        });
  
        const filteredTableData = prevTableData.filter((row) =>
          selectedIds.includes(row.id)
        );
  
        const newEntries = selectedArray
          .filter((item) => {
            const isExisting = filteredTableData.some((row) => row.id === item.value);
            return !isExisting; // Only keep items that are not already in the filtered data
          })
          .map((item) => ({
            id: item.__isNew__ ? generateUniqueId() : item.value, // Generate unique ID if new
            name: item.label,
            percentage: 100,
            billable: true,
            role: "Select role",
            startDate: new Date(),
            endDate: "",
          }));
  
        return [...filteredTableData, ...newEntries];
      }
      return prevTableData; // If not projectTeam, return previous data
    });
  }

  return (
    <div className="project-form-container">
      <div className="project-details">
        <h3>Project Details</h3>
        <div className="close-container">
          <Link to="/projects" state={{ headerText: "Projects" }}>
            <AiOutlineClose className="back-option" />
          </Link>
        </div>
      </div>

      <form className="project-form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="projectId">
                Project Id <span className="required-star">*</span>
              </label>
              <input
                type="text"
                name="projectId"
                className="project_input-feald"
                placeholder="Enter Project Id"
                value={formData.projectId}
                onChange={handleChange}
                required
              />
              {errors.projectId && (
                <span className="error-text">{errors.projectId}</span>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="projectTitle">
                Project Title <span className="required-star">*</span>
              </label>
              <input
                type="text"
                name="projectTitle"
                className="project_input-feald"
                placeholder="Enter project Title"
                value={formData.projectTitle}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
              {errors.projectTitle && (
                <span className="error-text">{errors.projectTitle}</span>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="projectDescription">
                Project Description <span className="required-star">*</span>
              </label>
              <input
                type="text"
                name="projectDescription"
                className="project_input-feald"
                placeholder="Enter  Project Desc"
                value={formData.projectDescription}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
              {errors.projectDescription && (
                <span className="error-text">{errors.projectDescription}</span>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="employee-label-card">
              <label htmlFor="CoreSkills">
                Technologies <span className="required-star">*</span>
              </label>
              {MultiSelectDropdown(
                employees,
                ConfigData.map((each) => each.Skills),
                "TechStack"
              )}
              {errors.TechStack && (
                <span className="error-text">{errors.TechStack}</span>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="employee-label-card">
              <label>Project Team</label>

              <CreatableSelect
                isMulti
                components={makeAnimated()}
                theme={customTheme}
                options={employeeOptions}
                value={formData?.projectTeam}
                onChange={(selectedOption) =>
                  handleCustomSelectChange(selectedOption, "projectTeam")
                }
                className="employee-multi-select"
                placeholder="Select"
                isSearchable
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="vendorName">
                Client Name <span className="required-star">*</span>
              </label>
              <input
                type="text"
                name="vendorName"
                className="project_input-feald"
                placeholder="Enter Vendor Name"
                value={formData.vendorName}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
              {errors.vendorName && (
                <span className="error-text">{errors.vendorName}</span>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="employee-label-card">
              <label htmlFor="categoryType">
                Project Category <span className="required-star">*</span>
              </label>
              {MultiSelectDropdown(
                employees,
                ConfigData.map((each) => each.projectCategory),
                "categoryType"
              )}
              {errors.categoryType && (
                <span className="error-text">{errors.categoryType}</span>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="projectEndDate" className="Project_status">
                Project Status <span className="required-star">*</span>
              </label>
              <div className="select-container">
                {StatusData.length > 0 &&
                  StatusData.map((option) =>
                    option.map((each) => (
                      <div key={each.value}>
                        <input
                          type="radio"
                          id={each.value}
                          name="projectStatus"
                          value={each.value}
                          checked={formData.projectStatus === each.value}
                          onChange={handleChange}
                          className="radio-input"
                        />
                        <label htmlFor={each.value}>{each.name}</label>
                      </div>
                    ))
                  )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="percentageOfCompletion">
                Percentage of Completion{" "}
              </label>
              <input
                type="number"
                name="percentageOfCompletion"
                className="project_input-feald"
                placeholder="Enter Percentage of Completion"
                value={formData.percentageOfCompletion}
                onBlur={handleBlur}
                onChange={handleChange}
                min={0}
                max={100}
                required
              />
              {errors.percentageOfCompletion && (
                <span className="error-text">
                  {errors.percentageOfCompletion}
                </span>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="project-name-label-card">
              <label htmlFor="projectStartDate">Project Start Date</label>
              <TextField
                className="project_input-feald"
                id="projectStartDate"
                type="date"
                required
                sx={{
                  width: "100%",
                  maxWidth: 505,
                  backgroundColor: "white",
                  "@media (max-width: 600px)": {
                    maxWidth: 400,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    maxWidth: 250,
                  },
                  "@media (min-width: 961px) and (max-width: 2560px)": {
                    maxWidth: "100vw",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleBlur}
                value={formData?.projectStartDate?.slice(0, 10)}
                onChange={(e) =>
                  handleDateChange("projectStartDate", e.target.value)
                }
                inputProps={{
                  max:
                    formData.projectStatus === "Completed" ||
                    formData.projectStatus === "In-Progress"
                      ? new Date().toISOString().split("T")[0]
                      : undefined,
                }}
                error={!!errors.projectStartDate}
              />
              {errors.projectStartDate && (
                <span className="error-text">{errors.projectStartDate}</span>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="project-name-label-card">
              <label htmlFor="projectEndDate">Project End Date</label>
              <TextField
                className="project_input-feald"
                id="projectEndDate"
                type="date"
                required
                sx={{ width: "100%", maxWidth: 505, backgroundColor: "white" }}
                InputLabelProps={{ shrink: true }}
                value={formData?.projectEndDate?.slice(0, 10)}
                onChange={(e) =>
                  handleChange({
                    target: { name: "projectEndDate", value: e.target.value },
                  })
                }
                inputProps={{
                  max: new Date().toISOString().split("T")[0],
                }}
                error={!!errors.projectEndDate}
              />

              {errors.projectEndDate && (
                <span className="error-text">{errors.projectEndDate}</span>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="JIRA_URL">Jira URL</label>
              <input
                type="text"
                name="JIRA_URL"
                className="project_input-feald"
                placeholder="Enter Jira Url"
                value={formData.JIRA_URL}
                onChange={handleChange}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="app_url">App URL</label>
              <input
                type="text"
                name="APP_URL"
                className="project_input-feald"
                placeholder="Enter App Url"
                value={formData.APP_URL}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="AdditionalInfo">Additional Information</label>
              <input
                type="text"
                name="AdditionalInfo"
                className="project_input-feald"
                placeholder="Additional Information..."
                value={formData?.AdditionalInfo}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <label htmlFor="region">Region</label>
            <div>
              {editMode ? (
                <input
                  type="text"
                  className="form-control"
                  id="region"
                  value={formData.region?.RegionName || ""}
                  style={{
                    height: 40,
                    width: 220,
                    borderRadius: 3,
                    border: "solid gray",
                  }}
                  readOnly
                />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  id="region"
                  value={selectedRegion?.name || ""}
                  readOnly
                  style={{
                    height: 40,
                    width: 220,
                    borderRadius: 3,
                    border: "solid gray",
                  }}
                />
              )}
            </div>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={10} sx={{ margin: 2, marginLeft: 10 }}>
              {tableData.length > 0 && (
                <TableContainer
                  component={Paper}
                  sx={{
                    marginTop: 2,
                    maxHeight: tableData.length > 3 ? 270 : "auto",
                    overflow: "auto",
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ padding: 1 }}>Name</TableCell>
                        <TableCell sx={{ padding: 1 }}>Role</TableCell>
                        <TableCell sx={{ padding: 1 }}>StartDate</TableCell>
                        <TableCell sx={{ padding: 1 }}>EndDate</TableCell>
                        <TableCell sx={{ padding: 1 }}>Percentage</TableCell>
                        <TableCell sx={{ padding: 1 }}>Billable</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((row, index) => (
                        <TableRow key={row.id} sx={{ height: 20 }}>
                          <TableCell sx={{ padding: 1 }}>{row.name}</TableCell>
                          <TableCell sx={{ width: "30%" }}>
                            {MultiSelectDropdown2(
                              data,
                              ConfigData.map((each) => each.Role),
                              "role",
                              index
                            )}
                          </TableCell>
                          <TableCell sx={{ width: "30%" }}>
                            <TextField
                              name="startDate"
                              variant="outlined"
                              required
                              type="date"
                              value={
                                row.startDate
                                  ? new Date(row.startDate)
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                              }
                              onChange={(e) =>
                                handleTableChange(
                                  index,
                                  "startDate",
                                  e.target.value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              name="endDate"
                              variant="outlined"
                              type="date"
                              value={
                                row.endDate
                                  ? new Date(row.endDate)
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                              }
                              onChange={(e) =>
                                handleTableChange(
                                  index,
                                  "endDate",
                                  e.target.value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 1 }}>
                            <TextField
                              type="number"
                              value={row.percentage}
                              onChange={(e) =>
                                handleTableChange(
                                  index,
                                  "percentage",
                                  e.target.value
                                )
                              }
                              inputProps={{ min: "0", max: "100" }}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 1 }}>
                            <Checkbox
                              checked={row.billable}
                              onChange={() =>
                                handleTableChange(index, "billable")
                              }
                              sx={{ padding: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className="employee-Actions-container">
            <button
              onClick={handleSubmit}
              className="employee-Action-btn"
              type="button"
            >
              {editMode
                ? creating
                  ? "Updating..."
                  : "Update"
                : creating
                ? "Creating..."
                : "Create"}
            </button>
          </div>
        </Grid>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ProjectForm;
