import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import "./index.css";
import CustomDataGrid from "../../../utilites/CustomDataGrid";
import {
  getAllEmployeeData,
  getAllEmployeeByRegion,
} from "../../../../redux/dataSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import { deleteEmployeeCard } from "../../../../redux/dataSlice";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const EmployeeListView = ({ data, filterStart }) => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state?.Data?.Resources?.Employee_Details);
  const employee = useSelector((state) => state?.Data?.Resources?.employee);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("role").split(",");

  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  console.log(selectedRegion?._id, "regions2");

  console.log("data means reagions from store Employee List View", data);
  const columns = [
    { field: "ID", headerName: "EmpID", headerAlign: 'center' },
    {
      field: "FirstName",
      headerName: "Name",
      width: 130,
      flex: 0.1,
      headerAlign: 'center',
      sortable: true,
      filterable: true,
      valueGetter: (params) => `${params.row.FirstName} ${params.row.LastName}`,
    },

    {
      field: "role",
      headerName: "Role",
      width: 170,
      flex: 0.1,
      sortable: true,
      headerAlign: 'center',
      filterable: true,
      valueGetter: (params) => params.row.role,
    },
    {
      field: "Department",
      headerName: "Department",
      width: 170,
      sortable: true,
      flex: 0.1,
      headerAlign: 'center',
      filterable: true,
      valueGetter: (params) => params.row.Department,
    },
    {
      field: "employmentStatus",
      headerName: "Status",
      width: 80,
      headerAlign: 'center',
      flex: 0.1,
      sortable: true,
      filterable: true,
      valueGetter: (params) => params.row.employmentStatus,
    },
    {
      field: "DOJ",
      headerName: "DOJ",
      width: 120,
      headerAlign: 'center',
      flex: 0.1,
      sortable: true,
      filterable: true,
      type: "date", // set the type of the column to "date"
      valueGetter: (params) =>
        params.row.DOJ ? new Date(params.row.DOJ) : null,
      valueFormatter: (params) => {
        if (params.value) {
          return params.value
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, "-");
        } else {
          return "";
        }
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      headerAlign: 'center',
      sortable: true,
      flex: 0.1,
      filterable: true,
      valueGetter: (params) => params.row.email,
    },
    {
      field: "phoneNumber",
      headerName: "Contact Number",
      width: 120,
      headerAlign: 'center',
      sortable: true,
      flex: 0.1,
      filterable: true,
      valueGetter: (params) => params.row.phoneNumber,
    },
    {
      field: "View",
      headerName: "Actions",
      width: 120,
      headerAlign: 'center',
      filterable: false,
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <strong>
            <Tooltip placement="top" title={"Edit"}>
              <IconButton
                style={{ color: "#24CCEF", cursor: "pointer" }}
                onClick={() =>
                  navigate(`/employee_details/${params.row._id}`, {
                    state: { headerText: "Employee" }, // State must be an object
                  })
                }
                aria-label="Edit"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title={"Delete"}>
              <IconButton
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setOpen(true);
                  setDeleteId(params.row._id);
                }}
                aria-label="Delete"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </strong>
        );
      },
    },
  ];

  useEffect(() => {
    if (selectedRegion && selectedRegion?._id) {
      dispatch(getAllEmployeeByRegion({ id: selectedRegion?._id }));
    }
  }, [selectedRegion]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteYesClick = () => {
    dispatch(deleteEmployeeCard(deleteId));
    setOpen(false);
  };

  return (
    <Box
      sx={{
        height: "80vh",
        width: "100%",
      }}
    >
      <Dialog
        open={open}
        onClose={() => console.log("close dialog")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#f0f0f0",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this Employee?"}
        </DialogTitle>
        <DialogContent>
          <Typography color="textPrimary">
            Are you sure you want to delete this Employee?
          </Typography>
        </DialogContent>

        <DialogActions>
          <button
            onClick={handleClose}
            className="dialog-cancel-btn"
            color="primary"
          >
            Cancel
          </button>
          <button
            className="dialog-delete-btn"
            onClick={(event) => {
              handleDeleteYesClick();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
      {!filterStart && (
        <CustomDataGrid
          Data={employee?.data}
          rows={employee?.data?.map((d) => ({
            ...d,
            id: d?._id,
          }))}
          loading={employee?.loading}
          getRowId={(row) => row._id}
          columns={
            userRole.some((role) => ["ADMIN", "HR"].includes(role))
              ? columns
              : columns.filter((column) => column.field !== "View")
          }
        />
      )}
      {/* {data.length > 0 && (
        <CustomDataGrid
          Data={Data}
          rows={data}
          getRowId={(row) => row._id}
          columns={
            userRole.some((role) => ["ADMIN", "HR"].includes(role))
              ? columns
              : columns.filter((column) => column.field !== "View")
          }
        />
      )}

      {data.length === 0 && filterStart && (
        <CustomDataGrid
          Data={Data}
          rows={data}
          getRowId={(row) => row._id}
          columns={
            userRole.some((role) => ["ADMIN", "HR"].includes(role))
              ? columns
              : columns.filter((column) => column.field !== "View")
          }
        />
      )} */}
    </Box>
  );
};

export default EmployeeListView;
