import { useState } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import UserGrid from "./UserGrid/UserGrid";
import { MenuItem, Select } from "@mui/material";
import "./users.css";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../../redux/dataSlice";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Users = () => {
  const [open, setOpen] = React.useState(false);
  const defaultRole = "EMPLOYEE";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const UserTypeData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.map((e) => e.UserType)
  );

  const [formData, setFormData] = useState({
    empId: "",
    role: [defaultRole],
    password: "",
    confirmPassword: "",
  });

  const styles = {
    Button: {
      marginLeft: 0,
      backgroundColor: "#24CCEF",
      color: "white",
      border: "none",
      padding: "8px 16px",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  const togglePassword = () => {
    setViewPassword(!viewPassword);
  };

  const toggleConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords are not matched");
      return;
    }
    if (formData.password.length < 8) {
      toast.error("Password must be at least 8 characters.");
      return;
    }

    //Password validation criteria
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$%^&*])[a-zA-Z\d@!#$%^&*]{8,}$/;

    //Explanation: Password must contain at least 8 characters, including one lowercase letter, one uppercase letter, and one digit.

    //Password validation check
    if (!formData.password.match(passwordRegex)) {
      toast.error(
        "Password must contain at least 8 characters, including one lowercase letter, one uppercase letter, one special character, and one digit."
      );
      return;
    }

    try {
      const response = await dispatch(
        addUser({
          empId: formData.empId.toUpperCase(),
          role: formData.role,
          password: formData.password,
        })
      );

      if (response.type === "redux/addUser/fulfilled") {
        setOpen(false);
        toast.success("User created successfully...!", {
          onClose: () => {
            setTimeout(() => {
              navigate("/users");
              window.location.reload();
            }, 1000);
          },
        });
      } else if (
        response.type === "redux/addUser/rejected" &&
        response.payload === "empId already exists"
      ) {
        toast.error("User already exists.", {
          onClose: () => {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
        });
      }
    } catch (error) {
      if (error.payload && error.payload.status === 409) {
        toast.error(error.payload.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <Box className="user-Button">
        <div>
          <Button
            variant="outlined"
            onClick={handleClickOpen}
            style={styles.Button}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add User
          </Button>
        </div>

        <BootstrapDialog
          sx={{ margin: "20px" }}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <div className="user-dialogHeader">
            <h4>Add User</h4>
            <Button onClick={handleClose}>
              <CloseIcon />
            </Button>
          </div>
          <DialogContent dividers sx={{ marginTop: "-30px" }}>
            <form onSubmit={handleSubmit} className="user-form">
              <div className="user-input-container">
                <label htmlFor="empId" className="user-text">
                  User Id <span className="required-star">*</span>
                </label>
                <div className="user-id-container">
                  <input
                    type="text"
                    id="empId"
                    className="user-input-feild uppercase"
                    placeholder="User Id"
                    name="empId"
                    autoComplete="off"
                    value={formData.empId}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="user-password-input-container">
                <label htmlFor="password">
                  New Password <span className="required-star">*</span>
                </label>

                <div className="user-password-container">
                  <input
                    type={viewPassword ? "text" : "password"}
                    id="password"
                    className="user-password-input-feild"
                    placeholder="password"
                    name="password"
                    onChange={handleChange}
                    required
                  />
                  {/* Render eye icon based on showPassword state */}
                  {viewPassword ? (
                    <FaEye
                      className="user-passwordView"
                      onClick={togglePassword}
                    />
                  ) : (
                    <FaEyeSlash
                      className="user-passwordView"
                      onClick={togglePassword}
                    />
                  )}
                </div>
              </div>

              <div className="user-password-input-container">
                <label htmlFor="confirm_password">
                  Confirm Password <span className="required-star">*</span>
                </label>

                <div className="user-password-container">
                  <input
                    type={viewConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    className="user-password-input-feild"
                    placeholder="confirm Password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                  {/* Render eye icon based on showPassword state */}
                  {viewConfirmPassword ? (
                    <FaEye
                      className="user-passwordView"
                      onClick={toggleConfirmPassword}
                    />
                  ) : (
                    <FaEyeSlash
                      className="user-passwordView"
                      onClick={toggleConfirmPassword}
                    />
                  )}
                </div>
              </div>

              <div className="user-input-container">
                <label htmlFor="userType" className="user-text">
                  User Type
                </label>
                <div className="user-type-container">
                  <Select
                    multiple
                    className="user-type-feild"
                    name="role"
                    value={formData.role}
                    onChange={handleSelectChange}
                  >
                    {UserTypeData.length > 0 &&
                      UserTypeData.map((option) =>
                        option.map((each) => (
                          <MenuItem key={each.value} value={each.value}>
                            {each.name}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                </div>
              </div>
              <button className="user-submit" type="submit">
                Submit
              </button>
            </form>
          </DialogContent>
        </BootstrapDialog>
      </Box>
      <UserGrid />
      <ToastContainer />
    </div>
  );
};

export default Users;
