import { Box, Divider, TextField, Typography, Button } from '@mui/material'
import React, { useState } from 'react'
import HelpIcon from '@mui/icons-material/Help';
import ScreeningDropDown from './ScreeningDropDown';
const Interview = () => {
    const [isvisible, setvisible] = useState(false)
    const date = new Date(Date.now()).toISOString().split('T')[0];
    const handleDropDown = () => {
        setvisible(true)
    }
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h5' sx={{ fontWeight: 'bold' }}>My Interviews</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {/* <Button variant='contained' sx={{ borderRadius: '10px' }} onClick={handleDropDown}><HelpIcon /> See Interview Kit</Button> */}
                    <ScreeningDropDown />
                    {/* {isvisible && <Box>
                        <ul>
                            <li>PreEliminary Screening</li>
                            <li>L1Screening</li>
                            <li>L2Screening</li>
                        </ul>
                    </Box>} */}
                </Box>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 1, paddingTop: '10px' }}>
                <Typography sx={{ paddingTop: '15px' }}>Interviews Scheduled on  </Typography><TextField sx={{}} type="date" value={date} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
                <Typography>Karthik</Typography>
                <Typography>PreEliminaryScreening</Typography>
                <Typography>Revathi</Typography>
                <Button variant='contained'>Scorecard</Button>
            </Box>
        </Box>
    )
}
export default Interview;