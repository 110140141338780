import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const ProjectsStatus = () => {
    const Insights = useSelector((state) => state?.Data?.Resources?.Insights?.data) || [];

    // Extract roles data from Insights
    const projectsStatus = Insights?.length > 0 ? Array.from(Insights)[0]?.projectsByMonth : [];

    // Transform projectsStatus into series format
    const transformData = (data) => {
        // Initialize the series structure
        const initialSeries = {
            "Yet-to-Start": Array(12).fill(0),
            "Completed": Array(12).fill(0),
            "In-Progress": Array(12).fill(0),
            "On-Hold": Array(12).fill(0),
        };

        data.forEach(({ Completed, "In-Progress": InProgress, "On-Hold": OnHold, "Yet-to-Start": YetToStart, month }) => {
            if (month >= 1 && month <= 12) {
                initialSeries["Completed"][month - 1] = Completed;
                initialSeries["In-Progress"][month - 1] = InProgress;
                initialSeries["On-Hold"][month - 1] = OnHold;
                initialSeries["Yet-to-Start"][month - 1] = YetToStart;
            }
        });

        return [
            { name: "Yet to Start", data: initialSeries["Yet-to-Start"] },
            { name: "Completed", data: initialSeries["Completed"] },
            { name: "In Progress", data: initialSeries["In-Progress"] },
            { name: "On Hold", data: initialSeries["On-Hold"] },
        ];
    };

    const [series, setSeries] = useState(transformData(projectsStatus));

    useEffect(() => {
        setSeries(transformData(projectsStatus));
    }, [projectsStatus]);

    const [options] = useState({
        chart: {
            type: "bar",
            height: 263, 
            stacked: true, // Enable stacked bars
            toolbar: {
                show: false, // Hide the toolbar
            },
            // background: "#ffffff", // Set chart background to white
        },
        plotOptions: {
            bar: {
                horizontal: false, // Vertical bars
                columnWidth: "80%", // Adjust the width of bars
                endingShape: "rounded", // Rounded bar ends
            },
        },
        dataLabels: {
            enabled: false, // Disable data labels
        },
        xaxis: {
            categories: [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ], // Months
            title: {
                text: "Month", // X-axis title
            },
        },
        yaxis: {
            title: {
                text: "Projects", // Y-axis title
            },
            min: 0, // Start y-axis at 0
        },
        grid: {
            borderColor: "#e0e0e0", // Light grey grid lines
        },
        fill: {
            colors: ["#1E90FF", "#32CD32", "#FF6347", "#FFD700"], // Ensure colors match the series order
        },
        tooltip: {
            theme: "dark", // Dark theme for tooltip
            y: {
                formatter: (value, { seriesIndex, dataPointIndex }) => {
                    const statuses = ["Yet-to-Start", "Completed", "In-Progress", "On-Hold"];
                    const count = series[seriesIndex]?.data[dataPointIndex] || 0;
                    return `${statuses[seriesIndex]}: ${count}`;
                },
            },
            x: {
                formatter: (value, { dataPointIndex }) => {
                    // Calculate total projects for the month
                    const total = series.reduce(
                        (acc, serie) => acc + (serie?.data[dataPointIndex] || 0),
                        0
                    );
                    return `Total Projects: ${total}`;
                },
            },
        },
        legend: {
            position: "bottom", // Position legend at the bottom
            horizontalAlign: "center", // Center align the legend
            markers: {
                fillColors: ["#1E90FF", "#32CD32", "#FF6347", "#FFD700"], // Ensure legend markers match bar colors
            },
        },
    });

    return (
        <div className="chart">
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                width={341} // Set chart width
                height={263} 
            />
        </div>
    );
};

export default ProjectsStatus;
