import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
const NoAccess = () => {
  const navigate = useNavigate();

  return (
    <div className="access-denied-container">
      <h1 className="access-denied-heading">Access Denied</h1>
      <img
        src="https://res.cloudinary.com/dcci6hybb/image/upload/v1680536281/1906.i402.009.P.m004.c23.internet_blocking_isometric-10_ociend.jpg"
        alt="Not Access"
        className="access-denied"
      />
      <button
        type="button"
        className="home-button"
        onClick={() => navigate("/")}
      >
        Home
      </button>
    </div>
  );
};

export default NoAccess;
