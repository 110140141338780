import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DvrIcon from "@mui/icons-material/Dvr";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import DeblurIcon from "@mui/icons-material/Deblur";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import StorageIcon from "@mui/icons-material/Storage";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import "./sidebar.css";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Collapse } from "@mui/material";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import FestivalIcon from "@mui/icons-material/Festival";
import GroupsIcon from "@mui/icons-material/Groups";
import ConfigIcon from "./sidebarIcons/ConfigIcon";
import AuditIcon from "./sidebarIcons/AuditIcon";
import ApplicationsIcon from "./sidebarIcons/ApplicationsIcon";
import JobsIcon from "./sidebarIcons/JobsIcon";
import Recruitmenticon from "./sidebarIcons/Recruitmenticon";
import ProjectsIcon from "./sidebarIcons/ProjectsIcon";
import EmployeeIcon from "./sidebarIcons/EmployeeIcon";
import DashboardIconFigma from "./sidebarIcons/DashboardIcon";
const Sidebar = () => {
  const navigate = useNavigate();

  const [openProject, setOpenProject] = useState(false);
  const [openRecruitment, setOpenRecruitment] = useState(false);
  const [openJob, setOpenJob] = useState(false);
  const location = useLocation();
  const { empid, jobId, id, applicationId } = useParams();

  const userRole = sessionStorage.getItem("role")?.split(",") || [];

  // const handleProjectClick = () => {
  //   setOpenProject(!openProject);
  // };
  const handleRecruitmentClick = () => {
    setOpenRecruitment(!openRecruitment);
  };
  const handleJobClick = () => {
    setOpenJob(!openJob);
  };

  const notifySuccess = () => toast.success("Logout Successfully...!");

  const onClickLogout = () => {
    // Cookies.remove("jwt_token");
    // Cookies.remove("userId");
    // Cookies.remove("user");
    // Cookies.remove("role");
    // Cookies.remove("isAuthenticated");
    // Cookies.set("isAuthenticated", false);
    sessionStorage.clear();
    localStorage.clear();
    notifySuccess();
    setTimeout(() => {
      // <Link to="/" className="not-found-link"></Link>
      navigate("/login", { replace: true });
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="sidebar-container">
      <div className="website-logo">
        <Link to="/" className="link-item">
          <img
            src="https://cdn.prod.website-files.com/659ab33ddfa99d9cee1363da/65ae639cb170ad4608835868_aifa_logo.svg"
            alt="website logo"
          />
        </Link>
      </div>
      <Divider />
      <div className="sidebar">
        <List className="List">
          {/* <ListItem
            disablePadding
            onClick={() => navigate("/")}
            className="ListItem"
          >
            <ListItemButton
              selected={location.pathname === "/"}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#6A5ACD",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFA500",
                  },
                },
              }}
            >
              <ListItemIcon
                sx={{ color: location.pathname === "/" ? "#fff" : "initial" }}
              >
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>
          </ListItem> */}
          {/* {userRole.some((role) => ["ADMIN", "ACCOUNTS"].includes(role)) && (
            <ListItem
              disablePadding
              onClick={() => navigate("/assets")}
              className="ListItem"
            >
              <ListItemButton
                selected={["/assets", "/addAssets", `/assets/${id}`].includes(
                  location.pathname
                )}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <DvrIcon
                    sx={{
                      color: [
                        "/assets",
                        "/addAssets",
                        `/assets/${id}`,
                      ].includes(location.pathname)
                        ? "#fff"
                        : "initial",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Assets</ListItemText>
              </ListItemButton>
            </ListItem>
          )} */}

          <ListItem
            disablePadding
            onClick={() =>
              userRole.some((role) =>
                ["ADMIN", "HR", "EMPLOYEE", "ACCOUNTS"].includes(role)
              ) &&
              navigate("/insights", {
                state: { headerText: "Dashboard" }, // State must be an object
              })
            }
            className="ListItem"
          >
            <ListItemButton
              selected={location.pathname === "/insights"}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#FFA500",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFA500",
                  },
                },
              }}
            >
              <ListItemIcon>
                {/* <TrendingUpIcon
                  sx={{
                    color:
                      location.pathname === "/insights" ? "#fff" : "initial",
                  }}
                /> */}
                <DashboardIconFigma/>
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() =>
              userRole.some((role) =>
                ["ADMIN", "HR", "EMPLOYEE", "ACCOUNTS"].includes(role)
              ) &&
              navigate("/employee", {
                state: { headerText: "Employee" }, // State must be an object
              })
            }
            className="ListItem"
          >
            <ListItemButton
              selected={[
                "/employee",
                "/addEmployee",
                `/employee_details/${empid}`,
              ].includes(location.pathname)}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#FFA500",//#6A5ACD
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFA500",
                  },
                },
              }}
            >
              <ListItemIcon>
                {/* <SupervisorAccountIcon
                  sx={{
                    color: [
                      "/employee",
                      "/addEmployee",
                      `/employee_details/${empid}`,
                    ].includes(location.pathname)
                      ? "#fff"
                      : "initial",
                  }}
                /> */}
               <EmployeeIcon/>
              </ListItemIcon>
              <ListItemText>Employee</ListItemText>
            </ListItemButton>
          </ListItem>

          <div>
            <ListItem
              disablePadding
              onClick={() =>
                userRole.some((role) =>
                  ["ADMIN", "HR", "EMPLOYEE", "ACCOUNTS"].includes(role)
                ) &&
                navigate("/projects", {
                  state: { headerText: "Projects" }, // State must be an object
                })
              }
              className="ListItem"
            >
              <ListItemButton
                selected={["/projects", "/createProject"].includes(
                  location.pathname
                )}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#FFA500",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  {/* <DeblurIcon
                    sx={{
                      color: ["/projects", "/createProject"].includes(
                        location.pathname
                      )
                        ? "#fff"
                        : "initial",
                    }}
                  /> */}

                  <ProjectsIcon/>
                </ListItemIcon>
                <ListItemText primary="Projects" />
                {/* {openProject ? (
                  <ExpandLessIcon color="primary" />
                ) : (
                  <ExpandMoreIcon color="primary" />
                )} */}
              </ListItemButton>
            </ListItem>
            {/* <Collapse in={openProject} timeout="auto" unmountOnExit>
              <ListItem
                button
                selected={[
                  "/assignProject",
                  "/assignProjectName",
                  `/AssignProject/${id}`,
                ].includes(location.pathname)}
                onClick={() =>
                  userRole.some((role) =>
                    ["ADMIN", "HR", "EMPLOYEE", "ACCOUNTS"].includes(role)
                  )
                    ? navigate("/assignProject")
                    : navigate("/noaccess")
                }
                className="NestedListItem"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemText
                  primary="Assign Member"
                  sx={{ marginLeft: "30px", fontWeight: "800" }}
                />
              </ListItem>
              <ListItem
                button
                selected={[
                  "/addProject",
                  "/createProject",
                  `/project/${id}`,
                ].includes(location.pathname)}
                onClick={() =>
                  userRole.some((role) =>
                    ["ADMIN", "HR", "EMPLOYEE", "ACCOUNTS"].includes(role)
                  )
                    ? navigate("/addProject")
                    : navigate("/noaccess")
                }
                className="NestedListItem"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemText
                  primary="Create Project"
                  sx={{ marginLeft: "30px", fontWeight: "800" }}
                />
              </ListItem>
            </Collapse> */}
          </div>

          {/* {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
            <ListItem
              disablePadding
              onClick={() => navigate("/users")}
              className="ListItem"
            >
              <ListItemButton
                selected={location.pathname === "/users"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <PersonPinIcon
                    sx={{
                      color:
                        location.pathname === "/users" ? "#fff" : "initial",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Users</ListItemText>
              </ListItemButton>
            </ListItem>
          )} */}

          {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
            <div>
              <ListItem
                disablePadding
                onClick={handleJobClick}
                className="ListItem"
              >
                <ListItemButton selected={location.pathname === "/recruitment"}>
                  {/* <ListItemIcon>
                    <Diversity3Icon
                      sx={{
                        color:
                          location.pathname === "/recruitment"
                            ? "#fff"
                            : "initial",
                      }}
                    />
                  </ListItemIcon> */}
                  <ListItemIcon>
                    <Recruitmenticon/>
                  </ListItemIcon>
                  <ListItemText primary="Recruitment" />
                  {openJob ? (
                    <ExpandLessIcon color="#fff" />
                  ) : (
                    <ExpandMoreIcon color="#fff" />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse
                in={openJob}
                timeout="auto"
                unmountOnExit
                sx={{ textAlign: "center" }}
              >
                <ListItem
                  button
                  onClick={
                    () =>
                      // userRole.some((role) =>
                      //   ["ADMIN", "HR", "EMPLOYEE"].includes(role)
                      // )
                      // ?
                      userRole.some((role) =>
                        ["ADMIN", "HR", "EMPLOYEE", "ACCOUNTS"].includes(role)
                      ) &&
                      navigate("manageJob", {
                        state: { headerText: "Jobs" }, // State must be an object
                      })
                    // : navigate("/noaccess")
                  }
                  className="NestedListItem"
                  selected={[
                    "/manageJob",
                    "addjob",
                    `editJob/${jobId}`,
                  ].includes(location.pathname)}
                  sx={{
                    paddingLeft: "60px",
                    "&.Mui-selected": {
                      backgroundColor: "#FFA500",//#6A5ACD
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#FFA500",
                      },
                    },
                  }}
                >
                  {" "}
                  <JobsIcon/>
                  <ListItemText
                    primary="Jobs"
                    sx={{
                      marginLeft: "20px",
                      fontWeight: "800",
                      color: "#fff",
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() =>
                    userRole.some((role) =>
                      ["ADMIN", "HR", "EMPLOYEE", "ACCOUNTS"].includes(role)
                    ) &&
                    navigate("/employeeScreening", {
                      state: { headerText: "Applications" }, // State must be an object
                    })
                  }
                  className="NestedListItem"
                  selected={[
                    "/recruitmentHome/employeeScreening",
                    "/employeeScreening",
                    "/addApplication",
                    `/updateApplication/${applicationId}`,
                  ].includes(location.pathname)}
                  sx={{
                    paddingLeft: "60px",
                    "&.Mui-selected": {
                      backgroundColor: "#FFA500",//#6A5ACD
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#FFA500",
                      },
                    },
                  }}
                >
                  {" "}
                 <ApplicationsIcon/>
                  <ListItemText
                    primary="Applications"
                    sx={{
                      marginLeft: "20px",
                      fontWeight: "800",
                      color: "#fff",
                    }}
                  />
                </ListItem>

                {/* {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
                <ListItem
                  button
                  onClick={() => navigate("/addjob")}
                  className="NestedListItem"
                  selected={[
                    "/addjob",
                    "employeeScreening",
                    "addApplication",
                    `updateApplication/${applicationId}`,
                  ].includes(location.pathname)}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#6A5ACD",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#FFA500",
                      },
                    },
                  }}
                >
                  <ListItemText
                    primary=" Add Opening"
                    sx={{ marginLeft: "30px", fontWeight: "800" }}
                  />
                </ListItem>
              )} */}
              </Collapse>
            </div>
          )}

          {/* {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
            <div>
              <ListItem
                disablePadding
                onClick={() => {
                  navigate("/recruitmentHome");
                }}
                className="ListItem"
              >
                <ListItemButton selected={location.pathname === "/recruitment"}>
                  <ListItemIcon>
                    <Diversity3Icon
                      sx={{
                        color:
                          location.pathname === "/recruitment"
                            ? "#fff"
                            : "initial",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Recruitment" />

                </ListItemButton>
              </ListItem>

            </div>
          )} */}

          {/* <ListItem
            disablePadding
            onClick={() => navigate("/profile")}
            className="ListItem"
          >
            <ListItemButton
              selected={location.pathname === "/profile"}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#6A5ACD",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFA500",
                  },
                },
              }}
            >
              <ListItemIcon>
                <ManageAccountsIcon
                  sx={{
                    color:
                      location.pathname === "/profile" ? "#fff" : "initial",
                  }}
                />
              </ListItemIcon>
              <ListItemText>My Profile</ListItemText>
            </ListItemButton>
          </ListItem> */}

          {/* {userRole.some((role) => ["ADMIN"].includes(role)) && (
            <ListItem
              disablePadding
              onClick={() => navigate("/notice")}
              className="ListItem"
            >
              <ListItemButton
                selected={location.pathname === "/notice"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <FestivalIcon
                    sx={{
                      color:
                        location.pathname === "/notice" ? "#fff" : "initial",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Add Event</ListItemText>
              </ListItemButton>
            </ListItem>
          )} */}

          {userRole.some((role) => ["ADMIN"].includes(role)) && (
            <ListItem
              disablePadding
              onClick={() =>
                userRole.some((role) =>
                  ["ADMIN", "HR", "EMPLOYEE", "ACCOUNTS"].includes(role)
                ) &&
                navigate("/config", {
                  state: { headerText: "Config" }, // State must be an object
                })
              }
              className="ListItem"
            >
              <ListItemButton
                selected={location.pathname === "/config"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#FFA500",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  {/* <StorageIcon
                    sx={{
                      color:
                        location.pathname === "/config" ? "#fff" : "initial",
                    }}
                  /> */}
                  <ConfigIcon/>
                </ListItemIcon>
                <ListItemText>Config</ListItemText>
              </ListItemButton>
            </ListItem>
          )}
          <ListItem
            disablePadding
            onClick={() =>
              userRole.some((role) =>
                ["ADMIN", "HR", "EMPLOYEE", "ACCOUNTS"].includes(role)
              ) &&
              navigate("/auditpage", {
                state: { headerText: "Audit" }, // State must be an object
              })
            }
            className="ListItem"
          >
            <ListItemButton
              selected={location.pathname === "/auditpage"}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#FFA500",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFA500",
                  },
                },
              }}
            >
              <ListItemIcon>
                {/* <PermIdentityIcon
                  sx={{
                    // color:
                    //   location.pathname === "/auditpage" ? "#fff" : "initial",
                    color: "#fff",
                  }}
                /> */}
              <AuditIcon/>
              </ListItemIcon>
              <ListItemText>Audit</ListItemText>
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding onClick={onClickLogout} className="ListItem">
            <ListItemButton
              selected={location.pathname === "/logout"}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#6A5ACD",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFA500",
                  },
                },
              }}
            >
              <ListItemIcon>
                <LogoutTwoToneIcon
                  sx={{
                    color: location.pathname === "/logout" ? "#fff" : "initial",
                  }}
                />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItemButton>
          </ListItem> */}
        </List>
      </div>
    </div>
  );
};
export default Sidebar;

// <ListItem
// button
// onClick={() =>
//   userRole.some((role) => ["ADMIN", "HR"].includes(role))
//     ? navigate("/manageArchiveApplication")
//     : navigate("/noaccess")
// }
// className="NestedListItem"
// selected={location.pathname === "/manageArchiveApplication"}
// sx={{
//   "&.Mui-selected": {
//     backgroundColor: "#6A5ACD",
//     color: "#fff",
//     "&:hover": {
//       backgroundColor: "#FFA500",
//     },
//   },
// }}
// >
// <ListItemText
//   primary="Manage Job Applicants"
//   sx={{ marginLeft: "30px", fontWeight: "800" }}
// />
// </ListItem>

// <ListItem
// button
// onClick={() =>
//   userRole.some((role) => ["ADMIN", "HR"].includes(role))
//     ? navigate("/manageJobOnBoarding")
//     : navigate("/noaccess")
// }
// className="NestedListItem"
// selected={location.pathname === "/manageJobOnBoarding"}
// sx={{
//   "&.Mui-selected": {
//     backgroundColor: "#6A5ACD",
//     color: "#fff",
//     "&:hover": {
//       backgroundColor: "#FFA500",
//     },
//   },
// }}
// >
// <ListItemText
//   primary="Manage Job On-Boarding"
//   sx={{ marginLeft: "30px", fontWeight: "800" }}
// />
// </ListItem>

// <ListItem
// button
// onClick={() =>
//   userRole.some((role) => ["ADMIN", "HR"].includes(role))
//     ? navigate("/manageInterviewSchedule")
//     : navigate("/noaccess")
// }
// className="NestedListItem"
// selected={location.pathname === "/manageInterviewSchedule"}
// sx={{
//   "&.Mui-selected": {
//     backgroundColor: "#6A5ACD",
//     color: "#fff",
//     "&:hover": {
//       backgroundColor: "#FFA500",
//     },
//   },
// }}
// >
// <ListItemText
//   primary="Manage Interview Schedule"
//   sx={{ marginLeft: "30px", fontWeight: "800" }}
// />
// </ListItem>
