import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const EmployeesAssignedProjectPerMonth = () => {
    const Insights =
        useSelector((state) => state?.Data?.Resources?.Insights?.data) || [];

    const [series, setSeries] = useState([]);

    useEffect(() => {
        function employees() {
            const result = Array.from(
                Insights
            )[0]?.employeesAssignedProjectPerMonth?.map((item) => {
                const data = new Array(12).fill(0);

                const monthIndex =
                    parseInt(item?.cumulativeCounts[0]?.month?.split("-")[1], 10) - 1;

                data[monthIndex] = item?.cumulativeCounts[0]?.cumulativeCount;

                return {
                    name: item?.projectName,
                    data: data,
                };
            });

            const limitedResult = result?.slice(0, 9);

            setSeries(limitedResult);

            // setSeries(result);
        }
        employees();
    }, []);

    const [options] = useState({
        chart: {
            type: "line",
            height: 320,
            zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
            },
            toolbar: {
                show: false,
            },
        },
        colors: [
            "#FF0000",
            "#00FF00",
            "#0000FF",
            "#FF00FF",
            "#00FFFF",
            "#FFA500",
            "#800080",
            "#00FF7F",
            "#FF1493",
            "#1E90FF",
        ],
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
        },
        title: {
            text: "",
            align: "left",
            style: {
                fontSize: "16px",
                fontWeight: "bold",
                color: "#333",
            },
        },
        stroke: {
            curve: "smooth",
            width: 3,
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            title: {
                text: "Month",
                style: {
                    color: "#333",
                    offsetX: 0,
                },
            },
            labels: {
                style: {
                    colors: "#333",
                    offsetY: 0,
                },
            },
        },
        yaxis: {
            title: {
                text: "Number of Employees",
                style: {
                    color: "#333",
                    offsetX: 0,
                },
            },
            min: 0,
            labels: {
                style: {
                    colors: "#333",
                    offsetX: 0,
                },
            },
        },
        tooltip: {
            shared: true,
            y: {
                formatter: (val) => val,
            },
            x: {
                formatter: (val) => `Month: ${val}`,
            },
        },
        grid: {
            borderColor: "#e0e0e0",
            strokeDashArray: 4,
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0, // Ensure grid padding is minimized
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.3,
                opacityTo: 0.1,
                stops: [0, 100],
            },
        },
    });

    return (
        <div className="line-chart" style={{width:"341px",height:"263px"}}>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={270}
                width={341}
            />
        </div>
    );
};

export default EmployeesAssignedProjectPerMonth;
