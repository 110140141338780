import React from "react";

const Recruitmenticon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="24" fill="url(#pattern0_27_163)" />
      <defs>
        <pattern
          id="pattern0_27_163"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_27_163" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_27_163"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAFOUlEQVR4nO2dS4wVRRSGWzOMj3kYwMSNIIk4aGDQtYIP0K1mGN3qSleKBFi4UFyZKBLQASQudImEBEeiiRtxhTE+El/gDCZuhoW8Fwgqo/CZk66J15m5XdXV3beqb58vuZub9DnV5++u56nqJFEURVEURVEURVEURVGUQFAxKqwKEDfoG6ACRA0wDLwNHAMuVf3E1oBLJhZvAauqDPwNwDvA1dB3HDESm71AbxXB/zz03dWII6WKAOwLfUc1ZE+Zdb5WO/n5B1hZhgDS4Cp+7CxDgOOezhX4qQwBftdIenOxDAGUAqgAgVEBAqMCBEYFCIwKEBgVIDAqQGBUgMCoAIFRAQKjAgRGBQiMChAYFSAwKkBgulWAKWAUGDC/EWCSCOlGAaaARfOUcxFwksjoRgFGM8r6FJHRjQIMZJR1kMhomgC3eNibBr40yWfPAfcDK4DbgBuB64GFwBJgDfAMsAs4CvzdRAFGMsoqDbMLfwLjwNMS3AKxkTfuSeBD4K/5HHkHvsVJbExmNMK/WK69ALwmT3fhwMz1fyvwCnC61WEZhmPkpGlwB81v1BJ8eeJfBfpLiXZ2vPqBbTP5VGUYvEi9+RS4s5To5ovbUuBAk1MTp4Hnk7pjWvy6cQZ4JOkGJMXapFrXhV/l9U+6CWA3XRx8oBd4yDTU0j392fSYps3vgvlv3DSwDwILqol2+wJ+Rtz8ljf4wN2ykwU45+HvrHkwV1QX+bkijEVaHU0Da3PcyzLgYEk7f8TGgY5Ve6ZN2CmbDyLaO7DRsezXAZuByxWUQWxuEh/Vq1BDSNcNPqZ6PurEgK9WkE4TfEPn+ApYHPq+Y3ryv6XzfN34N4G0zu9EtdOO8Ua3CaQNbh4mgDeAdcByoM/87gLWA9s91p43Fr2JtgsgMUPa1XTt7chhG0/keKtGcsyRyWEeS4rciAw4XpKGLKkRpP18F2Qw1eNhf4E5mMOF/UVupHWw84l5rWVp7nbgpiRCSEe4LoOsTR2q5mTwOuTrIDauzbciNqvMMr1gY7dv0OfxJ8f42BjzNR4bEw5TJlJt2ur83NWOpTqSBjyL014+iY/3LOV92MHG40UC3sbvBge/a3wMx8azlvLKlLL3G+SL6R2dsPh+2cdwbDxgKa/MxWTxepFAW3y/afF9yMdobNxhKa8snGSxvkiQLb4fs/g+5mM0NgYs5T1vub6yDAkzYs7inI/R2OixlPeK5fq+IkF2yAnK4oqP0VgWXcoSoL/imdcsLndDXtBgwSpoeZEgF6yCpnyMyomwMbGs4AMTshH+zsfoqsgW4tdayitz8FlsLxJki+8dFt8HfQ27zvrFMBDbZrl+sopFEjMQs2Vnb/E13muO442B9y1llaQp7z0Gvph9AuVPRcwSYU8E1dGEw8SY5IZmcbzMzDYTG9tK2alSJgAjyAu65jAd7ZJKubdwMP7z966Dv11JUyDd4+WyILO5BF9bceO+pEkAHzgGZp9PdWSqHZcnXzicNA3SXY2uX/iYNFuenHpHwKPAj4625U28N2kiwIvk44SZUpYAD7WkpQyZQdYOh67mbGSL13DSREj75rb1gU5wpski3Ax8EYkIq5MmAiw2CbOhafSb0OcwT9QpEVY3uU14oaLvn13Nsae6uSK0dFH3l/hxosPmY0fDDtMgM5xttAiC6V6OzT7fwZFTZg/1/0a4OUU40nptYwF6TL6rHLZxyMxznTfLm3+Ysym+Nwm/M7mxbSfWHEX4oW4Jz7UCuMdsndXgRyaCPvkBRdDgJwGQ3o5ZUZxT5/8LUT2eEbAy4sEAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};
export default Recruitmenticon;
