import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const RecruitmentAcrossMonths = ({ linearChartdata }) => {
    const [series, setSeries] = useState([
        {
            name: 'Recruitments',
            data: new Array(12).fill(0), // Initialize with zero data
        },
    ]);

    useEffect(() => {
        // Create a copy of the initial array
        const initialSeriesData = new Array(12).fill(0);

        // Process the data when it becomes available
        if (linearChartdata) {
            linearChartdata?.forEach(item => {
                // Extract the month (assuming the format 'YYYY-MM')
                const monthIndex = parseInt(item?._id?.slice(-2)) - 1;
                if (monthIndex >= 0 && monthIndex < 12) {
                    initialSeriesData[monthIndex] = item?.count;
                }
            });

            // Update the series state with the processed data
            setSeries([
                {
                    name: 'Recruitments',
                    data: initialSeriesData,
                },
            ]);
        }
    }, [linearChartdata]); // Depend on `linearChartdata` so that this runs when it changes

    const options = {
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 6,
            colors: ['#FF5733'],
            strokeColor: '#fff',
            strokeWidth: 2,
        },
        title: {
            text:"",
            align: 'left',
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#333',
            },
        },
        stroke: {
            curve: 'smooth',
            width: 3,
            colors: ['#007bff'],
        },
        xaxis: {
            categories: [
                'January', 'February', 'March', 'April', 'May',
                'June', 'July', 'August', 'September', 'October',
                'November', 'December'
            ],
            title: {
                text: 'Month',
                style: {
                    color: '#333',
                },
            },
            labels: {
                style: {
                    colors: '#333',
                },
            },
        },
        yaxis: {
            title: {
                text: 'Number of Recruitments',
                style: {
                    color: '#333',
                },
            },
            min: 0,
            labels: {
                style: {
                    colors: '#333',
                },
            },
        },
        tooltip: {
            shared: false,
            y: {
                formatter: (val) => val,
            },
            x: {
                formatter: (val) => `Month: ${val}`,
            },
        },
        grid: {
            borderColor: '#e0e0e0',
            strokeDashArray: 4,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.3,
                opacityTo: 0.1,
                stops: [0, 100],
            },
        },
    };

    return (
        <div className="line-chart">
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                width={341} 
                height={263} 
            />
        </div>
    );
};

export default RecruitmentAcrossMonths;
