import React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button, FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { addAsset } from "../../../../redux/dataSlice";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import "./index.css";

const AddAssets = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const Data = useSelector((state) => state.Data.Resources.Assests_Details);
  const AssetsData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.map((e) => e.Assets)
  );
  const AssetsStatusData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.map((e) => e.AssetsStatus)
  );

  const notifySuccess = () => toast.success("Added Successfully...!");

  const notifyError = (errorMessage) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  useEffect(() => {
    //dispatch(fetchData());
  }, [dispatch]);

  const addData = async () => {
    try {
      const response = await dispatch(addAsset(data));
      if (addAsset.fulfilled.match(response)) {
        notifySuccess();
        setTimeout(() => {
          navigate("/assets/");
          window.location.reload();
        }, 2000);
      } else {
        // Handle rejected response from server
        notifyError(response.payload);
      }
    } catch (error) {
      notifyError(error.message); // Display the error message returned by the server
    }
  };

  return (
    <div className="asset-form-container">
      <div className="heading-close-container">
        <h1 className="application-heading">Add Asset</h1>
        <div className="close-container">
          <Link to="/assets">
            <AiOutlineClose className="back-option" />
          </Link>
        </div>
      </div>
      <Divider />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          Asset Id <span className="required-star">*</span>
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Asset Id"
              variant="outlined"
              onChange={(e) => setData({ ...data, Asset_ID: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Asset Type <span className="required-star">*</span>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="asset-type"
              label="Asset Type"
              name="Asset Type"
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={(event) =>
                setData({ ...data, Asset_Type: event.target.value })
              }
              value={data.Asset_Type}
              select
            >
              <MenuItem value="">Select an asset type</MenuItem>
              {AssetsData.flatMap((asset) =>
                asset.map((each) => (
                  <MenuItem
                    key={each.value}
                    value={each.value}
                    className="asset-type-menuitem"
                  >
                    {each.name}
                  </MenuItem>
                ))
              )}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Model <span className="required-star">*</span>
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Model"
              variant="outlined"
              onChange={(e) => setData({ ...data, Model: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Vendor <span className="required-star">*</span>
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="vendor"
              variant="outlined"
              onChange={(e) => setData({ ...data, Vendor: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Serial Number <span className="required-star">*</span>
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Serial Number"
              variant="outlined"
              onChange={(e) =>
                setData({ ...data, Serial_Number: e.target.value })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Location <span className="required-star">*</span>
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Location"
              variant="outlined"
              onChange={(e) => setData({ ...data, Location: e.target.value })}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          Status <span className="required-star">*</span>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="asset-Status"
              label="Status"
              name="Status"
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={(e) => setData({ ...data, Status: e.target.value })}
              value={data.Status}
              select
            >
              {AssetsStatusData.flatMap((assetStatus) =>
                assetStatus.map((each) => (
                  <MenuItem
                    key={each.value}
                    value={each.value}
                    className="asset-status-menuitem"
                  >
                    {each.name}
                  </MenuItem>
                ))
              )}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div className="employee-label-card">
            <label htmlFor="PurchaseDate">
              Purchase Date <span className="required-star">*</span>
            </label>
            <TextField
              id="date"
              type="date"
              sx={{
                width: "100%",
                maxWidth: 505,
                height: "60px",

                "@media (max-width: 600px)": {
                  maxWidth: 400,
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  maxWidth: 250,
                },
                "@media (min-width: 961px) and (max-width: 2560px)": {
                  maxWidth: "100vw",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={data.Purchase_Date || ""}
              onChange={(e) => {
                const newValue = e.target.value; // Get the raw string value
                setData({ ...data, Purchase_Date: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Enter purchase date" />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          Purchase From
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Purchase From"
              variant="outlined"
              onChange={(e) =>
                setData({ ...data, Purchase_From: e.target.value })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Purchase Cost <span className="required-star">*</span>
          <FormControl fullWidth>
            <TextField
              type="number"
              id="outlined-basic"
              label="Purchase Cost"
              variant="outlined"
              onChange={(e) =>
                setData({ ...data, Purchase_Cost: e.target.value })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Warranty Info
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Warranty Info"
              variant="outlined"
              onChange={(e) =>
                setData({ ...data, warranty_info: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div className="employee-label-card">
            <label htmlFor="WarrantyExpirationDate">
              Warranty Expiration Date
            </label>
            <TextField
              id="date"
              type="date"
              sx={{
                width: "100%",
                maxWidth: 505,

                "@media (max-width: 600px)": {
                  maxWidth: 400,
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  maxWidth: 250,
                },
                "@media (min-width: 961px) and (max-width: 2560px)": {
                  maxWidth: "100vw",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={data.warranty_expiration_date || ""}
              onChange={(e) => {
                const newValue = e.target.value; // Get the raw string value
                setData({
                  ...data,
                  warranty_expiration_date: newValue,
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          Invoice Number
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Invoice number"
              variant="outlined"
              onChange={(e) =>
                setData({ ...data, invoice_number: e.target.value })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Quantity <span className="required-star">*</span>
          <FormControl fullWidth>
            <TextField
              type="number"
              id="outlined-basic"
              label="Quantity"
              variant="outlined"
              onChange={(e) => setData({ ...data, quantity: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Remarks
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Remarks"
              variant="outlined"
              onChange={(e) => setData({ ...data, remarks: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          Description
          <FormControl fullWidth>
            <TextareaAutosize
              onChange={(e) => setData({ ...data, desc: e.target.value })}
              minRows={3}
              placeholder="Description"
            />
          </FormControl>
        </Grid>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addData()}
            >
              Save
            </Button>{" "}
            &nbsp;
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate("/assets")}
              style={{ marginRight: "20px" }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ToastContainer />
    </div>
  );
};

export default AddAssets;
