import React, { useState, useEffect, useCallback } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import RoutesComponents from "../RoutesComponent/RoutesComponent";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Sidebar from "./Sidebar/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import {
  getUserData,
  updateUser,
  getAllEmployeeByRegion,
} from "../../../redux/dataSlice";
import {
  getAllRegionData,
  setSelectedRegion,
} from "../../../redux/regionSlice";

import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./index.css";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { apiURL } from "../../../utills/apiCalls";
import jwtDecode from "jwt-decode";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const drawerWidth = 250;

const Dashboard = React.memo(() => {
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileName, setProfileName] = useState(null);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [loading1, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const usersData = useSelector(
    (state) => state?.Data?.Resources?.Users_Details
  );
  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state?.regions);
  console.log(regions, "regions");

  const handleRegionChange = (event) => {
    // const newRegion = event.target.value;
    const selectedValue = JSON.parse(event.target.value);
    const newRegion = { _id: selectedValue._id, name: selectedValue.name };
    dispatch(setSelectedRegion(newRegion));
  };

  useEffect(() => {
    dispatch(getAllRegionData());
  }, [dispatch]);

  useEffect(() => {
    if (regions && regions.length > 0) {
      setSelectedRegion(regions[0].RegionName);
    }
  }, [regions]);

  const user = sessionStorage.getItem("user");
  const userRole = sessionStorage.getItem("role")?.split(",") || [];

  const [formData, setFormData] = useState({
    empId: "",
    role: [],
    password: "",
    confirmPassword: "",
  });

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();
  let stateObject = location.state;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const togglePassword = () => {
    setViewPassword(!viewPassword);
  };

  const toggleConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };

  const notifySuccess = () => {
    toast.success("Logout Successfully...!");
  };

  const notifyError = () => toast.error("Not Updated Successfully...!");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Password validation
    if (formData.password.length < 8) {
      toast.error("Password must be at least 8 characters.");
      return;
    }

    // Confirm password validation
    if (formData.password !== formData.confirmPassword) {
      toast.error("Password and Confirm Password are not matched.");
      return;
    }

    //Password validation criteria
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$%^&*])[a-zA-Z\d@!#$%^&*]{8,}$/;

    //Explanation: Password must contain at least 8 characters, including one lowercase letter, one uppercase letter, and one digit.

    //Password validation check
    if (!formData?.password.match(passwordRegex)) {
      toast.error(
        "Password must contain at least 8 characters, including one lowercase letter, one uppercase letter, one special character and one digit."
      );
      return;
    }

    dispatch(updateUser(formData))
      .unwrap() // extracts the fulfilled value from the promise
      .then((data) => {
        toast.success("Password Changed successfully...!", {
          onClose: () => {
            setTimeout(() => {
              navigate("/");
              window.location.reload();
            }, 1000);
          },
        });
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const useUserActivity = () => {
    const [isActive, setIsActive] = useState(true);

    const handleActivity = useCallback(() => {
      setIsActive(true);
    }, []);

    useEffect(() => {
      const activityEvents = ["mousemove", "keypress", "scroll", "click"];

      // Set user as active on any of these events
      activityEvents.forEach((eachEvent) =>
        window.addEventListener(eachEvent, handleActivity)
      );

      // Reset user activity status every 5 seconds
      const activityInterval = setInterval(() => {
        setIsActive(false);
      }, 300000);

      return () => {
        activityEvents.forEach((event) =>
          window.removeEventListener(event, handleActivity)
        );
        clearInterval(activityInterval);
      };
    }, [handleActivity]);

    return isActive;
  };

  //------------------SIDEBAR---------------

  const drawer = <Sidebar />;

  const container = undefined;
  const [userData, setUserData] = useState();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const [expiryTime, setExpiryTime] = useState(null);

  const decodeJwt = (token) => {
    const base64Url = token.split(".")[1]; // Get the payload part of the token
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload); // Return the decoded payload as an object
  };

  const recordTokenExpiry = () => {
    const now = new Date();
    setExpiryTime(now.toLocaleString());
    setTokenExpired(true);
    sessionStorage.clear();
    console.log("Token expired on:", now.toLocaleString());
  };

  const isActive = useUserActivity();

  // useEffect(() => {
  //   console.log("inside useffect")
  //   const checkTokenExpiration = async () => {
  //     try {
  //       const account = instance.getActiveAccount();
  //       if (!account) {
  //         recordTokenExpiry();
  //         navigate("/session-expired");
  //         return;
  //       }

  //       const tokenResponse = await instance.acquireTokenSilent({
  //         scopes: ["user.read"],
  //         account: account,
  //       });

  //       if (tokenResponse && tokenResponse.idToken) {
  //         const idTokenDetails = decodeJwt(tokenResponse.idToken);
  //         const expInSeconds = idTokenDetails.exp;
  //         const tokenExpiryDate = new Date(expInSeconds * 1000);
  //         console.log("token expires on", tokenExpiryDate)
  //         sessionStorage.setItem("jwt_token", tokenResponse.idToken)
  //         const now = new Date();
  //         console.log("is user active", isActive)

  //         if (now >= tokenExpiryDate) {
  //           if (isActive) {
  //             console.log("user active")
  //             try {
  //               const refreshToken = await instance.acquireTokenSilent({
  //                 scopes: ["user.read"]
  //               })
  //               if (refreshToken && refreshToken.idToken) {
  //                 console.log("Token refreshed successfully");
  //                 const refreshtokenDetails = decodeJwt(refreshToken.idToken);
  //                 const refreshexpInSeconds = refreshtokenDetails.exp;
  //                 const refreshtokenExpiryDate = new Date(refreshexpInSeconds * 1000);
  //                 console.log("token expires on", refreshtokenExpiryDate)
  //                 // Store the refreshed JWT token in sessionStorage
  //                 sessionStorage.setItem("jwt_token", refreshToken.idToken);
  //                 console.log("Token refreshed successfully")
  //               } else {
  //                 console.error("Token refresh did not return an idToken.");
  //                 sessionStorage.clear();
  //                 recordTokenExpiry();
  //                 navigate("/session-expired");
  //               }
  //             } catch (refreshError) {
  //               console.error("Token refresh failed:", refreshError);
  //               sessionStorage.clear()
  //               recordTokenExpiry();
  //               navigate("/session-expired");
  //             }
  //           } else {
  //             // If user is inactive, log them out
  //             recordTokenExpiry();
  //             sessionStorage.clear();
  //             navigate("/session-expired");
  //             console.log("token got expired when user not active", tokenExpiryDate)

  //           }

  //         } else {
  //           setExpiryTime(tokenExpiryDate.toLocaleString());
  //           // Set the token in session storage
  //           sessionStorage.setItem("jwt_token", tokenResponse.idToken);
  //           console.log("token not expired", tokenResponse.idToken)
  //         }
  //       } else {
  //         recordTokenExpiry();
  //         sessionStorage.clear()
  //         navigate("/session-expired");
  //         console.log("no token response")
  //       }
  //     } catch (error) {
  //       if (error instanceof InteractionRequiredAuthError) {
  //         try {
  //           await instance.acquireTokenRedirect({ scopes: ["user.read"] });
  //         } catch (interactiveError) {
  //           recordTokenExpiry();
  //           navigate("/session-expired");
  //           sessionStorage.clear()
  //         }
  //       } else {
  //         console.error("Token refresh failed:", error);
  //         recordTokenExpiry();
  //         sessionStorage.clear();
  //         navigate("/session-expired");
  //       }
  //     }
  //   };

  //   const intervalId = setInterval(checkTokenExpiration, 1000);

  //   return () => clearInterval(intervalId);
  // }, [instance, navigate, isActive]);

  // working code====
  useEffect(() => {
    const checkTokenExpiration = async () => {
      try {
        const account = instance.getActiveAccount();
        if (!account) {
          recordTokenExpiry();
          navigate("/session-expired");
          return;
        }

        const tokenResponse = await instance.acquireTokenSilent({
          scopes: ["user.read"],
          account: account,
        });

        if (tokenResponse && tokenResponse.idToken) {
          const idTokenDetails = decodeJwt(tokenResponse.idToken);

          const expInSeconds = idTokenDetails.exp;
          const tokenExpiryDate = new Date(expInSeconds * 1000);

          console.log(
            "ID Token expiry time:",
            tokenExpiryDate.toLocaleString()
          );

          const now = new Date();
          if (now >= tokenExpiryDate) {
            if (isActive) {
              // If user is active, refresh the token
              try {
                const refreshedTokenResponse =
                  await instance.acquireTokenSilent({
                    scopes: ["user.read"],
                    account: account,
                  });
                if (refreshedTokenResponse && refreshedTokenResponse.idToken) {
                  sessionStorage.setItem(
                    "jwt_token",
                    refreshedTokenResponse.idToken
                  );

                  const refreshedIdTokenDetails = decodeJwt(
                    refreshedTokenResponse.idToken
                  );
                  const refreshTokenExpInMs = refreshedIdTokenDetails.exp;
                  const refreshToeknexpiry = new Date(
                    refreshTokenExpInMs * 1000
                  );
                }
              } catch (refreshError) {
                console.error("Token refresh failed:", refreshError);
                recordTokenExpiry();
                navigate("/session-expired");
              }
            } else {
              // If user is inactive, log them out
              recordTokenExpiry();
              navigate("/session-expired");
            }
          } else {
            setExpiryTime(tokenExpiryDate.toLocaleString());
          }
        } else {
          recordTokenExpiry();
          navigate("/session-expired");
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          try {
            await instance.acquireTokenRedirect({ scopes: ["user.read"] });
          } catch (interactiveError) {
            recordTokenExpiry();
            navigate("/session-expired");
          }
        } else {
          console.error("Token refresh failed:", error);
          recordTokenExpiry();
          navigate("/session-expired");
        }
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 3000);

    return () => clearInterval(intervalId);
  }, [instance, navigate, isActive]);

  // const handleLogoutRedirect = async () => {

  //   const access_token = sessionStorage.getItem('access_token')
  //   const jwt_token = sessionStorage.getItem('jwt_token')
  //   const decoded = jwtDecode(access_token);

  //   const logoutPayload = {
  //     action: "logout",
  //     ipAddress: decoded?.ipaddr,
  //     userId: decoded?.oid,
  //   }

  //   const headerWithToken = {
  //     headers: {
  //       Authorization: `Bearer ${jwt_token}`,
  //     },
  //   }
  //   await axios.post(apiURL + 'authaction', logoutPayload, headerWithToken);

  //   sessionStorage.clear();
  //   instance.logoutRedirect().catch((error) => console.log(error));

  // };

  const handleLogoutRedirect = async () => {
    const access_token = sessionStorage.getItem("access_token");
    const jwt_token = sessionStorage.getItem("jwt_token");
    const decoded = jwtDecode(access_token);

    const logoutPayload = {
      action: "logout",
      ipAddress: decoded?.ipaddr,
      userId: decoded?.oid,
    };

    const headerWithToken = {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    };

    try {
      await axios.post(apiURL + "authaction", logoutPayload, headerWithToken);
    } catch (error) {
      console.error("Failed to log out:", error);
    }

    sessionStorage.clear();

    // Explicitly setting logout redirection target
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/login",
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setUserData(activeAccount);
  }, []);

  const onClickLogout = () => {
    handleLogoutRedirect();
  };
  const pathname = location.pathname;
  const path = pathname.split('/');
  const isEditOrUpdate =
    path[1] === "editJob" ||
    path[1] === "updateApplication" ||
    path[1] === "project" ||
    path[1] === "employee_details";
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {!tokenExpired && (
        <>
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
              backgroundColor: "white",
            }}
          >
            <Toolbar
              sx={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  "@media (min-width: 768px)": {
                    display: "none",
                  },
                }}
              >
                <MenuIcon className="menu-icon" />
              </IconButton>

              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "@media (max-width: 600px)": {
                    padding: "10px",
                    // marginLeft:8,
                  },
                  "@media (min-width:600px)and (max-width: 960px)": {
                    padding: "10px",
                    // marginLeft:10
                  },

                  "@media (min-width: 961px) and (max-width: 1024px)": {
                    // padding: "10px",
                    marginLeft: 10,
                  },
                  "@media (min-width: 1024px) and (max-width: 1440px)": {
                    marginLeft: 20,
                    padding: "10px",
                  },
                  "@media (min-width: 1440px) and (max-width: 2560px)": {
                    padding: "10px",
                    marginLeft: 20,
                  },
                }}
              >
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    color: "black",
                    fontWeight: "600",
                    fontStyle: "bold",

                    marginTop: 2,
                    "@media (max-width: 600px)": {
                      fontSize: "18px",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "20px",
                    },
                    "@media (min-width: 961px) and (max-width: 1024px)": {
                      fontSize: "24px",
                    },
                    "@media (min-width: 1440px)": {
                      fontSize: "26px",
                    },
                    "@media (min-width: 2560px)": {
                      fontSize: "50px",
                    },
                  }}
                >
                  <span
                    className="website-span"
                    style={{
                      color: "black",
                      cursor: "pointer",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {stateObject !== null
                      ? stateObject?.headerText
                      : "Dashboard"}
                  </span>
                </Typography>
              </Box>

              <Box sx={{ width: "30%" }} >
                <select
                  disabled={isEditOrUpdate}
                  id="region"
                  value={JSON.stringify(selectedRegion)}
                  onChange={handleRegionChange}
                  style={{
                    height: "45px",
                    width: "80%",
                    marginLeft: 30,
                    padding: "10px",
                    borderRadius: 10,
                  }}
                >
                  {regions?.map((region) => (
                    <option
                      key={region?._id}
                      value={JSON.stringify({
                        _id: region?._id,
                        name: region?.RegionName,
                      })}
                    >
                      {region?.RegionID} {region.RegionName}
                    </option>
                  ))}
                </select>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  marginRight: 3,
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      sx={{ width: 35, height: 35, backgroundColor: "#6A5ACD" }}
                    >
                      {!loading1 && profileName
                        ? profileName.slice(0, 1)
                        : userRole.length > 0
                          ? userRole[0].slice(0, 2)
                          : ""}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  // onClick={() => navigate("/profile")}
                  sx={{
                    "&:hover": {
                      bgcolor: "#FFA500",
                      color: "#fff",
                    },
                  }}
                >
                  <Avatar /> {userData?.name ?? "Profile"}
                </MenuItem>

                <Divider />

                {/* <MenuItem
              onClick={() => setChangePasswordOpen(true)}
              sx={{
                "&:hover": {
                  bgcolor: "#FFA500",
                  color: "#fff",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  "&:hover": {
                    color: "#fff",
                  },
                }}
              >
                <Settings fontSize="small" />
              </ListItemIcon>
              Change Password
            </MenuItem> */}

                <MenuItem
                  onClick={onClickLogout}
                  sx={{
                    "&:hover": {
                      bgcolor: "#FFA500",
                      color: "#fff",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      "&:hover": {
                        color: "#fff",
                      },
                    }}
                  >
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
              <Dialog
                open={changePasswordOpen}
                onClose={() => setChangePasswordOpen(false)}
              >
                <DialogTitle>
                  <div className="heading-close-container">
                    <h1 className="application-heading">Change Password</h1>
                    <div className="close-container">
                      <AiOutlineClose
                        className="back-option"
                        onClick={() => setChangePasswordOpen(false)}
                      />
                    </div>
                  </div>
                </DialogTitle>
                <DialogContent dividers sx={{ marginTop: "-35px" }}>
                  <form onSubmit={handleSubmit} className="user-form">
                    <div className="user-password-input-container">
                      <label htmlFor="password">New Password</label>
                      <div className="user-password-container">
                        <input
                          type={viewPassword ? "text" : "password"}
                          id="password"
                          className="user-password-input-feild"
                          placeholder="password"
                          name="password"
                          onChange={handleChange}
                        />

                        {viewPassword ? (
                          <FaEye
                            className="user-passwordView"
                            onClick={togglePassword}
                          />
                        ) : (
                          <FaEyeSlash
                            className="user-passwordView"
                            onClick={togglePassword}
                          />
                        )}
                      </div>
                    </div>

                    <div className="user-password-input-container">
                      <label htmlFor="confirm_password">Confirm Password</label>

                      <div className="user-password-container">
                        <input
                          type={viewConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          className="user-password-input-feild"
                          placeholder="confirm Password"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                        />

                        {viewConfirmPassword ? (
                          <FaEye
                            className="user-passwordView"
                            onClick={toggleConfirmPassword}
                          />
                        ) : (
                          <FaEyeSlash
                            className="user-passwordView"
                            onClick={toggleConfirmPassword}
                          />
                        )}
                      </div>
                    </div>

                    <button className="user-submit" type="submit">
                      Submit
                    </button>
                  </form>
                </DialogContent>

                <DialogActions>
                  <ToastContainer />
                </DialogActions>
              </Dialog>
            </Toolbar>
          </AppBar>

          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  border: "2px solid transparent",
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: "25px",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#f0f0f0",
          minHeight: "100vh",
        }}
      >
        <Toolbar />

        <RoutesComponents />
      </Box>
      <ToastContainer />
    </Box>
  );
});

export default Dashboard;
