import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteProjectCard } from "../../../../../redux/dataSlice";
import CustomDataGrid from "../../../../utilites/CustomDataGrid";
import ServerSideDataGrid from "../../../../utilites/ServerSideDataGrid";
import { getAllProjectData } from "../../../../../redux/dataSlice";
import AssignModal from "../../AssignModal";
import Avatars from "./Avatars";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import IconButton from "@mui/material/IconButton";
import ProgressBar from "./ProgressBar";

const ProjectsGrid = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.Data.Resources.project);
  const userRole = sessionStorage.getItem("role")?.split(",") || [];
  const [openModal, setModal] = useState(false);
  const [currentProject, setCurrentProject] = useState({});
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);

  console.log("slected region from store in projectgrid", selectedRegion);

  const columns = [
    { field: "projectId", headerName: "ID", width: 100, headerAlign: 'center', flex: 0.1 },
    {
      field: "projectTitle",
      headerName: "Project Title",
      width: 160,
      headerAlign: 'center',
      flex: 0.1
    },
    {
      field: "projectDescription",
      headerName: "Project Desc",
      width: 245,
      headerAlign: 'center',
      flex: 0.1
    },
    {
      field: "vendorName",
      headerName: "Client Name",
      width: 145,
      headerAlign: 'center',
      flex: 0.1
    },

    {
      field: "percentageOfCompletion",
      headerName: "Progress",
      width: 100,
      headerAlign: 'center',
      flex: 0.1,
      renderCell: ProgressBar,
    },
    {
      field: "projectStatus",
      headerName: "Status",
      width: 100,
      flex: 0.1,
      headerAlign: 'center',
    },
    {
      field: "projectStartDate",
      headerName: "Start Date",
      width: 110,
      type: "date",
      headerAlign: 'center',
      flex: 0.1,
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          : // .replace(/\//g, "-")
          "",
    },
    {
      field: "projectEndDate",
      headerName: "End Date",
      width: 110,
      type: "date",
      headerAlign: 'center',
      flex: 0.1,
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          : // .replace(/\//g, "-")
          "",
    },
    {
      field: "edit",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 140,
      flex: 0.1,
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <strong>
            <Tooltip placement="top" title={"Edit"}>
              <IconButton
                style={{ color: "#24CCEF", cursor: "pointer" }}
                onClick={() => handleEdit(params.row._id)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title={"Assign"}>
              <IconButton
                style={{ color: "green", cursor: "pointer" }}
                onClick={() => {
                  setCurrentProject(params.row);
                  setModal(true);
                }}
              >
                <AssignmentIndIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title={"Delete"}>
              <IconButton
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setOpen(true);
                  setDeleteId(params.row._id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </strong>
        </div>
      ),
    },
  ];

  const handleEdit = (id) => {
    userRole.some((role) => ["ADMIN"].includes(role))
      ? navigate(`/project/${id}`, {
        state: { headerText: "Projects" }, // Pass state here
      })
      : navigate("/noaccess");
  };

  const handleDelete = (id) => {
    if (userRole.some((role) => ["ADMIN"].includes(role))) {
      dispatch(deleteProjectCard(id))
        .then((response) => {
          toast.success("Project Deleted successfully...!");
          if (selectedRegion && selectedRegion?._id) {
            console.log("inside condition in prData", selectedRegion);
            dispatch(
              getAllProjectData({
                regionId: selectedRegion?._id,
                params: { page: 1, limit: 10 },
              })
            );
          }
        })
        .catch(() => {
          toast.error("An error occurred. Please try again.");
        });
    } else {
      navigate("/noaccess");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (!(project.data && project.data.length)) {
  //     dispatch(getAllProjectData({ page: 1, limit: 10 }))
  //     // .then((response) => {
  //       // if (response?.data?.statusCode === 401) {
  //       //   navigate("/session-expired");
  //       // }
  //     // });
  //   }
  // }, [dispatch]);

  useEffect(() => {
    console.log(
      "useffect running while changing the region in project field",
      selectedRegion
    );
    if (selectedRegion && selectedRegion?._id) {
      console.log("inside condition in prData", selectedRegion);
      dispatch(
        getAllProjectData({
          regionId: selectedRegion?._id,
          params: { page: 1, limit: 10 },
        })
      );
    }
  }, [dispatch, selectedRegion]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#f0f0f0",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this Project?"}
        </DialogTitle>
        <DialogContent>
          <Typography color="textPrimary">
            Are you sure you want to delete this Project?
          </Typography>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleClose}
            className="dialog-cancel-btn"
            color="primary"
          >
            Cancel
          </button>
          <button
            className="dialog-delete-btn"
            onClick={() => {
              handleDelete(deleteId);
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
      <ServerSideDataGrid
        loading={project.loading}
        columns={
          userRole.includes("ADMIN")
            ? columns
            : columns.filter(
              (column) => column.field !== "edit" && column.field !== "delete"
            )
        }
        fetchDataAction={getAllProjectData}
        dataSelector="project"
        sliceKey="Data.Resources"
        handleAdd={() =>
          navigate("/createProject", {
            state: { headerText: "Projects" }, // Pass state here
          })
        }
        addButtonName={"Create Project"}
        roles={["ADMIN"]}
      />
      <AssignModal
        openModal={openModal}
        setModal={setModal}
        currentProject={currentProject}
        setCurrentProject={setCurrentProject}
      />
      <ToastContainer />
    </>
  );
};

export default ProjectsGrid;
