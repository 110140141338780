import { SvgIcon } from "@mui/material";
import React from "react";

const ProjectsIcon = () => {
  return (
    <SvgIcon
      sx={{
        color: "red",
        // color: ["/projects", "/createProject"].includes(location.pathname)
        //   ? "black" // Change to white if the condition is met
        //   : "black", // Default to black
      }}
      viewBox="0 0 24 24"
    >
      <rect
        width="24"
        height="24"
        color="red"
        fill="url(#pattern0_95_18)"
        // fill="red"
      />
      <defs>
        <pattern
          id="pattern0_95_18"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
          color="red"
        >
          <use xlinkHref="#image0_95_18" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_95_18"
          width="96"
          height="96"
          color="red"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADeklEQVR4nO2dTW7UQBCFSyKwDXAM2CLBQXKRrGY5s0s2/IkjsInEDeAAjKx4FTgGCAE7kD40woQwjGfa7e6u6p9vHbtevVfdbmkiW6TRaDQajUaj0Wg0ZgI8BJ4DH4Dv6LOqIlTgDvAK+Ik9VlKB+e+wzUpKZZj8HFhJoXu+xW2njhCAF+THuZQC8BFbrFz/TkoA+IYh5LemRTUrAWPIX111hIAx5F9t5YeAMeR/fWWHgDFkt8ZyQ8AYMq6zzBAwhuzXWl4IGEMO6y0rBIwhbprLCQFjiLvuMkLAGDJNe/4hYAyZrj/vEDCG+PWQbwgYQ/z7yDMEjCHzeskvBIwh8/vJKwSMIWF6yicEjCHh+lqkrDdHqCkkbG+LlPV8RZpCwve3SFnPR6ApJE6Pi5T1poqrGtGGyhFtqBzRhsoRbagc0YbKEW2oHNGGyhFtqBzRhsoRbagc0YbKEW2oHNGG/FgDZ6Fupu1/bgFcAvcm/uy4F23/JUfz/xAiBNGGTM0PFYJoQ8bmhwhBtCH+A3M98/pjxz68HszxHT4sPOrkAsfAe9/rHXs49g06vsOHxcdgfXNyPQyaMvne5gNdVHMdGwjN5a7JnbASkkw+0AP3RRtP8V7mcTiEuszf4NmAt3mMh1Cf+RsIw9p1zx4xMNmeD9ydoPMU+AxcAY8kBgHMv3Sd3JGVYHLyd7w25wvwZGqfLoWSTf6IoRYnf+ydRZsQHrvex7VY0sn3QXnyiboSNCZ/Cokn/8TxvuFWgmdjZ6G3GG3zh3pHwMWEEOY/mPFn5Wie12pJbb5nCFc+NbYLEjoEZhwzR66Pve0ceYbwybXOPgGEDIFx85xCUDrtXHiGcDrR7p0iQnDuaN7eE4nyaefNjhBuAa9H6i1nWn9dJBRnjubtXAlGzvmuKyHcO0vRob85rcbO+YdWQpjJVw7geiUYmXzXlXAS1HzlAOb+ZBnL/NEQokCe9IG3HeftqAVA9MnfJvy2syUwJ/pEk0+UB+6IyFzoizN/EJoDfZHmD2Kt0xdr/iD4K3bpizZ/EL35YJtFusSnHZ1PogDPsEdf/OTfEP/A2Gesuiomf6uJl9igq878DcBt4C26dFWavxXC5qNuP0hPV7X5O54JT4d/x0vxjbGumZ8R5HraKQWa+VkGsFSWXQ4087MKYKkstzxo5mcRwFJZZrlgzPxfOjDjgPGxWKYAAAAASUVORK5CYII="
        />
      </defs>
    </SvgIcon>
  );
};
export default ProjectsIcon;
