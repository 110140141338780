import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(job, Submissions, Shortlisted, Interview, Offered, Hired) {
  return { job, Submissions, Shortlisted, Interview, Offered, Hired };
}

const rows = [
  createData("FullStack Developer", 15, 6, 3, 2, 1),
  createData("Python Developer", 7, 2, 2, 1, 1),
  createData("AI/ML Developer", 9, 4, 4, 2, 1),
  createData("AWS Admin", 5, 5, 3, 1, 1),
  createData("Devops Engineer", 7, 6, 6, 2, 2),
];

const HiringPipeline = () => {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: "50px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ bgcolor: "#d3d3d3" }}>
            <TableCell>Job Title</TableCell>
            <TableCell align="right">Submissions</TableCell>
            <TableCell align="right">Shortlisted</TableCell>
            <TableCell align="right">Interview</TableCell>
            <TableCell align="right">Offered</TableCell>
            <TableCell align="right">Hired</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.job}
              </TableCell>
              <TableCell align="right">{row.Submissions}</TableCell>
              <TableCell align="right">{row.Shortlisted}</TableCell>
              <TableCell align="right">{row.Interview}</TableCell>
              <TableCell align="right">{row.Offered}</TableCell>
              <TableCell align="right">{row.Hired}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HiringPipeline;
