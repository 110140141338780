import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../utills/authConfig";
import axios from "axios";
import { apiURL } from "../../../utills/apiCalls";
import jwtDecode from "jwt-decode";

const Login = () => {
  const { instance } = useMsal();

  const handleRedirect = async () => {
    await instance.initialize();
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'select_account',
      })
      .catch((error) => console.log('Login Error', error));
  };
  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await instance.initialize();
        const response = await instance.handleRedirectPromise();
        if (response) {
          console.log('response from tokenn', response)
          const { idToken, accessToken } = response;
          sessionStorage.setItem("isAuthenticated", true);
          sessionStorage.setItem("jwt_token", idToken);
          sessionStorage.setItem("access_token", accessToken);
          sessionStorage.setItem("role", "ADMIN");
          const decodedToken = jwtDecode(idToken);
          sessionStorage.setItem("permissions", JSON.stringify(decodedToken?.roles ?? []));

          const decoded = jwtDecode(accessToken);
          const loginPayload = {
            action: "login",
            ipAddress: decoded?.ipaddr,
            userId: decoded?.oid,
            firstname: decoded?.given_name,
            lastname: decoded?.family_name,
          };

          const headers = {
            Authorization: `Bearer ${idToken}`,
          };

          await axios.post(`${apiURL}authaction`, loginPayload, { headers });
          window.location.reload();

        }
      } catch (error) {
        console.error("Login failed: ", error);
      }
    };

    initializeMsal();

  }, [instance]);


  useEffect(() => {
    const initializeHandleRedirect = async () => {
      await handleRedirect();
    }
    initializeHandleRedirect();

  }, []);

  return (
    <div className="login-container">
      <div className="login-card">
      </div>
    </div>
  );
};

export default Login;
