import React from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { deleteAssignProject } from "../../../../../redux/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import "./index.css";
import CustomDataGrid from "../../../../utilites/CustomDataGrid";

const ProjectAssignGrid = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Data = useSelector(
    (state) => state.Data.Resources.Project_Assigned_Details
  );

  const userRole = sessionStorage.getItem("role").split(",");

  const rows = [];
  Data.map((e) => {
    const newAssignedProject = {
      id: e._id,
      employeeName: e.employeeName,
      projectTitle: e.projectTitle,
      projectRole: e.projectRole,
      startDate: e.startDate,
      endDate: e.endDate,
      projectStatus: e.projectStatus,
      projectLead: e.projectLead,
      qaLead: e.qaLead,
      projectTeam: e.projectTeam,
    };
    rows.push(newAssignedProject);
    return null;
  });

  const columns = [
    // {
    //   field: "employeeName",
    //   headerName: "EMPLOYEE NAME",
    //   width: 150,
    // },
    {
      field: "projectTitle",
      headerName: "PROJECT TITLE",
      width: 150,
    },
    // {
    //   field: "projectRole",
    //   headerName: "PROJECT ROLE",
    //   width: 150,
    // },
    {
      field: "startDate",
      headerName: "START DATE",
      width: 150,
      type: "date",
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, "-")
          : "",
    },
    {
      field: "endDate",
      headerName: "END DATE",
      width: 150,
      type: "date",
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, "-")
          : "",
    },
    {
      field: "projectStatus",
      headerName: "STATUS",
      width: 120,
    },
    {
      field: "projectLead",
      headerName: "PROJECT LEAD",
      width: 150,
    },
    {
      field: "qaLead",
      headerName: "QA LEAD",
      width: 150,
    },
    {
      field: "projectTeam",
      headerName: "PROJECT TEAM",
      width: 300,
    },
    {
      field: "edit",
      headerName: "EDIT",
      filterable: false,
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => handleEdit(params.row.id)}
            sx={{ border: "1px solid blue", height: "28px", width: "30px" }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "DELETE",
      filterable: false,
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Button
            color="error"
            onClick={() => handleDelete(params.row.id)}
            sx={{ border: "1px solid red", height: "28px", width: "30px" }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const handleEdit = (id) => {
    userRole.some((role) => ["ADMIN"].includes(role))
      ? navigate(`/AssignProject/${id}`)
      : navigate("/noaccess");
  };

  const handleDelete = (id) => {
    if (userRole.some((role) => ["ADMIN"].includes(role))) {
      dispatch(deleteAssignProject(id))
        .then((response) => {
          // if (response?.data?.statusCode === 401) {
          //   navigate("/session-expired")
          // }
          toast.success("Assign Project Deleted successfully...!", {
            onClose: () => {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            },
          });
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
        });
    } else {
      navigate("/noaccess");
    }
  };

  return (
    <CustomDataGrid
      Data={Data}
      rows={rows}
      columns={
        userRole.includes("ADMIN")
          ? columns
          : columns.filter(
              (column) => column.field !== "edit" && column.field !== "delete"
            )
      }
    />
  );
};

export default ProjectAssignGrid;
