import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  Switch,
  FormControlLabel,
  Autocomplete,
  Chip,
  TextareaAutosize,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  createJob,
  getJobById,
  updateJob,
  getJobs,
  getJobStatusCounts,
  getConfigData,
} from "../../../../../redux/Jobs/JobsSlice";
import "./index.css";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import {
  getAllEmployeeData,
  getAllEmployeeByRegion,
  getAllJobData,
} from "../../../../../redux/dataSlice";

const JobForm = ({ editMode }) => {
  const { jobId } = useParams();
  const jobData = useSelector((state) => state.Jobs.jobs);

  const employees = useSelector(
    (state) => state?.Data?.Resources?.employee?.data
  );
  console.log("jobData", jobData);
  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [formValues, setFormValues] = useState({
    jobID: "",
    jobTitle: "",
    jobDescription: "",
    jobAssignedTo: "",
    positions: "",
    jobStatus: "",
    minExperience: "",
    maxExperience: "",
    jobPriority: "",
    suitableQualification: "",
    coreSkills: [],
    otherSkills: [],
    minSalary: "",
    maxSalary: "",
    employmentType: "",
    workMode: "",
    jobLocation: "",
    region: "",
    offShore: true,
    datePosted: "",
    expectedClosingDate: "",
    actualClosingDate: "",
    createdBy: "",
    updatedBy: "",
    region: "",
  });
  const [options, setOptions] = useState({
    jobStatusOptions: [],
    coreSkills: [],
    otherSkills: [],
    employeeData: [],
  });

  const [errors, setErrors] = useState({});

  const notifySuccess = () =>
    editMode
      ? toast.success("Job Updated Successfully...!")
      : toast.success("Job Created Successfully...!");
  const notifyError = (errorMessage) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleChange = (e) => {
    if (!e.target) {
      const selectedOption = e;
      const name = "jobAssignedTo";
      setFormValues({
        ...formValues,
        [name]: selectedOption.value,
      });
      setErrors({ ...errors, [name]: "" });
    } else {
      const { name, value, checked, type } = e.target;

      if (type === "number") {
        const numericValue = Number(value);
        if (numericValue < 0 || isNaN(numericValue)) {
          return;
        }
      }

      setFormValues({
        ...formValues,
        [name]:
          type === "checkbox"
            ? checked
            : type === "number"
            ? Number(value)
            : value,
      });
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSkillChange = (event, value, name) => {
    setFormValues({
      ...formValues,
      [name]: value.map((option) => option.value),
    });
    if (value.length > 0) {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handleDateChange = (date, name) => {
    const formattedDate = formatDate(date);
    setFormValues({
      ...formValues,
      [name]: formattedDate,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const validateField = (fieldName, value) => {
    let error = "";

    switch (fieldName) {
      case "jobID":
        error = value.trim() ? "" : "Job ID is required";
        break;

      case "jobTitle":
        error = value.trim() ? "" : "Job Title is required";
        break;

      case "jobDescription":
        error = value.trim() ? "" : "Job Description is required";
        break;

      case "jobAssignedTo":
        error = value != "" ? "" : "Job Assigned To is required";
        break;
      case "jobStatus":
        error = value.trim() ? "" : "job Status To is required";
        break;
      case "jobPriority":
        error = value.trim() ? "" : "job Priority To is required";
        break;
      case "jobAssignedTo":
        error = value.trim() ? "" : "Job Assigned To is required";
        break;
      case "suitableQualification":
        error = value.trim() ? "" : "suitable Qualification To is required";
        break;

      case "coreSkills":
        error = value.length > 0 ? "" : "Core Skills are required";
        break;

      case "positions":
        error = value > 0 ? "" : "Number of Positions must be greater than 0";
        break;

      case "minExperience":
        // Ensuring minExperience is a non-negative number and required
        error =
          value >= 0 && value !== "" ? "" : "Min Experience must be 0 or more";
        break;

      case "maxExperience":
        // Ensuring maxExperience is non-negative and greater than or equal to minExperience
        if (value === "") {
          error = "Max Experience is required";
        } else if (value < 0) {
          error = "Max Experience must be 0 or more";
        } else if (Number(value) < Number(formValues.minExperience)) {
          error = "Max Experience cannot be less than Min Experience";
        }
        break;

      case "minSalary":
        // Ensuring minSalary is non-negative and required
        if (value === "") {
          error = "Min Salary is required";
        } else if (value < 0) {
          error = "Min Salary must be 0 or more";
        } else if (Number(value) > Number(formValues.maxSalary)) {
          error = "Min Salary cannot be greater than Max Salary";
        }
        break;

      case "maxSalary":
        // Ensuring maxSalary is non-negative and required
        if (value === "") {
          error = "Max Salary is required";
        } else if (value < 0) {
          error = "Max Salary must be 0 or more";
        } else if (Number(value) < Number(formValues.minSalary)) {
          error = "Max Salary cannot be less than Min Salary";
        }
        break;

      case "employmentType":
        error = value.trim() ? "" : "Employment Type is required";
        break;

      case "workMode":
        error = value.trim() ? "" : "Work Mode is required";
        break;

      case "jobLocation":
        error = value.trim() ? "" : "Job Location is required";
        break;

      case "expectedClosingDate":
        if (!value) {
          error = "Expected Closing Date is required";
        } else if (new Date(value) < new Date(formValues.datePosted)) {
          error = "Expected Closing Date cannot be before Date Posted";
        }
        break;

      case "actualClosingDate":
        if (!value) {
          error = "Actual Closing Date is required";
        } else if (value && new Date(value) < new Date(formValues.datePosted)) {
          error = "Actual Closing Date cannot be before Date Posted";
        }
        break;

      // Add other cases as necessary

      default:
        break;
    }

    // Update the errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.jobID = formValues.jobID ? "" : "Job ID is required";
    tempErrors.jobTitle = formValues.jobTitle ? "" : "Job Title is required";
    tempErrors.jobDescription = formValues.jobDescription
      ? ""
      : "Job Description is required";
    tempErrors.jobAssignedTo = formValues.jobAssignedTo
      ? ""
      : "Job Assigned To is required";
    tempErrors.positions =
      formValues.positions > 0
        ? ""
        : "Number of Positions must be greater than 0";
    tempErrors.minExperience =
      formValues.minExperience !== "" && formValues.minExperience >= 0
        ? ""
        : "Min Experience must be 0 or more";
    tempErrors.maxExperience =
      formValues.maxExperience !== "" && formValues.maxExperience >= 0
        ? ""
        : "Max Experience must be 0 or more";
    tempErrors.jobStatus = formValues.jobStatus ? "" : "Job Status is required";
    tempErrors.jobPriority = formValues.jobPriority
      ? ""
      : "Job Priority is required";
    tempErrors.suitableQualification = formValues.suitableQualification
      ? ""
      : "Suitable Qualification is required";
    tempErrors.coreSkills =
      formValues.coreSkills.length > 0 ? "" : "Core Skills are required";
    tempErrors.minSalary =
      formValues.minSalary !== "" && formValues.minSalary >= 0
        ? ""
        : "Min Salary must be 0 or more";
    tempErrors.maxSalary =
      formValues.maxSalary !== "" && formValues.maxSalary >= 0
        ? ""
        : "Max Salary must be 0 or more";
    tempErrors.employmentType = formValues.employmentType
      ? ""
      : "Employment Type is required";
    tempErrors.workMode = formValues.workMode ? "" : "Work Mode is required";
    tempErrors.jobLocation = formValues.jobLocation
      ? ""
      : "Job Location is required";

    tempErrors.expectedClosingDate = formValues.expectedClosingDate
      ? ""
      : "Expected Closing Date is required";

    if (
      formValues.expectedClosingDate &&
      formValues.datePosted &&
      new Date(formValues.expectedClosingDate) < new Date(formValues.datePosted)
    ) {
      tempErrors.expectedClosingDate =
        "Expected Closing Date cannot be before Date Posted";
    }
    if (
      formValues.actualClosingDate &&
      formValues.datePosted &&
      new Date(formValues.actualClosingDate) < new Date(formValues.datePosted)
    ) {
      tempErrors.actualClosingDate =
        "Actual Closing Date cannot be before Date Posted";
    }
    if (Number(formValues.maxExperience) < Number(formValues.minExperience)) {
      tempErrors.maxExperience =
        "Max Experience cannot be less than Min Experience";
    }

    if (Number(formValues.minSalary) > Number(formValues.maxSalary)) {
      tempErrors.minSalary = "Min salary cannot be greater than Max salary";
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setSubmitting(true);
      const payload = {
        JobID: formValues.jobID,
        JobTitle: formValues.jobTitle,
        JobDescription: formValues.jobDescription,
        JobAssignedTo: formValues.jobAssignedTo,
        No_Of_Positions: formValues.positions,
        JobStatus: formValues.jobStatus,
        ExperienceRequired: {
          minExperience: formValues.minExperience,
          maxExperience: formValues.maxExperience,
        },
        JobPriority: formValues.jobPriority,
        SuitableQualification: formValues.suitableQualification,
        Skillset: {
          CoreSkills: formValues.coreSkills,
          OtherSkills: formValues.otherSkills,
        },
        SalaryRange: {
          minSalary: formValues.minSalary,
          maxSalary: formValues.maxSalary,
        },
        EmploymentType: formValues.employmentType,
        WorkMode: formValues.workMode,
        OffShore: formValues.offShore,
        JobLocation: selectedRegion,
        ExpectedClosingDate: formValues.expectedClosingDate,
        region: editMode ? jobData.region._id : selectedRegion._id,
      };

      if (editMode) {
        await editJob(payload);
      } else {
        await AddJob(payload);
      }
    } else {
      console.log("errors=======>", errors);
    }
  };

  const AddJob = async (payload) => {
    try {
      console.log("payload in addJob", payload);
      const resultAction = await dispatch(createJob(payload));

      if (createJob.fulfilled.match(resultAction)) {
        // dispatch(getJobs());
        dispatch(getJobStatusCounts(selectedRegion?._id));
        notifySuccess();
        navigate("/manageJob", {
          state: { headerText: "Jobs" }, // Pass state here
        });
      } else {
        // notifyError("Failed to add job");
        console.error("Failed to create job:", resultAction.payload);
      }
    } catch (error) {
      // notifyError("Failed to add job");
      console.error("Error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const editJob = async (payload) => {
    try {
      // Ensure you pass an object with id and updatedJobData
      const resultAction = await dispatch(
        updateJob({ id: jobId, updatedJobData: payload })
      );
      console.log("result action from edit job", resultAction, payload);
      if (updateJob.fulfilled.match(resultAction)) {
        notifySuccess();
        // dispatch(getJobs());
        dispatch(getJobStatusCounts(selectedRegion?._id));
        navigate("/manageJob", {
          state: { headerText: "Jobs" }, // State must be an object
        });
        console.log(
          "Job edited successfully:",
          payload,
          "result payload",
          resultAction.payload
        );
      } else {
        notifyError("Failed to Update job");
        console.error("Failed to edit job:", resultAction.payload);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const populateFieldsOnEdit = async () => {
    try {
      const resultAction = await dispatch(getJobById(jobId));
      if (getJobById.fulfilled.match(resultAction)) {
        const jobData = resultAction.payload;
        console.log("experience issue", jobData.JobAssignedTo);

        if (editMode && jobData) {
          setFormValues({
            jobID: jobData?.JobID || "",
            jobTitle: jobData?.JobTitle || "",
            jobDescription: jobData?.JobDescription || "",
            jobAssignedTo: jobData?.JobAssignedTo?._id || "",
            positions: jobData?.No_Of_Positions || "",
            jobStatus: jobData?.JobStatus || "",
            minExperience: jobData?.ExperienceRequired?.minExperience ?? "",
            maxExperience: jobData?.ExperienceRequired?.maxExperience || "",
            jobPriority: jobData?.JobPriority || "",
            suitableQualification: jobData?.SuitableQualification || "",
            coreSkills: jobData?.Skillset?.CoreSkills || [],
            otherSkills: jobData?.Skillset?.OtherSkills || [],
            minSalary: jobData?.SalaryRange?.minSalary || "",
            maxSalary: jobData?.SalaryRange?.maxSalary || "",
            employmentType: jobData?.EmploymentType || "",
            workMode: jobData?.WorkMode || "",
            jobLocation: jobData?.JobLocation || "",
            offShore: jobData?.OffShore,
            region: {
              RegionName: jobData?.region?.RegionName || "",
              _id: jobData?.region?._id || "",
            },
            datePosted: jobData?.DatePosted
              ? new Date(jobData?.DatePosted)
              : null,
            expectedClosingDate: jobData?.ExpectedClosingDate
              ? new Date(jobData?.ExpectedClosingDate)
              : null,
            actualClosingDate: jobData?.ActualClosingDate
              ? new Date(jobData?.ActualClosingDate)
              : null,
            createdBy: jobData?.JobCreatedBy?.email || "",
            updatedBy: jobData?.JobUpdatedBy?.email || "",
          });
        }
      } else {
        console.error("Failed to fetch job data:", resultAction.payload);
        notifyError("Failed to load job details");
      }
    } catch (error) {
      notifyError("Something went wrong");
      console.error("Error occurred while fetching job data:", error);
    }
  };

  const populateOptionsFromConfig = async () => {
    const resultAction = await dispatch(getConfigData());
    if (getConfigData.fulfilled.match(resultAction)) {
      console.log("successful fetch", resultAction.payload);
      const config = resultAction.payload[0];
      setOptions({
        jobStatusOptions: config.JobStatus || [],
        jobPriorityOptions: config.Priority || [],
        employmentTypeOptions: config.Employment || [],
        workModeOptions: config.WorkMode || [],
        jobAssignedToOptions: config.jobAssignedToOptions || [],
        coreSkills: config.Skills || [],
        otherSkills: config.Skills || [],
      });
    }
  };

  useEffect(() => {
    if (editMode) {
      populateFieldsOnEdit();
    } else {
      if (selectedRegion) {
        setFormValues((prevData) => ({
          ...prevData,
          region: selectedRegion?._id,
        }));
      }
    }
  }, [editMode, selectedRegion]);

  useEffect(() => {
    populateOptionsFromConfig();
  }, [dispatch]);

  useEffect(() => {
    if (selectedRegion && selectedRegion?._id) {
      dispatch(getAllEmployeeByRegion({ id: selectedRegion?._id })).then(
        (response) => {
          console.log("response from dropdown", response);
          if (response?.data?.statusCode === 401) {
            navigate("/session-expired");
          } else {
            setEmployeeOptions(
              response?.payload?.map((emp) => {
                return {
                  label: `${emp?.FirstName ? emp.FirstName : ""} ${
                    emp?.LastName ? emp.LastName : ""
                  }`,
                  value: emp._id,
                };
              })
            );
          }
        }
      );
    }
  }, [selectedRegion]);

  return (
    <div className="job-form-container">
      <div className="add-job-header">
        <div className="add-job-heading">Job Details</div>
        <div>
          <Link to="/manageJob" state={{ headerText: "Jobs" }}>
            {/* <CloseIcon sx={{ color: "brown" }} /> */}
            <AiOutlineClose className="back-option" />
          </Link>
        </div>
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="jobID">
                Job ID <span className="required-star">*</span>
              </label>
              <TextField
                fullWidth
                // label="Job ID"
                name="jobID"
                placeholder="Job ID"
                value={formValues.jobID}
                onChange={handleChange}
                // onBlur={handleBlur}
                error={!!errors.jobID}
                helperText={errors.jobID}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="jobTitle">
                Job Title <span className="required-star">*</span>
              </label>
              <TextField
                fullWidth
                // label="Job Title"
                placeholder="Job Title"
                name="jobTitle"
                value={formValues.jobTitle}
                onChange={handleChange}
                // onBlur={handleBlur}
                error={!!errors.jobTitle}
                helperText={errors.jobTitle}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="jobDescription">
                Job Description <span className="required-star">*</span>
              </label>
              <TextField
                fullWidth
                aria-label="Job Description"
                multiline
                minRows={3.5}
                name="jobDescription"
                value={formValues.jobDescription}
                placeholder="Job Description"
                onChange={handleChange}
                // onBlur={handleBlur}
                error={!!errors.jobDescription}
                helperText={errors.jobDescription}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="employee-label-card">
                <label htmlFor="reportsTo">
                  Job Assigned To
                  <span className="required-star">*</span>
                </label>
                <Select
                  components={makeAnimated()}
                  // theme={customTheme}
                  name="jobAssignedTo"
                  options={employeeOptions}
                  value={
                    employeeOptions.find(
                      (option) => option.value === formValues?.jobAssignedTo
                    ) || null
                  }
                  // onBlur={handleBlur}
                  onChange={handleChange}
                  // onBlur={(e)=>{
                  //   console.log("blur event for jobAssignedTo triggered");
                  //   validateField("jobAssignedTo",formValues.jobAssignedTo)
                  // }}
                  className="employee-multi-select"
                  placeholder="Select"
                  isSearchable
                  required={true}
                  // isDisabled={isReadOnly}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      height: "40px", // Set your desired height here
                      minHeight: "38px",
                      "@media (min-width: 1440px) and (max-width: 2560px)": {
                        padding: 4,
                      },
                      "@media (min-width: 2560px)": {
                        padding: 18,
                        fontSize: 30,
                      },
                    }),
                  }}
                />
                {errors.jobAssignedTo && (
                  <span className="error-text">{errors.jobAssignedTo}</span>
                )}
              </div>
              <div style={{ display: "flex" }}>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ marginTop: 1.5 }}>
                  <label>
                    No. of Positions <span className="required-star">*</span>
                  </label>
                  <TextField
                    fullWidth
                    type="text"
                    // label="Number of Positions"
                    name="positions"
                    value={formValues.positions}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    } // Prevent negative and non-numeric values
                    error={!!errors.positions}
                    helperText={errors.positions}
                    inputProps={{ min: 0 }}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ marginLeft: 3, marginTop: 1 }}
                >
                  <label htmlFor="jobStatus">
                    Job Status <span className="required-star">*</span>
                  </label>
                  <TextField
                    fullWidth
                    select
                    // label="Job Status"
                    name="jobStatus"
                    value={formValues.jobStatus}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    error={!!errors.jobStatus}
                    helperText={errors.jobStatus}
                    size="small"
                  >
                    {options.jobStatusOptions.map((status) => (
                      <MenuItem key={status} value={status.value}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="minSalary">
                Salary Range <span className="required-star">*</span>
              </label>
              <div style={{ display: "flex" }}>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Min Salary"
                  name="minSalary"
                  value={formValues.minSalary}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  } // Prevent negative and non-numeric values
                  error={!!errors.minSalary}
                  helperText={errors.minSalary}
                  inputProps={{ min: 0 }}
                  size="small"
                />
                <HorizontalRuleRoundedIcon
                  sx={{ margin: 1, color: "gray", width: "3%" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Max Salary"
                  name="maxSalary"
                  value={formValues.maxSalary}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  } // Prevent negative and non-numeric values
                  error={!!errors.maxSalary}
                  helperText={errors.maxSalary}
                  // inputProps={{ min: 0 }}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="minExperience">
                Experience Required (Years){" "}
                <span className="required-star">*</span>
              </label>
              <div style={{ display: "flex" }}>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Min Experience"
                  name="minExperience"
                  value={formValues.minExperience}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  } // Prevent negative and non-numeric values
                  error={!!errors.minExperience}
                  helperText={errors.minExperience}
                  // inputProps={{ min: 0 }}
                  size="small"
                />
                <div> </div>
                <HorizontalRuleRoundedIcon
                  sx={{ margin: 1, color: "gray", width: "3%" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Max Experience"
                  name="maxExperience"
                  value={formValues.maxExperience}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  } // Prevent negative and non-numeric values
                  error={!!errors.maxExperience}
                  helperText={errors.maxExperience}
                  // inputProps={{ min: 0 }}
                  size="small"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label htmlFor="jobPriority">
                Job Priority <span className="required-star">*</span>
              </label>
              <TextField
                fullWidth
                select
                // label="Job Priority"
                name="jobPriority"
                value={formValues.jobPriority}
                onChange={handleChange}
                // onBlur={handleBlur}
                error={!!errors.jobPriority}
                helperText={errors.jobPriority}
                size="small"
              >
                {options?.jobPriorityOptions?.map((priority) => (
                  <MenuItem key={priority} value={priority.value}>
                    {priority.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label htmlFor="suitableQualification">
                Suitable Qualification <span className="required-star">*</span>
              </label>
              <TextField
                fullWidth
                // label="Suitable Qualification"
                name="suitableQualification"
                value={formValues.suitableQualification}
                onChange={handleChange}
                // onBlur={handleBlur}
                error={!!errors.suitableQualification}
                helperText={errors.suitableQualification}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="coreSkills">
                Core Skills <span className="required-star">*</span>
              </label>
              <Autocomplete
                multiple
                freeSolo
                options={options.coreSkills}
                getOptionLabel={(option) => option.name}
                value={options.coreSkills.filter((option) =>
                  formValues.coreSkills.includes(option.value)
                )}
                onChange={(event, value) => {
                  handleSkillChange(event, value, "coreSkills");
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    // label="Core Skills"
                    name="coreSkills"
                    error={!!errors.coreSkills}
                    helperText={errors.coreSkills}
                    size="small"
                    onFocus={() => console.log("Input focused")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="otherSkills">Other Skills</label>
              <Autocomplete
                multiple
                freeSolo
                options={options.otherSkills}
                getOptionLabel={(option) => option.name}
                value={options.otherSkills.filter((option) => {
                  return formValues.otherSkills.includes(option.value);
                })}
                onChange={(event, value) =>
                  handleSkillChange(event, value, "otherSkills")
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="otherSkills"
                    error={!!errors.otherSkills}
                    helperText={errors.otherSkills}
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label htmlFor="employmentType">
                Employment Type <span className="required-star">*</span>
              </label>
              <TextField
                fullWidth
                select
                // label="Employment Type"
                name="employmentType"
                value={formValues.employmentType}
                onChange={handleChange}
                // onBlur={handleBlur}
                error={!!errors.employmentType}
                helperText={errors.employmentType}
                size="small"
              >
                {options?.employmentTypeOptions?.map((type) => (
                  <MenuItem key={type} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label htmlFor="workMode">
                Work Mode <span className="required-star">*</span>
              </label>
              <TextField
                fullWidth
                select
                // label="Work Mode"
                name="workMode"
                value={formValues.workMode}
                onChange={handleChange}
                // onBlur={handleBlur}
                error={!!errors.workMode}
                helperText={errors.workMode}
                size="small"
              >
                {options?.workModeOptions?.map((mode) => (
                  <MenuItem key={mode} value={mode.value}>
                    {mode.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label htmlFor="jobLocation">
                Job Location <span className="required-star">*</span>
              </label>

              <TextField
                fullWidth
                // label="Job Location"
                name="jobLocation"
                value={formValues.jobLocation}
                onChange={handleChange}
                // onBlur={handleBlur}
                error={!!errors.jobLocation}
                helperText={errors.jobLocation}
                size="small"
              />
            </Grid>
            {editMode && (
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <label htmlFor="datePosted">Date Posted</label>
                <DatePicker
                  value={formValues.datePosted}
                  onChange={(date) => handleDateChange(date, "datePosted")}
                  // onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.datePosted}
                      helperText={errors.datePosted}
                      name="datePosted"
                      size="small"
                    />
                  )}
                  inputFormat="MM/dd/yyyy"
                  disabled
                />
              </Grid>
            )}

            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label htmlFor="expectedClosingDate">
                Expected Closing Date <span className="required-star">*</span>
              </label>
              <DatePicker
                // label="Expected Closing Date"
                value={formValues.expectedClosingDate}
                onChange={(date) =>
                  handleDateChange(date, "expectedClosingDate")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="expectedClosingDate"
                    error={!!errors.expectedClosingDate}
                    helperText={errors.expectedClosingDate}
                    size="small"
                    // onBlur={(e) => {
                    //   // Trigger handleBlur when the date field loses focus
                    //   handleBlur({
                    //     target: {
                    //       name: "expectedClosingDate",
                    //       value: e.target.value,
                    //     },
                    //   });
                    // }}
                  />
                )}
                inputFormat="MM/dd/yyyy"
              />
            </Grid>

            {/* //enable this in edit if value is available======================================================= */}
            {editMode && (
              <>
                {jobData?.ActualClosingDate && (
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <label htmlFor="actualClosingDate">
                      Actual Closing Date{" "}
                      <span className="required-star">*</span>
                    </label>
                    <DatePicker
                      value={formValues.actualClosingDate}
                      onChange={(date) =>
                        handleDateChange(date, "actualClosingDate")
                      }
                      // onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          name="actualClosingDate"
                          error={!!errors.actualClosingDate}
                          helperText={errors.actualClosingDate}
                          size="small"
                        />
                      )}
                      inputFormat="MM/dd/yyyy"
                    />
                  </Grid>
                )}
                {jobData?.JobCreatedBy && (
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <label htmlFor="createdBy">Created By</label>
                    <TextField
                      fullWidth
                      disabled
                      name="createdBy"
                      value={formValues.createdBy}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      error={!!errors.createdBy}
                      helperText={errors.createdBy}
                      size="small"
                    >
                      {employees?.map((employee) => (
                        <MenuItem key={employee} value={employee._id}>
                          {employee.email}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {jobData?.JobUpdatedBy && (
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <label htmlFor="createdBy">Updated By</label>
                    <TextField
                      fullWidth
                      disabled
                      name="createdBy"
                      value={formValues.updatedBy}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      error={!!errors.createdBy}
                      helperText={errors.createdBy}
                      size="small"
                    >
                      {employees?.map((employee) => (
                        <MenuItem key={employee} value={employee._id}>
                          {employee.email}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label htmlFor="region">Region</label>

              <div>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="region"
                    value={formValues?.region?.RegionName}
                    style={{
                      height: 40,
                      marginTop: 3,
                      width: "100%",
                      borderRadius: 5,
                      borderWidth: 1,
                    }}
                    readOnly
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    id="region"
                    value={selectedRegion?.name || ""}
                    readOnly
                    style={{
                      marginTop: 3,
                      height: 40,

                      width: "100%",
                      borderRadius: 5,
                      borderWidth: 1,
                    }}
                  />
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={1.5}
              md={1.5}
              lg={1.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "15px",
                marginLeft: "10px",
              }}
            >
              <label>
                Offshore <span className="required-star">*</span>
              </label>
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.offShore}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    name="offShore"
                  />
                }
                // label="Off Shore"
              />
            </Grid>

            {/* ====================================================================================================================== */}
            <Grid item xs={12} sm={12} md={12}>
              <div className="employee-Actions-container">
                <>
                  <button
                    onClick={handleSubmit}
                    disabled={submitting}
                    className="employee-Action-btn"
                    type="button"
                  >
                    {editMode
                      ? submitting
                        ? "Updating..."
                        : "Update"
                      : submitting
                      ? "Creating..."
                      : "Create"}
                  </button>
                </>
              </div>
            </Grid>
          </Grid>
        </form>
      </LocalizationProvider>
    </div>
  );
};

export default JobForm;
