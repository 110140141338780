import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import CustomDataGrid from "../../../utilites/CustomDataGrid";
import "./index.css";

const AssetsGridView = () => {
  const navigate = useNavigate();
  const Data = useSelector((state) => state?.Data.Resources?.Assests_Details);
  const userRole =  sessionStorage.getItem("role").split(",");

  const handleEdit = (id) => {
    userRole.some((role) => ["ADMIN", "ACCOUNTS"].includes(role))
      ? navigate(`/assets/${id}`)
      : navigate("/noaccess");
  };

  const styles = {
    button: {
      marginLeft: 0,
      backgroundColor: "#24CCEF",
      color: "white",
      border: "none",
      padding: "8px 16px",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  const rows = [];
  Data.map((e) => {
    const AssetsData = {
      Asset_ID: e.Asset_ID,
      Asset_Type: e.Asset_Type,
      Location: e.Location,
      Model: e.Model,
      Purchase_Cost: e.Purchase_Cost,
      Purchase_Date: e.Purchase_Date,
      Purchase_From: e.Purchase_From,
      Serial_Number: e.Serial_Number,
      Status: e.Status,
      Vendor: e.Vendor,
      createdAt: e.createdAt,
      createdBy: e.createdBy,
      desc: e.desc,
      invoice_number: e.invoice_number,
      quantity: e.quantity,
      remarks: e.remarks,
      updatedAt: e.updatedAt,
      updatedBy: e.updatedBy,
      warranty_expiration_date: e.warranty_expiration_date,
      warranty_info: e.warranty_info,
      id: e._id,
    };
    rows.push(AssetsData);
    return null;
  });

  const columns = [
    {
      field: "Asset_ID",
      headerName: "Asset ID",
      width: 120,
    },
    {
      field: "Asset_Type",
      headerName: "Asset Type",
      width: 120,
    },
    // {
    //   field: "desc",
    //   headerName: "Description",
    //   width: 100,
    // },
    // {
    //   field: "Model",
    //   headerName: "Model",
    //   width: 100,
    // },
    {
      field: "Vendor",
      headerName: "Vendor",
      width: 120,
    },
    // {
    //   field: "Serial_Number",
    //   headerName: "Serial Number",
    //   width: 120,
    // },
    // {
    //   field: "Location",
    //   headerName: "Location",
    //   width: 120,
    // },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
    },
    {
      field: "Purchase_Date",
      headerName: "Purchase Date",
      width: 150,
      type: "date",
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, "-")
          : "",
    },
    // {
    //   field: "Purchase_Date_Formatted",
    //   headerName: "Purchase Date",
    //   width: 150,
    //   filterable: false,
    //   valueGetter: (params) =>
    //     params.row.Purchase_Date
    //       ? new Date(params.row.Purchase_Date)
    //           .toLocaleDateString("en-GB", {
    //             day: "2-digit",
    //             month: "2-digit",
    //             year: "numeric",
    //           })
    //           .replace(/\//g, "-")
    //       : "",
    // },
    {
      field: "Purchase_From",
      headerName: "Purchase From",
      width: 120,
    },
    {
      field: "Purchase_Cost",
      headerName: "Purchase Cost",
      width: 120,
    },
    // {
    //   field: "warranty_info",
    //   headerName: "Warranty Info",
    //   width: 100,
    // },
    {
      field: "warranty_expiration_date",
      headerName: "Warranty Expiration Date",
      width: 180,
      type: "date",
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, "-")
          : "",
    },
    // {
    //   field: "warranty_expiration_Date_Formatted",
    //   headerName: "Warranty Expiration Date",
    //   width: 180,
    //   filterable: false,
    //   valueGetter: (params) =>
    //     params.row.warranty_expiration_date
    //       ? new Date(params.row.warranty_expiration_date)
    //           .toLocaleDateString("en-GB", {
    //             day: "2-digit",
    //             month: "2-digit",
    //             year: "numeric",
    //           })
    //           .replace(/\//g, "-")
    //       : "",
    // },

    // {
    //   field: "invoice_number",
    //   headerName: "Invoice Number",
    //   width: 120,
    // },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
    },
    // {
    //   field: "remarks",
    //   headerName: "Remarks",
    //   width: 70,
    // },
    {
      field: "View",
      headerName: "View",
      width: 120,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <strong>
            <button
              style={styles.button}
              onClick={() => handleEdit(params.row.id)}
            >
              View
            </button>
          </strong>
        );
      },
    },
  ];

  return (
    <CustomDataGrid
      Data={Data}
      rows={rows}
      columns={
        userRole.some((role) => ["ADMIN", "ACCOUNTS"].includes(role))
          ? columns
          : columns.filter(
              (column) => column.field !== "edit" && column.field !== "delete"
            )
      }
    />
  );
};

export default AssetsGridView;
