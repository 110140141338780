import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import Main from "../Main/Main";
import EmployeeDetails from "../Employee/EmployeeDetails/EmployeeDetails";
import Users from "../Users/Users";
import AssetsAssignButton from "../Assets/AssetsAssignButton/AssetsAssignButton";
import AddAssets from "../Assets/AddAssets/AddAssets";
import UpdateAssets from "../Assets/UpdateAssets/UpdateAssets";
import About from "../About/About";
import MyProfile from "../MyProfile/MyProfile";
import AddEmployee from "../Employee/AddEmployee/AddEmployee";
import UpdateEmployee from "../Employee/UpdateEmployee/UpdateEmployee";
import ProjectsList from "../Projects/ProjectFolder/ProjectsList/ProjectsList";
import AddProject from "../Projects/ProjectFolder/AddProject/AddProject";
import UpdateProject from "../Projects/ProjectFolder/UpdateProject/UpdateProject";
import ProjectAssignButton from "../Projects/ProjectAssignFolder/ProjectAssignButton/ProjectAssignButton";
import AssignProject from "../Projects/ProjectAssignFolder/AssignProject/AssignProject";
import UpdateAssignProject from "../Projects/ProjectAssignFolder/UpdateAssignProject/UpdatedAssignProject";
import JobForm from "../Recruitment/ManageJob/AddJob/JobForm";
import ViewAllJobs from "../Recruitment/ManageJob/ViewAllJobs/ViewAllJobs";
import EditJob from "../Recruitment/ManageJob/EditJob/EditJob";
import ManageJob from "../Recruitment/ManageJob/ManageJob";
import ManageJobOnBoarding from "../Recruitment/ManageJobOnBoarding/ManageJobOnBoarding";
import ManageInterviewSchedule from "../Recruitment/ManageInterviewSchedule/ManageInterviewSchedule";
import ApplicationGrid from "../Recruitment/JobApplications/ApplicationsGrid/ApplicationGrid";
import AddApplication from "../Recruitment/JobApplications/AddApplication/AddApplication";
import UpdateApplication from "../Recruitment/JobApplications/UpdateApplication/UpdateApplication";
import NoAccess from "../NoAccess/NoAccess";
import AddEvent from "../Notice/AddEvent/AddEvent";
import AddConfig from "../Config/ConfigPage/AddConfig";
import RecHome from "../RecruitmentDashBoard/Home/Home";
import Referral from "../RecruitmentDashBoard/Referral/Referral";
//import ConfigGridView from "../Config/ConfigPage/ConfigGridView";
import Overview from "../RecruitmentDashBoard/Overview/Overview";
import MyReferral from "../RecruitmentDashBoard/Referral/MyReferral";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import Interview from "../RecruitmentDashBoard/Interview/Interview";

import PreEliminery from "../RecruitmentDashBoard/Interview/PreEliminery";
import Reports from "../RecruitmentDashBoard/Reports/Reports";
import ConfigTable from "../Config/ConfigPage/ConfigTable";
import Insights from "../Insights/Insights";
import AuditPage from "../AuditPage/AuditPage";
import SessionExpired from "../SessionExpired/SessionExpired";
const RoutesComponent = () => {
  const userRole = sessionStorage?.getItem("role")?.split(",") || [];
  return (
    <Routes>
      <Route path="/" element={<Navigate to='/insights' />} />
      <Route path="/session-expired" element={<SessionExpired />} />
      <Route path="*" element={<NoAccess />} />
      {/* <Route path="/profile" element={<MyProfile />} /> */}
      <Route path="/about" element={<About />} />

      {/* {userRole.includes(["EMPLOYEE", "ACCOUNTS", "ADMIN", "HR"]) && (
        <Route path="/recruitmentHome" element={<RecHome />}>
          <Route path="referral" element={<Referral />}></Route>
          <Route path="myreferral" element={<MyReferral />} />
          <Route path="interviews" element={<Interview />} />
          <Route path="PreEliminery" element={<PreEliminery />} />
          <Route path="employeeScreening" element={<ApplicationGrid />}></Route>
          <Route path="addApplication" element={<AddApplication />}></Route>
          <Route path="addjob" element={<AddJob />}></Route>
          <Route
            path="updateApplication/:applicationId"
            element={<UpdateApplication />}
          ></Route>
          <Route path="manageJob" element={<ManageJob />}></Route>
          <Route path="overview" element={<Overview />} />

          <Route path="reports" element={<Reports />} />
          <Route
            path="manageJobOnBoarding"
            element={<ManageJobOnBoarding />}
          ></Route>
          <Route
            path="manageInterviewSchedule"
            element={<ManageInterviewSchedule />}
          ></Route>
          <Route path="editJob/:jobId" element={<EditJob />}></Route>
        </Route>
      )} */}

      {userRole.includes("ACCOUNTS") && (
        <>
          <Route path="/assets" element={<AssetsAssignButton />}></Route>
          <Route path="/addAssets" element={<AddAssets />}></Route>
          <Route path="/assets/:id" element={<UpdateAssets />}></Route>
          <Route path="/employee" element={<EmployeeDetails />} />
          <Route path="/assignProject" element={<ProjectAssignButton />} />
          <Route path="/projects" element={<ProjectsList />}></Route>
          {/* <Route path="/manageJob" element={<ManageJob />}></Route> */}
        </>
      )}

      {userRole.includes("EMPLOYEE") && (
        <>
          <Route path="/employee" element={<EmployeeDetails />} />
          <Route path="/assignProject" element={<ProjectAssignButton />} />
          <Route path="/projects" element={<ProjectsList />}></Route>
          {/* <Route path="/manageJob" element={<ManageJob />}></Route> */}
          <Route path="/viewalljobs" element={<ViewAllJobs />} />
          {/* <Route path="/RecruitmentHome" element={<RecHome />} /> */}
        </>
      )}

      {userRole.includes("ADMIN") && (
        <>
          <Route path="employeeScreening" element={<ApplicationGrid />}></Route>
          <Route path="addApplication" element={<AddApplication />}></Route>
          <Route path="addjob" element={<JobForm editMode={false} />}></Route>
          <Route
            path="updateApplication/:applicationId"
            element={<UpdateApplication />}
          ></Route>
          <Route path="manageJob" element={<ManageJob />}></Route>
          <Route path="editJob/:jobId" element={<JobForm editMode={true} />}></Route>

          <Route path="/assets" element={<AssetsAssignButton />}></Route>
          <Route path="/addAssets" element={<AddAssets />}></Route>
          <Route path="/assets/:id" element={<UpdateAssets />}></Route>
          <Route path="/employee" element={<EmployeeDetails />} />
          <Route path="/addEmployee" element={<AddEmployee />}></Route>
          <Route
            path="/employee_details/:empid"
            element={<UpdateEmployee />}
          ></Route>
          <Route path="/assignProject" element={<ProjectAssignButton />} />
          <Route path="/createProject" element={<AddProject />}></Route>
          <Route path="/assignProjectName" element={<AssignProject />}></Route>
          <Route path="/projects" element={<ProjectsList />}></Route>
          <Route path="/project/:id" element={<UpdateProject />}></Route>
          <Route
            path="/AssignProject/:id"
            element={<UpdateAssignProject />}
          ></Route>
          <Route path="/users" element={<Users />}></Route>
          <Route path="/notice" element={<AddEvent />}></Route>
          <Route path="/config" element={<ConfigTable />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/auditpage" element={<AuditPage />} />

        </>
      )}

      {userRole.includes("HR") && (
        <>
          <Route path="employeeScreening" element={<ApplicationGrid />}></Route>
          <Route path="addApplication" element={<AddApplication />}></Route>
          <Route path="addjob" element={<JobForm editMode={false} />}></Route>
          <Route
            path="updateApplication/:applicationId"
            element={<UpdateApplication />}
          ></Route>
          <Route path="manageJob" element={<ManageJob />}></Route>
          <Route path="editJob/:jobId" element={<JobForm editMode={true} />}></Route>
          <Route path="/employee" element={<EmployeeDetails />} />
          <Route path="/addEmployee" element={<AddEmployee />} />
          <Route path="/users" element={<Users />}></Route>
          {/* <Route path="manageJob" element={<ManageJob />} /> */}
          {/* <Route path="/addjob" element={<AddJob />}></Route>
          <Route path="/editJob/:jobId" element={<EditJob />}></Route> */}
          <Route path="/assignProject" element={<ProjectAssignButton />} />
          <Route path="/projects" element={<ProjectsList />}></Route>
          <Route
            path="/manageJobOnBoarding"
            element={<ManageJobOnBoarding />}
          />
          {/* <Route
            path="/manageJobOnBoarding"
            element={<ManageJobOnBoarding />}
          ></Route>
          <Route
            path="/manageInterviewSchedule"
            element={<ManageInterviewSchedule />}
          ></Route> */}
          <Route path="/addEmployee" element={<AddEmployee />}></Route>
          <Route
            path="/employee_details/:empid"
            element={<UpdateEmployee />}
          ></Route>
          {/* <Route
            path="/employeeScreening"
            element={<ApplicationGrid />}
          ></Route> */}
          {/* <Route path="/addApplication" element={<AddApplication />}></Route>

          <Route
            path="/updateApplication/:applicationId"
            element={<UpdateApplication />}
          ></Route> */}
          <Route path="/notice" element={<AddEvent />}></Route>
          <Route path="/config" element={<ConfigTable />} />

        </>
      )}
    </Routes>
  );
};

export default RoutesComponent;
