import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssetsGridView from "../AssetsGridView/AssetsGridView";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import "./index.css";

const styles = {
  Button: {
    marginLeft: 20,
    backgroundColor: "#24CCEF",//24CCEF
    color: "white",
    border: "none",
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: 20,
    marginBottom: 20,
  },
};

const AssetsAssignButton = () => {
  const navigate = useNavigate();
  const userRole = sessionStorage?.getItem("role")?.split(",")||[];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop: 3,
          "@media (min-width: 2560px)": {
            marginTop: "60px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",

            "@media (min-width: 2560px)": {
              marginRight: "60px",
              fontSize: "300",
            },
          }}
        >
          {userRole.some((role) => ["ADMIN","ACCOUNTS"].includes(role)) ? (
            <Button
              onClick={() => navigate("/addAssets")}
              startIcon={<AddCircleOutlineIcon />}
              style={styles.Button}
            >
              Add Asset
            </Button>
          ) : null}
        </Box>
      </Box>

      <AssetsGridView />
    </>
  );
};

export default AssetsAssignButton;
