import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@mui/material";

const columns = [
  { field: "id", headerName: "S No.", width: 90 },
  {
    field: "JobTitle",
    headerName: "Job Title",
    width: 200,

    // align: "center",
    // renderCell: (params) => (
    //   <div
    //     style={{
    //       wordWrap: "break-word",
    //       // whiteSpace: "normal",
    //       // textAlign: "center",
    //       // minHeight: "200px !important",
    //       // // "&.MuiDataGrid-row": {
    //       //   minHeight: "72px !important",
    //       //   maxHeight: "72px !important",
    //       // },
    //     }}
    //   >
    //     {params.value}
    //   </div>
    // ),

    // console.log(initialStatus);

    // editable: true,

    // editable: true,
  },
  {
    field: "Applied",
    headerName: "Applied",
    width: 200,
    // editable: true,
  },
  {
    field: "Interviewed",
    headerName: "Interviewed",
    // type: "number",
    width: 200,
    // editable: true,
  },
  {
    field: "Hired",
    headerName: "Hired",
    // type: "number",
    width: 200,
    // editable: true,
  },
  {
    field: "Status",
    headerName: "Status",
    color: "green",
    width: 200,
    renderCell: (params) => {
      const initialStatus = params.row.Status;

      let style = {};
      if (initialStatus === "Active") {
        style = {
          color: "green",
          border: "1px solid green",
          padding: "4px",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        };
      } else if (initialStatus === "Closed") {
        style = {
          color: "red",
          border: "1px solid red",
          padding: "4px",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        };
      }
      return (
        <div>
          <span style={style}>{initialStatus}</span>
        </div>
      );
      // editable: true,
    },
  },
];

const rows = [
  {
    id: 1,
    JobTitle: "Fullstack",
    Applied: 20,
    Interviewed: 10,
    Hired: 4,
    Status: "Active",
  },
  {
    id: 2,
    JobTitle: "Python",
    Applied: 18,
    Interviewed: 6,
    Hired: 1,
    Status: "Active",
  },
  {
    id: 3,
    JobTitle: "Rust",
    Applied: 8,
    Interviewed: 3,
    Hired: 0,
    Status: "Active",
  },
  {
    id: 4,
    JobTitle: "QA",
    Applied: 20,
    Interviewed: 14,
    Hired: 6,
    Status: "Closed",
  },
];

export default function Reports() {
  return (
    <Box>
      <Typography
        variant="h6"
        style={{
          fontWeight: "500",
          paddingTop: "9px",
          paddingBottom: "9px",
          paddingLeft: "10px",
        }}
      >
        Latest Jobs
      </Typography>
      <Box sx={{ height: 360, width: "100%", backgroundColor: "white" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          // style={{ width: "fit-content" }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}

// import * as React from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import { Box, Typography } from "@mui/material";

// function createData(JobTitle, Applied, Interviewed, Hired, Status) {
//   return { JobTitle, Applied, Interviewed, Hired, Status };
// }

// const rows = [
//   createData("Full Stack", 159, 100, 24, "Active"),
//   createData("Python", 10, 9, 2, "Closed"),
//   createData("DevOps", 12, 5, 1, "Active"),
//   createData("QA", 30, 30, 4, "Closed"),
// ];

// export default function BasicTable() {
//   return (
//     <Box>
//       <Box sx={{ mt: "10px" }}>
//         <Typography
//           variant="h6"
//           style={{
//             marginBottom: "10px",
//             marginLeft: "5px",
//             fontWeight: "300",
//           }}
//         >
//           Latest Jobs
//         </Typography>
//         <TableContainer component={Paper}>
//           <Table sx={{ minWidth: 650 }} aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 <TableCell>JobTitle</TableCell>
//                 <TableCell align="center">Applied</TableCell>
//                 <TableCell align="center">Interviewed</TableCell>
//                 <TableCell align="center">Hired</TableCell>
//                 <TableCell align="center">Status</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {rows.map((row) => (
//                 <TableRow
//                   key={row.name}
//                   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                 >
//                   <TableCell component="th" scope="row">
//                     {row.JobTitle}
//                   </TableCell>
//                   <TableCell align="center">{row.Applied}</TableCell>
//                   <TableCell align="center">{row.Interviewed}</TableCell>
//                   <TableCell align="center">{row.Hired}</TableCell>
//                   <TableCell align="center">
//                     <button
//                       style={{
//                         padding: "5px 15px",
//                         borderColor: row.Status === "Active" ? "green" : "red",
//                         borderRadius: "4px",
//                       }}
//                     >
//                       {row.Status}
//                     </button>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Box>
//   );
// }
