import React from "react";

const EmployeeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="24" fill="url(#pattern0_27_155)" />
      <defs>
        <pattern
          id="pattern0_27_155"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_27_155" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_27_155"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEGUlEQVR4nO2cx4vWQBjGo6IXxYYNT/aLF11FwYLlIp7sCgqCiP4DKljAimADEc96sIGe9GxDsGC7ibtrvejBguKu2EB/MuwI67ImM/mSTBKfH3yX7E7yPu+TKd87yRdFQgghhBBCCCGEEEIIIYQQJQHoAUwB9gLXgRbgs/0022N7gKbQsdYx8SuBVtwx5iw3bUPHX2mAscBd0nMHGB1aRyUBZgFvaJz3wPzQeioFMA/4QXZ8B+aE1lUJgNHAW7LH9IRxofVVYcK9S37c1sQcb8Aq8mdZcbdU9e7+1gIMaA6ttZQAUymOyaH1lg5gX4EG7A6tt3TQUUooiqtRlQH62AnzXJeaTIs9ZkoHfTzP2VqgAS2h9abGrCKA5w4inwFLPc7bRnG0hdbrDdALOJJC7GGgZ9UMIGe93qQM5g8HSzYENYfW64Xtho2yOOEa1yjJJFyEXt8JyIxvjfIibqKiYzOlKHaG1huqPLAi5jpNFMek0Hp9DDDLrKw4k1CKaCF/HpdBr48BT4paf9OxjZg3S8qi19WAtqKWf3T0ArONmBc3k8rRRep1NaA9w4A+OVxvVI4bMmPLpjcqeH3u1CWBOXYbMSvMuWaXVW9U4KR02uO6MzPclJ9Xdr1xAZlCU1YsT7E/fLvBMX9UVfTm/cXkKdA7xfV72G+m5uk3Vx6nLYyF1vuvoJZmENCiDOKYbDZTTCnBGtJuP8322K64L1lV09s1KFPlS8uBqGJQNr2mxAocShHMwVzKszlTWr2mymfHtySeZt4NA1BKvWZyMYUmU+voZiw+Y0sK2UxAJeB/0yuEEEJEwBBgLrDBrssvAQ/sYyLvgG/2884eM3+7aP93gy3qDVEqHQFG2tXGMZvMX2SDMecUsBGYqEfU/076RFte8Kn1NMpLa/Ks/7J3AMOBHcAjwvPIxjIsqgLACGANcBSY5tl2DHDSjttlw8R0wvetSmC6zcVqc2N5J9TxIsOAzbY2/9MGfNGj/QDgeMa7XHnx3Q5P/T30mYkfm5tbwCZgaOqEdzrxFLtD1DVxba5dFlgIvKZ6vAIWeNyg7d30qLOp3twHJgAXYlYhTs8+Ats79Zgq8hPY6qj1X5VTk8PzwHjXEuzmhDH6m8tYB+ynPuxzXFjEDbFf7dDUfakaGOj4gOxlh2DWUT/WOui+4nAe8z8DujYcDNx3DGSTw53wkfrxIWneA7Y4nuseMKjziwg3PAKZkRCEWVPXlW0Ov2/hinknrpdptN4ziDEJQTykvtx3+HUXH9ZFKX4aoG9CEHUcfv7wMUF7P/y4Yxp98WkRF4ANotZE2er/4p0wGRCPdz5lgB8J+ZcBeSMDAiMDAiMDAiMDAiMDAiMDAiMDAiMDAiMDAiMD6maAEEIIIYQQQgghhBBCCCGiuvMbLVSgxlnV6xMAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};
export default EmployeeIcon;
