import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  getGridStringOperators,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { ToastContainer } from "react-toastify";
import {
  getAllJobData,
  getloginlogs,
  getUsersList,
} from "../../redux/dataSlice";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CustomHeader = (props) => {
  return (
    <div
      style={{
        fontWeight: "bold",
        color: "rgba(0, 0, 0, 0.8)",
        fontSize: "14px",
        textAlign: "center",
        textTransform: "full-width",
        padding: "8px",
      }}
    >
      {props.colDef.headerName}
    </div>
  );
};
const customStringOperators = getGridStringOperators().filter(
  (operator) => operator.value === "contains" || operator.value === "equals"
);

const customColumnTypes = {
  string: {
    filterOperators: customStringOperators,
  },
};

function CustomToolbar({
  onSearchChange,
  searchText,
  onAddEmployeeClick,
  buttonText,
  showAddButton = true,
  roles = ["ADMIN"],
}) {
  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("role").split(",");

  console.log(
    onSearchChange,
    searchText,
    onAddEmployeeClick,
    buttonText,
    showAddButton,
    "Toolbar Props"
  );

  const styles = {
    Button: {
      marginLeft: 20,
      backgroundColor: "#8A2BE2",
      color: "white",
      border: "none",
      padding: "8px 16px",
      borderRadius: "4px",
      cursor: "pointer",
      marginRight: 20,
      marginBottom: 20,
      textTransform: "capitalize",
    },
  };

  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <GridToolbarQuickFilter
          debounceMs={500}
          onChange={onSearchChange}
          value={searchText}
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "#ffffff" /* White background */,
              borderRadius: "25px" /* Rounded corners */,
              padding: "12px 20px" /* Padding inside the input */,
              width: "300px" /* Input width */,
              height: "43px" /* Input height */,
              border: "none" /* Remove border */,
              boxShadow:
                "0 2px 6px rgba(0, 0, 0, 0.1)" /* Subtle shadow for depth */,
              fontSize: "16px" /* Font size */,
              color: "#2196f3" /* Bright blue text */,
              transition:
                "box-shadow 0.3s ease, background-color 0.3s ease" /* Smooth transition for shadow and background */,
              outline: "none" /* Remove default focus outline */,
              position:
                "relative" /* Ensure pseudo-elements are positioned relative to the input */,
              "&::before": {
                content: '""',
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                border: "none" /* Ensure no border */,
                borderRadius: "25px" /* Match border radius */,
                boxShadow:
                  "0 2px 6px rgba(0, 0, 0, 0.1)" /* Same shadow as the input */,
                zIndex: -1 /* Place behind the input */,
              },
              "&::after": {
                content: '""',
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                border: "none" /* Ensure no border */,
                borderRadius: "25px" /* Match border radius */,
                boxShadow:
                  "0 2px 6px rgba(0, 0, 0, 0.1)" /* Same shadow as the input */,
                zIndex: -1 /* Place behind the input */,
              },
              "&:hover": {
                backgroundColor: "#f0f4f8" /* Very light blue on hover */,
                boxShadow:
                  "0 4px 10px rgba(0, 0, 0, 0.15)" /* More pronounced shadow on hover */,
              },
              "& .MuiInputBase-input": {
                color: "#000000",
                padding: "0 12px" /* Padding inside the input */,
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#9e9e9e" /* Light grey placeholder text */,
                fontSize: "16px" /* Placeholder font size */,
              },
              "& .MuiInputBase-root.Mui-focused": {
                boxShadow:
                  "0 4px 10px rgba(0, 0, 0, 0.15)" /* Shadow on focus */,
                borderColor: "transparent",
              },
            },
            marginRight: "10px",
            paddingTop: "3px",
          }}
        />

        {/* {userRole?.some((role) => showAddButton?.includes(role)) ? ( */}
        {userRole?.some((role) => showAddButton && roles?.includes(role)) ? (
          <Button
            onClick={onAddEmployeeClick}
            startIcon={<AddCircleOutlineIcon />}
            style={styles.Button}
          >
            {buttonText}
          </Button>
        ) : null}
      </div>
    </GridToolbarContainer>
  );
}

const ServerSideDataGrid = ({
  columns,
  getRowId,
  handleCellEditChange,
  editingRowId,
  loading,
  fetchDataAction,
  dataSelector,
  sliceKey,
  status: JobStatus,
  startDate,
  endDate,
  handleAdd,
  showAddButton = true,
  addButtonName,
  roles,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterModel, setFilterModel] = useState({
    items: [{ columnField: "projectId", value: "", operatorValue: "contains" }],
  });
  const [selectionModel, setSelectionModel] = useState([]);
  const [sortModel, setSortModel] = useState([
    { field: "createdAt", sort: "desc" },
  ]);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  //  const [data, setData] = useState([]);

  const getNestedState = (state, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], state);
  };

  const { data } = useSelector((state) => {
    const slice = getNestedState(state, sliceKey);
    return slice && slice[dataSelector]
      ? slice[dataSelector]
      : { data: [], totalItems: 0, totalPages: 1 };
  });
  const {
    selectedRegion,
  } = useSelector((state) => state.regions);

  const fetchComponentDetails = useCallback(() => {
    console.log("fetchComponentDetails is running in SSG")
    const filters = filterModel.items.reduce((acc, item) => {
      if (item.value) {
        let value = item.value;
        console.log("filerated items===>", item);

        const operator =
          item.operatorValue === "equals" ? "_equals" : "_contains";
        acc[`${item.columnField}${operator}`] = value;
      }
      return acc;
    }, {});

    const sort = sortModel.length ? sortModel[0] : {};

    const params = {
      page,
      limit,
      ...filters,
      Search: searchText,
      sortField: sort.field,
      sortOrder: sort.sort,
      selectedRegion: selectedRegion
    };

    // Conditionally add params based on action type or a specific prop
    if (fetchDataAction === getAllJobData) {
      // Assuming `getAllJobData` is your jobs dispatch action
      params.JobStatus = JobStatus;
      params.startDate = startDate;
      params.endDate = endDate;
    }
    console.log("params for searchText", params)
    // dispatch(fetchDataAction(params))
    dispatch(fetchDataAction({ regionId: selectedRegion?._id, params }))
      .then((response) => {
        // if (response?.data?.statusCode === 401) {
        //   navigate("/session-expired")
        // }
        if (response.error) {
          console.log("error in dispatcj SSG", response)
          setPage(1);
          setTotalPages(1);
          setTotalItems(0);
        } else {
          const totalItems = response.payload.totalItems || 0;
          const totalPages =
            response.payload.totalPages || Math.ceil(totalItems / limit);
          console.log("total items and totalpages===>", totalItems, totalPages);
          setTotalItems(totalItems);
          setTotalPages(totalPages);

          //   const transformedData = fetchDataAction === getloginlogs||getUsersList
          //   ? addSerialNumbers(response.payload.data || [])
          //   : response.payload.data || [];
          // setData(transformedData);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        setPage(1);
        setTotalPages(1);
        setTotalItems(0);
      });
  }, [
    page,
    limit,
    filterModel,
    sortModel,
    searchText,
    dispatch,
    fetchDataAction,
    JobStatus,
    startDate,
    endDate,
    selectedRegion
  ]);

  useEffect(() => {
    fetchComponentDetails();
  }, [fetchComponentDetails]);

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setPage(1); // Reset to the first page when filter changes
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
    setPage(1); // Reset to the first page when sort changes
  };

  const handlePageSizeChange = (pageSize) => {
    setLimit(pageSize);
    setPage(1);
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setPage(1); // Reset to the first page when search changes
  };

  const updatedColumns = columns.map((col) => ({
    ...col,
    headerClassName: "custom-header",
    renderHeader: CustomHeader,
    type: customColumnTypes[col.type] ? col.type : "string", // Apply custom types only if specified
  }));

  const norecordsfound = { noRowsLabel: "No Matching Records Found..!" };
  const oopsdata = {
    noRowsLabel: "Oops! seems like there's an issue from the server",
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ height: "80vh", width: "100%", backgroundColor: "white" }}>
        <DataGrid
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={limit}
          page={page - 1}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          rows={data || []}
          rowCount={totalItems}
          columnTypes={customColumnTypes}
          paginationMode="server"
          getRowId={getRowId ? getRowId : null}
          columns={updatedColumns}
          pagination
          loading={loading}
          onFilterModelChange={handleFilterModelChange}
          onSortModelChange={handleSortModelChange}
          disableDensitySelector
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              onSearchChange: handleSearchChange,
              searchText: searchText,
              onAddEmployeeClick: handleAdd,
              buttonText: `${addButtonName}`,
              showAddButton: showAddButton,
            },
          }}
          isCellEditable={(params) =>
            params.field === "name" && editingRowId === params.row.id
          }
          onCellEditCommit={handleCellEditChange}
          sx={{
            '& .MuiDataGrid-cell': {
              textAlign: 'center',  // Center-align row data
              display: 'flex',      // Align text center inside the cell
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
          localeText={
            loading === false && !data.length ? norecordsfound : oopsdata
          }
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default ServerSideDataGrid;
