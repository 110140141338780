import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import Divider from "@mui/material/Divider";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Loader from "../../Loader/Loader";
import "./index.css";

const EmployeeCard = ({ data, filterStart }) => {
  const userRole =  sessionStorage.getItem("role").split(",");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Data = useSelector((state) => state?.Data);
  const loadingData = useSelector((state) => state?.Data);

  useEffect(() => {
    //dispatch(fetchData());
  }, [dispatch]);

  return (
    <Loader
      isLoading={loadingData?.loading === true}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div className="employee-card-container">
        {data?.length > 0 &&
          data.map((each) => {
            return (
              <div className="card_grid">
                <button
                  onClick={() =>
                    userRole.some((role) => ["ADMIN", "HR"].includes(role))
                      ? navigate(`/employee_details/${each._id}`)
                      : navigate("/noaccess")
                  }
                  className="navLink"
                >
                  <div className="card-client">
                    <div className="ActiveButtonCard">
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          color: "#32CD32",
                          border: "1.5px solid #32CD32",
                          height: "21px",
                          fontWeight: 500,
                          fontSize: "13px",
                          backgroundColor: "#dcfadc",
                          textTransform: "capitalize",
                        }}
                      >
                        Active
                      </Button>
                      <MoreHorizIcon
                        sx={{
                          display: "none",
                          marginLeft: "10px",
                          color: "black",
                        }}
                      />
                    </div>

                    <div className="user-picture">
                      <img src={each?.Photo} alt="userImage" />
                    </div>

                    <p className="name-client">
                      {each?.FirstName}
                      <span className="employee-Role">{each?.Role?.value}</span>
                    </p>

                    <div className="card">
                      <div className="employeeDetails">
                        <div className="employee-department-card">
                          <h3>Department</h3>
                          <p>{each?.Department?.value}</p>
                        </div>

                        <div className="employee-date-hired-card">
                          <h3>Date Hired</h3>
                          <p>{each?.Details?.Advance?.DOJ.slice(0, 10)}</p>
                        </div>
                      </div>
                      <Divider sx={{ marginTop: "-15px" }} />

                      <div className="employeelinks">
                        <div className="employeelinkcard">
                          <MarkunreadIcon sx={{ color: "black" }} />
                          <h6>{each?.Details?.Advance?.CompanyEmail}</h6>
                        </div>

                        <div className="employeelinkcard">
                          <AddIcCallIcon sx={{ color: "black" }} />
                          <h6>+91 {each?.Details?.Advance?.ContactNumber}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            );
          })}

        {data.length === 0 && filterStart && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
              backgroundColor: "#f0f0f0",
            }}
          >
            <img
              src="https://res.cloudinary.com/dcci6hybb/image/upload/v1677679606/no_data_found_r6ayov.webp"
              alt="no data found"
              className="nodata-img"
            />
          </Box>
        )}

        {!filterStart &&
          Data?.Resources?.Employee_Details?.map((each) => {
            return (
              <div className="card_grid">
                <button
                  onClick={() =>
                    userRole.some((role) => ["ADMIN", "HR"].includes(role))
                      ? navigate(`/employee_details/${each._id}`)
                      : null
                  }
                  className="navLink"
                >
                  <div className="card-client">
                    <div className="ActiveButtonCard">
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          color: "#32CD32",
                          border: "1.5px solid #32CD32",
                          height: "21px",
                          fontWeight: 500,
                          fontSize: "13px",
                          backgroundColor: "#dcfadc",
                          textTransform: "capitalize",
                          "@media (min-width: 2560px)": {
                            height: "60px",
                            width: "100px",
                            fontSize: "20px",
                          },
                        }}
                      >
                        Active
                      </Button>
                      <MoreHorizIcon
                        sx={{
                          display: "none",
                          marginLeft: "10px",
                          color: "black",
                        }}
                      />
                    </div>

                    <div className="user-picture">
                      <img src={each?.Photo} alt="userImage" />
                    </div>

                    <p className="name-client">
                      {each?.FirstName}
                      <span className="employee-Role">{each?.Role?.value}</span>
                    </p>

                    <div className="card">
                      <div className="employeeDetails">
                        <div className="employee-department-card">
                          <h3>Department</h3>
                          <p>{each?.Department?.value}</p>
                        </div>

                        <div className="employee-date-hired-card">
                          <h3>Date Hired</h3>
                          <p>
                            {new Date(
                              each?.Details?.Advance?.DOJ
                            ).toLocaleDateString("en-GB")}
                          </p>
                        </div>
                      </div>
                      <Divider sx={{ marginTop: "-15px" }} />

                      <div className="employeelinks">
                        <div className="employeelinkcard long-name">
                          <MarkunreadIcon sx={{ color: "black" }} />
                          <h6>{each?.Details?.Advance?.CompanyEmail}</h6>
                        </div>

                        <div className="employeelinkcard">
                          <AddIcCallIcon sx={{ color: "black" }} />
                          <h6>+91 {each?.Details?.Advance?.ContactNumber}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            );
          })}
      </div>
    </Loader>
  );
};

export default EmployeeCard;
