import React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getAssignProjectData,
  updateAssignProject,
} from "../../../../../redux/dataSlice";

import "./index.css";

const UpdateAssignProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    //dispatch(fetchData());
    dispatch(getAssignProjectData(id))
      .then((response) => {
        // if (response?.data?.statusCode === 401) {
        //   navigate("/session-expired")
        // }
        setFormData(response?.payload);
      })
      .catch((error) => {
        // handle error
      });
  }, [id, dispatch]);

  const StatusData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.map((e) => e.Status)
  );

  const projectsData = useSelector(
    (state) => state.Data.Resources.Project_Details
  );
  const EmployeeData = useSelector(
    (state) => state.Data.Resources.Employee_Details
  );

  const projectTitles = projectsData.map((each) => ({
    projectTitle: each.projectTitle,
  }));
  const projectData = {
    projectTitles: projectTitles.map((project) => ({
      name: project.projectTitle,
      value: project.projectTitle,
    })),
  };

  const employeeNames = EmployeeData.map((e) => ({
    employeeName: e.FirstName,
  }));
  const employeeData = {
    employeeNames: employeeNames.map((employee) => ({
      name: employee.employeeName,
      value: employee.employeeName,
    })),
  };

  const [formData, setFormData] = useState({
    //employeeName: "",
    projectTitle: "",
    //projectRole: "",
    startDate: "",
    endDate: "",
    projectStatus: "Not-Started",
    projectLead: "",
    qaLead: "",
    projectTeam: [],
  });

  const notifyError = (errorMessage) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (fieldName, dateValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: dateValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await dispatch(updateAssignProject(formData));
      // .then(response => {
      //   if (response?.data?.statusCode === 401) {
      //     navigate("/session-expired")
      //   }
      // });
      if (updateAssignProject.fulfilled.match(response)) {
        toast.success("Assigned Project Updated successfully...!", {
          onClose: () => {
            setTimeout(() => {
              navigate("/assignProject",{
                state: { headerText: 'Projects' }  // State must be an object
              });
              window.location.reload();
            }, 1000);
          },
        });
      } else {
        // Handle rejected response from server
        notifyError(response.payload);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="projectassign-form-container">
      <div className="TitleHeader">
        <h3>Update Assign Details</h3>
        <div className="close-container">
          <Link to="/assignProject">
            <AiOutlineClose className="back-option" />
          </Link>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="project-form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="projectTitle">
                Project Title <span className="required-star">*</span>
              </label>
              <br />
              <Select
                //multiple
                className="projectAssign_input-feald"
                name="projectTitle"
                value={formData.projectTitle}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select Project Title</option>
                {projectData.projectTitles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="projectLead">
                Project Lead <span className="required-star">*</span>
              </label>
              <br />
              <Select
                //multiple
                className="projectAssign_input-feald"
                name="projectLead"
                value={formData.projectLead}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select Project Lead</option>
                {employeeData.employeeNames.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="qaLead">
                QA Lead <span className="required-star">*</span>
              </label>
              <br />
              <Select
                //multiple
                className="projectAssign_input-feald"
                name="qaLead"
                value={formData.qaLead}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select QA Lead</option>
                {employeeData.employeeNames.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <div>
                <label htmlFor="projectTeam">
                  Project Team <span className="required-star">*</span>
                </label>{" "}
                <br />
                <Select
                  multiple
                  className="projectSelect_input"
                  name="projectTeam"
                  value={formData.projectTeam}
                  onChange={handleSelectChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  required
                >
                  {employeeData.employeeNames.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={
                          formData.projectTeam.indexOf(option.value) > -1
                        }
                      />
                      <ListItemText primary={option.value} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="project-name-label-card">
              <label htmlFor="startDate">
                Start Date <span className="required-star">*</span>
              </label>
              <TextField
                className="project_input-feald"
                id="date"
                type="date"
                required
                sx={{
                  width: "100%",
                  maxWidth: 505,
                  backgroundColor: "#F0F0F0",
                  "@media (max-width: 600px)": {
                    maxWidth: 400,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    maxWidth: 250,
                  },
                  "@media (min-width: 961px) and (max-width: 2560px)": {
                    maxWidth: "100vw",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.startDate.slice(0, 10)}
                onChange={(e) => handleDateChange("startDate", e.target.value)}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="project-name-label-card">
              <label htmlFor="endDate">
                End Date <span className="required-star">*</span>
              </label>
              <TextField
                className="project_input-feald"
                id="date"
                type="date"
                required
                sx={{
                  width: "100%",
                  maxWidth: 505,
                  backgroundColor: "#F0F0F0",
                  "@media (max-width: 600px)": {
                    maxWidth: 400,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    maxWidth: 250,
                  },
                  "@media (min-width: 961px) and (max-width: 2560px)": {
                    maxWidth: "100vw",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.endDate.slice(0, 10)}
                onChange={(e) => handleDateChange("endDate", e.target.value)}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="project-name-label-card">
              <label htmlFor="projectEndDate" className="Project_status">
                Project Status <span className="required-star">*</span>
              </label>
              <div className="select-container">
                {StatusData.length > 0 &&
                  StatusData.map((option) =>
                    option.map((each) => (
                      <div key={each.value}>
                        <input
                          type="radio"
                          id={each.value}
                          name="projectStatus"
                          value={each.value}
                          checked={formData.projectStatus === each.value}
                          onChange={handleChange}
                          className="radio-input"
                        />
                        <label htmlFor={each.value}>{each.name}</label>
                      </div>
                    ))
                  )}
              </div>
            </div>
          </Grid>
        </Grid>

        <button type="submit" className="project-submit-btn">
          Submit
        </button>
      </form>

      <ToastContainer />
    </div>
  );
};

export default UpdateAssignProject;

// <Grid item xs={12} sm={6} md={6} lg={6}>
// <div className="project-name-label-card">
//   <label htmlFor="employeeName">
//     Employee Name <span className="required-star">*</span>{" "}
//   </label>
//   <br />
//   <input
//     type="text"
//     name="employeeName"
//     className="projectAssign_input-feald"
//     value={formData.employeeName}
//     onChange={handleChange}
//     required
//   />
// </div>
// </Grid>

// <Grid item xs={12} sm={6} md={6} lg={6}>
// <div className="project-name-label-card">
//   <label htmlFor="projectRole">
//     Project Role <span className="required-star">*</span>
//   </label>
//   <br />
//   <Select
//     //multiple
//     className="projectAssign_input-feald"
//     name="projectRole"
//     value={formData.projectRole}
//     onChange={handleSelectChange}
//     required
//   >
//     <option value="">Select Project Role</option>
//     {RoleData.length > 0 &&
//       RoleData.map((option) =>
//         option.map((each) => (
//           <MenuItem key={each.value} value={each.value}>
//             {each.name}
//           </MenuItem>
//         ))
//       )}
//   </Select>
// </div>
// </Grid>
