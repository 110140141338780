import ProjectForm from '../ProjectForm'

const AddProject = () => {


  return (
    <>
    <ProjectForm {...{editMode:false}} />
    </>
  );
};

export default AddProject;
