
export function pickFields(obj, fields) {
    return fields.reduce((result, field) => {
      if (obj.hasOwnProperty(field)) {
        result[field] = obj[field];
      }
      return result;
    }, {});
  }

  export const objectToFormData = (obj, form = new FormData(), namespace = '') => {
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        const formKey = namespace ? `${namespace}[${property}]` : property;
  
        if (obj[property] instanceof Array) {
          obj[property].forEach((element, index) => {
            if (typeof element === 'object' && !(element instanceof File)) {
              objectToFormData(element, form, `${formKey}[${index}]`);
            } else {
              form.append(`${formKey}[${index}]`, element);
            }
          });
        } else if (obj[property] instanceof Date) {
          form.append(formKey, obj[property].toISOString());
        } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          objectToFormData(obj[property], form, formKey);
        } else {
          form.append(formKey, obj[property]);
        }
      }
    }
    return form;
  };

  export const _objectToFormData = (obj, form = new FormData(), namespace = '') => {
    for (let property in obj) {
      if (obj.hasOwnProperty(property) && obj[property] !== '' && obj[property] !== null && obj[property] !== undefined) {
        const formKey = namespace ? `${namespace}[${property}]` : property;
  
        if (obj[property] instanceof Array) {
          obj[property].forEach((element, index) => {
            if (typeof element === 'object' && !(element instanceof File)) {
              objectToFormData(element, form, `${formKey}[${index}]`);
            } else {
              form.append(`${formKey}[${index}]`, element);
            }
          });
        } else if (obj[property] instanceof Date) {
          form.append(formKey, obj[property].toISOString());
        } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          objectToFormData(obj[property], form, formKey);
        } else {
          form.append(formKey, obj[property]);
        }
      }
    }
    return form;
  };
  
  