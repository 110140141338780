import React from 'react'
import { Link } from 'react-router-dom'
import "./index.css"
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../../utills/authConfig';

const SessionExpired = () => {
    const { instance } = useMsal();

    const handleLoginClick = async () => {
        // const access_token = sessionStorage.getItem('access_token');
        // const jwt_token = sessionStorage.getItem('jwt_token');
        // const decoded = jwtDecode(access_token);

        // const logoutPayload = {
        //     action: "logout",
        //     ipAddress: decoded?.ipaddr,
        //     userId: decoded?.oid,
        // };

        // const headerWithToken = {
        //     headers: {
        //         Authorization: `Bearer ${jwt_token}`,
        //     },
        // };

        // try {
        //     await axios.post(apiURL + 'authaction', logoutPayload, headerWithToken);
        // } catch (error) {
        //     console.error("Failed to log out:", error);
        // }

        sessionStorage.clear();

        // Explicitly setting logout redirection target
        instance
            .loginRedirect({
                ...loginRequest,
                prompt: 'select_account',
            })
            .catch((error) => console.log('Login Error', error));

    }
    return (
        <div className='session-expired-container'>
            You have been logged out,Click here to login again
            <button className='login-btn-container' onClick={handleLoginClick}>Login</button>
        </div>
    )
}

export default SessionExpired
