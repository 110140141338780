import React from "react";

const ApplicationsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="24" fill="url(#pattern0_3_22)" />
      <defs>
        <pattern
          id="pattern0_3_22"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_3_22" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_3_22"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAACw0lEQVR4nO2dzWoUQRRGexVXiRj8i6+QuPEZxJVBwuAjCD6APkaCSlTcic/gUl27cBeF4DLZiaImUUQiRwproWHomZ4p79c/34HeDDNd997TdHVPX7qqyhhjjOkAwCXgLvAc2AO+5W0vf3YHWFHH2TuAFeAZ8JPJpO88BS6q4+4FwA3gC835DKyr4+80wG3gF7OTfntLnUcnAdaBY+Yn7eO6Op9OAZwFPlCOj8B5dV6dAXhCeR6r8+oEwDng+38Q8AO4UPUN4DJwH3gLHBHDHjACFvO2AewGjX2Uc70HrCkLfwp4NOdVyqzFXx4TzzKwTywp94fAgqL4r9Awqonrpiiml6ES0oSGjsWauJaEcW1HnvOjTzvTCjiNjnS/sRohIE0+SjZqYksTs5KtCAHvxEnu1kzC78Wx7UQIOEDPfp5wl/I2akHxEwcRAkwNFiDGAsRYgBgLEGMBYixAjAWIsYAyvAGuAp+a/tACyhT/TL7pvNJUggUUKv5fd/6NJFhAweLPIsECChe/qQQLaM7r9CBnyj8ir03amQUUPvJPPG1LsmqxAGHxWylAHU81//6nLr4FjCGy+POON21QjVDHUwUW3wLGEFn8WcdrGlyrAqLw+PMU3wLGEFn8puPNRNsCotD4JYofkW8vBVCo+BH5ui9oAhYgxgLEWIAYCxBjAWIsQIwFiLGAMrgvSIj7goS4L0iI+4KEuC9IiPuChLgvSIj7gkowqcoF9u++IJUA9wUJBbgvSCjAfUFCAe4LEgpwX5BQgPuChALcF9QjKvURN3QqC9BiAWIsQIwFiLEAMRYwAAGH6iRbzNchvLq4zewM4eXdbWYzQsBaoeWl+kbM6+uzhLRsh/mXByHFzwIW8rId5g8vFOvIJAnbAz8dHacjP7z4J0SsppUj0hXAQC5RD3OuW2HnfGOMMcYYY4wxxhhT9YHf80UiL4+FlXMAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};
export default ApplicationsIcon;
