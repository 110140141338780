import JobApplicationForm from '../JobApplicationForm';

const UpdateApplication = () => {


  return (
    <>
    <JobApplicationForm {...{editMode:true}} />
    </>
  );
};

export default UpdateApplication;