import { useState } from "react";
import { Button } from "@mui/material";
import * as React from "react";
import {
  deleteUser,
  getUserData,
  updateUser,
} from "../../../../redux/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { MenuItem, Select } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "./usergrid.css";
import CustomDataGrid from "../../../utilites/CustomDataGrid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function UserGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const Data = useSelector((state) => state.Data.Resources.Users_Details);
  const userRole =  sessionStorage.getItem("role").split(",");
  const UserTypeData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.map((e) => e.UserType)
  );

  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    empId: "",
    role: [],
    password: "",
    confirmPassword: "",
  });

  const togglePassword = () => {
    setViewPassword(!viewPassword);
  };

  const toggleConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 120, type: "string" },
    {
      field: "role",
      headerName: "USER TYPE",
      width: 200,
      sortable: true,
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 200,
      renderCell: (params) => {
        return <h5 style={{ color: "green" }}>Active</h5>;
      },
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      type: "date",
      width: 200,
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, "-")
          : "",
    },
    {
      field: "updatedAt",
      headerName: "UPDATED AT",
      type: "date",
      width: 200,
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, "-")
          : "",
    },
    {
      field: "edit",
      headerName: "EDIT",
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => handleEditId(params.row._id)}
            sx={{ border: "1px solid blue", height: "28px", width: "30px" }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "DELETE",
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            color="error"
            onClick={() => handleDeleteId(params.row._id)}
            sx={{ border: "1px solid red", height: "28px", width: "30px" }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const handleDeleteId = (id) => {
    if (userRole.some((role) => ["ADMIN"].includes(role))) {
      dispatch(deleteUser(id))
        .then((response) => {
          toast.success("User Deleted successfully...!", {
            onClose: () => {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            },
          });
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
        });
    } else {
      navigate("/noaccess");
    }
  };

  const notifySuccess = () => toast.success("Updated Successfully...!");
  const notifyError = (errorMessage) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const handleEditId = (id) => {
    setOpen(true);
    dispatch(getUserData(id))
      .unwrap() // extracts the fulfilled value from the promise
      .then((data) => {
        setFormData(data);
      })
      .catch((error) => {
        notifyError();
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Password validation criteria
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$%^&*])[a-zA-Z\d@!#$%^&*]{8,}$/;

      // Explanation: Password must contain at least 8 characters, including one lowercase letter, one uppercase letter, one special character, and one digit.

      // Password validation check
      if (!formData.password.match(passwordRegex)) {
        toast.error(
          "Password must contain at least 8 characters, including one lowercase letter, one uppercase letter, one special character, and one digit."
        );
        return;
      }

      if (formData.password !== formData.confirmPassword) {
        toast.error("Passwords are not match.");
        return;
      }

      if (formData.password.length < 8) {
        toast.error("Password must be at least 8 characters long.");
        return;
      }

      const response = await dispatch(updateUser(formData));

      if (updateUser.fulfilled.match(response)) {
        notifySuccess();
        setTimeout(() => {
          navigate("/users");
          window.location.reload();
        }, 2000);
      } else {
        // Handle rejected response from server
        notifyError(response.payload);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const rows = [];
  Data.map((each) => {
    const newUser = {
      _id: each._id,
      id: each.empId,
      role: each.role,
      createdAt: each.createdAt,
      updatedAt: each.updatedAt,
    };
    rows.push(newUser);
    return newUser; // Add a return statement here
  });

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <CustomDataGrid Data={Data} rows={rows} columns={columns} />

      <BootstrapDialog
        sx={{ margin: "20px" }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className="user-dialogHeader">
          <h4>Update User</h4>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </div>

        <DialogContent dividers sx={{ marginTop: "-35px" }}>
          <form onSubmit={handleSubmit} className="user-form">
            <div className="user-input-container">
              <label htmlFor="empId" className="user-text">
                User Id
              </label>
              <div className="user-id-container">
                <input
                  type="text"
                  id="empId"
                  className="user-input-feild uppercase"
                  placeholder="User Id"
                  name="empId"
                  autoComplete="off"
                  value={formData.empId}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="user-password-input-container">
              <label htmlFor="password">
                New Password <span className="required-star">*</span>
              </label>
              <div className="user-password-container">
                <input
                  type={viewPassword ? "text" : "password"}
                  id="password"
                  className="user-password-input-feild"
                  placeholder="password"
                  name="password"
                  onChange={handleChange}
                  required
                />
                {/* Render eye icon based on showPassword state */}
                {viewPassword ? (
                  <FaEye
                    className="user-passwordView"
                    onClick={togglePassword}
                  />
                ) : (
                  <FaEyeSlash
                    className="user-passwordView"
                    onClick={togglePassword}
                  />
                )}
              </div>
            </div>

            <div className="user-password-input-container">
              <label htmlFor="confirm_password">
                Confirm Password <span className="required-star">*</span>
              </label>

              <div className="user-password-container">
                <input
                  type={viewConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  className="user-password-input-feild"
                  placeholder="confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                {/* Render eye icon based on showPassword state */}
                {viewConfirmPassword ? (
                  <FaEye
                    className="user-passwordView"
                    onClick={toggleConfirmPassword}
                  />
                ) : (
                  <FaEyeSlash
                    className="user-passwordView"
                    onClick={toggleConfirmPassword}
                  />
                )}
              </div>
            </div>

            <div className="user-input-container">
              <label htmlFor="userType" className="user-text">
                User Type
              </label>
              <div className="user-type-container">
                <Select
                  multiple
                  className="user-type-feild"
                  name="role"
                  value={formData.role}
                  onChange={handleSelectChange}
                >
                  {UserTypeData.length > 0 &&
                    UserTypeData.map((option) =>
                      option.map((each) => (
                        <MenuItem key={each.value} value={each.value}>
                          {each.name}
                        </MenuItem>
                      ))
                    )}
                </Select>
              </div>
            </div>

            <button className="user-submit" type="submit">
              Submit
            </button>
          </form>
        </DialogContent>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  );
}
