import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  Chip,
  Button,
  Rating,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getActiveJobs } from "../../../../redux/dataSlice";
import { fetchData } from "../../../../redux/dataSlice";
import {
  getAllEmployeeData,
  getAllEmployeeByRegion,
} from "../../../../redux/dataSlice";
import "./jobApplication.css";
import { useNavigate } from "react-router-dom";

const PersonalDetailsForm = ({ onSubmit, initialValues }) => {
  const [panelError, setPanelError] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
  });
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState("panel1");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    jobId: "",
    reference: "",
    mobileNumber: "",
    resume: null,
    experienceYear: "",
    experienceMonth: "",
    linkedin: "",
    skills: [],
    noticePeriod: null,
    currentCTC: "",
    expectedCTC: "",
    highestQualification: "",
    yearOfPassing: "",
    willingToRelocate: "",
    initial: {
      date: "",
      takenBy: "",
      feedback: "",
      status: "",
      followUpDate: "",
      followUpReason: "",
      commSkillsRating: 0,
      educationAndSkillsRating: 0,
      overAllRating: 0,
    },
  });
  const [errors, setErrors] = useState({});
  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  console.log(selectedRegion?._id, "regions2");
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchData());
    if (selectedRegion && selectedRegion?._id) {
      dispatch(getAllEmployeeByRegion({ id: selectedRegion._id }));
      dispatch(getActiveJobs(selectedRegion._id));
    }
  }, [dispatch, selectedRegion]);

  useEffect(() => {
    if (initialValues) {
      setFormData({
        firstName: initialValues?.firstName || "",
        lastName: initialValues?.lastName || "",
        email: initialValues?.email || "",
        address: initialValues?.address || "",
        jobId:
          typeof initialValues?.jobId === "object"
            ? initialValues?.jobId._id
            : initialValues?.jobId || "",
        reference: initialValues?.reference || "",
        mobileNumber: initialValues?.mobileNumber || "",
        resume: null,
        experienceYear: initialValues?.experienceYear || "",
        experienceMonth: initialValues?.experienceMonth || "",
        linkedin: initialValues?.linkedin || "",
        skills: initialValues?.skills || [],
        noticePeriod: initialValues?.noticePeriod || null,
        currentCTC: initialValues?.currentCTC || "",
        expectedCTC: initialValues?.expectedCTC || "",
        highestQualification: initialValues?.highestQualification || "",
        yearOfPassing: initialValues?.yearOfPassing || "",
        willingToRelocate: initialValues?.willingToRelocate?.toString() || "",
        initial: {
          ...initialValues.initial,
          date: initialValues?.initial?.date
            ? new Date(initialValues.initial.date).toISOString().split("T")[0]
            : "",
          takenBy:
            typeof initialValues?.initial?.takenBy === "object"
              ? initialValues.initial.takenBy._id
              : initialValues?.initial?.takenBy || "",
          feedback: initialValues?.initial?.feedback || "",
          status: initialValues?.initial?.status || "",
          followUpDate: initialValues?.initial?.followUpDate
            ? new Date(initialValues.initial.followUpDate)
                .toISOString()
                .split("T")[0]
            : "",
          followUpReason: initialValues?.initial?.followUpReason || "",
          commSkillsRating: initialValues?.initial?.commSkillsRating || 0,
          educationAndSkillsRating:
            initialValues?.initial?.educationAndSkillsRating || 0,
          overAllRating: initialValues?.initial?.overAllRating || 0,
        },
      });
    }
  }, [initialValues]);

  function downloadFile(url, fileName) {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  const GetResumePreview = () => {
    if (initialValues?.resume?.creationAttachment?.public_id) {
      return (
        <div
          onClick={() => {
            downloadFile(
              initialValues?.resume?.creationAttachment?.url,
              initialValues?.resume?.creationAttachment?.public_id
            );
          }}
          className="preview-image-container"
        >
          <ArticleIcon
            style={{ fontSize: "3rem", color: "skyblue" }}
            className="preview-image"
          />
          <span>{initialValues?.resume?.creationAttachment?.public_id}</span>
        </div>
      );
    } else if (initialValues?.resume?.name) {
      return (
        <div className="preview-image-container">
          <ArticleIcon
            style={{ fontSize: "3rem", color: "skyblue" }}
            className="preview-image"
          />
          <span>{initialValues?.resume?.name}</span>
        </div>
      );
    } else return <></>;
  };
  const activeJobsData = useSelector(
    (state) => state?.Data?.Resources?.application?.activeJobs
  );
  console.log(activeJobsData, "activeJobsData");

  const SkillsData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.flatMap((e) => e?.Skills)
  );
  const WillingToRelocateData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.flatMap((e) => e.WillingToRelocate)
  );

  const employees = useSelector(
    (state) => state?.Data?.Resources?.employee?.data
  );

  const JobApplicationStatusData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.flatMap(
      (e) => e.JobApplicationStatus
    )
  );

  const validate = () => {
    const newErrors = {};

    if (!formData.jobId) newErrors.jobId = "Job ID is required";
    if (!formData.firstName.trim())
      newErrors.firstName = "First Name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last Name is required";
    if (!formData.mobileNumber.trim())
      newErrors.mobileNumber = "Mobile Number is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!formData.address.trim()) newErrors.address = "Address is required";
    if (!formData.experienceYear.trim())
      newErrors.experienceYear = "Experience in Years is required";
    if (!formData.experienceMonth.trim())
      newErrors.experienceMonth = "Experience in Months is required";
    if (!formData.skills.length)
      newErrors.skills = "At least one Technology is required";
    if (!formData.noticePeriod)
      newErrors.noticePeriod = "Notice Period is required";
    if (!formData.currentCTC.trim())
      newErrors.currentCTC = "Current CTC is required";
    if (!formData.expectedCTC.trim())
      newErrors.expectedCTC = "Expected CTC is required";
    if (!formData.highestQualification.trim())
      newErrors.highestQualification = "Qualification is required";
    if (!formData.yearOfPassing.trim())
      newErrors.yearOfPassing = "Year of Passing is required";
    if (formData.willingToRelocate === "") {
      newErrors.willingToRelocate = "Willing to relocate is required";
    }
    if (!formData.initial.date.trim())
      newErrors.screeningDate = "Screening Date is required";
    if (!formData.initial.takenBy)
      newErrors.screeningBy = "Screening By is required";
    if (!formData.initial.feedback.trim())
      newErrors.feedback = "Feedback is required";
    if (!formData.initial.status.trim())
      newErrors.status = "Status is required";
    if (!formData.initial.commSkillsRating)
      newErrors.communicationSkillsRating =
        "Communication Skills Rating is required";
    if (!formData.initial.educationAndSkillsRating)
      newErrors.educationQualificationRating =
        "Education and Qualification Rating is required";
    if (!formData.initial.overAllRating)
      newErrors.overallRating = "Overall Rating is required";
    if (formData.mobileNumber.startsWith("1")) {
      if (formData.mobileNumber.length !== 11) {
        newErrors.mobileNumber = "Please provide a valid US mobile number";
      }
    } else if (formData.mobileNumber.startsWith("91")) {
      if (formData.mobileNumber.length !== 12) {
        newErrors.mobileNumber = "Please provide a valid Indian mobile number";
      }
    } else {
      newErrors.mobileNumber = "Please provide a valid mobile number";
    }
    setPanelError({
      panel1:
        !!newErrors.jobId ||
        !!newErrors.firstName ||
        !!newErrors.lastName ||
        !!newErrors.mobileNumber ||
        !!newErrors.email ||
        !!newErrors.address,
      panel2:
        !!newErrors.experienceYear ||
        !!newErrors.experienceMonth ||
        !!newErrors.skills ||
        !!newErrors.noticePeriod ||
        !!newErrors.currentCTC ||
        !!newErrors.expectedCTC,
      panel3:
        !!newErrors.highestQualification ||
        !!newErrors.yearOfPassing ||
        !!newErrors.willingToRelocate,
      panel4:
        !!newErrors.screeningDate ||
        !!newErrors.screeningBy ||
        !!newErrors.feedback ||
        !!newErrors.status ||
        !!newErrors.communicationSkillsRating ||
        !!newErrors.educationQualificationRating ||
        !!newErrors.overallRating,
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange =
    (field, key = "") =>
    (eventOrValue) => {
      const value =
        field === "resume"
          ? eventOrValue.target.files[0]
          : field === "mobileNumber"
          ? eventOrValue
          : eventOrValue.target.value;
      if (key) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [key]: {
            ...prevFormData[key],
            [field]: value,
          },
        }));
      } else {
        setFormData({
          ...formData,
          [field]: value,
        });
      }
    };

  const handleSkillChange = (event, value, name) => {
    setFormData({
      ...formData,
      [name]: value.map((option) => option.value),
    });
  };

  const handleRatingChange = (field, key) => (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        [field]: newValue,
      },
    }));
  };
  function convertStringToBoolean(str) {
    return str.toLowerCase() === true;
  }

  const handleSubmit = (action) => {
    const payload = JSON.parse(JSON.stringify(formData));
    payload.noticePeriod = Number(payload?.noticePeriod || null);
    payload.Resume = formData.Resume;
    payload.willingToRelocate = convertStringToBoolean(
      payload.willingToRelocate
    );
    if (validate()) {
      onSubmit(payload, action);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fileUpload = (e) => {
    if (e.target.name == "resume") {
      const file = e.target.files[0];
      const fileSizeLimit = 1 * 1024 * 1024; // 500 KB in bytes

      if (file && file.size > fileSizeLimit) {
        setFormData({ ...formData, Resume: null });
      } else {
        setFormData({ ...formData, Resume: file });
      }
    }
  };
  const handleBlur = (name) => (e) => {
    const { value } = e.target;
    let error = "";

    switch (name) {
      case "jobId":
        console.log("jobid==>", formData.jobId);
        if (!formData.jobId) {
          error = "Job ID is required";
        }
        break;
      case "firstName":
        if (!value.trim()) {
          error = "First Name is required";
        }
        break;
      case "lastName":
        if (!value.trim()) {
          error = "Last Name is required";
        }
        break;
      case "email":
        if (!value.trim()) {
          error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Please provide a valid email";
        }
        break;
      case "mobileNumber":
        if (!value.trim()) {
          error = "Mobile Number is required";
        } else {
          // Remove any non-digit characters
          const cleanedValue = value.replace(/\D/g, "");

          // Check if the cleaned value is a valid number (digits only)
          if (!/^\d+$/.test(cleanedValue)) {
            error = "Please provide a valid mobile number";
          } else {
            // Optionally, add more conditions here if you need to validate specific length
            if (cleanedValue.length < 10 || cleanedValue.length > 15) {
              error = "Mobile number must be between 10 and 15 digits";
            }
          }
        }
        break;

      case "address":
        if (!value.trim()) {
          error = "Address is required";
        }
        break;
      case "experienceYear":
        if (!value.trim()) {
          error = "Experience in Years is required";
        }
        break;
      case "experienceMonth":
        if (!value.trim()) {
          error = "Experience in Months is required";
        }
        break;
      case "skills":
        if (!formData.skills.length) {
          console.log("blur is triggered in skills", value);
          console.log(value, "value is empty");
          console.log("blur is triggered in skills");
          error = "At least one Technology is required";
        }
        break;
      case "noticePeriod":
        if (!value.trim()) {
          error = "Notice Period is required";
        }
        break;
      case "currentCTC":
        if (!value.trim()) {
          error = "Current CTC is required";
        }
        break;
      case "expectedCTC":
        if (!value.trim()) {
          error = "Expected CTC is required";
        }
        break;
      case "highestQualification":
        if (!value.trim()) {
          error = "Qualification is required";
        }
        break;
      case "yearOfPassing":
        if (!value.trim()) {
          error = "Year of Passing is required";
        }
        break;
      case "willingToRelocate":
        if (value === "") {
          error = "Willing to relocate is required";
        }
        break;
      case "date":
        if (!value.trim()) {
          error = "Screening Date is required";
        }
        break;
      case "screeningBy":
        if (!formData.initial.takenBy) {
          error = "Screening By is required";
        }
        break;
      case "feedback":
        if (!value.trim()) {
          error = "Feedback is required";
        }
        break;
      case "status":
        if (!formData.initial.takenBy) {
          error = "Status is required";
        }
        break;
      case "communicationSkillsRating":
        if (!value || value < 1) {
          error = "Communication Skills Rating is required";
        }
        break;
      case "educationQualificationRating":
        if (!value.trim()) {
          error = "Education and Qualification Rating is required";
        }
        break;
      case "overallRating":
        if (!value.trim()) {
          error = "Overall Rating is required";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    console.log("errors==>", errors);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1" || panelError.panel1}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            style={{ padding: "16px", fontSize: "18px", fontWeight: "600" }}
          >
            Personal Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label htmlFor="jobId">
                Job ID <span className="required-star">*</span>{" "}
              </label>
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors.jobId}
                size="small"
              >
                <Select
                  value={formData.jobId}
                  onChange={handleChange("jobId")}
                  onBlur={handleBlur("jobId")}
                  // onClose={handleBlur("jobId")}
                  displayEmpty
                >
                  {activeJobsData?.map((job) => (
                    <MenuItem key={job._id} value={job._id}>
                      {job.JobID}
                    </MenuItem>
                  ))}
                </Select>
                {errors.jobId && (
                  <span className="required-field-text">{errors.jobId}</span>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="resume">
                Resume (PDF) <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="resume"
                variant="outlined"
                required
                type="file"
                // inputProps={{ accept: "application/pdf" }}
                inputProps={{ accept: ".pdf, .doc, .docx" }}
                onChange={fileUpload}
                value={formData.resume}
                error={!!errors.resume}
                helperText={errors.resume}
                size="small"
              />
              <GetResumePreview />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="firstName">
                First Name <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="firstName"
                variant="outlined"
                required
                value={formData.firstName}
                onChange={handleChange("firstName")}
                onBlur={handleBlur("firstName")}
                error={!!errors.firstName}
                helperText={errors.firstName}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="lastName">
                Last Name <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="lastName"
                variant="outlined"
                required
                value={formData.lastName}
                onChange={handleChange("lastName")}
                onBlur={handleBlur("lastName")}
                error={!!errors.lastName}
                helperText={errors.lastName}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="Skills">
                Mobile Number <span className="required-star">*</span>{" "}
              </label>{" "}
              <br />
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors.mobileNumber}
                size="small"
              >
                <PhoneInput
                  country={"us"}
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange("mobileNumber")}
                  onBlur={handleBlur("mobileNumber")}
                  onlyCountries={["us", "in"]}
                  disableCountryCode={false}
                  countryCodeEditable={false}
                  placeholder="Enter mobile number"
                  masks={{ in: "(...) ...-....", us: "(...) ...-...." }}
                />
              </FormControl>
              <span className="required-field-text">{errors.mobileNumber}</span>
            </Grid>

            <Grid item xs={12} sm={6}>
              <label htmlFor="email">
                Email <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="email"
                variant="outlined"
                required
                value={formData.email}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
                error={!!errors.email}
                helperText={errors.email}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="address">
                Address <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="address"
                variant="outlined"
                required
                value={formData.address}
                onChange={handleChange("address")}
                onBlur={handleBlur("address")}
                error={!!errors.address}
                helperText={errors.address}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="reference">Reference</label>
              <TextField
                fullWidth
                name="reference"
                variant="outlined"
                required
                value={formData.reference}
                onChange={handleChange("reference")}
                onBlur={handleBlur("reference")}
                size="small"

                // error={!!errors.reference}
                // helperText={errors.reference}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2" || panelError?.panel2}
        onChange={handleAccordionChange("panel2")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            style={{ padding: "16px", fontSize: "18px", fontWeight: "600" }}
          >
            Work Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <label htmlFor="experienceYears">
                Experience (Years) <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="experienceYears"
                variant="outlined"
                required
                value={formData.experienceYear}
                onChange={handleChange("experienceYear")}
                onBlur={handleBlur("experienceYear")}
                error={!!errors.experienceYear}
                helperText={errors.experienceYear}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <label htmlFor="experienceMonths">
                Experience (Months) <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="experienceMonths"
                variant="outlined"
                required
                value={formData.experienceMonth}
                onChange={handleChange("experienceMonth")}
                onBlur={handleBlur("experienceMonth")}
                error={!!errors.experienceMonth}
                helperText={errors.experienceMonth}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <label htmlFor="currentCTC">
                Current CTC <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="currentCTC"
                variant="outlined"
                required
                value={formData.currentCTC}
                onChange={handleChange("currentCTC")}
                onBlur={handleBlur("currentCTC")}
                error={!!errors.currentCTC}
                helperText={errors.currentCTC}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <label htmlFor="expectedCTC">
                Expected CTC <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="expectedCTC"
                variant="outlined"
                required
                value={formData.expectedCTC}
                onChange={handleChange("expectedCTC")}
                onBlur={handleBlur("expectedCTC")}
                error={!!errors.expectedCTC}
                helperText={errors.expectedCTC}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="skills">
                skills <span className="required-star">*</span>{" "}
              </label>
              <Autocomplete
                multiple
                options={SkillsData.map((skill) => ({
                  label: skill.name,
                  value: skill.value,
                }))}
                getOptionLabel={(option) => option.label}
                value={formData.skills.map((skill) => ({
                  label: skill.name || skill,
                  value: skill.value || skill,
                }))}
                onChange={(event, value) => {
                  handleSkillChange(event, value, "skills");
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option.label} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={!!errors.skills}
                    helperText={errors.skills}
                    fullWidth
                  />
                )}
                size="small"
                onBlur={handleBlur("skills")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="linkedInUrl">LinkedIn URL</label>
              <TextField
                fullWidth
                name="linkedInUrl"
                variant="outlined"
                required
                value={formData.linkedin}
                onChange={handleChange("linkedin")}
                onBlur={handleBlur("linkedin")}
                size="small"

                // error={!!errors.linkedin}
                // helperText={errors.linkedin}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <label htmlFor="noticePeriod">
                Notice Period (Days) <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="noticePeriod"
                variant="outlined"
                required
                value={formData.noticePeriod}
                onChange={handleChange("noticePeriod")}
                onBlur={handleBlur("noticePeriod")}
                error={!!errors.noticePeriod}
                helperText={errors.noticePeriod}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                size="small"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3" || panelError?.panel3}
        onChange={handleAccordionChange("panel3")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            style={{ padding: "16px", fontSize: "18px", fontWeight: "600" }}
          >
            Education Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="qualification">
                Qualification <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="qualification"
                variant="outlined"
                required
                value={formData.highestQualification}
                onChange={handleChange("highestQualification")}
                onBlur={handleBlur("highestQualification")}
                error={!!errors.highestQualification}
                helperText={errors.highestQualification}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="yearOfPassing">
                Year of Passing <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="yearOfPassing"
                variant="outlined"
                required
                // type="number"
                value={formData.yearOfPassing}
                onChange={handleChange("yearOfPassing")}
                onBlur={handleBlur("yearOfPassing")}
                error={!!errors.yearOfPassing}
                helperText={errors.yearOfPassing}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="willingToRelocate">
                Willing to Relocate <span className="required-star">*</span>{" "}
              </label>
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors.willingToRelocate}
                size="small"
              >
                <Select
                  value={formData.willingToRelocate}
                  onChange={handleChange("willingToRelocate")}
                  onBlur={handleBlur("willingToRelocate")}
                >
                  {WillingToRelocateData?.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.willingToRelocate && (
                  // <Typography color="error">
                  <span className="required-field-text">
                    {errors.willingToRelocate}
                  </span>
                  // </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4" || panelError?.panel4}
        onChange={handleAccordionChange("panel4")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            style={{ padding: "16px", fontSize: "18px", fontWeight: "600" }}
          >
            Initial Screening
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningDate">
                Screening Date <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="screeningDate"
                variant="outlined"
                required
                type="date"
                value={formData.initial.date}
                onChange={handleChange("date", "initial")}
                onBlur={handleBlur("screeningDate")}
                error={!!errors.screeningDate}
                helperText={errors.screeningDate}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="screeningBy">
                Screening By <span className="required-star">*</span>{" "}
              </label>
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors.screeningBy}
                size="small"
              >
                <Select
                  value={formData.initial.takenBy}
                  onChange={handleChange("takenBy", "initial")}
                  onBlur={handleBlur("screeningBy")}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflow: "auto",
                        marginTop: 8,
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {employees?.map((emp) => (
                    <MenuItem key={emp._id} value={emp._id}>
                      {emp.FirstName}
                      {emp.LastName}
                    </MenuItem>
                  ))}
                </Select>
                {errors.screeningBy && (
                  // <Typography color="error">{errors.screeningBy}</Typography>
                  <span className="required-field-text">
                    {errors.screeningBy}
                  </span>
                )}
              </FormControl>
            </Grid>
            {/* 
            <Grid item xs={12} sm={6}>
              <label htmlFor="feedback">
                Feedback <span className="required-star">*</span>{" "}
              </label>
              <TextField
                fullWidth
                name="feedback"
                variant="outlined"
                required
                multiline
                rows={4}
                value={formData.initial.feedback}
                onChange={handleChange("feedback", "initial")}
                error={!!errors.feedback}
                helperText={errors.feedback}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="status">
                Status <span className="required-star">*</span>{" "}
              </label>
              <FormControl
                fullWidth
                variant="outlined"
                required
                error={!!errors.status}
                size="small"
              >
                <Select
                  value={formData.initial.status}
                  onChange={handleChange("status", "initial")}
                  displayEmpty
                >
                  {JobApplicationStatusData?.map((status) => (
                    <MenuItem key={status.name} value={status.value}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.status && (
                  <Typography color="error">{errors.status}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="followUpDate">Follow-Up Date</label>
              <TextField
                fullWidth
                name="followUpDate"
                variant="outlined"
                required
                type="date"
                value={formData.initial.followUpDate}
                onChange={handleChange("followUpDate", "initial")}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="followUpReason">Follow-Up Reason</label>
              <TextField
                fullWidth
                name="followUpReason"
                variant="outlined"
                required
                value={formData.initial.followUpReason}
                onChange={handleChange("followUpReason", "initial")}
                size="small"
              />
            </Grid> */}

            {/* ------------------------------ */}

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <label htmlFor="feedback">
                Feedback <span className="required-star">*</span>
              </label>
              <TextField
                fullWidth
                aria-label="feedback"
                multiline
                minRows={4.25}
                name="feedback"
                value={formData.initial.feedback}
                placeholder="Feedback"
                onChange={handleChange("feedback", "initial")}
                onBlur={handleBlur("feedback")}
                error={!!errors.feedback}
                helperText={errors.feedback}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="employee-label-card">
                <label htmlFor="status">
                  Status
                  <span className="required-star">*</span>
                </label>
                <FormControl
                  fullWidth
                  variant="outlined"
                  required
                  error={!!errors.status}
                  size="small"
                >
                  <Select
                    value={formData.initial.status}
                    onChange={handleChange("status", "initial")}
                    onClose={handleBlur("status", "initial")}
                    displayEmpty
                  >
                    {JobApplicationStatusData?.map((status) => (
                      <MenuItem key={status.name} value={status.value}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.status && (
                    // <Typography color="error">{errors.status}</Typography>
                    <span className="required-field-text">{errors.status}</span>
                  )}
                </FormControl>
              </div>
              <div style={{ display: "flex" }}>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ marginTop: 1.5 }}>
                  <label htmlFor="followUpDate">Follow-Up Date</label>
                  <TextField
                    fullWidth
                    name="followUpDate"
                    variant="outlined"
                    required
                    type="date"
                    value={formData.initial.followUpDate}
                    onChange={handleChange("followUpDate", "initial")}
                    onBlur={handleBlur("followUpDate", "initial")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ marginLeft: 3, marginTop: 1.25 }}
                >
                  <label htmlFor="followUpReason">Follow-Up Reason</label>
                  <TextField
                    fullWidth
                    name="followUpReason"
                    variant="outlined"
                    required
                    value={formData.initial.followUpReason}
                    onChange={handleChange("followUpReason", "initial")}
                    onBlur={handleBlur("followUpReason", "initial")}
                    size="small"
                  />
                </Grid>
              </div>
            </Grid>

            {/* ------------------------------ */}
            <Grid item xs={12} sm={3}>
              <label htmlFor="communicationSkillsRating">
                Communication Skills Rating{" "}
                <span className="required-star">*</span>{" "}
              </label>
              <Rating
                name="communicationSkillsRating"
                value={formData.initial.commSkillsRating}
                onChange={handleRatingChange("commSkillsRating", "initial")}
                onBlur={handleBlur("communicationSkillsRating")}
              />
              {errors.communicationSkillsRating && (
                // <Typography color="error">
                //   {errors.communicationSkillsRating}
                // </Typography>
                <span className="required-field-text">
                  {" "}
                  {errors.communicationSkillsRating}
                </span>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <label htmlFor="educationQualificationRating">
                Education and Qualification Rating{" "}
                <span className="required-star">*</span>{" "}
              </label>
              <Rating
                name="educationQualificationRating"
                value={formData.initial.educationAndSkillsRating}
                onChange={handleRatingChange(
                  "educationAndSkillsRating",
                  "initial"
                )}
                onBlur={handleBlur("educationQualificationRating")}
              />
              {errors.educationQualificationRating && (
                // <Typography color="error">
                //   {errors.educationQualificationRating}
                // </Typography>
                <span className="required-field-text">
                  {" "}
                  {errors.educationQualificationRating}
                </span>
              )}
            </Grid>
            <Grid item xs={12} sm={2}>
              <label htmlFor="overallRating">
                Overall Rating <span className="required-star">*</span>{" "}
              </label>
              <Rating
                name="overallRating"
                value={formData.initial.overAllRating}
                onChange={handleRatingChange("overAllRating", "initial")}
                onBlur={handleBlur("overallRating")}
              />
              {errors.overallRating && (
                // <Typography color="error">{errors.overallRating}</Typography>
                <span className="required-field-text">
                  {errors.overallRating}
                </span>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "15px",
        }}
      >
        {formData.initial.status === "Selected" ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit("Save and Proceed")}
          >
            Save and Proceed
          </Button>
        ) : formData.status === "Rejected" ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit("Save")}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit("Save")}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

export default PersonalDetailsForm;
