import React, { useState, useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Button } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import ServerSideDataGrid from "../../../../utilites/ServerSideDataGrid";
import {
  getAllApplications,
  getActiveJobs,
  tabIndex,
  deleteJobApplication,
} from "../../../../../redux/dataSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";

const ApplicationGrid = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const application = useSelector((state) => state.Data.Resources.application);
  const userRole = sessionStorage.getItem("role")?.split(",") || [];
  const Data = useSelector(
    (state) => state?.Data?.Resources?.application?.data
  );
  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  console.log("selected regions from employee form", selectedRegion);

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleCardClick = (status) => {
    setSelectedCard(status);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEdit = (id) => {
    dispatch(tabIndex(0));
    navigate(`/updateApplication/${id}`, {
      state: { headerText: 'Applications' }  // State you want to pass
    });
  };
  const handleScreeningClick = (id) => {
    dispatch(tabIndex(1));

    navigate(`/updateApplication/${id}`, {
      state: { headerText: "Applications" }, // State you want to pass
    });
  };
  const booleanFilterOperators = [
    { value: "true", label: "Is True" },
    { value: "false", label: "Is False" },
  ];
  const handleDelete = (id) => {
    // if (userRole.some((role) => ["ADMIN"].includes(role))) {
    dispatch(deleteJobApplication(id))
      .then(() => {
        toast.success("Application Deleted successfully...!");
      })
      .catch(() => {
        toast.error("An error occurred. Please try again.");
      });
  };

  const columns = [
    {
      field: "personalDetails.firstName",
      headerName: "Name",
      width: 120,
      flex: 0.1,
      headerAlign: 'center',
      resizable: true,
      valueGetter: (params) => {
        return `${params.row.personalDetails.firstName} ${params.row.personalDetails.lastName}`;
      },
    },
    {
      field: "experienceYear",
      headerName: "Experience",
      filterable: false,
      flex: 0.1,
      width: 130,
      headerAlign: 'center',
      resizable: true,
      valueGetter: (params) => {
        let years = Number(params.row?.personalDetails?.experienceYear || 0);
        let months = Number(params.row?.personalDetails?.experienceMonth || 0);
        const totalMonths = years * 12 + months;
        const decimalYears = totalMonths / 12;

        // Return the formatted string
        if (months === 0) return `${years} year${years !== 1 ? "s" : ""}`;
        else
          return `${decimalYears.toFixed(1)} year${decimalYears.toFixed(1) !== "1.0" ? "s" : ""
            } `;
      },
    },
    {
      field: "initial.status",
      headerName: "Initial Status",
      filterable: false,
      flex: 0.1,
      // width: 120,
      headerAlign: 'center',
      resizable: true,
      renderCell: (params) => {
        const initialStatus = params.row.initial.status;
        const initialDate = params.row.initial.date;
        let style = {};
        if (initialStatus?.toLowerCase() === "selected") {
          style = {
            color: "green",
            border: "1px solid green",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (initialStatus?.toLowerCase() === "pending") {
          style = {
            color: "orange",
            border: "1px solid orange",
            padding: "4px",
            borderRadius: "6px",
            marginBottom: initialDate === undefined ? "16px" : "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (initialStatus?.toLowerCase() === "rejected") {
          style = {
            color: "red",
            border: "1px solid red",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span style={style}>{initialStatus ? initialStatus : "----"}</span>
            <span>{dayjs(initialDate).format("DD-MM-YYYY")}</span>
          </div>
        );
      },
    },
    {
      field: "screeningDetails.level1.status",
      headerName: "L1-Status",
      filterable: false,
      width: 120,
      flex: 0.1,
      headerAlign: 'center',
      resizable: true,
      renderCell: (params) => {
        const status = params?.row?.level1?.status;
        const date = params?.row?.level1?.date;
        let style = {};
        if (status && status?.toLowerCase() === "selected") {
          style = {
            color: "green",
            border: "1px solid green",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (status && status?.toLowerCase() === "pending") {
          style = {
            color: "orange",
            border: "1px solid orange",
            padding: "4px",
            borderRadius: "6px",
            marginBottom: date === undefined ? "16px" : "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (status && status?.toLowerCase() === "rejected") {
          style = {
            color: "red",
            border: "1px solid red",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={style}>{status ? status : "----"}</span>
            {date ? <span>{dayjs(date).format("DD-MM-YYYY")}</span> : <></>}
          </div>
        );
      },
    },
    {
      field: "screeningDetails.level2.status",
      headerName: "L2-Status",
      filterable: false,
      width: 120,
      headerAlign: 'center',
      flex: 0.1,
      resizable: true,
      renderCell: (params) => {
        const status = params?.row?.level2?.status;
        const date = params?.row?.level2?.date;
        let style = {};
        if (status && status?.toLowerCase() === "selected") {
          style = {
            color: "green",
            border: "1px solid green",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (status && status?.toLowerCase() === "pending") {
          style = {
            color: "orange",
            border: "1px solid orange",
            padding: "4px",
            borderRadius: "6px",
            marginBottom: date === undefined ? "16px" : "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (status && status?.toLowerCase() === "rejected") {
          style = {
            color: "red",
            border: "1px solid red",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={style}>{status ? status : "----"}</span>
            {date ? <span>{dayjs(date).format("DD-MM-YYYY")}</span> : <></>}
          </div>
        );
      },
    },
    {
      field: "client",
      headerName: "Client",
      filterable: false,
      width: 120,
      headerAlign: 'center',
      flex: 0.1,
      resizable: true,
      renderCell: (params) => {
        const status = params?.row?.client?.status;
        const date = params?.row?.client?.date;
        let style = {};
        if (status && status?.toLowerCase() === "selected") {
          style = {
            color: "green",
            border: "1px solid green",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (status && status?.toLowerCase() === "pending") {
          style = {
            color: "orange",
            border: "1px solid orange",
            padding: "4px",
            borderRadius: "6px",
            marginBottom: date === undefined ? "16px" : "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (status && status?.toLowerCase() === "rejected") {
          style = {
            color: "red",
            border: "1px solid red",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={style}>{status ? status : "----"}</span>
            {date ? <span>{dayjs(date).format("DD-MM-YYYY")}</span> : <></>}
          </div>
        );
      },
    },
    {
      field: "hr",
      filterable: false,
      headerName: "HR-Status",
      width: 110,
      headerAlign: 'center',
      flex: 0.1,
      resizable: true,
      renderCell: (params) => {
        const status = params?.row?.hr?.status;
        const date = params?.row?.hr?.date;
        let style = {};
        if (status && status?.toLowerCase() === "selected") {
          style = {
            color: "green",
            border: "1px solid green",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (status && status?.toLowerCase() === "pending") {
          style = {
            color: "orange",
            border: "1px solid orange",
            padding: "4px",
            borderRadius: "6px",
            marginBottom: date === undefined ? "16px" : "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        } else if (status && status?.toLowerCase() === "rejected") {
          style = {
            color: "red",
            border: "1px solid red",
            padding: "4px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          };
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={style}>{status ? status : "----"}</span>
            {date ? <span>{dayjs(date).format("DD-MM-YYYY")}</span> : <></>}
          </div>
        );
      },
    },

    {
      field: "screeningDetails.remark.isOnBoarded",
      headerName: "On Boarded",
      width: 120,
      headerAlign: 'center',
      flex: 0.1,
      resizable: true,
      renderCell: (params) => {
        if (
          // params?.row?.screeningDetails?.remark?.isOnBoarded &&
          params?.row?.screeningDetails?.remark?.isOnBoarded === true
        ) {
          return "Yes";
        } else if (
          // params?.row?.screeningDetails?.remark?.isOnBoarded &&
          params?.row?.screeningDetails?.remark?.isOnBoarded === false
        ) {
          return "No";
        }
      },
    },
    {
      field: "screeningDetails.remark.jobOffered",
      headerName: "Job Offered",
      width: 120,
      headerAlign: 'center',
      flex: 0.1,
      resizable: true,
      renderCell: (params) => {
        const isJobOffered = params?.row?.screeningDetails?.remark?.jobOffered;

        if (isJobOffered === true) {
          return "Yes";
        } else if (isJobOffered === false) {
          return "No";
        } else {
          return ""; // Return an empty string or any placeholder if the value is undefined or null
        }
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 150,
      headerAlign: 'center',
      flex: 0.1,
      resizable: true,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <strong>
              <Tooltip placement="top" title={"Edit"}>
                <IconButton
                  style={{ color: "#24CCEF", cursor: "pointer" }}
                  onClick={() => handleEdit(params.row._id)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top" title={"Screening"}>
                <IconButton
                  style={{ color: "green", cursor: "pointer" }}
                  onClick={() => handleScreeningClick(params.row._id)}
                >
                  <ScreenshotMonitorIcon />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top" title={"Delete"}>
                <IconButton
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => {
                    setOpen(true);
                    setDeleteId(params.row._id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
  ];

  const [selectedCard, setSelectedCard] = useState("All");

  const cardData = [
    { status: "All", label: "All Applications" },
    { status: "Selected", label: "Selected" },
    { status: "Pending", label: "Pending" },
    { status: "Rejected", label: "Rejected" },
  ];

  useEffect(() => {
    if (selectedRegion && selectedRegion?._id) {
      dispatch(
        getAllApplications({
          regionId: selectedRegion?._id,
          params: { page: 1, limit: 10 },
        })
      );
      if (application?.activeJobsData && application?.activeJobsData.length) {
        dispatch(getActiveJobs(selectedRegion._id));
      }
    }
  }, [selectedRegion]);

  const handleAddApplicationClick = () => {
    dispatch(tabIndex(0)); // Set tabIndex to 0
    navigate("/addApplication", {
      state: { headerText: 'Applications' }  // State you want to pass
    }); // Navigate to the addApplication route
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#f0f0f0",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this Application?"}
        </DialogTitle>
        <DialogContent>
          <Typography color="textPrimary">
            Are you sure you want to delete this Application?
          </Typography>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleClose}
            className="dialog-cancel-btn"
            color="primary"
          >
            Cancel
          </button>
          <button
            className="dialog-delete-btn"
            onClick={() => {
              handleDelete(deleteId);
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>

      <ServerSideDataGrid
        loading={application.loading}
        columns={
          userRole.includes("ADMIN")
            ? columns
            : columns.filter(
              (column) => column.field !== "edit" && column.field !== "delete"
            )
        }
        fetchDataAction={getAllApplications}
        dataSelector="application"
        sliceKey="Data.Resources"
        handleAdd={handleAddApplicationClick}
        addButtonName={"Add Application"}
        roles={["ADMIN", "HR"]}
      />
    </>
  );
};

export default ApplicationGrid;
