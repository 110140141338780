// export const baseURL = "http://localhost:8000/";
export const baseURL = "https://aifa360.aifalabs.com/";
// http://20.244.2.86:8000/
// export const baseURL = "http://13.235.93.223:8000/";
export const apiURL = baseURL + "api/";
export const ResourceApi = apiURL + "resources";
export const SignInApi = apiURL + "signin";
export const AssetApi = apiURL + "assets/";
export const EmployeeApi = apiURL + "employee/";
export const regionEmployeeApi = apiURL + "employee/region/";
export const AssignProjectApi = apiURL + "assignProject/";
export const ProjectApi = apiURL + "project/";
export const regionProjectApi = apiURL + "project/region/";
export const RegionApi = apiURL + "region/";
export const UserApi = apiURL + "user/";
export const JobApi = apiURL + "job/";
export const regionJobApi = apiURL + "job/region/";
export const JobApplicationApi = apiURL + "jobapplication/";
export const JobRegionApplicationApi = apiURL + "jobapplication/region/";
export const NoticeDataAPI = apiURL + "noticeData/";
export const ConfigDataApi = apiURL + "configData/";
export const InsightsApi = apiURL + "dashboard/";
