import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { ToastContainer } from "react-toastify";
import { fetchData } from "../../redux/dataSlice";
import "react-toastify/dist/ReactToastify.css";
import { GridToolbarExport } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CustomToolbar = () => {
  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("role").split(",");

  const styles = {
    Button: {
      marginLeft: 20,
      marginBottom: 5,
      backgroundColor: "#8A2BE2",
      color: "white",
      border: "none",
      padding: "6px 16px",
      borderRadius: "4px",
      cursor: "pointer",
      marginRight: 20,
      textTransform: "capitalize"
    },
  };

  return (
    <GridToolbarContainer style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <GridToolbarColumnsButton
          sx={{ marginRight: '10px' }}
        />
        <GridToolbarFilterButton
          sx={{ marginRight: '10px' }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>

        <GridToolbarQuickFilter
          debounceMs={500}
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: '#ffffff', /* White background */
              borderRadius: '25px', /* Rounded corners */
              padding: '12px 20px', /* Padding inside the input */
              width: '300px', /* Input width */
              height: '43px', /* Input height */
              border: 'none', /* Remove border */
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', /* Subtle shadow for depth */
              fontSize: '16px', /* Font size */
              color: '#2196f3', /* Bright blue text */
              transition: 'box-shadow 0.3s ease, background-color 0.3s ease', /* Smooth transition for shadow and background */
              outline: 'none', /* Remove default focus outline */
              position: 'relative', /* Ensure pseudo-elements are positioned relative to the input */
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                border: 'none', /* Ensure no border */
                borderRadius: '25px', /* Match border radius */
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', /* Same shadow as the input */
                zIndex: -1, /* Place behind the input */
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                border: 'none', /* Ensure no border */
                borderRadius: '25px', /* Match border radius */
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', /* Same shadow as the input */
                zIndex: -1, /* Place behind the input */
              },
              '&:hover': {
                backgroundColor: '#f0f4f8', /* Very light blue on hover */
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)', /* More pronounced shadow on hover */
              },
              '& .MuiInputBase-input': {
                color: '#000000',
                padding: '0 12px', /* Padding inside the input */
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#9e9e9e', /* Light grey placeholder text */
                fontSize: '16px', /* Placeholder font size */
              },
              '& .MuiInputBase-root.Mui-focused': {
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)', /* Shadow on focus */
                borderColor: 'transparent',
              },
            },
            marginRight: '10px',
            paddingTop: "3px",
            paddingBottom: "8px",

          }}
        />

        {userRole.some((role) => ["ADMIN", "HR"].includes(role)) ? (
          <Button
            onClick={() => navigate("/addEmployee", {
              state: { headerText: 'Employee' }  // Pass state here
            })}
            startIcon={<AddCircleOutlineIcon />}
            style={styles.Button}
          >
            Add Employee
          </Button>
        ) : null}
      </div>

    </GridToolbarContainer>
  );
};

const CustomDataGrid = ({
  rows,
  columns,
  configData,
  getRowId,
  updatedRows,
  handleCellEditChange,
  editingRowId,
  loading
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [loading, setLoading] = useState(true);
  const [filterModel, setFilterModel] = useState({
    items: [{ id: "ID", value: "", operatorValue: "contains" }],
  });
  const [selectionModel, setSelectionModel] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: "ID",
      sort: "asc",
    },
  ]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(fetchData())
    // .then(response => {
    //   if (response?.data?.statusCode === 401) {
    //     navigate("/session-expired")
    //   }
    // });
  }, [dispatch]);

  // useEffect(() => {
  //   if (rows.length > 0) {
  //     setLoading(false);
  //   }
  // }, [rows]);

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectionModel(newSelectionModel.selectionModel);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel.sortModel);
  };

  const handlePageSizeChange = (params) => {
    const newPageSize = params.pageSize;
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setPage(0);
    }
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const gridWidth = configData ? "70%" : "100%";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "80vh",
          width: gridWidth,

          backgroundColor: "white",
        }}
      >
        <DataGrid
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={pageSize}
          page={page}
          onPageChange={handlePageChange}
          onPageSizeChange={(params) => handlePageSizeChange(params)}
          rows={updatedRows || rows}
          getRowId={getRowId ? getRowId : null}
          columns={columns}
          pagination
          loading={loading}
          getRowHeight={(params) => 70}
          onSelectionModelChange={handleSelectionModelChange}
          selectionModel={selectionModel}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          disableDensitySelector
          disableSelectionOnClick
          disableColumnSelector={configData ? true : false}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
              exportButton: false,
            },
          }}
          isCellEditable={(params) =>
            params.field === "name" && editingRowId === params.row.id
          }
          onCellEditCommit={handleCellEditChange}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              textTransform: "full-width"

            },
            '& .MuiDataGrid-columnHeader': {
              borderBottom: 'none',
            },
            '& .MuiDataGrid-cell': {
              textAlign: 'center',  // Center-align row data
              display: 'flex',      // Align text center inside the cell
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default CustomDataGrid;

// import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { ToastContainer } from "react-toastify";
// import { fetchData } from "../../redux/dataSlice";
// import "react-toastify/dist/ReactToastify.css";

// const CustomDataGrid = ({ rows, columns, Data, getRowId }) => {
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(true);
//   const [filterModel, setFilterModel] = useState({
//     items: [{ id: "ID", value: "", operatorValue: "contains" }],
//   });
//   const [selectionModel, setSelectionModel] = useState([]);
//   const [sortModel, setSortModel] = useState([
//     {
//       field: "ID",
//       sort: "asc",
//     },
//   ]);
//   const [pageSize, setPageSize] = useState(5);
//   const [page, setPage] = useState(0);

//   useEffect(() => {
//     dispatch(fetchData());
//   }, [dispatch]);

//   useEffect(() => {
//     if (Data.length > 0) {
//       setLoading(false);
//     }
//   }, [Data]);

//   const handleSelectionModelChange = (newSelection) => {
//     setSelectionModel(newSelection.selectionModel);
//   };

//   const handleFilterModelChange = (newFilterModel) => {
//     setFilterModel(newFilterModel);
//   };

//   const handleSortModelChange = (newSortModel) => {
//     setSortModel(newSortModel.sortModel);
//   };

//   const handlePageSizeChange = (params) => {
//     const newPageSize = params.pageSize;
//     if (newPageSize !== pageSize) {
//       setPageSize(newPageSize);
//       setPage(0);
//     }
//   };

//   const handlePageChange = (params) => {
//     setPage(params.page);
//   };

//   // // Get the current page of rows based on the pageSize and page state
//   // const currentPageRows = rows.slice(page * pageSize, (page + 1) * pageSize);

//   return (
//     <div>
//       <div style={{ height: "80vh", width: "100%", backgroundColor: "white" }}>
//       <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
//       <Button size="small" onClick={removeRow}>
//         Remove a row
//       </Button>
//       <Button size="small" onClick={addRow}>
//         Add a row
//       </Button>
//     </Stack>
//         <DataGrid
//           rowsPerPageOptions={[5, 10, 20, 50]}
//           pageSize={pageSize}
//           page={page}
//           onPageChange={handlePageChange}
//           onPageSizeChange={(params) => handlePageSizeChange(params)}
//           rows={rows}
//           getRowId={getRowId ? getRowId : null}
//           columns={columns}
//           pagination
//           loading={loading}
//           getRowHeight={(params) => 70}
//           onSelectionModelChange={handleSelectionModelChange}
//           selectionModel={selectionModel}
//           sortModel={sortModel}
//           onSortModelChange={handleSortModelChange}
//           filterModel={filterModel}
//           onFilterModelChange={handleFilterModelChange}
//           disableDensitySelector
//           disableSelectionOnClick
//           //disableColumnMenu
//           components={{ Toolbar: GridToolbar }}
//           componentsProps={{
//             toolbar: {
//               showQuickFilter: true,
//               quickFilterProps: { debounceMs: 500 },
//               printOptions: { disableToolbarButton: true },
//               exportButton: false,
//             },
//           }}
//         />
//       </div>

//       <ToastContainer />
//     </div>
//   );
// };

// export default CustomDataGrid;

// import React, { useState, useEffect } from "react";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { ToastContainer } from "react-toastify";
// import { useDispatch } from "react-redux";

// const CustomDataGrid = ({ rows, columns, Data, getRowId }) => {
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(true);
//   const [filterModel, setFilterModel] = useState({
//     items: [{ id: "ID", value: "", operatorValue: "contains" }],
//   });
//   const [selectionModel, setSelectionModel] = useState([]);
//   const [sortModel, setSortModel] = useState([
//     {
//       field: "ID",
//       sort: "asc",
//     },
//   ]);
//   const pageSizeOptions = [10, 20, 50];

//   useEffect(() => {
//     //dispatch(fetchData());
//     if (Data.length > 0) {
//       setLoading(false);
//     }
//   }, [Data, dispatch]);

//   const handleSelectionModelChange = (newSelection) => {
//     setSelectionModel(newSelection.selectionModel);
//   };

//   const handleFilterModelChange = (newFilterModel) => {
//     setFilterModel(newFilterModel);
//   };

//   const handleSortModelChange = (newSortModel) => {
//     setSortModel(newSortModel.sortModel);
//   };

//   return (
//     <div className="asset-grid-container">
//       <div style={{ height: "80vh", width: "100%", backgroundColor: "white" }}>
//         <DataGrid
//           rows={rows}
//           getRowId={getRowId ? getRowId : null}
//           columns={columns}
//           pagination
//           rowsPerPageOptions={pageSizeOptions}
//           loading={loading}
//           getRowHeight={(params) => 70}
//           onSelectionModelChange={handleSelectionModelChange}
//           selectionModel={selectionModel}
//           sortModel={sortModel}
//           onSortModelChange={handleSortModelChange}
//           filterModel={filterModel}
//           onFilterModelChange={handleFilterModelChange}
//           disableDensitySelector
//           disableSelectionOnClick
//           components={{ Toolbar: GridToolbar }}
//           experimentalFeatures={{ newEditingApi: true }}
//           componentsProps={{
//             toolbar: {
//               showQuickFilter: true,
//               quickFilterProps: { debounceMs: 500 },
//               printOptions: { disableToolbarButton: true },
//             },
//           }}
//         />
//       </div>

//       <ToastContainer />
//     </div>
//   );
// };

// export default CustomDataGrid;
