import ProjectForm from '../ProjectForm'

const UpdateProject = () => {


  return (
    <>
    <ProjectForm {...{editMode:true}} />
    </>
  );
};

export default UpdateProject;
