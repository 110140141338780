import React from "react";

const JobsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="24" fill="url(#pattern0_3_21)" />
      <defs>
        <pattern
          id="pattern0_3_21"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_3_21" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_3_21"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADiUlEQVR4nO2dTUtVQRjHrxsrfAmkN1tWYKZBJPgNok0WIi1EhTbRCy3rQ0gkFVa0iegD1KaltTfbWSAttTYRVF4zC+0XQwN56dyb3jPzPPfMfX4wm8O53v//+Y9zzh3mzCmVDMMwDKMAAAeBG8BzYBH45tuiP3Yd6NbWmRxAN/AE+Mn/cec8Bg5o604C4Bzwhe3zGRjS1l9ogMvABvXjPntR20chAYaAdfLj/sYZbT+FAtgDfCQcn4B92r4KA/CQ8DzQ9lUIgL3AaoQA1oD9pdQAjgN3gDfACjIsAiNAh2/DwILQd694r7eBfs3C7wDu57xLqbf4XRl6uoAlZHHe7wGtGsV/iQ4jNXSdV9L0QjQEd0FDj44aujoVdU1LjvnSw85WA9iNHu73Rp9EAO7io8lwDW3uwqzJlEQAb5VNLtS4CL9T1jYvEcAy+iz5C26nbyMNUHzHskQARg0sAGUsAGUsAGUsAGUsAGUsAGUsAGUsAGUsAGUsAGUsAGWaIYBZv2CrB2jz7ag/9kpbXMoBlIFRoKWGthZgzJ+rQqoBlIGBbWgcEFyhUUGqAYzWoXNcQ2icqlca0xjzW+rQ6YajOWmxcapeaUyaSzm0XpUWG7ba2aak6cmh1d0diRK22tmmpGnLobVdWmzYamebkqY9h1a3blSUsNXONlWkIahXWmzYamebkuZKDq3XpMWGrXa2KWlm7TZUNwDHWB0dZQIFgvb2KsY0KNtUhG4A+Lmd8S1Mxk1ozQOl/B+wmTn/C7d303R0rz/2GmWaIYCGxgJQxgJQxgJQxgJQJsUA1oAZYBK4AAwCh/wTMa2+dfljg/6cSf/k4g9psakEsAE8Bc7mnIxr99vdPJN6sDBstbNNxeQX8Mj15gi6D/uNnNx3RCO07iwjMZ/7OiWg/zTwIZaJogaw5HpodPF/PRwB3scwIiE+NKvAsejC//XRB3wPbUZCeGhuRhdd3ctUaDMSokNzIrro6l5OhjYjITo0O6OLru5lV2gzEqKLJTg1P4UTnJqfwglOzU9owalhAShjAShjAShjAShjAShjAShjAShjAShjAShjAShjATRBAGrbABSAr82wdXEjM98Mm3c3MrckAugP9Hqp1JDZvt6H4F7bYVRyV6T4PoBWv/jV+MOMxntkXAjTTT4crbueL178jBVmboHTfJPcopa91ymxMd8wDMMwDMMwDMMwDMMopcBviwxRMW4TBxsAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};
export default JobsIcon;
