import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  AssetApi,
  AssignProjectApi,
  ConfigDataApi,
  EmployeeApi,
  JobApi,
  JobApplicationApi,
  NoticeDataAPI,
  ProjectApi,
  ResourceApi,
  SignInApi,
  UserApi,
  apiURL,
  InsightsApi,
  RegionApi,
  JobRegionApplicationApi,
  regionEmployeeApi,
  regionProjectApi,
  regionJobApi,
} from "../utills/apiCalls";

const initialState = {
  loading: false,
  Resources: {
    Assests_Details: [],
    Employee_Details: [],
    JobApplication_Details: [],
    region: {
      loading: true,
      data: [],
      error: "",
    },
    job: {
      loading: true,
      page: 1,
      limit: 2,
      totalPages: 0,
      totalItems: 0,
      data: [],
      error: "",
    },
    Users_Details: [],
    employee: {
      loading: false,
      data: [],
      error: "",
    },
    project: {
      loading: false,
      page: 1,
      limit: 2,
      totalPages: 0,
      totalItems: 0,
      data: [],
      error: "",
    },
    application: {
      tabValue: 0,
      activeJobs: [],
      loading: false,
      page: 1,
      limit: 2,
      totalPages: 0,
      totalItems: 0,
      data: [],
      error: "",
    },
    Project_Details: [],
    Project_Assigned_Details: [],
    ConfigData_Details: [],
    Notice_Details: [],
    Insights: [],
    LoginLogs: {
      loading: false,
      page: 1,
      limit: 2,
      totalPages: 0,
      totalItems: 0,
      data: [],
      error: "",
    },
    BlockuserList: {
      loading: false,
      page: 1,
      limit: 2,
      totalPages: 0,
      totalItems: 0,
      data: [],
      error: "",
    },
  },
  error: "",
};

const token = sessionStorage.getItem("jwt_token");

const config = {
  headers: {
    Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token value
  },
};

export const getInsightsData = createAsyncThunk(
  "redux/insightsdata",
  async (regionId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${InsightsApi}${regionId}`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const fetchData = createAsyncThunk(
  "redux/fetchData",
  async (id = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(ResourceApi, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// export const fetchData = createAsyncThunk(
//   "redux/fetchData",
//   async (id = null, { rejectWithValue }) => {

//     try {
//       const response = await axios.get(ResourceApi);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );

// login
export const signIn = createAsyncThunk(
  "redux/signIn",
  async (item, { rejectWithValue }) => {
    try {
      const response = await axios.post(SignInApi, item);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// Assets

export const getAssetData = createAsyncThunk(
  "redux/getAsset",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(AssetApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addAsset = createAsyncThunk(
  "redux/addAsset",
  async (item, { rejectWithValue }) => {
    try {
      const response = await axios.post(AssetApi, item, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateAsset = createAsyncThunk(
  "redux/updateAsset",
  async (item, { rejectWithValue }) => {
    try {
      const {
        _id,
        Asset_ID,
        Asset_Type,
        desc,
        Model,
        Vendor,
        Serial_Number,
        Location,
        Status,
        Purchase_Date,
        Purchase_From,
        Purchase_Cost,
        warranty_info,
        warranty_expiration_date,
        invoice_number,
        quantity,
        remarks,
        createdBy,
        updatedBy,
      } = item;

      const response = await axios.put(
        AssetApi + _id,
        {
          Asset_ID,
          Asset_Type,
          desc,
          Model,
          Vendor,
          Serial_Number,
          Location,
          Status,
          Purchase_Date,
          Purchase_From,
          Purchase_Cost,
          warranty_info,
          warranty_expiration_date,
          invoice_number,
          quantity,
          remarks,
          createdBy,
          updatedBy,
        },
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteAsset = createAsyncThunk(
  "redux/deleteAsset",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(AssetApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// Employee
export const getAllEmployeeByRegion = createAsyncThunk(
  "redux/getAllEmployeeByRegion",
  async (regionId, { rejectWithValue }) => {
    console.log(regionId, "regions2");

    try {
      const response = await axios.get(
        `${regionEmployeeApi}${regionId.id}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllEmployeeData = createAsyncThunk(
  "redux/getAllEmployeeData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(EmployeeApi, config);
      // if (response?.data?.statusCode === 401) {
      //   console.log("from error from redux", response);
      //   navigate('/login');
      // }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getEmployeeData = createAsyncThunk(
  "redux/getEmployeeData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(EmployeeApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addEmployeeCard = createAsyncThunk(
  "redux/addEmployeeCard",
  async (item, { dispatch, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token value
        },
      };
      const response = await axios.post(EmployeeApi, item, config);
      // Dispatch another action here
      toast.success("Employee Added Successfully!");
      dispatch(getAllEmployeeData());
      // dispatch(getAllEmployeeByRegion());
      return response.data;
    } catch (error) {
      if (error?.response?.data) toast.error(error?.response?.data);
      else toast.error("Something went wrong!");
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateEmployeeCard = createAsyncThunk(
  "redux/updateEmployeeCard",
  async (item, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token value
        },
      };
      const response = await axios.put(EmployeeApi + item?._id, item, config);
      toast.success("Employee Updated Successfully!");
      dispatch(getAllEmployeeData());
      // dispatch(getAllEmployeeByRegion());
      return response.data;
    } catch (error) {
      if (error?.response?.data) toast.error(error?.response?.data);
      else toast.error("Something went wrong!");
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteEmployeeCard = createAsyncThunk(
  "redux/deleteEmployeeCard",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(EmployeeApi + id, config);
      toast.success("Employee Deleted Successfully!");
      dispatch(getAllEmployeeData());
      // dispatch(getAllEmployeeByRegion());
      return response.data;
    } catch (error) {
      if (error?.response?.data) toast.error(error?.response?.data);
      else toast.error("Something went wrong!");
      return rejectWithValue(error.response?.data);
    }
  }
);

// Assign Project

export const getAssignProjectData = createAsyncThunk(
  "redux/getAssignProjectData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(AssignProjectApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addAssignProject = createAsyncThunk(
  "redux/addAssignProject",
  async (item, { rejectWithValue }) => {
    try {
      const response = await axios.post(AssignProjectApi, item, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateAssignProject = createAsyncThunk(
  "redux/updateAssignProject",
  async (item, { rejectWithValue }) => {
    try {
      const {
        _id,
        __v,
        //employeeName,
        projectTitle,
        //projectRole,
        startDate,
        endDate,
        projectStatus,
        projectLead,
        qaLead,
        projectTeam,
      } = item;

      const response = await axios.put(
        AssignProjectApi + _id,
        {
          _id,
          __v,
          //employeeName,
          projectTitle,
          //projectRole,
          startDate,
          endDate,
          projectStatus,
          projectLead,
          qaLead,
          projectTeam,
        },
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const deleteAssignProject = createAsyncThunk(
  "redux/deleteAssignProject",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(AssignProjectApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// Projects
export const getAllProjectData = createAsyncThunk(
  "redux/getAllProjectData",
  async ({ regionId, params }, { rejectWithValue }) => {
    try {
      console.log("params----->", params);
      console.log("region from project Dispatch", regionId);
      const queryParams = new URLSearchParams(params);
      console.log("q params ----->", queryParams);
      const response = await axios.get(
        // `${regionProjectApi}${regionId}?${queryParams.toString()}`,
        `${regionProjectApi}${regionId}?${queryParams.toString()}`,
        config
      );
      console.log(response, "project response");
      if (response?.data && response?.data?.projects) {
        response.data.projects = response.data.projects.map((p) => ({
          ...p,
          id: p?.projectId,
        }));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getProjectData = createAsyncThunk(
  "redux/getProjectData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(ProjectApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addProjectCard = createAsyncThunk(
  "redux/addProjectCard",
  async (item, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token value
        },
      };
      const response = await axios.post(ProjectApi, item, config);
      toast.success("Project Added Successfully!");

      return response.data;
    } catch (error) {
      if (error?.response?.data) toast.error(error?.response?.data);
      else toast.error("Something went wrong!");
      return rejectWithValue(error.response?.data);
    }
  }
);
export const addProjectAssignees = createAsyncThunk(
  "redux/addProjectCard",
  async (item, { rejectWithValue, dispatch, getState }) => {
    try {
      console.log("addProjectAssignData final data", item);
      const config = {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token value
        },
      };
      const response = await axios.post(
        AssignProjectApi + item?._id,
        item,
        config
      );
      toast.success("Saved Successfully!");

      return response.data;
    } catch (error) {
      if (error?.response?.data) toast.error(error?.response?.data);
      else toast.error("Something went wrong!");
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateProjectCard = createAsyncThunk(
  "redux/updateProjectCard",
  async (item, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(ProjectApi + item._id, item, config);
      toast.success("Project Updated Successfully!");

      return response.data;
    } catch (error) {
      if (error?.response?.data) toast.error(error?.response?.data);
      else toast.error("Something went wrong!");
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteProjectCard = createAsyncThunk(
  "redux/deleteProjectCard",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(ProjectApi + id, config);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// Users

export const getUserData = createAsyncThunk(
  "redux/getUserData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(UserApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addUser = createAsyncThunk(
  "redux/addUser",
  async (item, { rejectWithValue }) => {
    try {
      const response = await axios.post(UserApi, item, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "redux/updateUser",
  async (item, { rejectWithValue }) => {
    try {
      const { _id, empId, password, role, createdBy, updatedBy } = item;

      const response = await axios.put(
        UserApi + _id,
        {
          empId,
          password,
          role,
          createdBy,
          updatedBy,
        },
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "redux/deleteUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(UserApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// Jobs
export const getAllJobData = createAsyncThunk(
  "redux/getAllJobData",
  async ({ regionId, params }, { rejectWithValue }) => {
    try {
      console.log("params", params);
      const {
        JobStatus,
        startDate,
        endDate,
        page,
        limit,
        sortField,
        sortOrder,
        Search,
        ...filters
      } = params;
      // const queryParams = `JobStatus=${JobStatus}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&Search=${Search}`;
      const filterQuery = Object.entries(filters)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

      const queryParams = `JobStatus=${JobStatus}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&Search=${Search}&${filterQuery}`;
      
      if(regionId){
        const response = await axios.get(
          `${JobApi}region/${regionId}?${queryParams}`,
          config
        );
        console.log(response, "Job response");
        if (response?.data && response?.data?.jobs) {
          response.data.jobs = response.data.jobs.map((p) => ({
            ...p,
            id: p?.JobID,
          }));
        }
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobData = createAsyncThunk(
  "redux/getJobData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(JobApi + id, config);
      console.log("response from job data===========>", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addJobCard = createAsyncThunk(
  "redux/addJobCard",
  async (item, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(JobApi, item, config);
      // dispatch(getAllJobData({ page: 1, limit: 10 }));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateJobCard = createAsyncThunk(
  "redux/updateJobCard",
  async (item, { rejectWithValue, dispatch }) => {
    try {
      const {
        _id,
        JobID,
        JobTitle,
        JobDescription,
        JobAssignedTo,
        ExperienceRequired,
        Skillset,
        SalaryRange,
        EmploymentType,
        OffShore,
        WorkMode,
        JobLocation,
        JobStatus,
        SuitableQualification,
        JobPriority,
        No_Of_Positions,
        ExpectedClosingDate,
      } = item;

      const response = await axios.put(
        JobApi + _id,
        {
          JobID,
          JobTitle,
          JobDescription,
          JobAssignedTo,
          ExperienceRequired,
          Skillset,
          SalaryRange,
          EmploymentType,
          OffShore,
          WorkMode,
          JobLocation,
          JobStatus,
          SuitableQualification,
          JobPriority,
          No_Of_Positions,
          ExpectedClosingDate,
        },
        config
      );
      // dispatch(getAllJobData({ page: 1, limit: 10 }));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteJobCard = createAsyncThunk(
  "redux/deleteJobCard",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(JobApi + id, config);
      // dispatch(getAllJobData({ page: 1, limit: 10 }));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// Job Applications
export const getActiveJobs = createAsyncThunk(
  "recruitment/getActiveJobs",
  async (regionId, { rejectWithValue }) => {
    try {
      // const response = await axios.get(
      //   `${regionJobApi}${regionId} + ?JobStatus=Open`,
      //   config
      // );
      const response = await axios.get(
        regionJobApi + `${regionId}` + "?JobStatus=Open",
        config
      );
      console.log("Active JObs", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getAllApplications = createAsyncThunk(
  "redux/getAllApplications",
  async ({ regionId, params }, { rejectWithValue }) => {
    try {
      if (params._id & regionId) {
        var response = await axios.get(
          `${JobRegionApplicationApi}${regionId}${params._id}`,
          config
        );
      } else {
        const queryParams = new URLSearchParams(params);
        console.log("applications queryparams", queryParams.toString());
        var response = await axios.get(
          `${JobRegionApplicationApi}${regionId}?${queryParams.toString()}`,
          config
        );
      }

      if (response?.data && response?.data?.data) {
        // console.log("just response", JSON.parse(JSON.stringify(response)))
        response.data.data = response.data.data.map((p) => ({
          ...p,
          id: p?._id,
          ...p.personalDetails,
          ...p.screeningDetails,
        }));
      }
      console.log("response employees", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobApplicationData = createAsyncThunk(
  "redux/getJobApplicationData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(JobApplicationApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addJobApplication = createAsyncThunk(
  "redux/addJobApplication",
  async (formData, { rejectWithValue, dispatch }) => {
    console.log("formindispatch", formData);
    const message = formData?._id
      ? "Application Updated Successfully!"
      : "Application Added Successfully!";
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token value
        },
      };
      const response = await axios.post(JobApplicationApi, formData, config);
      // Dispatch another action here
      toast.success(message);
      dispatch(getAllApplications({ page: 1, limit: 10 }));
      return response.data;
    } catch (error) {
      if (error?.response?.data) toast.error(error?.response?.data);
      else toast.error("Something went wrong!");
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateJobApplication = createAsyncThunk(
  "redux/updateJobApplication",
  async (formData, { rejectWithValue, dispatch }) => {
    const _id = formData.get("_id");
    // const { __v, ...updateData } = formData;
    //console.log("id from redux",_id,formData)
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token value
        },
      };
      const response = await axios.put(
        JobApplicationApi + _id,
        formData,
        //updateData,
        config
      );
      toast.success("Application Updated Successfully!");
      dispatch(getAllApplications({ page: 1, limit: 10 }));
      return response.data;
    } catch (error) {
      if (error?.response?.data) toast.error(error?.response?.data);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteJobApplication = createAsyncThunk(
  "redux/deleteJobApplication",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(JobApplicationApi + id, config);
      dispatch(getAllApplications({ page: 1, limit: 10 }));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// Notice
export const addNoticeData = createAsyncThunk(
  "redux/addNoticeData",
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(NoticeDataAPI, formData, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNoticeData = createAsyncThunk(
  "redux/getNoticeData",
  async (id = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(NoticeDataAPI);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getloginlogs = createAsyncThunk(
  "redux/getloginlogs",
  async (params, { rejectWithValue }) => {
    try {
      const queryString = new URLSearchParams(params).toString();
      const response = await axios.get(
        `${apiURL}getloginlogs?${queryString}`,
        config
      );
      console.log("Job response login", response?.data);
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching login logs:", error);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getUsersList = createAsyncThunk(
  "redux/getUsersList",
  async (params, { rejectWithValue }) => {
    try {
      const queryString = new URLSearchParams(params).toString();
      const response = await axios.get(
        `${apiURL}getuserslist?${queryString}`,
        config
      );

      return response?.data;
    } catch (error) {
      console.error("Error fetching users list:", error);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateBlockedStatus = createAsyncThunk(
  "redux/updateBlockedStatus",
  async ({ userId, blockUser }, { dispatch, rejectWithValue }) => {
    console.log("block api triggered with==>", userId, blockUser);
    try {
      const response = await axios.post(
        `${apiURL}blockuser`,
        { userId, blockUser },
        config
      );

      // if(response.statusCode==200){

      dispatch(getUsersList());
      // }

      return response.data;
    } catch (error) {
      console.error("Error updating blocked status:", error);
      return rejectWithValue(
        error.response?.data || "Failed to update blocked status"
      );
    }
  }
);

// Config

export const fetchConfigData = createAsyncThunk(
  "redux/fetchConfigData",
  async (fieldName, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ConfigDataApi}`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createConfigData = createAsyncThunk(
  "config/createConfigData",
  async ({ addTabName, finalData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ConfigDataApi}${addTabName}`,
        finalData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateConfigData = createAsyncThunk(
  "redux/updateConfigData",
  async ({ addTabName, name, finalData }, { rejectWithValue }) => {
    console.log(addTabName, name, finalData, "23456");
    try {
      const response = await axios.patch(
        `${ConfigDataApi}${addTabName}/${name}`,
        finalData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteConfigData = createAsyncThunk(
  "config/deleteConfigData",
  async ({ addTabName, deleteId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${ConfigDataApi}${addTabName}/${deleteId}`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllRegionData = createAsyncThunk(
  "redux/getAllRegionData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${RegionApi}`, config);
      console.log(response, "region response");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    tabIndex: (state, action) => {
      state.Resources.application.tabValue = action.payload;
    },
    setSelectedRegion: (state, action) => {
      state.Resources.selectedRegion = action.payload;
    },
    // Action to clear the selected region
    clearSelectedRegion: (state) => {
      state.Resources.selectedRegion = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getInsightsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInsightsData.fulfilled, (state, action) => {
      state.loading = false;
      state.Resources.Insights = action.payload;
      state.error = "";
    });
    builder.addCase(getInsightsData.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Insights = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchConfigData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchConfigData.fulfilled, (state, action) => {
      state.loading = false;
      state.Resources.ConfigData_Details = action.payload;
      state.error = "";
    });
    builder.addCase(fetchConfigData.rejected, (state, action) => {
      state.loading = false;
      state.Resources.ConfigData_Details = [];
      state.error = action.error.message;
    });

    builder.addCase(fetchData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.loading = false;
      const {
        Assests_Details,
        Employee_Details,
        JobApplication_Details,
        // Jobs_Details,
        Users_Details,
        Project_Details,
        Project_Assigned_Details,
        ConfigData_Details,
        Notice_Details,
      } = action.payload.Resources || {};

      state.Resources.Assests_Details = Assests_Details;
      state.Resources.Employee_Details = Employee_Details;
      state.Resources.JobApplication_Details = JobApplication_Details;
      // state.Resources.Jobs_Details = Jobs_Details;
      state.Resources.Users_Details = Users_Details;
      state.Resources.Project_Details = Project_Details;
      state.Resources.Project_Assigned_Details = Project_Assigned_Details;
      state.Resources.ConfigData_Details = ConfigData_Details;
      state.Resources.Notice_Details = Notice_Details;
      state.error = "";
    });

    builder.addCase(fetchData.rejected, (state, action) => {
      state.loading = false;
      state.Resources = {
        ...state.Resources,
        Assests_Details: [],
        Employee_Details: [],
        JobApplication_Details: [],
        // Jobs_Details: [],
        Users_Details: [],
        Project_Details: [],
        Project_Assigned_Details: [],
        ConfigData_Details: [],
        Notice_Details: [],
      };
      state.error = action.error.message;
    });

    // // Assets

    // addAsset

    builder.addCase(addAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAsset.fulfilled, (state, action) => {
      state.loading = false;
      state.Resources.Assests_Details.push(action.payload);
      state.error = "";
    });
    builder.addCase(addAsset.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Assests_Details = [];
      state.error = action.error.message;
    });

    // updateAsset

    builder.addCase(updateAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAsset.fulfilled, (state, action) => {
      const updateAsset = state.Resources.Assests_Details.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      state.loading = false;
      state.Resources.Assests_Details = updateAsset;
      state.error = "";
    });
    builder.addCase(updateAsset.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Assests_Details = [];
      state.error = action.error.message;
    });

    // deleteEmployee

    builder.addCase(deleteAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAsset.fulfilled, (state, action) => {
      const currentAsset = state.Resources.Assests_Details.filter(
        (item) => item._id !== action.payload._id
      );
      state.loading = false;
      state.Resources.Assests_Details = currentAsset;
      state.error = "";
    });
    builder.addCase(deleteAsset.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Assests_Details = [];
      state.error = action.error.message;
    });

    // Region
    builder.addCase(getAllRegionData.pending, (state) => {
      state.Resources.region.loading = true;
    });
    builder.addCase(getAllRegionData.fulfilled, (state, action) => {
      state.Resources.region.loading = false;
      state.Resources.region.data = action.payload;
      state.error = "";
    });
    builder.addCase(getAllRegionData.rejected, (state, action) => {
      state.Resources.region.loading = false;
      state.Resources.region.data = [];
      state.error = action.error.message;
    });

    // // Employee
    // Employee by Region
    builder.addCase(getAllEmployeeByRegion.pending, (state) => {
      state.Resources.employee.loading = true;
    });
    builder.addCase(getAllEmployeeByRegion.fulfilled, (state, action) => {
      state.Resources.employee.loading = false;
      console.log(
        "builder action.payload for getAllEmployeeByRegion",
        action.payload
      );
      state.Resources.employee.data = action.payload;
      state.error = "";
    });
    builder.addCase(getAllEmployeeByRegion.rejected, (state, action) => {
      state.Resources.employee.loading = false;
      state.Resources.employee.data = [];
      state.error = action.error.message;
    });

    // builder.addCase(getAllEmployeeData.pending, (state) => {
    //   state.Resources.employee.loading = true;
    // });
    // builder.addCase(getAllEmployeeData.fulfilled, (state, action) => {
    //   state.Resources.employee.loading = false;
    //   state.Resources.employee.data = action.payload;
    //   state.error = "";
    // });
    // builder.addCase(getAllEmployeeData.rejected, (state, action) => {
    //   state.Resources.employee.loading = false;
    //   state.Resources.employee.data = [];
    //   state.error = action.error.message;
    // });

    // addEmployee
    builder.addCase(addEmployeeCard.pending, (state) => { });
    builder.addCase(addEmployeeCard.fulfilled, (state, action) => { });
    builder.addCase(addEmployeeCard.rejected, (state, action) => { });

    // updateEmployee
    builder.addCase(updateEmployeeCard.pending, (state) => { });
    builder.addCase(updateEmployeeCard.fulfilled, (state, action) => { });
    builder.addCase(updateEmployeeCard.rejected, (state, action) => { });
    builder.addCase(deleteEmployeeCard.pending, (state) => { });
    builder.addCase(deleteEmployeeCard.fulfilled, (state, action) => { });
    builder.addCase(deleteEmployeeCard.rejected, (state, action) => { });
    // // Assign Project
    builder.addCase(addAssignProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAssignProject.fulfilled, (state, action) => {
      state.loading = false;
      state.Resources.Project_Assigned_Details.push(action.payload);
      state.error = "";
    });
    builder.addCase(addAssignProject.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Project_Assigned_Details = [];
      state.error = action.error.message;
    });

    // updateProject
    builder.addCase(updateAssignProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAssignProject.fulfilled, (state, action) => {
      const updatedAssignProject = state.Resources.Project_Assigned_Details.map(
        (item) => (item._id === action.payload._id ? action.payload : item)
      );
      state.loading = false;
      state.Resources.Project_Assigned_Details = updatedAssignProject;
      state.error = "";
    });
    builder.addCase(updateAssignProject.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Project_Assigned_Details = [];
      state.error = action.error.message;
    });

    // deleteProject
    builder.addCase(deleteAssignProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAssignProject.fulfilled, (state, action) => {
      const currentAssignProject =
        state.Resources.Project_Assigned_Details.filter(
          (item) => item._id !== action.payload._id
        );
      state.loading = false;
      state.Resources.Project_Assigned_Details = currentAssignProject;
      state.error = "";
    });
    builder.addCase(deleteAssignProject.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Project_Assigned_Details = [];
      state.error = action.error.message;
    });

    // // Projects
    builder.addCase(getAllProjectData.pending, (state) => {
      state.Resources.project.loading = true;
    });
    builder.addCase(getAllProjectData.fulfilled, (state, action) => {
      state.Resources.project.loading = false;
      state.Resources.project.data = action.payload?.projects || [];
      state.Resources.project.totalPages = action.payload?.totalPages;
      state.Resources.project.totalItems = action.payload?.totalItems;
      state.error = "";
    });
    builder.addCase(getAllProjectData.rejected, (state, action) => {
      state.Resources.project.loading = false;
      state.Resources.project.data = [];
      state.error = action.error.message;
    });

    // addProject
    builder.addCase(addProjectCard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addProjectCard.fulfilled, (state, action) => {
      state.loading = false;
      state.Resources.Project_Details.push(action.payload);
      state.error = "";
    });
    builder.addCase(addProjectCard.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Project_Details = [];
      state.error = action.error.message;
    });

    // updateProject

    builder.addCase(updateProjectCard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProjectCard.fulfilled, (state, action) => {
      const updatedProject = state.Resources.Project_Details.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      state.loading = false;
      state.Resources.Project_Details = updatedProject;
      state.error = "";
    });
    builder.addCase(updateProjectCard.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Project_Details = [];
      state.error = action.error.message;
    });

    // deleteProject

    builder.addCase(deleteProjectCard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteProjectCard.fulfilled, (state, action) => {
      const currentProjectCards = state.Resources.Project_Details.filter(
        (item) => item._id !== action.payload._id
      );
      state.loading = false;
      state.Resources.Project_Details = currentProjectCards;
      state.error = "";
    });
    builder.addCase(deleteProjectCard.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Project_Details = [];
      state.error = action.error.message;
    });

    // // Users
    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addUser.fulfilled, (state, action) => {
      state.loading = false;
      state.Resources.Users_Details.push(action.payload);
      state.error = "";
    });

    builder.addCase(addUser.rejected, (state, action) => {
      state.loading = false;
      if (action.payload && action.payload.status === 409) {
        state.Resources.Users_Details = [];
        state.error = {
          message: action.payload.data.message,
          status: action.payload.status,
        };
      } else {
        state.error = action.payload;
      }
    });

    // updateUser
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const index = state.Resources.Users_Details.findIndex(
        (user) => user._id === action.payload._id
      );
      if (index !== -1) {
        state.Resources.Users_Details[index] = action.payload;
      }
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      if (action.payload && action.payload.status === 409) {
        state.error = {
          message: action.payload.data.message,
          status: action.payload.status,
        };
      } else {
        state.error = action.payload;
      }
    });

    // deleteUser
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const currentUsers = state.Resources.Users_Details.filter(
        (item) => item._id !== action.payload._id
      );
      state.loading = false;
      state.Resources.Users_Details = currentUsers;
      state.error = "";
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Users_Details = [];
      state.error = action.error.message;
    });

    // // Job
    builder.addCase(addJobCard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addJobCard.fulfilled, (state, action) => {
      state.loading = false;
      state.Resources.job.push(action.payload);
      state.error = "";
    });
    builder.addCase(addJobCard.rejected, (state, action) => {
      state.loading = false;
      state.Resources.job = [];
      state.error = action.error.message;
    });

    // getAllJobs
    builder.addCase(getAllJobData.pending, (state) => {
      state.Resources.job.loading = true;
    });
    builder.addCase(getAllJobData.fulfilled, (state, action) => {
      state.Resources.job.loading = false;
      state.Resources.job.data = action.payload?.jobs || [];
      state.Resources.job.totalPages = action.payload?.totalPages;
      state.Resources.job.totalItems = action.payload?.totalItems;
      state.error = "";
    });
    builder.addCase(getAllJobData.rejected, (state, action) => {
      state.Resources.job.loading = false;
      state.Resources.job.data = [];
      state.error = action.error.message;
    });
    // updateJob

    builder.addCase(updateJobCard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateJobCard.fulfilled, (state, action) => {
      const updatedJobCard = state.Resources.job.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      state.loading = false;
      state.Resources.job = updatedJobCard;
      state.error = "";
    });
    builder.addCase(updateJobCard.rejected, (state, action) => {
      state.loading = false;
      state.Resources.job = [];
      state.error = action.error.message;
    });

    // deleteJob

    builder.addCase(deleteJobCard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteJobCard.fulfilled, (state, action) => {
      const currentJobCards = state.Resources.job.filter(
        (item) => item._id !== action.payload._id
      );
      state.loading = false;
      state.Resources.job = currentJobCards;
      state.error = "";
    });
    builder.addCase(deleteJobCard.rejected, (state, action) => {
      state.loading = false;
      state.Resources.job = [];
      state.error = action.error.message;
    });

    // // Job Application
    builder.addCase(getAllApplications.pending, (state) => {
      state.Resources.application.loading = true;
    });
    builder.addCase(getAllApplications.fulfilled, (state, action) => {
      state.Resources.application.loading = false;
      state.Resources.application.data = action.payload?.data || [];
      state.Resources.application.totalPages = action.payload?.totalPages;
      state.Resources.application.totalItems = action.payload?.totalItems;
      state.error = "";
    });
    builder.addCase(getAllApplications.rejected, (state, action) => {
      state.Resources.application.loading = false;
      state.Resources.application.data = [];
      state.error = action.error.message;
    });

    // get all Jobs
    builder.addCase(getActiveJobs.pending, (state) => { });
    builder.addCase(getActiveJobs.fulfilled, (state, action) => {
      state.Resources.application.activeJobs = action.payload?.jobs || [];
    });
    builder.addCase(getActiveJobs.rejected, (state, action) => {
      state.Resources.application.activeJobs = [];
    });

    // addJobApplication
    builder.addCase(addJobApplication.pending, (state) => { });
    builder.addCase(addJobApplication.fulfilled, (state, action) => { });
    builder.addCase(addJobApplication.rejected, (state, action) => { });
    builder.addCase(updateJobApplication.pending, (state) => { });
    builder.addCase(updateJobApplication.fulfilled, (state, action) => { });
    builder.addCase(updateJobApplication.rejected, (state, action) => { });
    builder.addCase(deleteJobApplication.pending, (state) => { });
    builder.addCase(deleteJobApplication.fulfilled, (state, action) => { });
    builder.addCase(deleteJobApplication.rejected, (state, action) => { });

    //Notice
    builder.addCase(addNoticeData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addNoticeData.fulfilled, (state, action) => {
      const noticeData = state.Resources.Notice_Details.filter(
        (item) => item._id !== action.payload._id
      );
      state.loading = false;
      state.Resources.Notice_Details = noticeData;
      state.error = "";
    });
    builder.addCase(addNoticeData.rejected, (state, action) => {
      state.loading = false;
      state.Resources.Notice_Details = [];
      state.error = action.error.message;
    });
    builder.addCase(updateConfigData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateConfigData.fulfilled, (state, action) => {
      const configData = state.Resources.ConfigData_Details.filter(
        (item) => item._id !== action.payload._id
      );
      state.loading = false;
      state.Resources.ConfigData_Details = configData;
      state.error = "";
    });
    builder.addCase(updateConfigData.rejected, (state, action) => {
      state.loading = false;
      state.Resources.ConfigData_Details = [];
      state.error = action.error.message;
    });

    builder.addCase(getloginlogs.pending, (state) => {
      state.Resources.LoginLogs.loading = true;
    });
    builder.addCase(getloginlogs.fulfilled, (state, action) => {
      state.Resources.LoginLogs.loading = false;
      state.Resources.LoginLogs.data = action.payload?.auditLogs || [];
      state.Resources.LoginLogs.totalPages = action.payload?.totalPages;
      state.Resources.LoginLogs.totalItems = action.payload?.totalItems;
      state.error = "";
    });
    builder.addCase(getloginlogs.rejected, (state, action) => {
      state.Resources.LoginLogs.loading = false;
      state.Resources.LoginLogs.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getUsersList.pending, (state) => {
      state.Resources.BlockuserList.loading = true;
    });
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      state.Resources.BlockuserList.loading = false;
      state.Resources.BlockuserList.data = action.payload?.auditLogs || [];
      state.Resources.BlockuserList.totalPages = action.payload?.totalPages;
      state.Resources.BlockuserList.totalItems = action.payload?.totalItems;
      state.error = "";
    });
    builder.addCase(getUsersList.rejected, (state, action) => {
      state.loading = false;
      state.Resources.BlockuserList = [];
      state.error = action.error.message;
    });

    builder.addCase(updateBlockedStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBlockedStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateBlockedStatus.rejected, (state, action) => {
      state.loading = false;
      state.Resources.LoginLogs = [];
      state.error = action.error.message;
    });
  },
});
export const { tabIndex, setSelectedRegion, clearSelectedRegion } =
  dataSlice.actions;
export default dataSlice.reducer;
