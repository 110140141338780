import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const YearWisePopulation = () => {
    const Insights =
        useSelector((state) => state?.Data?.Resources?.Insights?.data) || [];

    const [categories] = useState(
        Array.from(Insights)[0]?.yearwisePopulation?.map((i, index) => i.year) || []
    );

    const [series] = useState([
        {
            data:
                Array.from(Insights)[0]?.yearwisePopulation?.map(
                    (i) => i.employeeCount
                ) || [],
        },
    ]);

    const [options] = useState({
        chart: {
            type: "bar",
            height: 272,
            toolbar: {
                show: false,
            },
            // background: "#ffffff", // Set chart background to white
        },
        plotOptions: {
            bar: {
                horizontal: false, // Vertical bars
                columnWidth: "70%", // Width of bars
            },
        },
        dataLabels: {
            enabled: true, // Show data labels
        },
        xaxis: {
            categories: categories,
            title: {
                text: "Year",
            },
        },
        yaxis: {
            title: {
                text: "Number of Employees",
            },
            min: 0, // Start y-axis at 0
        },
        grid: {
            borderColor: "#e0e0e0", // Light grey grid lines
        },
        fill: {
            colors: ["#1E90FF"], // Color of the bars
        },
        tooltip: {
            theme: "dark", // Dark theme for tooltip
        },
    });

    return (
        <div className="bar-chart"style={{ width: '324px', height: '272px' }} >
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                width={324} // Set chart width
                height={272} // Set chart height
            />
        </div>
    );
};

export default YearWisePopulation;
