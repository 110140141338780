import React from 'react'

const ConfigIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="24" fill="url(#pattern0_27_168)" />
      <defs>
        <pattern
          id="pattern0_27_168"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_27_168" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_27_168"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADqElEQVR4nO3cz0sWQRzH8THJxLQsKAo61iUlCOrg1fo3jLplmVLZJTsE0q0fUgSR0dnOQpfqJHXOX4fOHcoOldrBUnvH4AQP4bO7M7vbzO58X/CAPMLM7Hx0Z3Z2dpUSTQG3gA2a078ba16CyAWYI91svlpEU8D3DAF8a16CcAZ0kd0e95rEtoBeiwB6ty9FWNN/zcAI8NEigCXgNrDfvsbIAKeBF8AFoL3h+xbgMrCCuxVTRktDue2mLl3naRUzoA340NBhX4Bx4AQwTXGmTZnjpo6/dN1tKlbADfwbVTECDmacVpZNn6YOq9gAzwjHpIoJcBLYJByb0QzIbM1uZnJ01pyZlvYAu82nx3w3n6PcmcbZUm0BfY4dtAYMAjsSym41086fjnX0qboDuoFFy47RHdpvUUe/Qwi6TXtVDIAO4LlF5ww61DFkUb5uS0eeNZIJYAH4gX9nLNo+AKxmOOe3OvRLq+mTJLruAetOb7iKfBzYbEI7ankc70g24tRBW2VfTSn7bZ7Of0OYui2PRS+cJTle4grqJ9eC9V9+qHZaHkvaYNlV4j2ENddUk+6LVi2AtaoFoAfckHXX+hTkMIf+347VehDOeYOiitPQ+RzT0MXCp6FpR6/qeSE25FDHcCkXYlUPAPelCJv/rLPAL8s6FjONXzUIoM+yYxpDuJJ0OjKnnWGHzs++GJdWgqr/cvSCGVz1DKfTfPTP13JOULItR6eVoqpzQ2aDcOjlnFNZG59IVQQwSTie2jQ8kaoIwropf8im4YlUhQCj+HfdttGJVLU3Zi0FvzGrTgFoevADpoDzwK6CtyYuA5eabE2cyjzw1jmAjCuatptzP5vNuftU0WILILjt6REH0GkRQFeZDYkyAE0/fpSh87+qMkUewGyGAN6X3YiYAxjL8JjqzbIbEW0AQZAAPJMAPJMAPJMAPJMA3ABHgDtFFCSzoIyAA8A54CWwXsgsUQJIBlwEnugLMuB34dN0CSBZ2nZ9lZcEkEwC8EwC8EwC8EwC8EwC8EwC8EwC8EwC8EwC8EwC8EwC8EwC8EwC8EwCiCCA0B/UDtlyEQGE/qqCkM0XEcAD30dRYXeLCKAnsEc8q2Ijz5tX/g3hke+jqaCJQjq/4eG2176PqEJe2b5IKmsID+V0lGjdvOCq2M7fZky4b96rk/bunRismr6453TO99362EkAnkkAnkkAnkkAnkkAnkkAnkkAnpVysSaEEEIIIYQQQgghhBBCCBWSPw7pTuRee6TIAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default ConfigIcon;

 