import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "78731b3c-897e-40c0-8b4b-3331a19d8aca", // This is mandatory field. Add Application (client) ID here.
    authority:
      "https://login.microsoftonline.com/netorgft10794464.onmicrosoft.com/", // Add Tenant subdomain
    // This redirectUri, postLogoutRedirectUri URL should be same while registering app in Entra ID
    // redirectUri: "http://localhost:3000",
    // postLogoutRedirectUri: "http://localhost:3000",
    redirectUri: "https://aifa360.aifalabs.com/",
    postLogoutRedirectUri: "https://aifa360.aifalabs.com/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [],
};
