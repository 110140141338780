import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchData,
  updateEmployeeCard,
  addEmployeeCard,
  deleteEmployeeCard,
  getEmployeeData,
  getAllEmployeeData,
  getAllEmployeeByRegion,
} from "../../../redux/dataSlice";
import TextField from "@mui/material/TextField";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import validator from "validator";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ToastContainer, toast } from "react-toastify";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { MenuItem } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { setSelectedRegion } from "../../../redux/regionSlice";
import ArticleIcon from "@mui/icons-material/Article";
import PhoneInput from "react-phone-input-2";
import { _objectToFormData } from "../../../utills/commonUtills";
import "react-phone-input-2/lib/style.css";

const EmployeeForm = ({ editMode = false }) => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state?.Data);
  const employees = useSelector(
    (state) => state?.Data?.Resources?.employee?.data
  );
  console.log(employees, "employees from store");

  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  console.log("selected regions from employee form", selectedRegion);
  const genderData = ["Male", "Female", "Other"];
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const [submiting, setSubmiting] = useState(false);
  const navigate = useNavigate();
  const { empid } = useParams();
  const [isCompanyEmailValid, setIsCompanyEmailValid] = useState(true);
  const [isPersonalEmailValid, setIsPersonalEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isPictureValid, setIsPictureValid] = useState("");
  const [isResumeValid, setIsResumeValid] = useState("");
  const [errors, setErrors] = useState({});
  const ConfigData = useSelector(
    (state) => state?.Data?.Resources?.ConfigData_Details
  );

  useEffect(() => {
    if (editMode) {
      dispatch(getEmployeeData(empid))
        .then((response) => {
          if (response?.payload?.reportsTo?._id) {
            console.log(
              "response in formData in employeeform",
              response.payload
            );
            const temp = {
              label: `${response?.payload?.reportsTo?.FirstName} ${response?.payload?.reportsTo?.LastName}`,
              value: response?.payload?.reportsTo?._id,
            };
            setFormData({ ...response?.payload, reportsTo: temp });
          } else {
            setFormData(response.payload);
          }
          if (
            response?.payload?.presentAddress ===
            response?.payload?.permanentAddress
          ) {
            setIsSameAddress(true);
          }
        })
        .catch((error) => {
          // handle error
        });
    } else {
      if (selectedRegion) {
        setFormData((prevData) => ({
          ...prevData,
          region: selectedRegion?._id,
        }));
      }
    }
  }, [empid, editMode, dispatch, selectedRegion]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      employmentStatus: "Active",
    }));
  }, []);

  var data;

  if (Data.Resources !== undefined) {
    data = Data?.Resources?.Employee_Details;
  }

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "orange",
        primary: "green",
      },
    };
  }

  const [isFocused, setIsFocused] = useState(false);

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phoneNumber: phone });
    // Simple validation: check if the phone number has at least 10 digits
    setIsPhoneValid(phone.replace(/\D/g, "").length >= 10);
  };

  function handleEmailChange(event, emailType) {
    const emailValue = event.target.value;
    let isEmailValid = emailValue === "" || validator.isEmail(emailValue);

    if (emailType === "personal") {
      setIsPersonalEmailValid(isEmailValid);
    } else if (emailType === "company") {
      setIsCompanyEmailValid(isEmailValid);
    }

    handleDetailsChange(event);
  }

  function uniqByKeepLast(each, key) {
    return [...new Map(each.map((x) => [key(x), x])).values()];
  }

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateFormOnChange(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleDetailsChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  function downloadFile(url, fileName) {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleOptionChange = (event) => {
    setFormData({
      ...formData,
      Offshore: event.target.checked,
    });
  };
  const handleEmploymentStatusChange = (event) => {
    setFormData({
      ...formData,
      employmentStatus: event.target.checked ? "Active" : "In-Active",
    });
  };
  const fileUpload = (e) => {
    if (e.target.name == "Picture") {
      const file = e.target.files[0];
      const fileSizeLimit = 500 * 1024; // 500 KB in bytes

      if (file && file.size > fileSizeLimit) {
        setIsPictureValid("File size should be less than 500 KB");
        setFormData({ ...formData, Picture: null });
      } else {
        setIsPictureValid("");
        setFormData({ ...formData, Picture: file });
      }
    } else if (e.target.name == "Resume") {
      const file = e.target.files[0];
      const fileSizeLimit = 1 * 1024 * 1024; // 500 KB in bytes

      if (file && file.size > fileSizeLimit) {
        setIsResumeValid("File size should be less than 1 MB");
        setFormData({ ...formData, Resume: null });
      } else {
        setIsResumeValid("");
        setFormData({ ...formData, Resume: file });
      }
    } else
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.files[0],
      }));
  };

  const validateForm = (formData) => {
    let errors = {};

    // Validate required text fields
    if (!formData?.FirstName?.trim()) {
      errors.FirstName = "First Name is required";
    }
    if (!formData?.LastName?.trim()) {
      errors.LastName = "Last Name is required";
    }
    if (!formData?.ID?.trim()) {
      errors.ID = "Employee ID is required";
    } else if (!formData?.ID?.trim().toUpperCase().startsWith("AL")) {
      //ensure that user entering the EMPID Correclty
      errors.ID = "Enter the valid ID Starts with ALXXX";
    }
    if (!formData?.experienceYears) {
      errors.experienceYears = "Experience (Years) is required";
    }
    if (!formData?.experienceMonths && formData?.experienceMonths !== 0) {
      // Experience in months can be zero or a positive integer.
      errors.experienceMonths = "Experience (Months) is required";
    }

    // Validate email formats
    if (!isValidEmail(formData?.email, "aifalabs.com")) {
      errors.email = "Invalid company email format";
    }
    if (!isValidEmail(formData?.personalEmail)) {
      errors.personalEmail = "Invalid personal email format";
    }

    // Validate phone number
    if (!formData?.phoneNumber || !isValidPhone(formData?.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phone number";
    }

    // Validate date fields
    if (!formData?.DOB || !isValidDate(formData.DOB)) {
      errors.DOB = "Date of Birth is required";
    } else if (formData.DOJ && isValidDate(formData.DOJ)) {
      if (formData.DOB >= formData.DOJ) {
        errors.DOB = "Date of Birth should be earlier than Date of Joining.";
      }
    }
    if (!formData?.DOJ || !isValidDate(formData.DOJ)) {
      errors.DOJ = "Date of Joining is required";
    }

    if (!formData?.Skillset || formData?.Skillset?.length === 0) {
      errors.Skillset = "Core Skills is required";
    }

    // Validate address
    if (!formData?.presentAddress?.trim()) {
      errors.presentAddress = "Present Address is required";
    }
    if (!formData?.permanentAddress?.trim() && !isSameAddress) {
      errors.permanentAddress = "Permanent Address is required";
    }
    if (!formData?.HighestQualification?.trim()) {
      errors.HighestQualification = "Education is required";
    }
    if (!formData?.BloodGroup?.trim()) {
      errors.BloodGroup = "Blood Group is required";
    }
    if (!formData?.role) {
      errors.role = "Role is required";
    }

    if (!formData?.gender) {
      errors.gender = "Gender is required";
    }
    if (!formData?.Department) {
      errors.Department = "Department is required";
    }
    if (!formData?.employmentType) {
      errors.employmentType = "Employement is required";
    }
    if (!formData?.Designation) {
      errors.Designation = "Designation is required";
    }

    // Additional validations can be added similarly...

    return errors;
  };
  const validateFormOnChange = (fieldName, value) => {
    let error = "";

    switch (fieldName) {
      case "FirstName":
        if (!value.trim()) {
          error = "First Name is required";
        }
        break;
      case "LastName":
        if (!value.trim()) {
          error = "Last Name is required";
        }
        break;
      case "ID":
        if (!value.trim()) {
          error = "Employee ID is required";
        } else if (!value.trim().toUpperCase().startsWith("AL")) {
          error = "Enter the valid ID Starts with ALXXX";
        }
        break;
      case "experienceYears":
        if (!value.trim()) {
          error = "Experience (Years) is required";
        }
        break;
      case "experienceMonths":
        if (!value.trim()) {
          error = "Experience (Months) is required";
        }
        break;
      case "email":
        if (!isValidEmail(value, "aifalabs.com")) {
          error = "Invalid company email format";
        }
        break;
      case "personalEmail":
        if (!isValidEmail(value)) {
          error = "Invalid personal email format";
        }
        break;
      case "phoneNumber":
        if (!value || !isValidPhone(value)) {
          error = "Please enter a valid phone number";
        }
        break;
      case "DOB":
        if (!value || !isValidDate(value)) {
          error = "Date of Birth is required";
        }
        // error for dob greter then doj
        else if (formData.DOJ && isValidDate(formData.DOJ)) {
          if (value >= formData.DOJ) {
            error = "Date of Birth should be earlier than Date of Joining.";
          }
        }
        break;
      case "DOJ":
        if (!value || !isValidDate(value)) {
          error = "Date of Joining is required";
        }
        break;
      case "Skillset":
        if (!value || value.length === 0) {
          error = "Core Skills is required";
        }
        break;
      case "presentAddress":
        if (!value.trim()) {
          error = "Present Address is required";
        }
        break;
      case "permanentAddress":
        if (!value.trim()) {
          error = "Permanent Address is required onblur";
        }
        break;
      case "HighestQualification":
        if (!value.trim()) {
          error = "Education is required";
        }
        break;
      case "BloodGroup":
        if (!value.trim()) {
          error = "Blood Group is required";
        }
        break;
      case "role":
        if (!value.trim()) {
          error = "Role is required";
        }
        break;
      case "gender":
        if (!value.trim()) {
          error = "Gender is required";
        }
        break;
      case "Department":
        if (!value.trim()) {
          error = "Department is required";
        }
        break;
      case "employmentType":
        if (!value.trim()) {
          error = "Employement is required";
        }
        break;
      case "Designation":
        if (!value.trim()) {
          error = "Designation is required";
        }
        break;
      // Add other fields as needed
      default:
        break;
    }

    return error;
  };
  useEffect(() => {
    if (isSameAddress) {
      setErrors({
        ...errors,
        permanentAddress: "",
      });
    } else if (formData.permanentAddress === "") {
      setErrors({
        ...errors,
        permanentAddress: "Permanent Address is required",
      });
    }
  }, [isSameAddress]);

  const isValidEmail = (email, domain) => {
    // Check if the email is valid and matches the optional domain if provided
    const re = /\S+@\S+\.\S+/;
    return re.test(email) && (!domain || email.endsWith(`@${domain}`));
  };

  const isValidPhone = (phoneNumber) => {
    const normalizedPhone = phoneNumber.replace(/\D/g, "");
    if (normalizedPhone.length >= 10) {
      return true;
    }
    return false;
  };

  const isValidDate = (date) => {
    // Validate date format (YYYY-MM-DD)
    return !isNaN(new Date(date).getTime());
  };

  const handleSubmit = async (event) => {
    console.log(formData, "payload");

    event.preventDefault();
    const errors = validateForm(formData);
    setErrors(errors);

    if (Object.keys(errors).length != 0) {
      console.log("errors return", errors);
      // No errors, proceed with form submission
      return;
    }
    setSubmiting(true);
    let finalData = { ...formData };

    if (!editMode && selectedRegion) {
      finalData.region = selectedRegion._id;
    }

    Object.keys(finalData).forEach((key) => {
      if (
        [
          "role",
          "gender",
          "Department",
          "employmentType",
          "Designation",
          "reportsTo",
          "region",
        ].includes(key) &&
        typeof finalData[key] == "object"
      ) {
        finalData[key] = finalData[key]?.value;
      }
    });
    try {
      let response;
      if (editMode) {
        response = await dispatch(updateEmployeeCard(finalData));
        // .then(
        //   (response) => {
        //     if (response?.data?.statusCode === 401) {
        //       navigate("/session-expired");
        //     }
        //   }
        // );
      } else {
        console.log("create clicked..");
        response = await dispatch(
          addEmployeeCard(_objectToFormData(finalData))
          // .then((response) => {
          //   if (response?.data?.statusCode === 401) {
          //     navigate("/session-expired");
          //   }
          // })
        );
      }
      setSubmiting(false);
      if (!response?.error) {
        setTimeout(() => {
          navigate("/employee", {
            state: { headerText: "Employee" }, // State must be an object
          });
        }, 2000);
      }
    } catch (error) {
      setSubmiting(false);
      // notifyError(error.message);
    }
  };

  function handleSelectChange(selectedOption, selectedName) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [selectedName]: selectedOption,
      };
    });
    const error = validateFormOnChange({
      target: { name: selectedName, value: selectedOption },
    });
    setErrors((prevErrors) => ({ ...prevErrors, [selectedName]: error }));
  }

  function handleMultiSelectChange(selectedOption, selectedName) {
    setFormData({
      ...formData,
      [selectedName]: selectedOption.map((item) => item?.value),
    });
    const error = validateFormOnChange({
      target: { name: selectedName, value: selectedOption },
    });
    console.log("eror=====>1",selectedName,selectedOption)
    setErrors((prevErrors) => ({ ...prevErrors, [selectedName]: error }));
  }

  const handleDateChange = (fieldName, fieldValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: fieldValue,
    }));
  };
  const handleClick = () => {
    navigate("/employee", {
      state: { headerText: "Employee" }, // State you want to pass
    });
  };
  const handleCheckboxChange = () => {
    setIsSameAddress(!isSameAddress);
    if (!isSameAddress) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        permanentAddress: prevFormData.presentAddress,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        permanentAddress: "",
      }));
    }
  };
  const getValues = (item) => {
    if (!item) return item;
    if (typeof item == "string") return { value: item, label: item };
    if (Array.isArray(item))
      return item.map((v) => {
        return { value: v, label: v };
      });
    return item;
  };

  function MultiSelectDropdown(data, opt, key) {
    const optionList = [];

    if (data !== undefined) {
      opt.forEach((each) => {
        each.map((e) => {
          optionList.push({ value: e.name, label: e.name });
          return null; // Add a return statement here
        });
      });
    }

    const uniq_options = uniqByKeepLast(optionList, (o) => o.value);

    return key === "Skillset" || key === "Projects" ? (
      <Select
        components={makeAnimated()}
        theme={customTheme}
        options={uniq_options}
        onChange={(selectedOption) => {
          handleMultiSelectChange(selectedOption, key);
          setIsFocused(true);
        }}
        value={
          (key === "Skillset" && getValues(formData?.Skillset)) ||
          (key === "Skillset" && getValues(formData?.Skillset))
        }
        className="employee-multi-select"
        placeholder="Select"
        isSearchable
        isMulti
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        onMenuClose={() => {
          // Inline blur handling
          if (isFocused) {
            const value = getValues(formData[key]);
            const error = validateFormOnChange(key, value);
            setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
            setIsFocused(false);
          }
        }}
        required={true}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            "@media (min-width: 1440px) and (max-width: 2560px)": {
              padding: 4,
            },
            "@media (min-width: 2560px)": {
              padding: 19,
              fontSize: 30,
            },
          }),
        }}
        // isDisabled={isReadOnly}
      />
    ) : (
      <Select
        components={makeAnimated()}
        theme={customTheme}
        options={uniq_options}
        onChange={(selectedOption) => handleSelectChange(selectedOption, key)}
        className="employee-multi-select"
        placeholder="Select"
        isSearchable
        required={true}
        name={key}
        onBlur={handleBlur}
        value={
          (key === "role" && getValues(formData?.role)) ||
          (key === "gender" && getValues(formData.gender)) ||
          (key === "Department" && getValues(formData?.Department)) ||
          (key === "employmentType" && getValues(formData?.employmentType)) ||
          (key === "Designation" && getValues(formData?.Designation)) ||
          (key === "experience" && getValues(formData?.experience))
        }
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            "@media (min-width: 1440px) and (max-width: 2560px)": {
              padding: 4,
            },
            "@media (min-width: 2560px)": {
              padding: 18,
              fontSize: 30,
            },
          }),
        }}
        // isDisabled={isReadOnly}
      />
    );
  }
  // useEffect(() => {
  //   if (!(employees && employees.length)) {
  //     // if (selectedRegion && selectedRegion?._id) {
  //     dispatch(getAllEmployeeData(null, { navigate })).then((response) => {
  //       if (response?.data?.statusCode === 401) {
  //         navigate("/session-expired");
  //       }
  //     });
  //     // dispatch(getAllEmployeeByRegion({ id: selectedRegion._id }));
  //   } else {
  //     setEmployeeOptions(
  //       employees.map((emp) => {
  //         return {
  //           label: `${emp?.FirstName ? emp.FirstName : ""} ${
  //             emp?.LastName ? emp.LastName : ""
  //           }`,
  //           value: emp._id,
  //         };
  //       })
  //     );
  //   }
  // }, [employees, selectedRegion]);
  useEffect(() => {
    if (selectedRegion && selectedRegion?._id) {
      dispatch(getAllEmployeeByRegion({ id: selectedRegion?._id })).then(
        (response) => {
          console.log("response from dropdown", response);
          // if (response?.data?.statusCode === 401) {
          //   navigate("/session-expired");
          // } else {
          setEmployeeOptions(
            response.payload.map((emp) => {
              return {
                label: `${emp?.FirstName ? emp.FirstName : ""} ${
                  emp?.LastName ? emp.LastName : ""
                }`,
                value: emp._id,
              };
            })
          );
          // }
        }
      );
    }
  }, [selectedRegion, dispatch]);
  const GetProfilePreview = () => {
    if (formData?.Picture?.creationAttachment?.public_id) {
      return (
        <div
          onClick={() => {
            downloadFile(
              formData?.Picture?.creationAttachment?.url,
              formData?.Picture?.creationAttachment?.public_id
            );
          }}
          className="preview-image-container"
        >
          <img
            src={formData?.Picture?.creationAttachment?.url}
            alt="Preview"
            className="preview-image"
          />
          <span>{formData?.Picture?.creationAttachment?.public_id}</span>
        </div>
      );
    } else if (formData?.Picture?.name) {
      return (
        <div className="preview-image-container">
          <img
            src={
              typeof formData.Picture == "string"
                ? formData.Picture
                : URL.createObjectURL(formData.Picture)
            }
            alt="Preview"
            className="preview-image"
          />
          <span>{formData?.Picture?.name}</span>
        </div>
      );
    } else return <></>;
  };
  const GetResumePreview = () => {
    if (formData?.Resume?.creationAttachment?.public_id) {
      return (
        <div
          onClick={() => {
            downloadFile(
              formData?.Resume?.creationAttachment?.url,
              formData?.Resume?.creationAttachment?.public_id
            );
          }}
          className="preview-image-container"
        >
          <ArticleIcon
            style={{ fontSize: "3rem", color: "skyblue" }}
            className="preview-image"
          />
          <span>{formData?.Resume?.creationAttachment?.public_id}</span>
        </div>
      );
    } else if (formData?.Resume?.name) {
      return (
        <div className="preview-image-container">
          <ArticleIcon
            style={{ fontSize: "3rem", color: "skyblue" }}
            className="preview-image"
          />
          <span>{formData?.Resume?.name}</span>
        </div>
      );
    } else return <></>;
  };
  return (
    <>
      <div className="employee-form-container">
        <div className="heading-close-container">
          <h1 className="application-heading">Employee Details</h1>
          <div className="close-container">
            {/* <Link to="/employee">
              <AiOutlineClose className="back-option" />
            </Link> */}
            <AiOutlineClose className="back-option" onClick={handleClick} />
          </div>
        </div>

        <form className="employee-form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="employee-name-label-card">
                <label htmlFor="FirstName">
                  First Name <span className="required-star">*</span>
                </label>
                <input
                  type="text"
                  name="FirstName"
                  className="employee-input"
                  placeholder="Enter your First Name"
                  value={formData.FirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  // disabled={isReadOnly}
                />
                {errors.FirstName && (
                  <span className="error-text">{errors.FirstName}</span>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="employee-name-label-card">
                <label htmlFor="LastName">
                  Last Name <span className="required-star">*</span>{" "}
                </label>
                <input
                  type="text"
                  name="LastName"
                  className="employee-input"
                  placeholder="Enter your Last Name"
                  value={formData.LastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  // disabled={isReadOnly}
                />
                {errors.LastName && (
                  <span className="error-text">{errors.LastName}</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="ID">
                      Employee Id <span className="required-star">*</span>{" "}
                    </label>
                    <input
                      className="employee-input"
                      type="text"
                      name="ID"
                      placeholder="Enter your Emp Id"
                      value={formData.ID}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={editMode} // While Updating the Employee Details EmployeeID should be disabled
                      // disabled={isReadOnly}
                    />
                    {errors.ID && (
                      <span className="error-text">{errors.ID}</span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="role">
                      Role <span className="required-star">*</span>
                    </label>
                    {MultiSelectDropdown(
                      data,
                      ConfigData.map((each) => each.Role),
                      "role"
                    )}
                    {errors.role && (
                      <span className="error-text">{errors.role}</span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card ">
                    <label htmlFor="DOJ">
                      Date of Joining <span className="required-star">*</span>
                    </label>
                    <TextField
                      id="date"
                      type="date"
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        maxWidth: 505,
                        "@media (max-width: 600px)": {
                          maxWidth: 400,
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          maxWidth: 250,
                        },
                        "@media (min-width: 961px) and (max-width: 2560px)": {
                          maxWidth: "100vw",
                        },
                      }}
                      min="2022-06-01"
                      max="2050-01-01"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="DOJ"
                      // disabled={isReadOnly}
                      value={formData?.DOJ?.slice(0, 10)}
                      onChange={(e) => handleDateChange("DOJ", e.target.value)}
                      onBlur={handleBlur}
                    />
                    {errors.DOJ && (
                      <span className="error-text">{errors.DOJ}</span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="DOB">
                      Date of Birth <span className="required-star">*</span>
                    </label>
                    <TextField
                      id="date"
                      type="date"
                      sx={{
                        width: "100%",
                        maxWidth: 505,
                        backgroundColor: "white",
                        "@media (max-width: 600px)": {
                          maxWidth: 400,
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          maxWidth: 250,
                        },
                        "@media (min-width: 961px) and (max-width: 2560px)": {
                          maxWidth: "100vw",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="DOB"
                      // disabled={isReadOnly}
                      value={formData?.DOB?.slice(0, 10)}
                      onChange={(e) => handleDateChange("DOB", e.target.value)}
                      onBlur={handleBlur}
                    />
                    {errors.DOB && (
                      <span className="error-text">{errors.DOB}</span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="gender">
                      Gender <span className="required-star">*</span>
                    </label>
                    {MultiSelectDropdown(
                      genderData,
                      ConfigData.map((each) => each.Gender),
                      "gender"
                    )}
                    {errors.gender && (
                      <span className="error-text">{errors.gender}</span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card ">
                    <label htmlFor="Designation">
                      Designation <span className="required-star">*</span>
                    </label>
                    {MultiSelectDropdown(
                      data,
                      ConfigData.map((each) => each.Designation),
                      "Designation"
                    )}
                    {errors.Designation && (
                      <span className="error-text">{errors.Designation}</span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="Department">
                      Department <span className="required-star">*</span>
                    </label>
                    {MultiSelectDropdown(
                      data,
                      ConfigData.map((each) => each.Department),
                      "Department"
                    )}
                    {errors.Department && (
                      <span className="error-text">{errors.Department}</span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="employee-label-card">
                    <label htmlFor="experience">
                      Experience(Years) <span className="required-star">*</span>
                    </label>
                    <input
                      type="text"
                      name="experienceYears"
                      className="employee-input"
                      placeholder="Years"
                      onBlur={handleBlur}
                      value={formData.experienceYears}
                      onChange={handleChange}
                      required
                      pattern="\d*" // Allow only digits
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace non-digits with an empty string
                      }}
                    />
                  </div>
                  {errors.experienceYears && (
                    <span className="error-text">{errors.experienceYears}</span>
                  )}
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="employee-label-card">
                    <label htmlFor="experience">
                      Experience(Months){" "}
                      <span className="required-star">*</span>
                    </label>
                    <input
                      type="text"
                      name="experienceMonths"
                      className="employee-input"
                      placeholder="Months"
                      value={formData.experienceMonths}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      pattern="\b([0-9]|1[0-1])\b"
                      onInput={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, "");
                        if (value > 11) {
                          e.target.value = value.slice(0, -1);
                        } else {
                          e.target.value = value;
                        }
                      }}
                    />
                    {errors.experienceMonths && (
                      <span className="error-text">
                        {errors.experienceMonths}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="CompanyEmail">
                      Company Email <span className="required-star">*</span>
                    </label>
                    <input
                      className="employee-input lowercase"
                      type="email"
                      name="email"
                      onBlur={handleBlur}
                      placeholder="_______________@aifalabs.com"
                      value={formData.email}
                      onChange={(event) => handleEmailChange(event, "company")}
                      required
                      // disabled={isReadOnly}
                    />
                    {errors.email && (
                      <span className="error-text">{errors.email}</span>
                    )}
                    {/* {!isCompanyEmailValid && (
                        <div style={{ color: "red", fontSize: "13px" }}>
                          Invalid format
                        </div>
                      )} */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="PersonalEmail">
                      Personal Email <span className="required-star">*</span>
                    </label>
                    <input
                      className="employee-input lowercase"
                      type="email"
                      name="personalEmail"
                      onBlur={handleBlur}
                      placeholder="_______________@gmail.com"
                      value={formData.personalEmail}
                      onChange={(event) => handleEmailChange(event, "personal")}
                      // disabled={isReadOnly}
                    />
                    {errors.personalEmail && (
                      <span className="error-text">{errors.personalEmail}</span>
                    )}
                    {/* {!isPersonalEmailValid && (
                        <div style={{ color: "red", fontSize: "13px" }}>
                          Invalid format
                        </div>
                      )} */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label>
                      Contact Number <span className="required-star">*</span>
                    </label>
                    <PhoneInput
                      country={"in"}
                      enableSearch={true}
                      value={formData.phoneNumber}
                      onBlur={handleBlur}
                      onChange={handlePhoneChange}
                      onlyCountries={["us", "in"]}
                      countryCodeEditable={false} // it doesnot allow users to edit country code
                      containerClass="phone-input" // Add a custom class for styling
                      // inputClass={isPhoneValid ? '' : 'phone-input-invalid'} // Conditional styling for invalid input
                      inputProps={{
                        required: true,
                        name: "phoneNumber",
                        // autoFocus: true, // Due to This Property Page will autoScroll to this field
                      }}
                    />
                    {errors.phoneNumber && (
                      <span className="error-text">{errors.phoneNumber}</span>
                    )}
                    {/* {!isPhoneValid && (
                      <div className="error-message">
                        Please enter a valid phone number.
                      </div>
                    )} */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="reportsTo">
                      Reporting To
                      {/* <span className="required-star">*</span> */}
                    </label>
                    <Select
                      components={makeAnimated()}
                      theme={customTheme}
                      options={employeeOptions}
                      value={formData?.reportsTo}
                      onBlur={handleBlur}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "reportsTo")
                      }
                      className="employee-multi-select"
                      placeholder="Select"
                      isSearchable
                      // required={true}
                      // isDisabled={isReadOnly}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          backgroundColor: "white",
                          "@media (min-width: 1440px) and (max-width: 2560px)":
                            {
                              padding: 4,
                            },
                          "@media (min-width: 2560px)": {
                            padding: 18,
                            fontSize: 30,
                          },
                        }),
                      }}
                    />
                    {/* {errors.LastName && <span className="error-text">{errors.LastName}</span>} */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="employmentType">
                      Employment <span className="required-star">*</span>
                    </label>
                    {MultiSelectDropdown(
                      data,
                      ConfigData.map((each) => each.Employment),
                      "employmentType"
                    )}
                    {errors.employmentType && (
                      <span className="error-text">
                        {errors.employmentType}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="CoreSkills">
                      Core Skills <span className="required-star">*</span>
                    </label>
                    {MultiSelectDropdown(
                      data,
                      ConfigData.map((each) => each.Skills),
                      "Skillset"
                    )}
                    {errors.Skillset && (
                      <span className="error-text">{errors.Skillset}</span>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="presentAddress">
                      Present Address <span className="required-star">*</span>
                    </label>
                    <textarea
                      name="presentAddress"
                      className="employee-address-text"
                      placeholder="Enter your Present Address"
                      value={formData.presentAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      style={{
                        width: "100%",
                        minHeight: "100px",
                        resize: "none",
                        backgroundColor: "white",
                      }}
                      // disabled={isReadOnly}
                    />
                    {errors.presentAddress && (
                      <span className="error-text">
                        {errors.presentAddress}
                      </span>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <label htmlFor="permanentAddress">
                          Permanent Address{" "}
                          <span className="required-star">*</span>
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          checked={isSameAddress}
                          onChange={handleCheckboxChange}
                        />
                        Same as present address
                      </div>
                    </div>
                    <textarea
                      name="permanentAddress"
                      className="employee-address-text"
                      placeholder="Enter your Permanent Address"
                      value={formData.permanentAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      style={{
                        width: "100%",
                        minHeight: "100px",
                        resize: "none",
                        backgroundColor: "white",
                      }}
                      disabled={isSameAddress}
                    />
                    {errors.permanentAddress && (
                      <span className="error-text">
                        {errors.permanentAddress}
                      </span>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="Education">
                      Education <span className="required-star">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      name="HighestQualification"
                      className="employee-input"
                      placeholder="Enter your Education"
                      value={formData.HighestQualification}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // disabled={isReadOnly}
                    />
                    {errors.HighestQualification && (
                      <span className="error-text">
                        {errors.HighestQualification}
                      </span>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="BloodGroup">
                      {" "}
                      Blood Group <span className="required-star">*</span>{" "}
                    </label>
                    <input
                      className="employee-input capitals"
                      type="text"
                      name="BloodGroup"
                      placeholder="Enter your Blood Group"
                      value={formData.BloodGroup}
                      onChange={handleDetailsChange}
                      onBlur={handleBlur}
                      // disabled={isReadOnly}
                    />
                    {errors.BloodGroup && (
                      <span className="error-text">{errors.BloodGroup}</span>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card ">
                    <label htmlFor="Photo"> Photo</label>
                    <input
                      // disabled={isReadOnly}
                      id="image"
                      accept=".jpg,.jpeg,.png" // Accept the file Only in Required Formates(extenstions) .jpg,.jpeg,.png
                      type="file"
                      name="Picture"
                      className="employee-input"
                      style={{ margin: "10px 0", width: "100%" }}
                      onChange={fileUpload}
                    />
                    {isPictureValid && (
                      <div className="error-message">{isPictureValid}</div>
                    )}
                    <GetProfilePreview />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div className="employee-label-card">
                    <label htmlFor="Resume">Resume</label>

                    <input
                      //  disabled={isReadOnly}
                      type="file"
                      accept=".pdf,.doc,.docx"
                      name="Resume"
                      className="employee-input"
                      style={{ margin: "10px 0", width: "100%" }}
                      onChange={fileUpload}
                    />
                    {isResumeValid && (
                      <div className="error-message">{isResumeValid}</div>
                    )}
                    <GetResumePreview />
                  </div>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <label>
                    Offshore <span className="required-star">*</span>
                  </label>
                  <FormGroup sx={{ width: "fit-content" }}>
                    {" "}
                    {/* Unwanted behavior of radio button has been restricted */}
                    <FormControlLabel
                      control={
                        <Switch
                          // disabled={isReadOnly}
                          checked={
                            formData?.Offshore && formData?.Offshore == true
                          }
                          onChange={(event) =>
                            handleOptionChange({
                              target: {
                                name: "Offshore",
                                checked: !formData?.Offshore,
                              },
                            })
                          }
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <label>
                    Employment Status <span className="required-star">*</span>
                  </label>
                  <FormGroup sx={{ width: "fit-content" }}>
                    {" "}
                    {/* Unwanted behavior of radio button has been restricted */}
                    <FormControlLabel
                      control={
                        <Switch
                          // disabled={isReadOnly}
                          checked={formData?.employmentStatus === "Active"}
                          onChange={handleEmploymentStatusChange}
                        />
                      }
                      label={formData?.employmentStatus}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <label htmlFor="region">Region</label>
                  <div>
                    {editMode ? (
                      <input
                        type="text"
                        className="form-control"
                        id="region"
                        value={formData.region?.RegionName || ""}
                        style={{
                          height: 40,
                          width: 220,
                          borderRadius: 3,
                          border: "solid gray",
                        }}
                        readOnly
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="region"
                        value={selectedRegion?.name || ""}
                        readOnly
                        style={{
                          height: 40,
                          width: 220,
                          borderRadius: 3,
                          border: "solid gray",
                        }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="employee-Actions-container">
                  <>
                    <button
                      onClick={handleSubmit}
                      disabled={submiting}
                      className="employee-Action-btn"
                      type="submit"
                    >
                      {editMode
                        ? submiting
                          ? "Updating..."
                          : "Update"
                        : submiting
                        ? "Creating..."
                        : "Create"}
                    </button>
                  </>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default EmployeeForm;
