import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import "./header.css"; // Ensure this contains minimal styling if needed

const initialChartData = {
  series: [0, 0], // Placeholder for Billable and Total Employees
  options: {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "No. of Employees",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  },
};

function GraphHeader() {
  const [data, setData] = useState(initialChartData);
  const Insights =
    useSelector((state) => state?.Data?.Resources?.Insights?.data) || [];

  useEffect(() => {
    if (Insights.length > 0) {
      const currentData = Array.from(Insights)[0] || {};
      const billableEmployees = currentData?.billableEmployees || [];

      // Initialize arrays for the different series
      const totalEmployeesData = [];
      const billableCountData = [];
      const nonBillableCountData = [];
      const activeEmployeesData = [];
      const inactiveEmployeesData = [];

      // Populate the arrays with the corresponding values
      billableEmployees.forEach((entry) => {
        totalEmployeesData.push(entry.totalEmployees);
        billableCountData.push(entry.billableCount);
        nonBillableCountData.push(entry.nonBillableCount);
        activeEmployeesData.push(entry.noOfActiveEmployees);
        inactiveEmployeesData.push(entry.noOfInactiveEmployees);
      });

      // Prepare the data in the desired format
      const formattedData = [
        { name: "Total Employees", data: totalEmployeesData },
        { name: "Billable Employees", data: billableCountData },
        // { name: "Non-Billable Employees", data: nonBillableCountData },
        // { name: "Active Employees", data: activeEmployeesData },
        // { name: "Inactive Employees", data: inactiveEmployeesData },
      ];

      // Update chart data dynamically
      setData({
        series: formattedData,
        options: {
          ...initialChartData.options,
          labels: billableEmployees.map((entry) => `Month ${entry.month}`),
        },
      });
    }
  }, [Insights]);

  return (
    <div>
      <div id="chart" style={{width:"341px",height:"263px"}}>
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="bar"
          width={341} 
          height={263} 
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default GraphHeader;
