import React, { useState, useEffect } from "react";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import EmployeeCard from "../EmployeeCard/EmployeeCard";
import EmployeeListView from "../EmployeeListView/EmployeeListView";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";
import "./index.css";

const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const employees = useSelector(
    (state) => state?.Data?.Resources?.Employee_Details
  );
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState({
    Role: [],
    Department: [],
    Skills: [],
    Experience: [],
  });

  const [filterStart, setfilterStart] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [view, setView] = useState(() => {
    const storedView = localStorage.getItem("view");
    return storedView || "list";
  });
  const userRole = sessionStorage.getItem("role").split(",");
  const navigate = useNavigate();

  const RoleData = useSelector((state) =>
    state?.Data?.Resources?.ConfigData_Details?.map((e) => e.Role)
  );

  const DepartmentData = useSelector((state) =>
    state?.Data?.Resources?.ConfigData_Details?.map((e) => e.Department)
  );

  const SkillsData = useSelector((state) =>
    state?.Data?.Resources?.ConfigData_Details?.map((e) => e.Skills)
  );

  const ExperienceData = useSelector((state) =>
    state?.Data?.Resources?.ConfigData_Details?.map((e) => e.Experience)
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClear = () => {
    setFilterOption({
      Role: [],
      Department: [],
      Skills: [],
      Experience: [],
    });
    setSearchText("");
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleSearch = () => {
    // perform search operation based on filter option and search text
    const filteredData = employees.filter((employee) => {
      const { Role, Department, Skills, Experience } = filterOption || {};

      const searchRegex = new RegExp(searchText, "i");

      if (Role?.length > 0 && !Role.some((role) => employee?.Role?.value === role))
        return false;

      if (
        Department.length > 0 &&
        !Department.some(
          (department) => employee?.Department?.value === department
        )
      )
        return false;

      if (
        Skills?.length > 0 &&
        !Skills.some((skill) =>
          employee?.Details?.Advance?.TechStack.some(
            (empSkill) => empSkill?.value === skill
          )
        )
      ) {
        return false;
      }

      if (
        Experience?.length > 0 &&
        !Experience.some(
          (experience) =>
            employee?.Details?.Advance?.Experience?.value === experience
        )
      )
        return false;

      // filter by search text
      if (searchText && !searchRegex.test(employee.Name)) return false;

      return true;
    });

    setFilteredEmployees(filteredData);
    setfilterStart(true);

    handleClose();
  };

  const handleFilterOptionChange = (event) => {
    const { name, value } = event.target;
    setFilterOption((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    //dispatch(fetchData());
  }, [dispatch]);

  // const handleViewChange = (event, nextView) => {
  //   setView(nextView);
  //   localStorage.setItem("view", nextView);
  // };

  const handleListViewClick = () => {
    if (view !== "list") {
      setView("list");
    }
  };

  const handleGridViewClick = () => {
    if (view !== "grid") {
      setView("grid");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: 1,
        "@media (min-width: 2560px)": {
          marginTop: "60px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          {/* <Button onClick={handleClickOpen} style={styles.Button}>
            <FilterAltIcon />
            Filter
          </Button> */}

          <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: { width: "80%", maxWidth: "1000px", minHeight: "300px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px",
              }}
            >
              <DialogTitle>FILTER</DialogTitle>
              <div className="close-container">
                <AiOutlineClose className="back-option" onClick={handleClose} />
              </div>
            </Box>
            <DialogContent>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  justifyContent: "flexStart",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <FormControl
                  sx={{
                    m: 1,
                    width: "100%",
                    maxWidth: "300px",
                  }}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filterOption.Role}
                    name="Role"
                    onChange={handleFilterOptionChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {RoleData?.length > 0 &&
                      RoleData?.map((option) =>
                        option.map((each) => (
                          <MenuItem key={each.value} value={each.value}>
                            <Checkbox
                              checked={
                                filterOption?.Role.indexOf(each.value) > -1
                              }
                            />
                            <ListItemText primary={each.value} />
                          </MenuItem>
                        ))
                      )}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    width: "100%",
                    maxWidth: "300px",
                  }}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Department
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filterOption?.Department}
                    name="Department"
                    onChange={handleFilterOptionChange}
                    input={<OutlinedInput label="Department" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {DepartmentData?.length > 0 &&
                      DepartmentData?.map((option) =>
                        option.map((each) => (
                          <MenuItem key={each?.value} value={each?.value}>
                            <Checkbox
                              checked={
                                filterOption?.Department.indexOf(each.value) > -1
                              }
                            />
                            <ListItemText primary={each?.value} />
                          </MenuItem>
                        ))
                      )}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    width: "100%",
                    maxWidth: "300px",
                  }}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Skills
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filterOption.Skills}
                    name="Skills"
                    onChange={handleFilterOptionChange}
                    input={<OutlinedInput label="Skills" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {SkillsData?.length > 0 &&
                      SkillsData?.map((option) =>
                        option.map((each) => (
                          <MenuItem key={each?.value} value={each?.value}>
                            <Checkbox
                              checked={
                                filterOption?.Skills.indexOf(each.value) > -1
                              }
                            />
                            <ListItemText primary={each?.value} />
                          </MenuItem>
                        ))
                      )}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    width: "100%",
                    maxWidth: "300px",
                  }}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Experience
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filterOption?.Experience}
                    name="Experience"
                    onChange={handleFilterOptionChange}
                    input={<OutlinedInput label="Experience" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {ExperienceData?.length > 0 &&
                      ExperienceData?.map((option) =>
                        option.map((each) => (
                          <MenuItem key={each?.value} value={each?.value}>
                            <Checkbox
                              checked={
                                filterOption?.Experience.indexOf(each?.value) > -1
                              }
                            />
                            <ListItemText primary={each?.value} />
                          </MenuItem>
                        ))
                      )}
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <button className="filter-apply-actions" onClick={handleSearch}>
                Apply
              </button>
              <button className="filter-clear-actions" onClick={handleClear}>
                Clear
              </button>
            </DialogActions>
          </Dialog>
        </Box>
        {/* 
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",

            "@media (min-width: 2560px)": {
              marginRight: "60px",
              fontSize: "300",
            },
          }}
        > */}
        {/* <ToggleButtonGroup
            value={view}
            exclusive
            aria-label="employee view"
            sx={{
              display: "flex",
              marginRight: !userRole.some((role) =>
                ["ADMIN", "HR"].includes(role)
              )
                ? "80px"
                : null,
              "@media (min-width: 2560px)": {
                marginRight: "60px",
              },
            }}
          >
            <ToggleButton
              value="list"
              aria-label="list"
              onClick={handleListViewClick}
            >
              <ViewListIcon
                sx={{
                  "@media (min-width: 2560px)": {
                    fontSize: "60px",
                  },
                }}
              />
            </ToggleButton>
            <ToggleButton
              value="grid"
              aria-label="grid"
              onClick={handleGridViewClick}
            >
              <ViewModuleIcon
                sx={{
                  "@media (min-width: 2560px)": {
                    fontSize: "60px",
                  },
                }}
              />
            </ToggleButton>
          </ToggleButtonGroup> */}
        {/* {userRole.some((role) => ["ADMIN", "HR"].includes(role)) ? (
            <Button
              onClick={() => navigate("/addEmployee")}
              startIcon={<AddCircleOutlineIcon />}
              style={styles.Button}
            >
              Add Employee
            </Button>
          ) : null}
        </Box> */}
      </Box>
      {view === "list" ? (
        <EmployeeListView data={filteredEmployees} filterStart={filterStart} />
      ) : (
        <EmployeeCard data={filteredEmployees} filterStart={filterStart} />
      )}
    </Box>
  );
};

export default EmployeeDetails;
