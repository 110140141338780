import Employeeform from '../EmployeeForm';

const AddEmployee = () => {
  return (
    <>
     <Employeeform editMode={false} />
    </>
  );
};

export default AddEmployee;
