import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from '@mui/material/Autocomplete';

const AddReferral = ({ open, handleClose }) => {

    const role = ['Full-Stack Developer', 'DevOps Developer', 'Rust Developer']

    return (
        <Dialog sx={{ margin: 'auto', width: '700px', }} open={open} onClose={handleClose}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogTitle>Add A Referral</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose}><CloseIcon /></Button>
                </DialogActions>
            </div>
            <DialogContent>

                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={role}
                    sx={{ width: 280, }}
                    renderInput={(params) => <TextField {...params} label="Select Role" />}
                />

            </DialogContent>
            <DialogContent sx={{ display: 'flex', gap: 2 }}>
                <Typography component='label'>Upload Resume</Typography>
                <Input type='file' sx={{ width: '170px' }} />
            </DialogContent>
            <DialogContent sx={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                <Button variant='contained'>Sumbit</Button>
            </DialogContent>


        </Dialog>
    )
}

export default AddReferral