import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ProjectsGrid from "../ProjectsGrid/ProjectsGrid";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./index.css";

const ProjectsList = () => {
  return (
    <>
      <ProjectsGrid />
    </>
  );
};

export default ProjectsList;
