import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./header.css";
import { useSelector } from "react-redux";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from "react-router-dom";

const initialChartData = {
  series: [0, 0], // Placeholder for Assigned and Unassigned
  options: {
    chart: {
      type: "donut",
      height: 100,
      width: 200,
    },
    labels: ["Assigned", "Unassigned"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            horizontalAlign: "center",
          },
        },
      },
    ],
  },
};

function GraphHeader() {
  const [data, setData] = useState(initialChartData);
  const Insights =
    useSelector((state) => state?.Data?.Resources?.Insights?.data) || [];
  const navigate = useNavigate()
  useEffect(() => {
    // Extract data from Insights
    const currentData = Insights.length > 0 ? Array.from(Insights)[0] : {};
    const employeesAssignedToProjects =
      currentData?.employeesAssignedToProjects || 0;
    const employeesUnAssignedToProjects =
      currentData?.employeesUnAssignedToProjects || 0;

    // Update chart data dynamically
    setData({
      series: [employeesAssignedToProjects, employeesUnAssignedToProjects],
      options: {
        ...data.options,
        labels: [
          `Assigned`,
          `Unassigned`,
          // `Assigned (${employeesAssignedToProjects})`,
          // `Unassigned (${employeesUnAssignedToProjects})`
        ],
      },
    });
  }, [Insights]);

  // Extract other values for display
  const currentData = Insights.length > 0 ? Array.from(Insights)[0] : {};
  const CurrentOpeningsCount = currentData?.CurrentOpeningsCount || 0;
  const projectsCount = currentData?.projectsCount || 0;
  const employeeCount = currentData?.employeeCount || 0;

  return (
    <div className="upper-container">
      <div className="sub-container" onClick={() => {
        navigate('/employee', {
          state: { headerText: "Employee" },
        })
      }}>
        <div className="headers">Total Employees</div>
        <div className="headers">
          <p className="values">{employeeCount}</p>
        </div>
      </div>
      <div className="sub-container" onClick={() => {
        navigate('/projects', {
          state: { headerText: "Projects" },
        })
      }} >
        <div className="headers">Projects</div>
        <div className="headers">
          <p className="values">{projectsCount}</p>
        </div>
      </div>
      <div className="sub-container" onClick={() => {
        navigate('/manageJob', {
          state: { headerText: "Jobs" },
        })
      }}>
        <div className="headers">Current Openings</div>
        <div className="headers">
          <p className="values">{CurrentOpeningsCount}</p>
        </div>
      </div>
      <div className="sub-container" onClick={() => {
        navigate('/employeeScreening', {
          state: { headerText: "Applications" },
        })
      }}>
        <div style={{ marginLeft: "-60px" }} className="headers">
          Employees Status
        </div>
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="donut"
        />
      </div>
    </div >
  );
}

export default GraphHeader;