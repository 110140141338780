import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import PersonalDetailsForm from "./PersonalDetailsForm";
import { ScreeningDetailsForm } from "./ScreeningDetailsForm";
import {
  addJobApplication,
  updateJobApplication,
  getJobApplicationData,
  tabIndex,
} from "../../../../redux/dataSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddApplication({ editMode = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { applicationId } = useParams();

  const tabIndexValue = useSelector(
    (state) => state?.Data?.Resources?.application?.tabValue
  );
  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  console.log("selected regions from Applications form", selectedRegion);
  useEffect(() => {}, [tabIndexValue]);
  const [value, setValue] = useState(tabIndexValue);
  const [formData, setFormData] = useState({
    personalDetails: {},
    screeningDetails: {},
    region: null,
  });
  const [applicationIdState, setApplicationIdState] = useState(
    applicationId || null
  );

  useEffect(() => {
    if (editMode && applicationId) {
      dispatch(getJobApplicationData(applicationId)).then((response) => {
        console.log("response payload", response.payload);
        setFormData({
          personalDetails: response?.payload?.personalDetails || {},
          screeningDetails: response?.payload?.screeningDetails || {}, // Ensure empty object if undefined
          region: response?.payload?.region || null,
          _id: response?.payload?._id || applicationIdState, // Preserve _id
        });
      });
    } else {
      if (selectedRegion) {
        setFormData((prevData) => ({
          ...prevData,
          region: selectedRegion?._id,
        }));
      }
    }
  }, [editMode, applicationId, dispatch, selectedRegion]);

  function formatformdata(obj, resume) {
    const form = new FormData();
    form.append("resume", resume);
    form.append("personalDetails", JSON.stringify(obj.personalDetails));
    form.append("region", obj.region);
    if (obj.personalDetails.initial.status !== "Selected") {
      form.append(
        "screeningDetails",
        JSON.stringify({
          level1: {
            date: "",
            takenBy: null,
            feedback: "",
            status: "",
            technicalSkillsRating: "",
            softSkillsRating: "",
          },
          level2: {
            date: "",
            takenBy: null,
            feedback: "",
            status: "",
            technicalSkillsRating: "",
            softSkillsRating: "",
          },
          client: {
            date: "",
            takenBy: null,
            feedback: "",
            status: "",
            clientRating: "",
          },
          hr: {
            date: "",
            takenBy: null,
            feedback: "",
            status: "",
            performanceRating: "",
            professionalisimRating: "",
          },
          remark: {
            status: "",
            remarks: "",
            isOnBoarded: false,
            jobOffered: false,
            onBoardedDate: null,
          },
        })
      );
    } else {
      form.append(
        "screeningDetails",
        JSON.stringify(obj.screeningDetails || {})
      );
    }
    if (applicationIdState) {
      form.append("_id", obj._id);
    }
    return form;
  }

  const handlePersonalDetailsSubmit = (personalDetails, action) => {
    let updatedData;
    if (personalDetails.initial.status !== "Selected") {
      updatedData = {
        ...formData,
        personalDetails,
        screeningDetails: {},
        region: formData.region,
      };
    } else {
      updatedData = {
        ...formData,
        personalDetails,
        screeningDetails: formData?.screeningDetails || {},
        region: formData.region,
      };
    }

    if (!applicationIdState) {
      const data = formatformdata(updatedData, personalDetails.Resume);
      dispatch(addJobApplication(data)).then((response) => {
        console.log("response from add application", response);

        const newId = response?.payload?.payload?._id;
        const success = response?.payload?.success;

        if (newId && success) {
          // Update the state with the new ID
          setFormData((prev) => ({ ...prev, _id: newId }));
          setApplicationIdState(newId);

          if (action === "Save and Proceed") {
            // Ensure that the tab index is updated and navigate
            setValue(1);
            dispatch(tabIndex(1));

            console.log(tabIndexValue, "tabIndexValue");
            setTimeout(() => {
              navigate(`/updateApplication/${newId}`, {
                state: { headerText: "Applications" },
              });
            }, 500);
          } else {
            navigate("/employeeScreening", {
              state: { headerText: "Applications" },
            });
          }
        } else {
          console.error(
            "Failed to create a new application or retrieve new ID."
          );
        }
      });
    } else {
      setFormData(updatedData);
      const data = formatformdata(updatedData, personalDetails.Resume);
      dispatch(updateJobApplication(data)).then((response) => {
        console.log("response from update application", response);
        if (response?.payload?.success) {
          if (action === "Save and Proceed") {
            setValue(1);
            dispatch(tabIndex(1));

            setTimeout(() => {
              navigate(`/updateApplication/${applicationId}`, {
                state: { headerText: "Applications" },
              });
            }, 100);
          } else {
            navigate("/employeeScreening", {
              state: { headerText: "Applications" },
            });
          }
        }
      });
    }
  };

  const handleScreeningDetailsSubmit = (screeningDetails) => {
    const updatedData = {
      ...formData,
      screeningDetails: screeningDetails || {},
      region: formData.region,
    };

    if (applicationIdState) {
      setFormData(updatedData);

      const data = formatformdata(
        updatedData,
        formData?.personalDetails?.Resume
      );
      dispatch(updateJobApplication(data)).then((response) => {
        console.log("update application", response);
        if (response.payload?.success) {
          setValue(1);
          dispatch(tabIndex(1));

          setTimeout(() => {
            navigate("/employeeScreening", {
              state: { headerText: "Applications" },
            });
          }, 100);
        }
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(tabIndex(newValue));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          margin: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Personal Details"
              {...a11yProps(0)}
              sx={{
                margin: "5px",
                borderRadius: "6px",
                bgcolor: value === 0 ? "green" : "grey",
                color: value === 0 ? "white !important" : "black",
              }}
            />
            <Tab
              disabled={
                !(formData?.personalDetails?.initial?.status === "Selected")
              }
              label="Screening"
              {...a11yProps(1)}
              sx={{
                margin: "5px",
                borderRadius: "6px",
                bgcolor: value === 1 ? "green" : "grey",
                color: value === 1 ? "white !important" : "black",
              }}
            />
          </Tabs>
        </div>

        <div>
          <Link to="/employeeScreening" state={{ headerText: "Applications" }}>
            <AiOutlineClose className="back-option" />
          </Link>
        </div>
      </Box>

      <TabPanel value={value} index={0}>
        <PersonalDetailsForm
          onSubmit={handlePersonalDetailsSubmit}
          initialValues={formData?.personalDetails || {}}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ScreeningDetailsForm
          onSubmit={handleScreeningDetailsSubmit}
          initialValues={formData?.screeningDetails || {}}
          enableScreening={
            formData?.personalDetails?.initial?.status === "Selected"
          }
        />
      </TabPanel>
      <ToastContainer />
    </Box>
  );
}
