import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { fetchConfigData } from "../../../../redux/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfigGrid from "./ConfigGrid";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ConfigTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const configData = useSelector(
        (state) => state?.Data?.Resources?.ConfigData_Details
    );

    let tabData = [];

    if (configData[0]) {
        tabData = [...Object.keys(configData[0])];
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        dispatch(fetchConfigData()).then(response => {
            if (response?.data?.statusCode === 401) {
                navigate("/session-expired")
            }
        });
    }, [dispatch]);

  return (
    <Box sx={{ width: "100%", mt: "20px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          overflow: "scroll",
          "& ::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="config table tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            backgroundColor: "white !important",
            "& .MuiTab-root": {
              textTransform: "capitalize",
              color: "black",
              fontSize: "16px",
            },
          }}
        >
          {tabData.map((config, index) => {
         
            if (
              config !== "_id" &&
              config !== "updatedAt" &&
              config !== "createdAt" &&
              config !== "__v"
            ) {
              return (
                <Tab
                  key={index}
                  label={config} // This gets the first key from each config
                  {...a11yProps(index)}
                />
              );
            }
            return null; // Optional: You can return null for filtered-out items.
          })}
          {/* <Tab label="Role" {...a11yProps(1)} />
                    <Tab label="Assets" {...a11yProps(2)} />
                    <Tab label="Department" {...a11yProps(3)} />
                    <Tab label="Designation" {...a11yProps(4)} />
                    <Tab label="User Type" {...a11yProps(5)} />
                    <Tab label="Skills" {...a11yProps(6)} />
                    <Tab label="Experience" {...a11yProps(7)} />
                    <Tab label="Assets Status" {...a11yProps(8)} />
                    <Tab label="Job Application Status" {...a11yProps(9)} />
                    <Tab label="Job Status" {...a11yProps(10)} />
                    <Tab label="Project Status" {...a11yProps(11)} />
                    <Tab label="Location" {...a11yProps(12)} />
                    <Tab label="Gender" {...a11yProps(13)} />
                    <Tab label="Priority" {...a11yProps(14)} />
                    <Tab label="Is Onboard" {...a11yProps(15)} />
                    <Tab label="Willing To Relocate" {...a11yProps(16)} />
                    <Tab label="Work Mode" {...a11yProps(17)} />
                    <Tab label="Country Codes" {...a11yProps(18)} />
                    <Tab label="Project Category" {...a11yProps(19)} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <ConfigGrid
                    addButtonName={"Employment"}
                    addTabName={"Employment"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Employment.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <ConfigGrid
                    addButtonName={"Project Category"}
                    addTabName={"projectCategory"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.projectCategory.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <ConfigGrid
                    addButtonName={"Role"}
                    addTabName={"Role"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Role.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <ConfigGrid
                    addButtonName={"Designation"}
                    addTabName={"Designation"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Designation.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <ConfigGrid
                    addButtonName={"Department"}
                    addTabName={"Department"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Department.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
                <ConfigGrid
                    addButtonName={"Experience"}
                    addTabName={"Experience"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Experience.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
                <ConfigGrid
                    addButtonName={"Priority"}
                    addTabName={"Priority"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Priority.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={7}>
                <ConfigGrid
                    addButtonName={"Location"}
                    addTabName={"Location"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Location.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={8}>
                <ConfigGrid
                    addButtonName={"UserType"}
                    addTabName={"UserType"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.UserType.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={9}>
                <ConfigGrid
                    addButtonName={"Status"}
                    addTabName={"Status"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Status.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={10}>
                <ConfigGrid
                    addButtonName={"Job Status"}
                    addTabName={"JobStatus"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.JobStatus.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={11}>
                <ConfigGrid
                    addButtonName={"Willing To Relocate"}
                    addTabName={"WillingToRelocate"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.WillingToRelocate.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={12}>
                <ConfigGrid
                    addButtonName={"Is Onboard"}
                    addTabName={"IsOnboard"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.IsOnboard.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={13}>
                <ConfigGrid
                    addButtonName={"Job Application Status"}
                    addTabName={"JobApplicationStatus"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.JobApplicationStatus.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={14}>
                <ConfigGrid
                    addButtonName={"Skills"}
                    addTabName={"Skills"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Skills.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={15}>
                <ConfigGrid
                    addButtonName={"Gender"}
                    addTabName={"Gender"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Gender.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={16}>
                <ConfigGrid
                    addButtonName={"Assets"}
                    addTabName={"Assets"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.Assets.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={17}>
                <ConfigGrid
                    addButtonName={"AssetsStatus"}
                    addTabName={"AssetsStatus"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.AssetsStatus.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={18}>
                <ConfigGrid
                    addButtonName={"countryCodes"}
                    addTabName={"countryCodes"}
                    rows={
                        configData.length > 0
                            ? configData[0]?.countryCodes.map((val, index) => ({
                                name: val,
                                value: val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={19}>
                <ConfigGrid
                    addButtonName={"WorkMode"}
                    addTabName={"WorkMode"}
                    rows={
                        configData?.length > 0
                            ? configData[0]?.WorkMode.map((val, index) => ({
                                ...val,
                                id: index + 1,
                            }))
                            : []
                    }
                    getRowId={(row) => row.id}
                />
            </CustomTabPanel>
            <ToastContainer />
        </Box>
    );
};

export default ConfigTable;
