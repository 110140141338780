import React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsset,
  getAssetData,
  updateAsset,
} from "../../../../redux/dataSlice";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import Box from "@material-ui/core/Box";

const UpdateAssets = () => {
  const [formData, setFormData] = useState({});
  const [open, setOpen] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const AssetsData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.map((e) => e.Assets)
  );
  const AssetsStatusData = useSelector((state) =>
    state.Data.Resources.ConfigData_Details.map((e) => e.AssetsStatus)
  );

  const notifySuccess = () => toast.success("Updated Successfully...!");
  const notifyError = (errorMessage) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyDeleted = () => toast.info("Deleted Successfully...!");

  useEffect(() => {
    //dispatch(fetchData());
    dispatch(getAssetData(id))
      .then((response) => {
        setFormData(response.payload);
      })
      .catch((error) => {});
  }, [id, dispatch]);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   dispatch(updateAsset(formData))
  //     .unwrap() // extracts the fulfilled value from the promise
  //     .then((data) => {
  //       notifySuccess();
  //       setTimeout(() => {
  //         navigate("/assets");
  //         window.location.reload();
  //       }, 2000);
  //     })
  //     .catch((error) => {
  //       notifyError();
  //     });
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await dispatch(updateAsset(formData));
      if (updateAsset.fulfilled.match(response)) {
        notifySuccess();
        setTimeout(() => {
          navigate("/assets");
          window.location.reload();
        }, 2000);
      } else {
        // Handle rejected response from server
        notifyError(response.payload);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelClick = () => {
    navigate("/assets");
  };

  const handleDelete = (event, id) => {
    event.preventDefault();
    handleClickOpen();
    try {
      dispatch(deleteAsset(id));
      notifyDeleted();

      setTimeout(() => {
        navigate("/assets");
        window.location.reload();
      }, 2000);
    } catch (error) {
      notifyError();
    }
  };

  const handleEditClick = () => {
    setIsReadOnly(!isReadOnly);
  };

  return (
    <div className="projectassign-form-container">
      <div className="heading-close-container">
        <h1 className="application-heading">Edit Asset</h1>
        <div className="close-container">
          <Link to="/assets">
            <AiOutlineClose className="back-option" />
          </Link>
        </div>
      </div>
      <Divider />
      <br />

      <form onSubmit={handleSubmit} className="employee-form">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            Asset Id <span className="required-star">*</span>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, Asset_ID: e.target.value })
                }
                value={formData.Asset_ID}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            Asset Type <span className="required-star">*</span>
            <FormControl fullWidth variant="outlined">
              <Select
                id="asset-type"
                name="Asset_Type"
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={(event) =>
                  setFormData({ ...formData, Asset_Type: event.target.value })
                }
                value={formData.Asset_Type || ""}
                labelId="asset-type-label"
                disabled={isReadOnly}
              >
                {AssetsData.flatMap((asset) =>
                  asset.map((each) => (
                    <MenuItem
                      key={each.value}
                      value={each.value}
                      className="asset-type-menuitem"
                    >
                      {each.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            Model <span className="required-star">*</span>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, Model: e.target.value })
                }
                value={formData.Model}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            Vendor <span className="required-star">*</span>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, Vendor: e.target.value })
                }
                value={formData.Vendor}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            Serial Number <span className="required-star">*</span>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, Serial_Number: e.target.value })
                }
                value={formData.Serial_Number}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            Location <span className="required-star">*</span>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, Location: e.target.value })
                }
                value={formData.Location}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            Status <span className="required-star">*</span>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="asset-Status"
                label="Status"
                name="Status"
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={(e) =>
                  setFormData({ ...formData, Status: e.target.value })
                }
                value={formData.Status || ""}
                disabled={isReadOnly}
                select
              >
                {AssetsStatusData.flatMap((assetStatus) =>
                  assetStatus.map((each) => (
                    <MenuItem
                      key={each.value}
                      value={each.value}
                      className="asset-status-menuitem"
                    >
                      {each.name}
                    </MenuItem>
                  ))
                )}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="employee-label-card">
              <label htmlFor="PurchaseDate">
                Purchase Date <span className="required-star">*</span>
              </label>
              <TextField
                id="date"
                type="date"
                sx={{
                  width: "100%",
                  maxWidth: 505,

                  "@media (max-width: 600px)": {
                    maxWidth: 400,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    maxWidth: 250,
                  },
                  "@media (min-width: 961px) and (max-width: 2560px)": {
                    maxWidth: "100vw",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  formData.Purchase_Date
                    ? formData.Purchase_Date.slice(0, 10)
                    : "yyyy-MM-dd"
                }
                disabled={isReadOnly}
                onChange={(e) => {
                  const newValue = e.target.value; // Get the raw string value
                  setFormData({
                    ...formData,
                    Purchase_Date: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Enter purchase date" />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            Purchase From
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, Purchase_From: e.target.value })
                }
                value={formData.Purchase_From}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            Purchase Cost <span className="required-star">*</span>
            <FormControl fullWidth>
              <TextField
                type="number"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, Purchase_Cost: e.target.value })
                }
                value={formData.Purchase_Cost}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            Warranty Info
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, warranty_info: e.target.value })
                }
                value={formData.warranty_info}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="employee-label-card">
              <label htmlFor="WarrantyExpirationDate">
                Warranty Expiration Date
              </label>
              <TextField
                id="date"
                type="date"
                sx={{
                  width: "100%",
                  maxWidth: 505,

                  "@media (max-width: 600px)": {
                    maxWidth: 400,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    maxWidth: 250,
                  },
                  "@media (min-width: 961px) and (max-width: 2560px)": {
                    maxWidth: "100vw",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  formData.warranty_expiration_date
                    ? formData.warranty_expiration_date.slice(0, 10)
                    : "yyyy-MM-dd"
                }
                disabled={isReadOnly}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setFormData({
                    ...formData,
                    warranty_expiration_date: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Enter Warrenty Expiration Date"
                  />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            Invoice Number
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, invoice_number: e.target.value })
                }
                value={formData.invoice_number}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            Quantity <span className="required-star">*</span>
            <FormControl fullWidth>
              <TextField
                type="number"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, quantity: e.target.value })
                }
                value={formData.quantity}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            Remarks
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, remarks: e.target.value })
                }
                value={formData.remarks}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            Description
            <FormControl fullWidth>
              <TextareaAutosize
                onChange={(e) =>
                  setFormData({ ...formData, desc: e.target.value })
                }
                minRows={3}
                placeholder="Description"
                defaultValue={formData.desc}
                disabled={isReadOnly}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className="employee-Actions-container">
            {isReadOnly ? (
              <button
                className="employee-Action-btn"
                type="button"
                onClick={handleEditClick}
              >
                Edit
              </button>
            ) : (
              <button
                className="employee-Action-btn"
                type="button"
                onClick={handleEditClick}
              >
                Read
              </button>
            )}
            <Box>
              <button
                className="employee-Action-btn"
                type="button"
                onClick={handleClickOpen}
              >
                Delete
              </button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    backgroundColor: "#f0f0f0",
                    color: "black",
                    boxShadow: "none",
                  },
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  {"Delete this Asset?"}
                </DialogTitle>
                <DialogContent>
                  <Typography color="textPrimary">
                    Are you sure you want to delete this Asset?
                  </Typography>
                </DialogContent>

                <DialogActions>
                  <button
                    onClick={handleClose}
                    className="dialog-cancel-btn"
                    color="primary"
                  >
                    Cancel
                  </button>
                  <button
                    className="dialog-delete-btn"
                    onClick={(event) => {
                      handleDelete(event, id);
                      handleClose();
                    }}
                    color="primary"
                    autoFocus
                  >
                    Delete
                  </button>
                </DialogActions>
              </Dialog>
            </Box>
            <button className="employee-Action-btn" type="submit">
              Submit
              <ToastContainer />
            </button>
            <button
              className="employee-Action-btn"
              type="button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </Grid>
      </form>

      <ToastContainer />
    </div>
  );
};

export default UpdateAssets;

// <Grid container spacing={2} justifyContent="flex-end">
// <Grid item>
//   <Button
//     variant="contained"
//     color="primary"
//     onClick={() => {
//       handleEdit();
//     }}
//   >
//     Update
//   </Button>{" "}
//   &nbsp;
// </Grid>
// <Grid item>
//   <Button
//     variant="contained"
//     color="error"
//     onClick={() => navigate("/assets")}
//   >
//     Cancel
//   </Button>
// </Grid>
// </Grid>
