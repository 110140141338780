
/**
 * Helper function to fetch user permssions.
 */
export const getUserPermissions = () => {
    let userPermissions = [];
    try {
        const permissions = sessionStorage.getItem("permissions");
        if (permissions) {
            userPermissions = permissions;
        }
    } catch (err) {
        console.error(err)
    }
    return userPermissions;
};

/**
 * Helper function to check user can access
 */
export const canAccess = accessName => {
    // accessName can be
    // For Admin -  Application.FullAccessIndia, Application.FullAccessUsa, 
    // For HR - Application.ReadEditIndia, Application.ReadEditUsa, 
    // For Support - Application.ReadIndia, Application.ReadUsa

    let canAccessPage = false;
    const permissionsData = getUserPermissions();
    if (permissionsData && permissionsData.includes(accessName) && typeof accessName == "string") {
        canAccessPage = true;
    }
    return canAccessPage;
};