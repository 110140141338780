import React, { useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const PreEliminery = () => {
    const [samplevisible, setSample] = useState(true)
    const [jobvisible, setJobs] = useState(false)
    const [Role, setRole] = React.useState('FullStack Developer');


    const handleChange = (event) => {
        setRole(event.target.value);
    };
    const jobs = ['FullStack Developer', 'Python Developer', 'DevOps Developer']
    return (
        <Box>
            <Box>
                <Typography variant='h5'> PreEliminaryScreening</Typography>
            </Box>
            <Box sx={{ paddingTop: '10px', display: 'flex', gap: 2 }}>
                <Link onClick={() => {
                    setSample(true)
                    setJobs(false)
                }} >Sample Questions</Link>
                <Link onClick={() => {
                    setSample(false)
                    setJobs(true)
                }}>Job Details</Link>
            </Box>
            {samplevisible &&
                <Box sx={{ paddingTop: '15px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Role}
                            label="Role"
                            onChange={handleChange}
                        >
                            {jobs.map((job) => { return (<MenuItem value={job}>{job}</MenuItem>) })}

                        </Select>
                    </FormControl>
                    <Typography sx={{ paddingTop: '10px' }}>Sample Questions for {Role} </Typography>



                </Box>
            }
            {jobvisible &&
                <Box sx={{ paddingTop: '15px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Role}
                            label="Role"
                            onChange={handleChange}
                        >
                            {jobs.map((job) => { return (<MenuItem value={job}>{job}</MenuItem>) })}

                        </Select>
                    </FormControl>

                    <Typography sx={{ paddingTop: '15px' }}>{Role}</Typography>
                </Box>

            }

        </Box>
    )
}
export default PreEliminery