import React from "react";
import TaskIcon from "@mui/icons-material/Task";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import DonutChart from "react-donut-chart";

import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import HiringPipeline from "../HiringPipeline/HiringPipeline";

const drawerWidth = 250;

const data = [
  { id: 0, value: 35, label: "LinkedIn" },
  { id: 1, value: 26, label: "Naukari" },
  { id: 2, value: 28, label: "Glassdoor" },
  { id: 3, value: 11, label: "Others" },
];

const size = {
  width: 370,
  height: 220,
};

const Overview = () => {
  const date = new Date(Date.now()).toISOString().split('T')[0];
  const cards = [
    {
      Category: "Job Openings",
      value: 5,
      style: { borderBottom: "solid orange", textColor: "orange" },
    },
    {
      Category: "Submissions",
      value: 10,
      style: { borderBottom: "solid purple", textColor: "purple" },
    },
    {
      Category: "Hired",
      value: 15,
      style: { borderBottom: "solid green", textColor: "green" },
    },
    {
      Category: "Active Jobs",
      value: 15,
      style: { borderBottom: "solid blue", textColor: "blue" },
    },
    {
      Category: "Positions to fill",
      value: 15,
      style: { borderBottom: "solid red", textColor: "red" },
    },
  ];
  return (
    <Box sx={{ position: "absolute", minHeight: "200px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <Typography
          variant="h4"
          sx={{ marginLeft: "2rem", fontWeight: "bold" }}
        >
          Overview
        </Typography>
        <TextField type="date" value={date} />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "17px",
          marginTop: "40px",
          marginLeft: "25px",
          borderRadius: "300px",
        }}
      >
        {cards.map((card) => {
          return (
            <Card
              sx={{
                width: "220px",
                height: "120px",
                borderBottom: card.style.borderBottom,
                fontSize: "19px",
                textAlign: "start",
                "&:hover": {
                  transform: "scale(1.05)",
                  bgcolor: "#FAFAFA",
                },
              }}
            >
              <CardContent
                sx={{
                  padding: "0px",
                  marginTop: "28px",
                  marginX: "19px",
                  fontWeight: "bold",
                }}
              >
                {card.value}
              </CardContent>
              <CardContent
                sx={{
                  color: card.style.textColor,
                  padding: "0px",
                  marginTop: "5px",
                  marginX: "18px",
                }}
              >
                {card.Category}
              </CardContent>
            </Card>
          );
        })}
      </Box>
      <Box sx={{ marginTop: "70px", ml: "10px" }}>
        <Box sx={{ display: "flex", gap: "26%" }}>
          <Typography variant="h5" sx={{ mb: "20px", ml: "10px" }}>
            Offer Acceptance
          </Typography>
          <Typography variant="h5" sx={{ mb: "20px" }}>
            Hires By Sources
          </Typography>
          {/* <Typography variant="h5" sx={{ mb: "30px" }}>
      Offer Acceptance
    </Typography> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "70px",
          }}
        >
          <Box
            sx={{
              marginTop: "10px",
              width: "390px",
              height: "290px",
              border: "3px ",
              bgcolor: "white",
            }}
          >
            <Box sx={{ padding: "12px", fontSize: "13px" }}>
              <DonutChart
                height={320}
                width={370}
                colors={["#5ac55d", " #fb6362"]}
                className=""
                data={[
                  { label: "Accepted", value: 60 },

                  { label: "Rejected", value: 40 },
                ]}
              />
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "auto",
              width: "390px",
              height: "290px",
              border: "3px ",
              bgcolor: "white",
            }}
          >
            <Box sx={{ paddingTop: "22px", fontSize: "13px" }}>
              <PieChart
                series={[
                  {
                    arcLabel: (item) => ` ${item.value}%`,
                    arcLabelMinAngle: 15,
                    data,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontWeight: "bold",
                  },
                }}
                {...size}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: "40px", ml: "10px" }}>
        <Typography variant="h5" sx={{ mb: "20px", ml: "10px" }}>
          Hiring Pipeline
        </Typography>
        <HiringPipeline />
      </Box>
    </Box>
  );
};

export default Overview;
