import React, { useState, useEffect } from "react";
import { Routes, Route, HashRouter, Outlet } from "react-router-dom";
import EmployeeDetails from "./components/Pages/Employee/EmployeeDetails/EmployeeDetails";
import Users from "./components/Pages/Users/Users";
import AssetsAssignButton from "./components/Pages/Assets/AssetsAssignButton/AssetsAssignButton";
import About from "./components/Pages/About/About";
import MyProfile from "./components/Pages/MyProfile/MyProfile";
import AddEmployee from "./components/Pages/Employee/AddEmployee/AddEmployee";
import Login from "./components/Pages/Login/Login";
import ProtectedRoute from "./components/Pages/ProtectedRoute/ProtectedRoute";
import UpdateEmployee from "./components/Pages/Employee/UpdateEmployee/UpdateEmployee";
import Dashboard from "./components/Pages/Dashboard/Dashboard";
import JobForm from "./components/Pages/Recruitment/ManageJob/AddJob/JobForm";
import ViewAllJobs from "./components/Pages/Recruitment/ManageJob/ViewAllJobs/ViewAllJobs";
import EditJob from "./components/Pages/Recruitment/ManageJob/EditJob/EditJob";
import ManageJob from "./components/Pages/Recruitment/ManageJob/ManageJob";
import ManageJobOnBoarding from "./components/Pages/Recruitment/ManageJobOnBoarding/ManageJobOnBoarding";
import ManageInterviewSchedule from "./components/Pages/Recruitment/ManageInterviewSchedule/ManageInterviewSchedule";
import AddProject from "./components/Pages/Projects/ProjectFolder/AddProject/AddProject";
import ProjectAssignButton from "./components/Pages/Projects/ProjectAssignFolder/ProjectAssignButton/ProjectAssignButton";
import AssignProject from "./components/Pages/Projects/ProjectAssignFolder/AssignProject/AssignProject";
import UpdateAssignProject from "./components/Pages/Projects/ProjectAssignFolder/UpdateAssignProject/UpdatedAssignProject";
import UpdateProject from "./components/Pages/Projects/ProjectFolder/UpdateProject/UpdateProject";
import ProjectsList from "./components/Pages/Projects/ProjectFolder/ProjectsList/ProjectsList";
import ApplicationGrid from "./components/Pages/Recruitment/JobApplications/ApplicationsGrid/ApplicationGrid";
import AddApplication from "./components/Pages/Recruitment/JobApplications/AddApplication/AddApplication";
import UpdateApplication from "./components/Pages/Recruitment/JobApplications/UpdateApplication/UpdateApplication";
import Cookies from "js-cookie";
import NoAccess from "./components/Pages/NoAccess/NoAccess";
import NotFound from "./components/Pages/Notfound/Notfound";
import UpdateAssets from "./components/Pages/Assets/UpdateAssets/UpdateAssets";
import AddAssets from "./components/Pages/Assets/AddAssets/AddAssets";
import AddEvent from "./components/Pages/Notice/AddEvent/AddEvent";
import AddConfig from "./components/Pages/Config/ConfigPage/AddConfig";
//import ConfigGridView from "./components/Pages/Config/ConfigPage/AddConfig";
import { useDispatch } from "react-redux";
import { fetchData } from "./redux/dataSlice";
import RecHome from "./components/Pages/RecruitmentDashBoard/Home/Home";
import Referral from "./components/Pages/RecruitmentDashBoard/Referral/Referral";
import Overview from "./components/Pages/RecruitmentDashBoard/Overview/Overview";
import MyReferral from "./components/Pages/RecruitmentDashBoard/Referral/MyReferral";
import Interview from "./components/Pages/RecruitmentDashBoard/Interview/Interview";
import PreEliminery from "./components/Pages/RecruitmentDashBoard/Interview/PreEliminery";
import Reports from "./components/Pages/RecruitmentDashBoard/Reports/Reports";
import { MsalProvider } from "@azure/msal-react";
import './App.css'
const App = ({ instance }) => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("isAuthenticated") === "true" // Check if the "isAuthenticated" cookie exists and its value is "true"
  );
  const [aut, setAut] = useState(false);

  useEffect(() => {
    dispatch(fetchData());
    // .then(response => {
    //   if (response?.data?.statusCode === 401) {
    //     window.location.href = "/session-expired"
    //   }
    // });
  }, [dispatch]);

  return (
    <MsalProvider instance={instance}>
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />

          {isAuthenticated ? (
            <Route>
              <Route
                path="/recruitmentHome"
                element={<RecHome />}
                roles={["ADMIN", "HR", "EMPLOYEE"]}
              >
                <Route path="referral" element={<Referral />}>
                  {" "}
                </Route>

                <Route path="myreferral" element={<MyReferral />} />
                <Route path="PreEliminery" element={<PreEliminery />} />
                <Route path="reports" element={<Reports />} />
                <Route path="overview" element={<Overview />} />
                <Route path="employeeScreening" element={<ApplicationGrid />} />
                <Route path="interviews" element={<Interview />} />
                <Route
                  path="manageJob"
                  element={<ManageJob />}
                  roles={["ADMIN", "HR"]}
                ></Route>
                <Route
                  path="addApplication"
                  element={<AddApplication />}
                  roles={["ADMIN", "HR"]}
                />

                <Route
                  path="updateApplication/:applicationId"
                  element={<UpdateApplication />}
                  roles={["ADMIN", "HR"]}
                />
                <Route
                  path="addjob"
                  element={<JobForm editMode={false} />}
                  roles={["ADMIN", "HR"]}
                />
                <Route
                  path="editJob/:jobId"
                  element={<JobForm editMode={true} />}
                  roles={["ADMIN", "HR"]}
                />

                <Route
                  path="viewalljobs"
                  element={<ViewAllJobs />}
                  roles={["ADMIN", "EMPLOYEE"]}
                />
              </Route>

              <Route path="/" element={<Dashboard />}>
                <Route path="*" element={<Login />} />
                <Route path="/noaccess" element={<NoAccess />} />
                {/* new routes  */}
                <Route path="employeeScreening" element={<ApplicationGrid />} />
                <Route
                  path="manageJob"
                  element={<ManageJob />}
                  roles={["ADMIN", "HR"]}
                ></Route>
                <Route
                  path="addApplication"
                  element={<AddApplication />}
                  roles={["ADMIN", "HR"]}
                />

                <Route
                  path="updateApplication/:applicationId"
                  element={<UpdateApplication />}
                  roles={["ADMIN", "HR"]}
                />
                <Route
                  path="addjob"
                  element={<JobForm editMode={false} />}
                  roles={["ADMIN", "HR"]}
                />
                <Route
                  path="editJob/:jobId"
                  element={<JobForm editMode={true} />}
                  roles={["ADMIN", "HR"]}
                />

                <Route
                  path="viewalljobs"
                  element={<ViewAllJobs />}
                  roles={["ADMIN", "EMPLOYEE"]}
                />
                <Route
                  path="/assets"
                  element={
                    <ProtectedRoute
                      element={<AssetsAssignButton />}
                      roles={["ADMIN", "ACCOUNTS"]}
                    />
                  }
                />

                <Route
                  path="/addAssets"
                  element={
                    <ProtectedRoute
                      element={<AddAssets />}
                      roles={["ADMIN", "ACCOUNTS"]}
                    />
                  }
                />

                <Route
                  path="/assets/:id"
                  element={
                    <ProtectedRoute
                      element={<UpdateAssets />}
                      roles={["ADMIN", "ACCOUNTS"]}
                    />
                  }
                />

                <Route
                  path="/employee"
                  element={
                    <ProtectedRoute
                      element={<EmployeeDetails />}
                      roles={["ADMIN", "HR", "EMPLOYEE"]}
                    />
                  }
                />
                <Route
                  path="/addEmployee"
                  element={
                    <ProtectedRoute
                      element={<AddEmployee />}
                      roles={["ADMIN", "HR"]}
                    />
                  }
                />
                <Route
                  path="/employee_details/:empid"
                  element={
                    <ProtectedRoute
                      element={<UpdateEmployee />}
                      roles={["ADMIN", "HR"]}
                    />
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute
                      element={<Users />}
                      roles={["ADMIN", "HR"]}
                    />
                  }
                />
                {/* <Route
                  path="/profile"
                  element={
                    <ProtectedRoute
                      element={<MyProfile />}
                      roles={["ADMIN", "EMPLOYEE"]}
                    />
                  }
                /> */}
                <Route
                  path="/addProject"
                  element={
                    <ProtectedRoute
                      element={<ProjectsList />}
                      roles={["ADMIN"]}
                    />
                  }
                />
                <Route
                  path="/createProject"
                  element={
                    <ProtectedRoute
                      element={<AddProject />}
                      roles={["ADMIN"]}
                    />
                  }
                />
                <Route
                  path="/assignProject"
                  element={
                    <ProtectedRoute
                      element={<ProjectAssignButton />}
                      roles={["ADMIN"]}
                    />
                  }
                />
                <Route
                  path="/assignProjectName"
                  element={
                    <ProtectedRoute
                      element={<AssignProject />}
                      roles={["ADMIN"]}
                    />
                  }
                />
                <Route
                  path="/project/:id"
                  element={
                    <ProtectedRoute
                      element={<UpdateProject />}
                      roles={["ADMIN"]}
                    />
                  }
                />
                <Route
                  path="/AssignProject/:id"
                  element={
                    <ProtectedRoute
                      element={<UpdateAssignProject />}
                      roles={["ADMIN"]}
                    />
                  }
                />
                {/* <Route
                path="/manageJob"
                element={
                  <ProtectedRoute
                    element={<ManageJob />}
                    roles={["ADMIN", "HR"]}
                  />
                }
              /> */}
                {/* <Route
                path="/addjob"
                element={
                  <ProtectedRoute
                    element={<AddJob />}
                    roles={["ADMIN", "HR"]}
                  />
                }
              />
              <Route
                path="/editJob/:jobId"
                element={
                  <ProtectedRoute
                    element={<EditJob />}
                    roles={["ADMIN", "HR"]}
                  />
                }
              />

              <Route
                path="/viewalljobs"
                element={
                  <ProtectedRoute
                    element={<ViewAllJobs />}
                    roles={["ADMIN", "EMPLOYEE"]}
                  />
                }
              /> */}

                {/* <Route
                path="/addApplication"
                element={
                  <ProtectedRoute
                    element={<AddApplication />}
                    roles={["ADMIN", "HR"]}
                  />
                }
              />

              <Route
                path="/updateApplication/:applicationId"
                element={
                  <ProtectedRoute
                    element={<UpdateApplication />}
                    roles={["ADMIN", "HR"]}
                  />
                }
              /> */}
                <Route
                  path="/manageJobOnBoarding"
                  element={
                    <ProtectedRoute
                      element={<ManageJobOnBoarding />}
                      roles={["ADMIN", "HR"]}
                    />
                  }
                />
                <Route
                  path="/manageInterviewSchedule"
                  element={
                    <ProtectedRoute
                      element={<ManageInterviewSchedule />}
                      roles={["ADMIN", "HR"]}
                    />
                  }
                />
                <Route
                  path="/notice"
                  element={
                    <ProtectedRoute
                      element={<AddEvent />}
                      roles={["ADMIN", "HR"]}
                    />
                  }
                />

                <Route
                  path="/config"
                  element={
                    <ProtectedRoute
                      element={<AddConfig />}
                      roles={["ADMIN", "HR"]}
                    />
                  }
                />
                {/* <Route path="/recruitmentHome" 
            //element={ <ProtectedRoute
            element={<RecHome />}
            //roles={["ADMIN", "HR","EMPLOYEE"]}
            ></Route> */}

                <Route
                  path="/about"
                  element={
                    <ProtectedRoute
                      element={<About />}
                      roles={["ADMIN", "EMPLOYEE"]}
                    />
                  }
                />

                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          ) : (
            <>
              <Route path="*" element={<Login />} />
            </>
          )}
        </Routes>
      </HashRouter>
    </MsalProvider>
  );
};

export default App;
