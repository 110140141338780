import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Divider,
  Input,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Link, Outlet } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LinkIcon from "@mui/icons-material/Link";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import ComboBox from "./SearchBar";
import AddReferral from "./AddReferral";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const Referral = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [selected, setSelected] = useState(null);

  console.log("inside referral");
  const Myreferral = () => {
    navigate("myreferral");
  };
  const addReferral = (e) => {
    setOpen(true);
    console.log("Inside Addreferral");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePlatforms = () => {
    console.log("Connected to platfroms");
  };

  const handleSelect = (event, value) => {
    setSelected(value);
  };

  const generateLink = () => {
    return `https://recruitment/referral/${selected.toLowerCase()}`;
  };
  const jobs = [
    "Full-Stack Developer",
    "Python Developer",
    "DevOps Developer",
    "FrontEnd Developer",
    "BackEnd Developer",
    "Rust Developer",
  ];
  return (
    <Box>
      <Card sx={{ height: "350px" }}>
        <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" sx={{ color: "violet" }}>
            Add a Referral
          </Typography>
          <Typography variant="h6" sx={{ color: "black" }}>
            <Link to="/recruitmentHome/myreferral">My Referrals</Link>
          </Typography>
        </CardContent>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: 3 }}>
            <EditNoteIcon />
            <Typography>
              Add a referral for a new job<br></br>
              <span style={{ color: "grey", fontSize: "small" }}>
                Refer your friends/family,help them get a job.
              </span>
            </Typography>
          </Box>

          <Button
            variant="contained"
            sx={{
              borderRadius: "13px",
              width: "230px",
              display: "flex",
              gap: 2,
            }}
            onClick={addReferral}
          >
            <AddCircleIcon />
            <span>Add a Referral</span>
          </Button>
        </CardContent>

        <Divider>or</Divider>
        <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 3 }}>
            <LinkIcon />
            <Typography>
              Share your link to people interested to work in your company.
              <br></br>
              <span style={{ color: "grey", fontSize: "small" }}>
                Share the link, help the needy.
              </span>{" "}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            {/* <ComboBox /> */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={jobs}
              sx={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label="Choose a Job" />
              )}
              onChange={handleSelect}
            />
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={selected ? generateLink() : "Link Doesn't Exist"}
              />
              <Button
                variant="contained"
                sx={{ borderRadius: "10px", height: "50px" }}
                onClick={() => {}}
              >
                Copy
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Divider />
      <Card sx={{ marginTop: "10px" }}>
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography>Share via Social Platforms</Typography>
          <Box sx={{ display: "flex", gap: 2, justifyItems: "center" }}>
            <Button variant="contained">
              <LinkedInIcon onClick={handlePlatforms} />
            </Button>
            <Button variant="contained" color="secondary">
              <Icon
                onClick={handlePlatforms}
                style={{ paddingTop: "3px" }}
                icon="simple-icons:indeed"
              ></Icon>
            </Button>
          </Box>
        </CardContent>
      </Card>
      <AddReferral open={open} handleClose={handleClose} />
      {/* <Outlet /> */}
    </Box>
  );
};
export default Referral;
