import React, { useEffect } from "react";
import "./index.css";
import WorkingPercentage from "./WorkingPercentage";
import { useDispatch, useSelector } from "react-redux";
import { getInsightsData } from "../../../redux/dataSlice";
import GraphHeader from "./GraphHeader";
import "./index.css";
import YearWisePopulation from "./YearWisePopulation";
import EmployeesAssignedProjectPerMonth from "./EmployeesAssignedProjectPerMonth";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import ProjectsStatus from "./ProjectsStatus";
import EmployeeBasedOnRoles from "./EmployeeBasedOnRoles";
import RecruitmentAcrossMonths from "./RecruitmentAcrossMonths";
import { WidthFull } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function Insights() {
  const dispatch = useDispatch();
  const Insights = useSelector(
    (state) => state?.Data?.Resources?.Insights?.data
  );

  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  console.log(selectedRegion?._id, "regions23");

  const navigate = useNavigate();
  useEffect(() => {
    if (selectedRegion && selectedRegion?._id)
      dispatch(getInsightsData(selectedRegion?._id));
  }, [dispatch, selectedRegion]);

  // Handle the case where Insights data is empty or not loaded yet
  if (!Insights || Insights?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Extract relevant data for LineChart
  const selectedCandidates = Insights[0]?.selectedCandidates || [];
  const outerBoxStyle = {
    // width:"443px",
    // height:"360px",
    backgroundColor: "white",
    padding: 4,
    borderRadius: "10px",
    height: "100%", // Full height for each box
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const innerBoxStyle = {
    backgroundColor: "#f0f0f0", // Grey background for the chart content
    padding: 1, // Padding inside the grey area
    borderRadius: 1, // Optional: rounded corners
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const typographyStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "20px",

    color: "#000000",
    fontWeight: 500,
    lineHeight: "30px",
    textAlign: "center",
    marginBottom: "16px",
  };
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <GraphHeader />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box sx={outerBoxStyle}>
          <Typography sx={typographyStyle}>
            Total Vs Billable Employees
          </Typography>
          <Box sx={innerBoxStyle}>
            <WorkingPercentage />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box sx={outerBoxStyle}>
          <Typography sx={typographyStyle}>
            No. of Employees Assigned To Projects Across Months
          </Typography>
          <Box sx={innerBoxStyle}>
            <EmployeesAssignedProjectPerMonth />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box sx={outerBoxStyle}>
          <Typography sx={typographyStyle}>
            Project Status Across Months
          </Typography>
          <Box sx={innerBoxStyle}>
            <ProjectsStatus />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box sx={outerBoxStyle}>
          <Typography sx={typographyStyle}>
            Recruitment Across Months
          </Typography>
          <Box sx={innerBoxStyle}>
            <RecruitmentAcrossMonths linearChartdata={selectedCandidates} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box sx={outerBoxStyle}>
          <Typography sx={typographyStyle}>Employees Based on Roles</Typography>
          <Box sx={innerBoxStyle}>
            <EmployeeBasedOnRoles />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box sx={outerBoxStyle}>
          <Typography sx={typographyStyle}>
            Company Growth Across Years
          </Typography>
          <Box sx={innerBoxStyle}>
            <YearWisePopulation />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Insights;
