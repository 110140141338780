import { Box, TextField } from "@mui/material";
import React from "react";
import "./AddEvent.css";
import { useState } from "react";
import { addNoticeData } from "../../../../redux/dataSlice";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const AddEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Data = useSelector((state) => state.Data.Resources.Notice_Details);

  const [formData, setFormData] = useState({
    title: "",
    description: " ",
    status: "active",
    image: "",
  });

  const handleActive = () => {
    if (formData.status === "active") {
      setFormData({
        ...formData,
        status: "Inactive",
      });
    } else {
      setFormData({
        ...formData,
        status: "active",
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const convertToBase64 = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setFormData((prevState) => ({
        ...prevState,
        image: reader.result,
      }));
    };
    reader.onerror = (error) => {
      console.log("Error", error);
    };
  };

  const notifySuccess = () => toast.success("Added Successfully...!");
  const notifyError = () => toast.error("Please provide required fields");

  const handleSubmit = async () => {
    try {
      const response = await dispatch(addNoticeData(formData));
      if (addNoticeData.fulfilled.match(response)) {
        notifySuccess();
        setTimeout(() => {
          //window.location.reload();
          navigate("/");
        }, 1000);
      } else {
        notifyError();
      }
    } catch (error) {
      console.log("Error adding notice data:", error);
      notifyError(error.message);
    }
  };

  return (
    <Box className="main_container">
      <div className="event_container">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="title">
              Title <span className="required-star">*</span>
            </label>
            <TextField
              id="title"
              name="title"
              variant="outlined"
              style={{ margin: "10px 0", width: "100%" }}
              value={formData.title}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="image">Upload Image</label>
            <TextField
              id="image"
              accept="image/"
              type="file"
              name="image"
              variant="outlined"
              style={{ margin: "10px 0", width: "100%" }}
              onChange={convertToBase64}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} container>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              style={{ marginLeft: "30px", display: "flex" }}
            >
              <label style={{ marginTop: "8px" }}>Status</label>
              <FormGroup style={{ marginLeft: "15px" }}>
                <FormControlLabel
                  control={
                    <Switch
                      onClick={handleActive}
                      checked={formData.status === "active"}
                    />
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="description">Description</label>
            <TextField
              id="description"
              name="description"
              variant="outlined"
              style={{ margin: "10px 0", width: "100%" }}
              onChange={handleInputChange}
              value={formData.description}
              multiline
              rows={4}
              rowsMax={4}
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ display: "flex", marginTop: "20px" }}>
        {formData.image === "" || formData.image == null ? (
          " "
        ) : (
          <img
            width={600}
            height={300}
            alt="img"
            src={formData.image}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)" }}
          />
        )}
      </div>

      <div className="noticeButton">
        <Button
          variant="contained"
          color="success"
          type="submit"
          startIcon={<SendIcon />}
          onClick={handleSubmit}
          className="noticeButton"
        >
          Submit
        </Button>
      </div>
      <ToastContainer />
    </Box>
  );
};

export default AddEvent;
