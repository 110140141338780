import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    // {
    //     field: 'firstName',
    //     headerName: 'First name',
    //     width: 150,
    //     editable: true,
    // },
    // {
    //     field: 'lastName',
    //     headerName: 'Last name',
    //     width: 150,
    //     editable: true,
    // },
    {
        field: 'Name',
        headerName: 'Name',
        width: '200',
        type: 'text'
    },
    {
        field: 'Role',
        headerName: 'Role',
        width: 170,
        type: 'text'
    },
    {
        field: 'PreEliminaryScreening',
        headerName: 'PreEliminary-Screening',
        type: 'text',
        width: 165,
        editable: true,
    },
    {
        field: 'L1Screening',
        headerName: 'L1-Screening',
        description: 'This column has a value getter and is not sortable.',
        type: 'text',
        width: 160,

    },
    {
        field: 'L2Screening',
        headerName: 'L2-Screening',
        type: 'text',
        width: '160'
    },
    {
        field: 'Status',
        headerName: 'Status',
        type: 'text',
        width: '140'
    }
];


const rows = [
    { id: 1, Name: 'Snow', Role: 'Full-Stack Developer', PreEliminaryScreening: 'Selected', L1Screening: 'Selected', L2Screening: 'Selected', Status: 'Selected' },
    { id: 2, Name: 'Snow', Role: 'Full-Stack Developer', PreEliminaryScreening: 'Selected', L1Screening: 'Selected', L2Screening: 'Selected', Status: 'Selected' },
];

export default function DataGridDemo() {
    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    );
}
