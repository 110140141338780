import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const EmployeeBasedOnRoles = () => {
    const Insights = useSelector((state) => state?.Data?.Resources?.Insights?.data) || [];

    // Extract roles data from Insights
    const rolesData = Insights?.length > 0 ? Array.from(Insights)[0]?.employeesByRole : [];

    // State for series and options
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({
        chart: {
            width: 380,
            type: "pie",
        },
        labels: [],
        legend: {
            position: "bottom", // Move legend to the bottom
            horizontalAlign: "center", // Align legend in the center
            fontSize: '14px',
            labels: {
                colors: ['#333'], // Customize the label color if needed
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 250,
                    },
                    legend: {
                        position: "bottom",
                        horizontalAlign: "center",
                    },
                },
            },
        ],
        dataLabels: {
            enabled: true,
            formatter: (val, { seriesIndex }) => {
                return series[seriesIndex]; // Show the actual number
            },
            style: {
                colors: ["#ffffff"], // Customize text color if needed
            },
        },
        tooltip: {
            y: {
                formatter: (val) => {
                    return val; // Show the actual number in the tooltip
                },
            },
        },
    });

    // Update series and options when rolesData changes
    useEffect(() => {
        if (Array.isArray(rolesData) && rolesData?.length > 0) {
            const newSeries = rolesData?.map(role => role?.count || 0);
            const newLabels = rolesData?.map(role => role?._id || 'Unknown');

            setSeries(newSeries);
            setOptions(prevOptions => ({
                ...prevOptions,
                labels: newLabels,
            }));
        } else {
            setSeries([]);
            setOptions(prevOptions => ({
                ...prevOptions,
                labels: [],
            }));
        }
    }, [rolesData]);

    return (
        <div className="pie-chart" style={{
            width: "324px",
height: "272px"

        }}>
            
            <ReactApexChart
                options={options}
                series={series}
                type="pie"
            // width={250}
            // height={300}
            />
        </div>
    );
};

export default EmployeeBasedOnRoles;
