import { Navigate, Route } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = ({ element: Component, roles, ...rest }) => {
  const isAuthenticated = sessionStorage.getItem("jwt_token");
  const userRole = sessionStorage.getItem("role")?.split(",") || [];
  console.log("test::::", roles, isAuthenticated, userRole)
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // if (roles && !userRole.some((r) => roles.includes(r))) {
  //   return <Navigate to="/noaccess" />;
  // }

  return <Route {...rest} element={<Component />} />;
};

export default ProtectedRoute;
