import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RegionApi } from "../utills/apiCalls";

const token = sessionStorage.getItem("jwt_token");

const config = {
  headers: {
    Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token value
  },
};

export const getAllRegionData = createAsyncThunk(
  "redux/getAllRegionData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${RegionApi}`, config);
      console.log(response, "region response");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

const regionsSlice = createSlice({
  name: "regions",
  initialState: {
    data: [],
    loading: false,
    error: "",
    selectedRegion: {},
  },
  reducers: {
    setSelectedRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRegionData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRegionData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        if (action.payload.length > 0) {
          state.selectedRegion = {
            _id: action.payload[0]._id,
            name: action.payload[0].RegionName,
          };
        }
      })
      .addCase(getAllRegionData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const { setSelectedRegion } = regionsSlice.actions;
export default regionsSlice.reducer;
