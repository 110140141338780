import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Grid } from "@mui/material";
import "./index.css";
import {
  createConfigData,
  updateConfigData,
} from "../../../../redux/dataSlice";

const AddConfigItem = ({ editMode, state, onClose }) => {
  const navigate = useNavigate();
  const { addButtonName, name, addTabName } = state;

  const [creating, setCreating] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: name || "",
  });
  const [errors, setErrors] = useState({
    name: "",
  });

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "name":
        if (!value) error = `${addButtonName} is required`;
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) errors.name = `${addButtonName} is required`;
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setCreating(true);
      let finalData = {
        ...{
          name: formData?.name,
          value: formData?.name,
        },
      };

      try {
        let response;
        if (editMode) {
          response = await dispatch(
            updateConfigData({ addTabName, name, finalData })
          )
            .then((response) => {
              // if (response?.data?.statusCode === 401) {
              //     navigate("/session-expired")
              // }
              onClose();
              toast.success(`${addButtonName} updated successfully...!`);
            })
            .catch(() => {
              toast.error("An error occurred. Please try again.");
            });
        } else {
          // Dispatch the create action here
          response = await dispatch(createConfigData({ addTabName, finalData }))
            .then((response) => {
              // if (response?.data?.statusCode === 401) {
              //   navigate("/session-expired");
              // }
              onClose();
              toast.success(`New ${addButtonName} added successfully...!`);
            })
            .catch(() => {
              toast.error("An error occurred. Please try again.");
            });
        }
        setCreating(false);
        if (!response?.error) {
          setTimeout(() => {
            navigate("/config",{
              state: { headerText: 'Config' }  // State must be an object
            });
          }, 2000);
        }
      } catch (error) {
        setCreating(false);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    validateField(name, value);
  };

  return (
    <div
      className="project-form-container"
      style={{
        height: "270px",
        width: "400px",
        margin: "auto",
      }}
    >
      <div className="title-header">
        <h3>Add {addButtonName}</h3>
        <div className="close-container" onClick={onClose}>
          {/* <Link to="/config"> */}
          <AiOutlineClose className="back-option" />
          {/* </Link> */}
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="label-card">
              <label htmlFor="name">
                Name <span className="required-star">*</span>
              </label>
              <input
                type="text"
                name="name"
                className="project_input-feald1"
                placeholder="Enter Name"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.name && (
              <div className="error-text">
                <span>{errors.name}</span>
              </div>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div className="employee-Actions-container">
            <button className="employee-Action-btn" type="submit">
              {editMode
                ? creating
                  ? "Updating..."
                  : "Update"
                : creating
                ? "Creating..."
                : "Create"}
            </button>
          </div>
        </Grid>
      </form>

      <ToastContainer />
    </div>
  );
};

export default AddConfigItem;
