import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    DataGrid,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { deleteConfigData, fetchConfigData } from "../../../../redux/dataSlice";
import AddConfigItem from "./AddConfigItem";
import { GridToolbarColumnsButton } from "@mui/x-data-grid";

const CustomToolbar = ({
    onAddEmployeeClick,
    buttonText,
    showAddButton,
    ...props
}) => {
    const userRole = sessionStorage.getItem("role").split(",");

    const styles = {
        Button: {
            marginLeft: 20,
            backgroundColor: "#8A2BE2",
            color: "white",
            border: "none",
            padding: "8px 16px",
            borderRadius: "4px",
            cursor: "pointer",
            marginBottom: 5,
            marginRight: 10,
            marginRight: 10,
            textTransform: "capitalize",
        },
    };

    return (
        <GridToolbarContainer
            style={{ display: "flex", justifyContent: "space-between" }}
            {...props}
        >
            <div>
                <GridToolbarColumnsButton sx={{ marginRight: "10px" }} />
                <GridToolbarFilterButton sx={{ marginRight: "10px" }} />
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <GridToolbarQuickFilter
                    debounceMs={500}
                    sx={{
                        "& .MuiInputBase-root": {
                            backgroundColor: "#ffffff" /* White background */,
                            borderRadius: "25px" /* Rounded corners */,
                            padding: "12px 20px" /* Padding inside the input */,
                            width: "300px" /* Input width */,
                            height: "43px" /* Input height */,
                            border: "none" /* Remove border */,
                            boxShadow:
                                "0 2px 6px rgba(0, 0, 0, 0.1)" /* Subtle shadow for depth */,
                            fontSize: "16px" /* Font size */,
                            color: "#2196f3" /* Bright blue text */,
                            transition:
                                "box-shadow 0.3s ease, background-color 0.3s ease" /* Smooth transition for shadow and background */,
                            outline: "none" /* Remove default focus outline */,
                            position:
                                "relative" /* Ensure pseudo-elements are positioned relative to the input */,
                            "&::before": {
                                content: '""',
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                border: "none" /* Ensure no border */,
                                borderRadius: "25px" /* Match border radius */,
                                boxShadow:
                                    "0 2px 6px rgba(0, 0, 0, 0.1)" /* Same shadow as the input */,
                                zIndex: -1 /* Place behind the input */,
                            },
                            "&::after": {
                                content: '""',
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                border: "none" /* Ensure no border */,
                                borderRadius: "25px" /* Match border radius */,
                                boxShadow:
                                    "0 2px 6px rgba(0, 0, 0, 0.1)" /* Same shadow as the input */,
                                zIndex: -1 /* Place behind the input */,
                            },
                            "&:hover": {
                                backgroundColor: "#f0f4f8" /* Very light blue on hover */,
                                boxShadow:
                                    "0 4px 10px rgba(0, 0, 0, 0.15)" /* More pronounced shadow on hover */,
                            },
                            "& .MuiInputBase-input": {
                                color: "#000000",
                                padding: "0 12px" /* Padding inside the input */,
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#9e9e9e" /* Light grey placeholder text */,
                                fontSize: "16px" /* Placeholder font size */,
                            },
                            "& .MuiInputBase-root.Mui-focused": {
                                boxShadow:
                                    "0 4px 10px rgba(0, 0, 0, 0.15)" /* Shadow on focus */,
                                borderColor: "transparent",
                            },
                        },
                        marginRight: "10px",
                        paddingTop: "3px",
                        paddingBottom: "8px",
                    }}
                />

                {userRole.some((role) => showAddButton.includes(role)) ? (
                    <Button
                        onClick={onAddEmployeeClick}
                        startIcon={<AddCircleOutlineIcon />}
                        style={styles.Button}
                    >
                        {buttonText}
                    </Button>
                ) : null}
            </div>
        </GridToolbarContainer>
    );
};

const ConfigGrid = ({
    addButtonName,
    addTabName,
    rows,
    getRowId,
    updatedRows,
    handleCellEditChange,
    editingRowId,
    loading,
}) => {
    console.log(
        addButtonName,
        addTabName,
        rows,
        getRowId,
        updatedRows,
        handleCellEditChange,
        editingRowId,
        loading,
        "PROPS"
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState("");
    const [deleteId, setDeleteId] = useState("");

    const userRole = sessionStorage.getItem("role").split(",");

    const [filterModel, setFilterModel] = useState({
        items: [{ columnField: "id", value: "", operatorValue: "contains" }],
    });
    const [selectionModel, setSelectionModel] = useState([]);
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "asc",
        },
    ]);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);

    // useEffect(() => {
    //     dispatch(fetchData());
    // }, [dispatch]);

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel.selectionModel);
    };

    const handleFilterModelChange = (newFilterModel) => {
        setFilterModel(newFilterModel);
    };

    const handleSortModelChange = (newSortModel) => {
        setSortModel(newSortModel.sortModel);
    };

    const handlePageSizeChange = (params) => {
        const newPageSize = params;
        if (newPageSize !== pageSize) {
            setPageSize(newPageSize);
            setPage(0);
        }
    };

    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const handleEdit = (id, name) => {
        // userRole.some((role) => ["ADMIN"].includes(role))
        //     ? navigate(`/config/${id}`, { state: { addButtonName: addButtonName, name: name } })
        //     : navigate("/noaccess");

        if (userRole.some((role) => ["ADMIN"].includes(role))) {
            setEditOpen(true);
            setName(name);
            // dispatch(deleteConfigData({ addButtonName, deleteId: id }))
            //     .then(() => {
            //         dispatch(fetchConfigData())
            //         toast.success("Project Deleted successfully...!");
            //     })
            //     .catch(() => {
            //         toast.error("An error occurred. Please try again.");
            //     });
        } else {
            navigate("/noaccess");
        }
    };

    const handleDelete = (id) => {
        if (userRole.some((role) => ["ADMIN"].includes(role))) {
            dispatch(deleteConfigData({ addTabName, deleteId: id }))
                .then((response) => {
                    // if (response?.data?.statusCode === 401) {
                    //     navigate("/session-expired")
                    // }
                    dispatch(fetchConfigData());
                    toast.success(`${addButtonName} Deleted successfully...!`);
                })
                .catch(() => {
                    toast.error("An error occurred. Please try again.");
                });
        } else {
            navigate("/noaccess");
        }
    };

    const columns = [
        { field: "id", headerName: "ID", width: 100, align: "left" },
        {
            field: "name",
            headerName: "Name",
            width: 350,
            align: "left",
        },
        {
            field: "value",
            headerName: "Value",
            width: 350,
            align: "left",
        },
        {
            field: "edit",
            headerName: "Actions",
            filterable: false,
            sortable: false,
            width: 200,
            align: "left",
            renderCell: (params) => (
                <div style={{ cursor: "pointer" }}>
                    <strong>
                        <Tooltip placement="top" title={"Edit"}>
                            <IconButton
                                style={{ color: "#24CCEF", cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit(params.row.id, params.row.name);
                                    setEditOpen(true);
                                    setEditMode(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title={"Delete"}>
                            <IconButton
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => {
                                    setOpen(true);
                                    setDeleteId(params.row.name);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </strong>
                </div>
            ),
        },
    ];

    const handleAdd = () => {
        setEditOpen(true);
        setEditMode(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        backgroundColor: "#f0f0f0",
                        color: "black",
                        boxShadow: "none",
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete this Project?"}
                </DialogTitle>
                <DialogContent>
                    <Typography color="textPrimary">
                        Are you sure you want to delete this Project?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <button
                        onClick={() => setOpen(false)}
                        className="dialog-cancel-btn"
                        color="primary"
                    >
                        Cancel
                    </button>
                    <button
                        className="dialog-delete-btn"
                        onClick={() => {
                            handleDelete(deleteId);
                            setOpen(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Delete
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={editOpen}
                onClose={() => {
                    setEditOpen(false);
                    dispatch(fetchConfigData());
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        backgroundColor: "#f0f0f0",
                        color: "black",
                        boxShadow: "none",
                    },
                }}
            >
                <AddConfigItem
                    editMode={editMode}
                    onClose={() => {
                        setEditOpen(false);
                        dispatch(fetchConfigData());
                    }}
                    state={{
                        addButtonName: addButtonName,
                        addTabName: addTabName,
                        name: editMode ? name : "",
                    }}
                />
            </Dialog>
            {/* <Box
                sx={{
                    width: "100%",
                    // paddingTop: 3,
                    "@media (min-width: 2560px)": {
                        marginTop: "60px",
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",

                        "@media (min-width: 2560px)": {
                            marginRight: "60px",
                            fontSize: "300",
                        },
                    }}
                >
                    {userRole.some((role) => ["ADMIN"].includes(role)) ? (
                        <Button
                            // onClick={() => navigate("/addConfig", { state: { addButtonName: addButtonName } })}
                            onClick={() => {
                                setEditOpen(true);
                                setEditMode(false)
                            }}
                            startIcon={<AddCircleOutlineIcon />}
                            style={styles.Button}
                        >
                            {`Create ${addButtonName}`}
                        </Button>
                    ) : null}
                </Box>
            </Box> */}

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        height: "72vh",
                        width: "100%",
                        backgroundColor: "white",
                    }}
                >
                    <DataGrid
                        sx={{
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "bold",
                                color: "rgba(0, 0, 0, 0.8)",
                                fontSize: "14px",
                                textAlign: "center",
                                textTransform: "full-width",
                                padding: "8px",
                            },
                            "& .MuiDataGrid-row": {
                                minHeight: "45px !important", // Set minimum height for rows
                                height: "45px !important", // Set height for rows
                            },
                            "& .MuiDataGrid-cell": {
                                minHeight: "45px !important", // Set minimum height for rows
                                height: "45px !important", // Set height for rows
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#f5f5f5", // Light purple background color
                                color: "#000",
                                fontWeight: "bold", // Make headers bold
                            },
                        }}
                        rowHeight={45} // Set row height to 45px
                        rowsPerPageOptions={[10, 20, 50]}
                        pageSize={pageSize}
                        page={page}
                        onPageChange={handlePageChange}
                        onPageSizeChange={(params) => handlePageSizeChange(params)}
                        rows={rows}
                        getRowId={null}
                        // rows={updatedRows || rows}
                        // getRowId={getRowId ? getRowId : null}
                        columns={columns}
                        pagination
                        // loading={loading}
                        getRowHeight={() => 45} // Ensures the row height is always 45px
                        onSelectionModelChange={handleSelectionModelChange}
                        selectionModel={selectionModel}
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        filterModel={filterModel}
                        onFilterModelChange={handleFilterModelChange}
                        disableDensitySelector
                        disableSelectionOnClick
                        //   disableColumnSelector={configData ? true : false}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                printOptions: { disableToolbarButton: true },
                                exportButton: false,
                                onAddEmployeeClick: handleAdd,
                                buttonText: `Create ${addButtonName}`,
                                showAddButton: ["ADMIN"],
                            },
                        }}
                        isCellEditable={(params) =>
                            params.field === "name" && editingRowId === params.row.id
                        }
                        onCellEditCommit={handleCellEditChange}
                    />
                </div>

                <ToastContainer />
            </div>
        </>
    );
};

export default ConfigGrid;
