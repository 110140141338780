
import './index.css'

import React from 'react';

const Loader = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? (
        <div className="loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Loader;
