import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DvrIcon from "@mui/icons-material/Dvr";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import DeblurIcon from "@mui/icons-material/Deblur";
import StorageIcon from "@mui/icons-material/Storage";
import "./Sidebar.css";
import { useNavigate, Link, useParams, Outlet } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Collapse } from "@mui/material";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import AccessibilityIcon from "@mui/icons-material/Accessibility";

import FestivalIcon from "@mui/icons-material/Festival";
import RoutesComponent from "../../RoutesComponent/RoutesComponent";

const Sidebar = () => {
  const navigate = useNavigate();

  // const [openProject, setOpenProject] = useState(false);
  // const [openRecruitment, setOpenRecruitment] = useState(false);

  const [openJob, setOpenJob] = useState(false);
  const location = useLocation();
  const { empid, jobId, id, applicationId } = useParams();

  const userRole =  sessionStorage.getItem("role").split(",");

  const handleJobClick = () => {
    setOpenJob(!openJob);
  };
  // const handleRecruitmentClick = () => {
  //   setOpenRecruitment(!openRecruitment);
  // };

  const notifySuccess = () => toast.success("Logout Successfully...!");

  const onClickLogout = () => {
    // Cookies.remove("jwt_token");
    // Cookies.remove("userId");
    // Cookies.remove("user");
    // Cookies.remove("role");
    // Cookies.remove("isAuthenticated");
    // Cookies.set("isAuthenticated", false);
    sessionStorage.clear()
    localStorage.clear();

    notifySuccess();
    setTimeout(() => {
      // <Link to="/" className="not-found-link"></Link>
      navigate("/login", { replace: true });
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="sidebar-container">
      <div className="website-logo">
        <Link to="/" className="link-item">
          <img
            src="https://secureservercdn.net/160.153.137.91/nhd.f13.myftpupload.com/wp-content/uploads/2022/06/logo-1-14.svg?time=1665549221"
            alt="website logo"
          />
        </Link>
      </div>
      <Divider />
      <div className="sidebar">
        <List className="List">
          <div>
            <ListItem
              disablePadding
              onClick={() => navigate("overview")}
              className="ListItem"
            >
              <ListItemButton
                selected={location.pathname === "/recruitmentHome/overview"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === "/recruitmentHome/overview"
                        ? "#fff"
                        : "initial",
                  }}
                >
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText>Home</ListItemText>
              </ListItemButton>
            </ListItem>
          </div>

          {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
            <ListItem
              button
              onClick={() => navigate("/recruitmentHome/employeeScreening")}
              className="ListItem"
              selected={[
                "/recruitmentHome/employeeScreening",
                "employeeScreening",
                "addApplication",
                `updateApplication/${applicationId}`,
              ].includes(location.pathname)}
              
              sx={{
                paddingTop: "20px",
                "&.Mui-selected": {
                  backgroundColor: "#6A5ACD",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFA500",
                  },
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === "/recruitmentHome/employeeScreening"
                      ? "#fff"
                      : "initial",
                }}
              >
                <AccessibilityIcon />
              </ListItemIcon>
              <ListItemText
                primary=" Candidates"
                // sx={{ marginLeft: "30px", fontWeight: "800" }}
              />
            </ListItem>
          )}

          <div>
            <ListItem
              disablePadding
              onClick={handleJobClick}
              className="ListItem"
            >
              <ListItemButton selected={location.pathname === "/jobs"}>
                <ListItemIcon>
                  <DeblurIcon
                    sx={{
                      color: location.pathname === "/jobs" ? "#fff" : "initial",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Jobs" />
                {openJob ? (
                  <ExpandLessIcon color="primary" />
                ) : (
                  <ExpandMoreIcon color="primary" />
                )}
              </ListItemButton>
            </ListItem>
            <Collapse
              in={openJob}
              timeout="auto"
              unmountOnExit
              sx={{ textAlign: "center" }}
            >
              <ListItem
                button
                onClick={() =>

                 navigate("manageJob",{
                  state: { headerText: 'Jobs' }  // State must be an object
                })
                }
                className="NestedListItem"
                selected={[
                  "/recruitmentHome/manageJob",
                  "addjob",
                  `editJob/${jobId}`,
                ].includes(location.pathname)}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemText
                  primary="Current Openings"
                  sx={{ marginLeft: "30px", fontWeight: "800" }}
                />
              </ListItem>
              {/* {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
                <ListItem
                  button
                  onClick={() => navigate("employeeScreening")}
                  className="NestedListItem"
                  selected={[
                    "employeeScreening",
                    "addApplication",
                    `updateApplication/${applicationId}`,
                  ].includes(location.pathname)}
                  sx={{
                   "&.Mui-selected": {
                      backgroundColor: "#6A5ACD",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#FFA500",
                      },
                    },
                  }}
                >
                  <ListItemText
                    primary=" Employee Screening"
                    sx={{ marginLeft: "30px", fontWeight: "800" }}
                  />
                </ListItem>
              )} */}
              {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
                <ListItem
                  button
                  onClick={() => navigate("/recruitmentHome/addjob",{
                    state: { headerText: 'Jobs' }  // State must be an object
                  })}
                  className="NestedListItem"
                  selected={[
                    "/recruitmentHome/addjob",
                    "employeeScreening",
                    "addApplication",
                    `updateApplication/${applicationId}`,
                  ].includes(location.pathname)}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#6A5ACD",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#FFA500",
                      },
                    },
                  }}
                >
                  <ListItemText
                    primary=" Add Opening"
                    sx={{ marginLeft: "30px", fontWeight: "800" }}
                  />
                </ListItem>
              )}
            </Collapse>
          </div>
          {/* Users */}
          {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
            <ListItem
              disablePadding
              onClick={() => navigate("/recruitmentHome/interviews")}
              className="ListItem"
            >
              <ListItemButton
                selected={location.pathname === "/recruitmentHome/interviews"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <PersonPinIcon
                    sx={{
                      color:
                        location.pathname === "/recruitmentHome/interviews"
                          ? "#fff"
                          : "initial",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Interviews</ListItemText>
              </ListItemButton>
            </ListItem>
          )}

          {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
            <div>
              <ListItem
                disablePadding
                onClick={() => {
                  navigate("reports");
                }}
                className="ListItem"
              >
                <ListItemButton selected={location.pathname === "reports"}>
                  <ListItemIcon>
                    <Diversity3Icon
                      sx={{
                        color:
                          location.pathname === "reports" ? "#fff" : "initial",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItemButton>
              </ListItem>
              {/* <Collapse in={openRecruitment} timeout="auto" unmountOnExit>
                <ListItem
                  button
                  onClick={() =>
                    userRole.some((role) =>
                      ["ADMIN", "HR", "EMPLOYEE"].includes(role)
                    )
                      ? navigate("/manageJob")
                      : navigate("/noaccess")
                  }
                  className="NestedListItem"
                  selected={[
                    "/manageJob",
                    "/addjob",
                    `/editJob/${jobId}`,
                  ].includes(location.pathname)}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#6A5ACD",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#FFA500",
                      },
                    },
                  }}
                >
                  <ListItemText
                    primary="Current Openings"
                    sx={{ marginLeft: "30px", fontWeight: "800" }}
                  />
                </ListItem>

                {userRole.some((role) => ["ADMIN", "HR"].includes(role)) && (
                  <ListItem
                    button
                    onClick={() => navigate("/employeeScreening")}
                    className="NestedListItem"
                    selected={[
                      "/employeeScreening",
                      "/addApplication",
                      `/updateApplication/${applicationId}`,
                    ].includes(location.pathname)}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#6A5ACD",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#FFA500",
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary=" Employee Screening"
                      sx={{ marginLeft: "30px", fontWeight: "800" }}
                    />
                  </ListItem>
                )}
                {userRole.some((role) =>
                  ["ADMIN", "HR", "Employee"].includes(role)
                ) && (
                  <ListItem
                    className="NestedListItem"
                    onClick={() => navigate("/recruitmentHome")}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#6A5ACD",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#FFA500",
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary=" Home"
                      sx={{ marginLeft: "30px", fontWeight: "800" }}
                    />
                  </ListItem>
                  )}
              </Collapse> */}
            </div>
          )}

          <ListItem
            disablePadding
            onClick={() => {
              navigate("referral");
            }}
            className="ListItem"
          >
            <ListItemButton
              selected={location.pathname === "/recruitmentHome/referral"}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#6A5ACD",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFA500",
                  },
                },
              }}
            >
              {/* <Outlet /> */}
              <ListItemIcon>
                <ManageAccountsIcon
                  sx={{
                    color:
                      location.pathname === "/recruitmentHome/referral"
                        ? "#fff"
                        : "initial",
                  }}
                />
              </ListItemIcon>

              <ListItemText>Refferal</ListItemText>
            </ListItemButton>
          </ListItem>

          {userRole.some((role) => ["ADMIN"].includes(role)) && (
            <ListItem
              disablePadding
              onClick={() => navigate("/notice")}
              className="ListItem"
            >
              <ListItemButton
                selected={location.pathname === "/notice"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <FestivalIcon
                    sx={{
                      color:
                        location.pathname === "/notice" ? "#fff" : "initial",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Add Event</ListItemText>
              </ListItemButton>
            </ListItem>
          )}

          {userRole.some((role) => ["ADMIN"].includes(role)) && (
            <ListItem
              disablePadding
              onClick={() => navigate("/config",{
                state: { headerText: 'Config' }  // State must be an object
              })}
              className="ListItem"
            >
              <ListItemButton
                selected={location.pathname === "/config"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#6A5ACD",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFA500",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <StorageIcon
                    sx={{
                      color:
                        location.pathname === "/config" ? "#fff" : "initial",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Config Page</ListItemText>
              </ListItemButton>
            </ListItem>
          )}
          <ListItem
            disablePadding
            onClick={() => navigate("/")}
            className="ListItem"
          >
            <ListItemButton
              selected={location.pathname === "/"}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#6A5ACD",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFA500",
                  },
                },
              }}
            >
              <ListItemIcon
                sx={{ color: location.pathname === "/" ? "#fff" : "initial" }}
              >
                <ArrowBackIcon />
              </ListItemIcon>
              <ListItemText>Back to Dashboard</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </div>
      {/* <RoutesComponent /> */}
    </div>
  );
};
export default Sidebar;

// <ListItem
// button
// onClick={() =>
//   userRole.some((role) => ["ADMIN", "HR"].includes(role))
//     ? navigate("/manageArchiveApplication")
//     : navigate("/noaccess")
// }
// className="NestedListItem"
// selected={location.pathname === "/manageArchiveApplication"}
// sx={{
//   "&.Mui-selected": {
//     backgroundColor: "#6A5ACD",
//     color: "#fff",
//     "&:hover": {
//       backgroundColor: "#FFA500",
//     },
//   },
// }}
// >
// <ListItemText
//   primary="Manage Job Applicants"
//   sx={{ marginLeft: "30px", fontWeight: "800" }}
// />
// </ListItem>

// <ListItem
// button
// onClick={() =>
//   userRole.some((role) => ["ADMIN", "HR"].includes(role))
//     ? navigate("/manageJobOnBoarding")
//     : navigate("/noaccess")
// }
// className="NestedListItem"
// selected={location.pathname === "/manageJobOnBoarding"}
// sx={{
//   "&.Mui-selected": {
//     backgroundColor: "#6A5ACD",
//     color: "#fff",
//     "&:hover": {
//       backgroundColor: "#FFA500",
//     },
//   },
// }}
// >
// <ListItemText
//   primary="Manage Job On-Boarding"
//   sx={{ marginLeft: "30px", fontWeight: "800" }}
// />
// </ListItem>

// <ListItem
// button
// onClick={() =>
//   userRole.some((role) => ["ADMIN", "HR"].includes(role))
//     ? navigate("/manageInterviewSchedule")
//     : navigate("/noaccess")
// }
// className="NestedListItem"
// selected={location.pathname === "/manageInterviewSchedule"}
// sx={{
//   "&.Mui-selected": {
//     backgroundColor: "#6A5ACD",
//     color: "#fff",
//     "&:hover": {
//       backgroundColor: "#FFA500",
//     },
//   },
// }}
// >
// <ListItemText
//   primary="Manage Interview Schedule"
//   sx={{ marginLeft: "30px", fontWeight: "800" }}
// />
// </ListItem>
