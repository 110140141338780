import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@material-ui/core";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllEmployeeData,
  getAllEmployeeByRegion,
  addProjectAssignees,
} from "../../../redux/dataSlice";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableContainer,
  Checkbox,
} from "@mui/material";
import CreatableSelect from "react-select/creatable";

export default function FormDialog({
  openModal,
  setModal,
  currentProject,
  setCurrentProject,
}) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    projectTeam: [],
  });
  const [tableData, setTableData] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const dispatch = useDispatch();
  const employees = useSelector(
    (state) => state?.Data?.Resources?.employee?.data
  );
  const Data = useSelector((state) => state.Data);
  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  console.log(selectedRegion?._id, "regions2");
  const handleTableChange = (index, field, value) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      if (field === "percentage") {
        updatedTableData[index][field] = Number(value);
      } else if (field === "billable") {
        updatedTableData[index][field] = !updatedTableData[index][field];
      } else if (field === "role") {
        updatedTableData[index][field] = value;
      } else if (field === "startDate") {
        updatedTableData[index][field] = new Date(value)
          .toISOString()
          .split("T")[0];
      } else if (field === "endDate") {
        updatedTableData[index][field] = new Date(value)
          .toISOString()
          .split("T")[0];
      }
      return updatedTableData;
    });
  };

  var data;

  if (Data.Resources !== undefined) {
    data = Data.Resources.Employee_Details;
  }
  const ConfigData = useSelector(
    (state) => state.Data.Resources.ConfigData_Details
  );
  // const generateUniqueId = () => {
  //   return 'id-' + Date.now() + '-' + Math.random().toString(36).substr(2, 9);
  // };
  const generateUniqueId = () => {
    // Generate a timestamp
    const timestamp = Date.now().toString(36); // Convert to base-36
    // Generate a random string
    const randomPart = Math.random().toString(36).substr(2, 9); // 9 characters
    // Concatenate parts with a prefix
    return `uid-${timestamp}-${randomPart}`;
};

  const handleSelectChange = (selectedOption, selectedName) => {
  const selectedArray = Array.isArray(selectedOption)
    ? selectedOption
    : [selectedOption];
  console.log("selected Array from handle select change",selectedArray)
  setFormData((prevFormData) => ({
    ...prevFormData,
    [selectedName]: selectedOption,
  }));

  setTableData((prevTableData) => {
    if (selectedName === "projectTeam") {
      const selectedIds = selectedArray.map((item) => {
        // If the item is a free-text option, generate a new unique ID
        return item.__isNew__ ? generateUniqueId() : item.value;
      });

      const filteredTableData = prevTableData.filter((row) =>
        selectedIds.includes(row.id)
      );

      const newEntries = selectedArray
        .filter((item) => {
          const isExisting = filteredTableData.some((row) => row.id === item.value);
          return !isExisting; // Only keep items that are not already in the filtered data
        })
        .map((item) => ({
          id: item.__isNew__ ? generateUniqueId() : item.value, // Generate unique ID if new
          name: item.label,
          percentage: 100,
          billable: true,
          role: "Select role",
          startDate: new Date(),
          endDate: "",
        }));

      return [...filteredTableData, ...newEntries];
    }
    return prevTableData; // If not projectTeam, return previous data
  });
};

  useEffect(() => {
    const data = {};
    const newTableData = [];
    console.log("currentproject assign modal", currentProject);
    if (currentProject.projectTeam) {
      data["projectTeam"] = currentProject.projectTeam.map((item) => ({
        label: item.email ? item.email : "",
        value: item._id,
      }));
      currentProject.projectTeam.forEach((item) => {
        newTableData.push({
          id: item._id,
          name: `${item.email ? item.email : ""} ${
            item.LastName ? item.LastName : ""
          }`,
          percentage: item.Project?.[0]?.percentage || 100,
          billable:
            item.Project?.[0]?.billable !== undefined
              ? item.Project[0].billable
              : true,
          startDate: item.Project?.[0]?.startDate,
          endDate: item.Project?.[0]?.endDate,
          role: item.Project?.[0]?.role,
        });
        formData.role = {}; 
      });
    }

    if (currentProject.projectTeamOther) {
      const otherOptions = currentProject.projectTeamOther.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      data["projectTeam"] = [...(data["projectTeam"] || []), ...otherOptions];
      currentProject.projectTeamOther.forEach((item) => {
        newTableData.push({
          id: item.id,
          name: item.name,
          startDate: item?.startDate,
          endDate: item?.endDate,
          percentage: item?.percentage || 100,
          billable: item?.billable || true,
          role:item.role
        });
      });
    }
    setFormData(data);
    setTableData(newTableData);
  }, [currentProject]);

  useEffect(() => {
    console.log("projects form regions", selectedRegion);
    if (selectedRegion && selectedRegion?._id) {
      dispatch(getAllEmployeeByRegion({ id: selectedRegion?._id })).then(
        (response) => {
          console.log("response from dropdown", response);
          // if (response?.data?.statusCode === 401) {
          //   navigate("/session-expired");
          // }else{
          setEmployeeOptions(
            response?.payload?.map((emp) => {
              return {
                label: `${emp?.FirstName ? emp.FirstName : ""} ${
                  emp?.LastName ? emp.LastName : ""
                }`,
                value: emp._id,
              };
            })
          );
        }
        // }
      );
    }
  }, [selectedRegion]);

  const handleClose = () => {
    setModal(false);
  };

  function uniqByKeepLast(each, key) {
    return [...new Map(each.map((x) => [key(x), x])).values()];
  }

  const handleRoleChange = (selectedOption, index) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      if (index >= 0 && index < updatedTableData.length) {
        updatedTableData[index] = {
          ...updatedTableData[index],
          role: selectedOption ? selectedOption.value : "", // Ensure value is handled properly
        };
      }
      return updatedTableData;
    });
  };
  function MultiSelectDropdown(data, opt, key, index) {
    const selectedRole = tableData[index]?.role || "";
    const optionList = [];

    if (data !== undefined) {
      opt.forEach((each) => {
        each.map((e) => {
          optionList.push({ value: e.name, label: e.name });
          return null; // Add a return statement here
        });
      });
    }

    const uniq_options = uniqByKeepLast(optionList, (o) => o.value);

    return (
      <Select
        components={makeAnimated()}
        theme={customTheme}
        options={uniq_options}
        onChange={
          (selectedOption) => handleRoleChange(selectedOption, index) // Pass index to handleRoleChange
        }
        placeholder="Select"
        isSearchable
        // value={formData.projectTeam[index]?.role} // Ensure value is correctly set
        value={
          selectedRole ? { value: selectedRole, label: selectedRole } : null
        }
        required={true}
        name={key}
        styles={{
          control: (styles) => ({
            ...styles,
            width: "160px",
            backgroundColor: "white",
            "@media (min-width: 1440px) and (max-width: 2560px)": {
              padding: 4,
            },
            "@media (min-width: 2560px)": {
              padding: 18,
              fontSize: 30,
            },
          }),
        }}
      />
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let finalData = {};
    console.log("final data in assign diolagform", finalData);
    console.log("table data in assign diolagform", tableData);
    finalData.projectTeam = tableData
    try {
      await dispatch(
        addProjectAssignees({ ...finalData, _id: currentProject._id })
      );
      setModal(false);
    } catch (error) {
      console.error("Error adding project assignees:", error);
    }
  };
  
  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "orange",
        primary: "green",
      },
    };
  }

  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            minWidth: "60vw",
            maxHeight: "100%",
            minHeight: "60vh",
          },
        }}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>
          <b>Assign To Project</b>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Project Team */}
            <Grid item xs={6}>
              <div className="employee-label-card">
                <label>Project Team</label>
                <CreatableSelect
                  isMulti
                  components={makeAnimated()}
                  theme={customTheme}
                  options={employeeOptions}
                  value={formData?.projectTeam}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, "projectTeam")
                  }
                  className="employee-multi-select"
                  placeholder="Select"
                  // isSearchable
                />
              </div>
            </Grid>
          </Grid>
          {/* Table */}
          <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>StartDate</TableCell>
                  <TableCell>EndDate</TableCell>
                  <TableCell>Percentage</TableCell>
                  <TableCell>Billable</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {MultiSelectDropdown(
                        data,
                        ConfigData.map((each) => each.Role),
                        "role",
                        index
                      )}
                    </TableCell>
                    <TableCell>
                      {" "}
                      <TextField
                        name="startDate"
                        variant="outlined"
                        required
                        type="date"
                        value={
                          row.startDate
                            ? new Date(row.startDate)
                                .toISOString()
                                .split("T")[0]
                            : ""
                        }
                        onChange={(e) =>
                          handleTableChange(index, "startDate", e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="endDate"
                        variant="outlined"
                        type="date"
                        value={
                          row.endDate
                            ? new Date(row.endDate).toISOString().split("T")[0]
                            : ""
                        }
                        onChange={(e) =>
                          handleTableChange(index, "endDate", e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={row.percentage}
                        onChange={(e) =>
                          handleTableChange(index, "percentage", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.billable}
                        onChange={() =>
                          handleTableChange(index, "billable", !row.billable)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
